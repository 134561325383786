<template>
  <v-footer padless>
    <v-card
      flat
      color="#333333"
      tile
      width="100%"
    >
      <v-container fluid>
        <div
          class="d-flex justify-space-between align-center"
          :class="{'flex-column': mobile}"
        >
          <p class="white-txt mb-0 body-1 font-weight-regular">
            БЕЛТАМОЖСЕРВИС
          </p>
          <p class="white-txt mb-0 body-1 font-weight-regular">
            г. Минск, улица Лермонтова, 27
          </p>
          <v-btn
            dark
            text
            @click="getDocument"
          >
            Руководство пользователя
          </v-btn>
          <download-sign-service />
          <p class="white-txt mb-0 body-1 font-weight-regular">
            +375 33 911 11 97
          </p>
        </div>
      </v-container>
    </v-card>
  </v-footer>
</template>
<script>
import axios from 'axios'
import { AUTH } from '@/http-routes'
import {downloadFile, getFilenameFromContentDisposition} from '@/helpers/documents'
import DownloadSignService from "@/components/shared/download-sign-service.vue";
export default {
  components: {DownloadSignService},
  computed:{
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods:{
    getDocument(){
      axios.get(`${AUTH}user/guide`, {responseType:'blob'}).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({
          data:res.data,
          type:'application/pdf',
          name
        })
      }).catch(() => this.$error("Не удалось скачать файл"))
    }
  }
}
</script>

