<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-avatar
        :color="avatarClass"
        size="40"
        v-bind="attrs"
        v-on="on"
      >
        <span class="white--text ">{{ initials }}</span>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item>ФИО <span class="mx-5 font-weight-bold">{{ fullName || 'Отсутствует' }}</span></v-list-item>
      <v-list-item v-if="paymentUser.email">
        E-mail <span class="mx-5 font-weight-bold">{{ paymentUser.email }}</span>
      </v-list-item>
      <v-list-item v-if="paymentUser.phone">
        Телефон <span class="mx-5 font-weight-bold">{{ paymentUser.phone }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {getInitialsString} from "@/helpers/text";

export default {
  props: {
    paymentUserId: {
      type: Number,
      required: true
    },
    paymentUser: {
      type: Object,
      required: true
    },
    currentUserId: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      required: true,
      default: null
    },
  },
  computed: {
    fullName() {
      const {first_name, middle_name, last_name} = this.paymentUser
      return getInitialsString({first_name, middle_name, last_name})
    },
    initials() {
      const {first_name, last_name} = this.paymentUser
      return `${first_name.charAt(0)}${last_name.charAt(0)}`
    },
    avatarClass() {
      return this.paymentUserId === this.currentUserId ? "red" : "blue lighten-3"
    },
  }
}
</script>