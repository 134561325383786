
import axios from 'axios'
import { TNVED, CATALOGS } from '@/http-routes';
import router from '../router/index.js'
import {getState, patchState} from '../helpers/indexeddb'

const tnved = {
  namespaced: true,
  state: {
    dataBase: [],
    codePage: null,
    noTarifs: null,
    intellectual: null,
    special: null,
    preferencies: null,
    examples: null,
    notes: null,
    explanations: null,
    saveSearch: ''
  },
  mutations: {
    SET_TNVED(state, dataBase){
      state.dataBase = dataBase
    },
    SET_SINGLE_CODE(state, codePage){
      state.codePage = codePage
    },
    SET_NOTARIFS(state, noTarifs){
      state.noTarifs = noTarifs
    },
    SET_INTELLECTUAL(state, intellectual){
      state.intellectual = intellectual
    },
    SET_SPECIAL(state, special){
      state.special = special
    },
    SET_PREFS(state, preferencies){
      state.preferencies = preferencies
    },
    SET_EXAMPLES(state, examples){
      state.examples = examples
    },
    SET_NOTES(state, notes){
      state.notes = notes
    },
    SET_EXPL(state, expl){
      state.explanations = expl
    },
    SET_OPEN_EXAMPLE(state, opened){
      state.examples.tnVedVariantNameWithGroups[opened.index].tnVedVariantNames = opened.codes
    },
    SET_HIDE_EXAMPLE(state, index){
      var hiden = state.examples.tnVedVariantNameWithGroups[index].tnVedVariantNames.slice(0, 5)
      state.examples.tnVedVariantNameWithGroups[index].tnVedVariantNames = hiden

    },
    SAVE_SEARCH(state, search){
      state.saveSearch = search
    }
  },
  actions: {
    loadTnved({commit, dispatch}){
      let updated;
      return dispatch('catalogs/checkUpdates', null , {root:true})
        .then(res => {
          updated = res.data.nsiTnVeds
          return getState(updated)
        })
        .then((data)=> {
          if(!data){
            return dispatch('fillFromDatabase', {updated})
          }else{
            commit("SET_TNVED", data)
            return Promise.resolve(data)
          }
        })
    },
    fillFromDatabase({commit}, {updated}){
      return axios
        .get(`${TNVED}api/read/nsiTnVedsWithEtt`)
        .then(res =>{
          patchState({updated, value:res.data})
          commit("SET_TNVED", res.data)
          return res
        })
    },
    async loadExamples({commit}, url){
      await axios
        .get(url)
        .then(res =>{
          commit("SET_EXAMPLES", res.data)
        })
    },
    async loadAllExamples({commit}, allParams){
      let url = ''

      if(allParams.txt === ''){
        url = `${TNVED}api/read/nsiTnVedVariantNames?code=${allParams.code}`
      }else if(allParams.code === ''){
        url = `${TNVED}api/read/nsiTnVedVariantNames?description=${allParams.txt}`
      }else{
        url = `${TNVED}api/read/nsiTnVedVariantNames?description=${allParams.txt}&code=${allParams.code}`
      }

      await axios.get(url)
        .then(res =>{
          let opened = {
            codes: res.data,
            index: allParams.index
          }
          commit("SET_OPEN_EXAMPLE", opened)
        })
    },
    hideAllExamples({commit}, allParams){
      commit("SET_HIDE_EXAMPLE", allParams.index)
    },
    async loadNotes({commit}, url){
      await axios
        .get(url)
        .then(res =>{
                
          commit("SET_NOTES", res.data)
        })
    },
    async loadExplanations({commit}, url){
      await axios
        .get(url)
        .then(res =>{
          commit("SET_EXPL", res.data)
        })
    },
    // codepage
    loadSingleCode({commit}, codeParams){
      const code = codeParams.code
      axios
        .get(`${TNVED}api/read/nsiTarifs?code=${codeParams.code}&direction=${codeParams.procedure}&date=${codeParams.date}`)
        .then(res =>{            
          if(res.data.commonTarif == null && res.data.tnVed == null){
            commit("SET_SINGLE_CODE", 'Notfound')
          }else{
            commit("SET_SINGLE_CODE", res.data)
          }                
        })
        .catch(() =>{
          router.push(`/tnvederror/${code}`)
        })
    },
    // not redirect
    getCodeInfo({commit}, codeParams){
      axios
        .get(`${TNVED}api/read/nsiTarifs?code=${codeParams.code}&direction=${codeParams.procedure}&date=${codeParams.date}`)
        .then(res =>{
                
          if(res.data.commonTarif == null && res.data.tnVed == null){
            commit("SET_SINGLE_CODE", 'Notfound')
          }else{
            commit("SET_SINGLE_CODE", res.data)
          }                
        })
        .catch(err =>{
          console.log(err)
        })
    },
    getSingleCode(_, codeParams){
      return axios
        .get(`${TNVED}api/read/nsiTarifs?code=${codeParams.code}&direction=${codeParams.procedure}&date=${codeParams.date}`)
    },
    loadNoTariffs({commit}, codeParams){
      axios
        .get(`${TNVED}api/read/nsiNoTarifLists?code=${codeParams.code}&direction=${codeParams.procedure}&date=${codeParams.date}`)
        .then(res =>{
          commit("SET_NOTARIFS", res.data)
        })
    },
    loadIntellectual({commit}, codeParams){
      axios
        .get(`${TNVED}api/read/nsiIntellectualProperties?code=${codeParams.code}`)
        .then(res =>{
          commit("SET_INTELLECTUAL", res.data)
        })
    },
    loadSpecial({commit}, codeParams){
      axios.get(`${TNVED}api/read/nsiSpecialRequirements?code=${codeParams.code}&date=${codeParams.date}&direction=${codeParams.procedure}`)
        .then(res =>{
          commit("SET_SPECIAL", res.data)
        })
    },
    loadPreferencies({commit}, codeParams){
      axios.get(`${TNVED}api/read/nsiSpecificLists?code=${codeParams.code}&date=${codeParams.date}&direction=${codeParams.procedure}`)
        .then(res =>{
          commit("SET_PREFS", res.data)
        })
    },
    saveSearch({commit}, search){
      commit("SAVE_SEARCH", search)
    },
    fetchAddInfoByCode(_, code){
      return axios.get(`${CATALOGS}getAddInfoByCode?code=${code}`)
    },
    fetchSpecificMeasureUnit(_, code){
      return axios.get(`${CATALOGS}getSpecificMeasureUnitByTnVed?tnVed=${code}`)
    },
    checkValidTnVed(_, {code, declarationDate}){
      return axios.get(`${CATALOGS}nsiTnVeds/checkValidTnVed?tnVed=${code}&declarationDate=${declarationDate}`)
    }
  },
  getters: {
    getDataBase(state){
      return state.dataBase
    },
    getCodePage(state){
      return state.codePage
    },
    getNoTarifs(state){
      return state.noTarifs
    },
    getIntellectual(state){
      return state.intellectual
    },
    getSpecial(state){
      return state.special
    },
    getPreferencies(state){
      return state.preferencies
    },
    getExamples(state){
      return state.examples
    },
    getNotes(state){
      return state.notes
    },
    getExplanations(state){
      return state.explanations
    },
    getSaveSearch(state){
      return state.saveSearch
    }
  }
}

export default tnved


