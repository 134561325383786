<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        background-color="#EDEDED"
        class="elevation-0 my-0 rounded-r-0"
        x-large
        dark
        v-bind="attrs"
        height="auto"
        min-width="50px"
        v-on="on"
      >
        <span>{{ selectedConditionName }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(condition, idx) in conditions"
        :key="'place-' + idx"
        @click="$emit('select', condition.value)"
      >
        <v-list-item-title>{{ condition.description }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props:{
    conditions:{
      type:Array,
      required:true
    },
    value:{
      type:String,
      required:true
    }
  },
  computed:{
    selectedConditionName(){
      return this.conditions.find(c => c.value === this.value)?.name
    }
  }

}
</script>