export const OWNER_NAME = "ownerName"
export const PAYMENT_DOC_NUMBER = "paymentDocNumber"

export const CONDITIONS = [
  {
    name:"Владелец",
    description:"Владелец: УНП, наименование, личный номер, ФИО",
    value:OWNER_NAME
  },
  {
    name:"Номер",
    description:"Номер: номер платежного поручения",
    value:PAYMENT_DOC_NUMBER
  },
]


