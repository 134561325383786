function deleteNulls(value) {
  if (value[0] === "0" && value[1] === "0") {
    const num = Number.parseInt(value.substr(2))
    let prefix = ""
    if (num >= 0 && num <= 50) {
      prefix = "20"
    } else if (num >= 51 && num <= 99) {
      prefix = "19"
    }
    return [prefix, value.substr(2)].join("")
  } else {
    return value
  }

}

function debounce(callback, timeout = 500) {
  let timer;
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => callback(...args), timeout)
  }
}

function setChangesTracker(selector) {
  const MutationObserverEntity = window.MutationObserver || window.WebKitMutationObserver;

  const els = document.querySelectorAll(selector);
  const elements = [...els];

  let observer = {}
  elements.forEach(el => {
    observer = new MutationObserverEntity(function (mutations) {
      if (mutations[0].attributeName == "value") {
        const event = new Event("change");
        el.dispatchEvent(event);
      }
    });
  })


  elements.forEach((el) => {
    observer.observe(el, {
      attributes: true,
    });
  });
}

// Удаляем последнюю запятую или дефис
function trimRange(value) {
  return value.replace(/,\s*$/, '').replace(/-\s*$/, '')
}

function isGoodsRangeValid(range) {
  if (range === "") {
    return range
  } else {
    const regexp = /^[0-9,-]+$/;
    const regexp2 = /(,-)|(-,)|(,,)|(--)/
    const value = range ? range.replace(" ", "") : "";
    return regexp.test(value) && !regexp2.test(value) ? value : false
  }
}

function getPreparedNumbers(numbers){
  return numbers.map(number => {
    return !(["", null].includes(number) && Number.parseInt(number) < 0 || Number.isNaN(Number.parseInt(number)))
  })
}
// Проверка на наличие в диапазоне промежутков где начальное значение больше чем конечное: 66-55
function isCorrectStartEnd(range) {
  if (['', null].includes(range)) return true; // Пустое значение валидно
  const parts = range !== null ? range.split(',') : []

  const [ranges, numbers] = partition(parts, (i) => i.indexOf('-') !== -1)
  const preparedNumbers = getPreparedNumbers(numbers)
  if (preparedNumbers.includes(false)) return false;

  const result = ranges.map(element => {
    const [start, end] = element.split('-').map(i => Number.parseInt(i))
    return start < end
  })
  return !result.includes(false)
}

function partition(arr, predicate) {
  return arr.reduce(
    function (partitionsAccumulator, arrElement, i, arr) {
      if (predicate(arrElement, i, arr)) {
        partitionsAccumulator[0].push(arrElement);
      } else {
        partitionsAccumulator[1].push(arrElement);
      }
      return partitionsAccumulator;
    },
    [[], []]
  );
}

export function getIdsFromRange(){
  this.range = trimRange(this.range)
  if(!isCorrectStartEnd(this.range)){
    return this.$error("Неверный диапазон")
  }
  const idxs = rangeToArray(this.range, true)
  const values = idxs.map(idx => {
    return this.wares[idx]?.id
  })
  if(values.length > this.wares.length){
    this.range = ""
    return this.$error('Неверно указан диапазон. Кол-во выбранных товаров превышает имеющееся кол-во.')
  }
  this.selectedWares = values
}



function rangeToArray(range, fromZero = false) {
  if (!range) {
    return []
  }
  const parts = range.split(',')
  const [ranges, individual] = partition(parts, (i) => i.indexOf('-') !== -1)
  const single = fromZero ? individual.map(i => i - 1) : individual
  const completeRanges = fillEmptySpaceInRanges(ranges, fromZero)
  const total = [...single, ...completeRanges]
  const totalNumeric = total.map(i => Number.parseInt(i)).sort((a, b) => a - b)
  return [...new Set(totalNumeric)]
}

function getPreparedRange(range){
  return range.split('-').map(i => Number.parseInt(i)).sort(function (a, b) {
    return a - b
  })
}
function fillEmptySpaceInRanges(ranges, fromZero){
  const completeRange = []
  ranges.forEach(range => {
    const [start, end] = getPreparedRange(range)
    for (let i = start; i <= end; i++) {
      completeRange.push(fromZero ? i - 1 : i)
    }
  })
  return completeRange
}

// Фунция для объединения полей артикулов, для последующей подстановки в "Описание товара"
function waresToDescription(items) {
  let result = ""
  items.forEach((item, index) => {
    const {
      description,
      article,
      trade_mark,
      ware_quantity,
      measure_unit_letter,
    } = item;

    const main = [
      description,
      article,
      trade_mark,
    ].filter(i => ![null, ''].includes(i)).join(" ")

    const amount = [
      ware_quantity,
      measure_unit_letter
    ].filter(i => ![null, ''].includes(i)).join(" ")

    const element = joinDescriptionWithQuantity(main, amount)

    if (element) {
      result += index === items.length - 1 ? element : element + '; '
    }
  });

  result = result.trim()
  result = removeSemicolon(result)
  return result.toUpperCase()
}
function joinDescriptionWithQuantity(main, amount) {
  return main && amount ? [main, amount].join(" - ") : (main || amount)
}
function removeSemicolon(text){
  return text.charAt(text.length - 1) === ';' ? text.slice(0, -1) : text
}
const customVinMask = {
  mask: 'CCCCCCCCCCCCCCCCC',
  tokens: {
    'C': {
      pattern: /(?![QIOqio])[0-9a-zA-Z]/,
      transform: function(v) {
        return v.toLocaleUpperCase();
      }
    }
  }
}


export {
  debounce,
  waresToDescription,
  deleteNulls,
  setChangesTracker,
  isGoodsRangeValid,
  partition,
  rangeToArray,
  isCorrectStartEnd,
  trimRange,
  customVinMask
};
