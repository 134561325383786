export default {
  props:{
    id:{
      required:true,
      type: Number,
    },
    search: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    field: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    zIndex:{
      type: Number,
      default: 10
    },
    customEventName:{
      validator:(v) => typeof v === "string" || v === null,
      default: null
    }
  },
}