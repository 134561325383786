import axios from 'axios'
import {AUTH} from '@/http-routes'

const billing = {
  namespaced: true,
  state: {
    // services: [
    //     {
    //         name: 'API ТН ВЭД (20 запросов на месяц)',
    //         amount: '20',
    //         period: '1 месяц',
    //         date: '01.12.2021',
    //         price: '90 BYN',
    //         priceFor: '4,5 BYN за 1 шт.',
    //         isActive: false
    //     },
    //     {
    //         name: 'API ТН ВЭД (300 запросов на 6 месяцев)',
    //         amount: '300',
    //         period: '6 месяцев',
    //         date: '01.12.2021',
    //         price: '300 BYN',
    //         priceFor: '1 BYN за 1 шт.',
    //         isActive: true
    //     },
    //     {
    //         name: 'API ТН ВЭД (300 запросов на 6 месяцев)',
    //         amount: '300',
    //         period: '6 месяцев',
    //         date: '01.12.2021',
    //         price: '300 BYN',
    //         priceFor: '1 BYN за 1 шт.',
    //         isActive: true
    //     },
    // ],
    services: [],
    companies: [],
    singleCompany: null,
    admins: [] ,
    servPackages: [],
    servicesForBuy: [],
    availablePackages: [],
    balance: null,
    activeInvoices: [],
    billingHistory: [],
    allSellers: []
  },
  mutations: {
    SET_COMPANIES(state, companies){
      state.companies = companies
    },
    SET_SINGLE(state, singleCompany){
      state.singleCompany = singleCompany
    },
    SET_ADMINS(state, admins){
      state.admins = admins
    },
    SET_SERVICES(state, services){
      state.services = services
    },
    SET_PACKAGES(state, servPackages){
      state.servPackages = servPackages
    },
    SET_SERVICES_FOR_BUY(state, servicesForBuy){
      state.servicesForBuy = servicesForBuy
    },
    SET_PURCHASED_PACKAGES(state, availablePackages){
      state.availablePackages = availablePackages
    },
    SET_BALANCE(state, balance){
      state.balance = balance
    },
    SET_ACTIVE_INVOICES(state, activeInvoices){
      state.activeInvoices = activeInvoices
    },
    SET_BILLING_HISTORY(state, billingHistory){
      state.billingHistory = billingHistory
    },
    SET_SELLERS(state, allSellers){
      state.allSellers = allSellers
    }
  },
  actions: {
    loadCompanies({commit}, url){
      axios
        .get(url)
        .then(res =>{
          let arr = res.data
          arr.forEach(item =>{
            item.selected = false
          })
          commit("SET_COMPANIES", arr)
        })
    },
    loadSellers({commit}){
      axios
        .get(`${AUTH}user/getAllSellers`)
        .then(res =>{
          commit("SET_SELLERS", res.data)
        })
    },
    loadSingleCompany({commit}, id){
      axios
        .get(`${AUTH}division/getById?id=${id}`)
        .then(res =>{
          commit("SET_SINGLE", res.data)
        })
    },
    loadAdmins({commit}, id){
      axios
        .get(`${AUTH}division/getOrganizationAdmins?id=${id}`)
        .then(res =>{
          commit("SET_ADMINS", res.data)
        })
    },
    loadServices({commit}){
      axios.get(`${AUTH}serviceType/getAllServiceTypesForSeller`)
        .then(res =>{
          commit("SET_SERVICES", res.data)
        })
    },
    loadServPackages({commit}){
      axios.get(`${AUTH}serviceType/getAllPackagesForSeller`)
        .then(res =>{
          let arr = [...res.data]
          arr.sort((a, b) =>{
            // return b.dOff - !a.dOff
            return (a.d_off===null)-(b.d_off===null)
          })
          commit("SET_PACKAGES", arr)
        })
    },
    addNewPackage({dispatch}, servPackage){
      return axios.post(`${AUTH}serviceType/save`, servPackage)
        .then((res) =>{
          dispatch('loadServPackages')
          return res
        })
    },
    loadPackagesForBuyer({commit}){
      axios.get(`${AUTH}serviceType/getAllPackagesForBuyer`)
        .then(res =>{
          commit("SET_SERVICES_FOR_BUY", res.data.services)
        })
    },
    async buyPackage({dispatch}, obj){
      try {
        await axios.post(`${AUTH}divisionService/buy?packageId=${obj.pack}&divisionId=${obj.division}`, {})
        dispatch('loadPackagesForBuyer') // некупленные
        dispatch('loadPurchasedPackages', obj.division) //купленные
        dispatch('loadBallance', obj.division) //обновить баланс
      }
      catch (error) {
        // return error.response.data
        return error
      }

    },
    loadPurchasedPackages({commit}, divisionId){
      axios.get(`${AUTH}divisionService/getPurchasedPackages?divisionId=${divisionId}`)
        .then(res =>{
          commit("SET_PURCHASED_PACKAGES", res.data.packages)
        })
    },
    async loadBallance({commit}, divisionId){
      await axios.get(`${AUTH}accountBalance/getBalanceSumByDivisionId?divisionId=${divisionId}`)
        .then(res => {
          commit("SET_BALANCE", res.data)
        })
    },
    loadInvoices({commit}, divisionId){
      // &status=false' - удалены
      // &status=true' - оплачены
      axios.get(`${AUTH}divisionInvoice/getByDivisionIdAndStatus?divisionId=${divisionId}`)
        .then(res =>{
          commit('SET_ACTIVE_INVOICES', res.data)
        })
    },
    loadHistory({commit, state}, divisionId){
      state.billingHistory = []
      axios.get(`${AUTH}accountTransaction/getByDivisionId?divisionId=${divisionId}`)
        .then(res =>{
          commit('SET_BILLING_HISTORY', res.data)
        })
    },
    loadHistoryDates({commit, state}, obj){
      state.billingHistory = []
      axios.get(`${AUTH}accountTransaction/getByDivisionId?divisionId=${obj.id}&dateFrom=${obj.dateFrom}&dateTo=${obj.dateTo}`)
        .then(res =>{
          commit('SET_BILLING_HISTORY', res.data)
        })
    },
    // раюота с действиями
    loadSelectedAction({commit}, url){
      axios.get(url)
        .then(res =>{
          let arr = res.data
          arr.forEach(item =>{
            item.selected = false
          })
          console.log(arr)
          commit("SET_COMPANIES", arr)
        })
    }
  },
  getters: {
    getServices(state){
      return state.services
    },
    getCompanies(state){
      return state.companies
    },
    getSingleCompany(state){
      return state.singleCompany
    },
    getAdmins(state){
      return state.admins
    },
    getServPackages(state){
      return state.servPackages
    },
    getServicesForBuy(state){
      return state.servicesForBuy
    },
    getAvailablePackages(state){
      return state.availablePackages
    },
    getBalance(state){
      return state.balance
    },
    getActiveInvoices(state){
      return state.activeInvoices
    },
    getBillingHistory(state){
      return state.billingHistory
    },
    getAllSellers(state){
      return state.allSellers
    }
  }
}

export default billing


