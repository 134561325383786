const routes = [
  {
    path: "/epi/:id",
    name: "epiMain",
    component: () => import('@/views/epi/epi-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  }
];

export default routes;
