<template>
  <section class="pt-10">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card v-if="step1">
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Уведомление
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Для того, чтобы зарегестирировать новую компанию, Вам необходимо быть уполномеченным на создание.
          Приложите следующие документы: <strong>Свидетельство о государственной регистрации,  Доверенность или приказ для директора, 4 первых листа Устава.</strong>
          <br>
          Для ИП только <strong>Свидетельство о государственной регистрации.</strong>
          <br><br>

          <strong>Если полномочий на подписание договора у вас нет - выберите “Нет полномочий”, в следующем окне введите e-mail уполномоченного. Ему придет ссылка для регистрации себя и компании.</strong>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            dark
            class="mr-2"
            @click="step1 = false"
          >
            Нет полномочий
          </v-btn>
          <router-link
            tag="a"
            to="/registration-ul"
          >
            <v-btn
              color="#5CB7B1"
              dark
            >
              Есть полномочия
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Уведомление
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Введите e-mail уполномоченного. Ему придет ссылка для регистрации себя и компании.
        </v-card-text>

        <v-form
          ref="form"
          class="pa-6 pt-0 pb-10"
          @submit.prevent
        >
          <label for="">E-mail</label>
          <v-text-field
            v-model="email"
            color="#5cb7b1"
            background-color="#EDEDED"
            solo
            hide-details="auto"
            flat
            placeholder="example@mail.by"
            :rules="emailRules"
          />
        </v-form>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="step1 = true"
          >
            Назад
          </v-btn>
          <v-btn
            color="#5CB7B1"
            dark
            :loading="load"
            @click="submit"
          >
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <div class="text-h2 font-weight-bold">
            Web Declarant
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <v-card
            elevation="2"
            class="mb-6 text-center px-10 py-10"
          >
            <div
              class="text-h5 font-weight-bold mb-3"
              style="color:#5CB7B1;"
            >
              Зарегистрировать новую компанию
            </div>
            <img src="../../assets/img/addnew.svg">
            <v-btn
              color="#333"
              dark
              x-large
              block
              class="mt-6"
              @click="openDialog"
            >
              + Добавить
            </v-btn>

            <!-- <div class="pa-7 text-center"  @click="openDialog">
                           <img src="../../assets/img/division.svg" class="divivsion-btn" alt="">
                        </div> -->
          </v-card>
        </v-col>
        <!-- компании -->
        <!-- <v-col
          cols="12"
          lg="4"
        >
          <v-card
            elevation="2"
            class="mb-6 pb-2 px-2"
            color="#5CB7B1"
          >
            <div class="pa-8 text-center">
              <img
                src="../../assets/img/demo.svg"
                style="width:100%;margin-bottom:14px;"
              >
              <div class="text-body-2 text-left white--text">
                Прежде, чем преобрести наш продукт, Вы можете попробовать <strong>ДемоВерсию</strong>. <br> В ДемоВерсии доступен весь функционал нашего продукта, кроме, фактической отправки деклараций.
              </div>
              <v-btn
                block
                color="#333"
                dark
                x-large
                class="mt-5"
              >
                Попробовать
              </v-btn>
            </div>
          </v-card>
        </v-col> -->

        <v-col
          v-if="divisions.length && dvsn && !dvsn.customsAgentClient"
          cols="12"
          lg="4"
        >
          <v-card
            elevation="2"
            class="mb-6 text-center px-10 py-10"
          >
            <div
              class="text-h5 font-weight-bold mb-2"
              style="color:#5CB7B1;"
            >
              Заказать услугу таможенного оформления
            </div>
            <img
              src="../../assets/img/cclearence.webp"
              style="width:90%;margin-bottom:1px;"
            >
            <v-btn
              block
              color="#333"
              dark
              x-large
              class="mt-6"
              :to="`/registration-client/${user.user.activeDivisionId}`"
            >
              Заказать
            </v-btn>

            <!-- <div class="pa-7 text-center"  @click="openDialog">
                           <img src="../../assets/img/division.svg" class="divivsion-btn" alt="">
                        </div> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import {AUTH} from '@/http-routes';
import { mapGetters } from 'vuex';

export default {
  data(){
    return{
      dialog: false,
      step1: true,
      load: false,
      loadCompany: true,
      email: '',
      emailRules: [
        v => !!v || 'Поле должно быть заполнено',
      ]
    }
  },
  computed: {
    ...mapGetters({
      divisions: 'auth/getDivisions',
      user: 'auth/getUser',
      dvsn: 'auth/getSingleDivision',
    })
  },
  watch: {
    user:{
      handler(nv){
        const division = nv?.user?.activeDivisionId
        if(division){
          this.$store.dispatch('auth/loadSingleDivisions',division)
        }
      },
      deep:true,
      immediate:true
    },
    dialog(){
      if(!this.dialog){
        this.step1 = true
      }
    }
  },
  // created(){
  //     this.$store.dispatch('auth/loadDivisions').then(()=>{
  //         this.loadCompany = false
  //     })
  // },
  methods: {
    openDialog(){
      if(!this.user){
        this.$router.replace("/login");
      }else{
        this.dialog = true
      }
    },
    submit(){
      if(this.$refs.form.validate()){
        const headers = {
          'Authorization': `Bearer ${this.user.jwtToken}`
        }
        this.load = true
        axios
          .post(`${AUTH}division/designeePerson?email=${this.email}`, {}, {headers} )
          .then(() =>{
            this.load = false
            this.dialog = false
          })
          .catch(() =>{
            this.load = false
            throw 'Unable to fetch current user'
          })
      }
    }
  }
}
</script>

<style scoped>
.divivsion-btn{
    cursor: pointer;
    transition: all .3s ease;
    height: 133px;
    margin: 30px 0;
}
.divivsion-btn:hover{
    opacity: .8;
}
</style>
