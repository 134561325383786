<template>
  <div>
    <section id="usual">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="12"
          >
            <!-- <v-select v-model="chanel" :items="chanels" item-value="value" item-text="txt"
                        text-color="#333" color="#333" solo hide-details="auto" item-color="#EBEBEB"
                        append-icon="mdi-chevron-down" background-color="#EBEBEB" flat 
                        style="max-width: 220px;">
                        </v-select> -->
            <v-chip-group
              v-model="chanel"
              active-class="active-tnved-tag"
              column
              mandatory
            >
              <v-chip
                v-for="(tag, index) in chanels"
                :key="index"
                v-scroll-to="'header'"
                class="mr-5"
                :value="tag.value"
                :to="tag.link"
                style="overflow: visible;"
              >
                {{ tag.txt }}
              </v-chip>
            </v-chip-group>
          </v-col>


          <v-col
            v-if="nsiItems"
            cols="12"
            lg="12"
            class="text-center"
          >
            <div
              v-if="!Object.keys(nsiItems).length"
              class="shadow-box"
            >
              <h4 class="mb-0">
                Новых обновлений нет
              </h4>
            </div>
            <div
              v-else
              class="shadow-box"
            >
              <v-timeline
                v-for="(item, index) in Object.keys(nsiItems)"
                :key="index"
                dense
                clipped
                align-top
              >
                <v-timeline-item
                  fill-dot
                  color="#5cb7b1"
                  large
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-left"
                    >
                      <h2 class="mt-1 mb-0">
                        {{ formateData(item) }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-timeline-item>



                <v-timeline-item
                  v-for="(update, ind) in nsiItems[item]"
                  :key="ind"
                  color="grey"
                  small
                  class="align-center curs-p"
                >
                  <v-row>
                    <v-col cols="12">
                      <router-link
                        tag="a"
                        :to="`/nsi/${update.groupId}`"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-sheet
                            elevation="1"
                            :class="{'hovered-sheet' : hover}"
                            class="px-4 py-0 mb-3 rounded"
                          >
                            <v-row>
                              <v-col lg="3">
                                <v-card-title
                                  class="pa-0 text-left"
                                  style="word-break: inherit;"
                                >
                                  {{ update.groupName }}
                                </v-card-title>
                              </v-col>
                              <v-col
                                v-if="update.updatedRecordsCount"
                                lg="5"
                                class="text-left"
                              >
                                <div class="text-body-2 mt-2">
                                  {{ update.updatedRecordsCount[2] }} удаление
                                  {{ update.updatedRecordsCount[1] }} изменение,
                                  {{ update.updatedRecordsCount[0] }} добавление
                                </div>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-hover>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>

          <v-col
            v-else
            cols="12"
            lg="3"
            class="mx-auto text-center"
          >
            <h4 class="mb-4">
              Загружаем обновления
            </h4>
            <v-progress-linear
              color="#32a1af"
              indeterminate
              rounded
              height="6"
            />
          </v-col>




          <!-- <v-col cols="12" lg="12" class="text-right" v-if="nsiItems.length">
                        <v-btn color="#5cb7b1" dark>Выпустить обновление</v-btn>
                    </v-col> -->
        </v-row>
      </v-container>
    </section>
  </div>
</template>


<script>
import moment from "moment";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      chanels: [{
        txt: 'Новые',
        value: 'PRESENTED',
        link: '/nsinew'
      },
      {
        txt: 'В очереди',
        value: 'CHECKED',
        link: '/nsiline'
      },
      {
        txt: 'Выпущенные',
        value: 'UPDATED',
        link: '/nsi'
      }
      ],
      chanel: 'UPDATED',
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      nsiItems: "nsi/getNsiItems",
      user: "auth/getUser"
    })
  },
  created() {
    if(!this.user.authorities.some(e => e === 'nsi.global.read')){
      this.chanels = [
        {
          txt: 'В очереди',
          value: 'CHECKED',
          link: '/nsiline'
        },
        {
          txt: 'Выпущенные',
          value: 'UPDATED',
          link: '/nsi'
        }
      ]
    }
    this.$store.dispatch("nsi/loadNsi", this.chanel)
  },
  methods: {
    changeChanel(link){
      this.$router.replace(link)
    },
    goToUpdate(id) {
      this.$router.replace(`/nsi/${id}`)
    },
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
    #usual {
        min-height: calc(100vh - 129px);
    }

    a {
        color: #000 !important;
    }
</style>