<template>
  <v-col cols="12">
    <v-text-field
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="() => (searchValue = '')"
    />

    <v-tabs
      v-model="tab"
      class="my-2"
    >
      <v-tab>Характер сделки</v-tab>
      <v-tab>Особенность сделки</v-tab>
      <!-- <v-tab>Вид поставки</v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredCharacters"
              :key="'characters-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredFeatures"
              :key="'features-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-simple-table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in filteredDelivery"
              :key="'delivery-item-' + idx"
            >
              <td
                width="150px"
                @click="select(item.code)"
              >
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item.code
                  }}
                </text-highlight>
              </td>
              <td>
                <div class="light-txt mt-1">
                  <text-highlight :queries="searchValue || ''">
                    {{
                      item.name
                    }}
                  </text-highlight>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item> -->
    </v-tabs-items>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex"
function getPrefix(){
  if(window.location.pathname.startsWith('/dt')){
    return 'DT'
  }else{
    return 'SD'
  }
}

export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
  },
  data: () => ({
    tab: 0,
    searchValue: "",
    charactersItems: [],
    featuresItems: [],
    deliveryItems: [],
  }),
  computed: {
    ...mapGetters({
      characters: `catalogs/getNsiDealCharacters${getPrefix()}`,
      features: `catalogs/getNsiDealFeatures${getPrefix()}`,
      delivery: "catalogs/getNsiTypesOfDelivery",
    }),
    filteredCharacters() {
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 0
      ) {
        return this.charactersItems.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.charactersItems;
    },
    filteredFeatures() {
      if (
        this.searchValue !== null &&
        this.searchValue.length &&
        this.tab === 1
      ) {
        return this.featuresItems.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.featuresItems;
    },
    // filteredDelivery() {
    //   if (
    //     this.searchValue !== null &&
    //     this.searchValue.length &&
    //     this.tab === 2
    //   ) {
    //     return this.deliveryItems.filter(
    //       (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
    //     );
    //   }
    //   return this.deliveryItems;
    // },
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true,
    },
    characters: {
      handler(nv) {
        this.charactersItems = nv.map((i) => {
          return {
            ...i,
            search: `${i.code}-${i.name}`.toLowerCase(),
          };
        });
      },
      deep: true,
      immediate: true,
    },
    features: {
      handler(nv) {
        this.featuresItems = nv.map((i) => {
          return {
            ...i,
            search: `${i.code}-${i.name}`.toLowerCase(),
          };
        });
      },
      deep: true,
      immediate: true,
    },
    delivery: {
      handler(nv) {
        this.deliveryItems = nv.map((i) => {
          return {
            ...i,
            search: `${i.code}-${i.name}`.toLowerCase(),
          };
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.characters.length) {
      this.$store.dispatch(`catalogs/fetchNsiDealCharacters${getPrefix()}`);
    }
    if (!this.features.length) {
      this.$store.dispatch(`catalogs/fetchNsiDealFeatures${getPrefix()}`);
    }
    if (!this.delivery.length) {
      this.$store.dispatch("catalogs/fetchNsiTypesOfDelivery");
    }
  },
  methods: {
    select(code) {
      let catalog = null
      switch(this.tab){
      case 0: catalog = 'deal_operation_code'; ++this.tab; break;
      case 1: catalog = 'deal_feature_code'; this.tab = 0;break;
      // case 2: catalog = 'delivery_type_code'; this.tab = 0;break;
      }
      this.$emit("select", {code, catalog});
    },
  },
};
</script>