const routes = [
  {
    path: "/zvt/:id",
    name: "ZvtMain",
    component: () => import('@/views/zvt/zvt-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]
export default routes;
