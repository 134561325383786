<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="sd-tnved-viewer">
        <tnved-extra
          :item-id="id"
          :search="search"
          :action-date="actionDate"
          @select="closeOnSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import TnvedExtra from "@/components/catalogs/tnved-extra.vue";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {catalogValueSelected} from '@/events/statistics'
import {eventBus} from "@/bus";
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
export default {
  components: {
    TnvedExtra,
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  props: {
    actionDate:{
      required:true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    closeOnSelect(code) {
      const payload = {code, id:this.id, field:this.field}
      if(this.customEventName){
        eventBus.$emit(this.customEventName, payload)
      }else {
        catalogValueSelected.trigger(payload, this.field)
      }
      this.close()
    },
  },
};
</script>
<style scoped>
.sd-tnved-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
