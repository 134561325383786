import { notification } from "@/events/common";

export function setSnackbar(params){
  notification.trigger(params)
}

export function setSuccess(text){
  notification.trigger({
    text,
    color:'green',
    top:false
  })
}

const defaultSettings = {
  top:false,
  timeout:5000
}

export function setError(text = "Ошибка"){
  notification.trigger({
    text,
    color:'red',
    ...defaultSettings
  })
}

export function setInfo(text){
  notification.trigger({
    text,
    color:'blue',
    top:false,
    ...defaultSettings
  })
}

export function setWarning(text){
  notification.trigger({
    text,
    color:'orange',
    top:false,
    ...defaultSettings
  })
}
