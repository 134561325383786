import store from "../store";

export function checkAuthority(string){
  if(string){
    const authorities = store.getters['auth/getAuthorities']
    return authorities.includes(string)
  }
  return false

}

export const isAllowed = (rules = []) => { // Принимает массив обязательных к наличию правил
  const res = rules.map(permission => checkAuthority(permission))
  return !res.includes(false)
} 
