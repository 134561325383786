<template>
  <v-card
    elevation="2"
    class="mb-2"
  >
    <v-toolbar
      color="#fff"
      flat
    >
      <v-card-title
        class="text-body-1 font-weight-medium"
        color="black"
      >
        {{ getParent.name }} <span class="text-body-2 font-weight-regular"> ({{ card.name }})</span>
      </v-card-title>
    </v-toolbar>
    <v-divider />
    <div class="pa-7">
      <p style="font-size:10px;min-height:80px;">
        {{ getParent.description }}
      </p>
      <v-row>
        <v-col
          cols="12"
          lg="5"
        >
          <div class="text-body-2">
            Количество
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <div class="text-body-2 font-weight-bold">
            {{ card.for_num }} шт.
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
        >
          <div class="text-body-2">
            Период
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <div class="text-body-2 font-weight-bold">
            {{ card.for_time }} {{ getTimeType }}
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
        >
          <div class="text-body-2">
            Дата активации
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <div
            class="text-body-2 font-weight-bold"
            :class="getDateColor()"
          >
            {{ formatDate(card.d_on) }}
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
        >
          <div class="text-body-2">
            Цена
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <div
            style="color:#5cb7b1;"
            class="text-h5 font-weight-bold mb-0"
          >
            {{ card.cost.toLocaleString() }} BYN
          </div>
          <p
            style="font-size:10px;"
            class="mb-6"
          >
            {{ card.cost_for_one }}
          </p>
        </v-col>
      </v-row>
      <v-btn
        v-if="card.d_off"
        dark
        block
        x-large
        color="#CE1B0E"
        type="submit"
      >
        Активен до {{ formatDate(card.d_off) }}
      </v-btn>
      <v-btn
        v-else
        dark
        block
        x-large
        color="#333333"
        type="submit"
        outlined
        @click="dialog = true"
      >
        Завершить действие
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      width="360"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Завершение пакета
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          <label for="">Выберите дату окончания пакета</label>
          <v-text-field
            v-model="dateStop"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            class="mb-5"
            append-icon="mdi-calendar-blank-outline"
            type="date"
            outlined
            dense
            @change="deleteNulls($event, 'dateStop')"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false, dateStop = '' "
          >
            Отмена
          </v-btn>
          <v-btn
            color="#333"
            dark
            @click="updateDateOff"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import formatDate from '@/mixins/format-date.js'
import moment from 'moment'
import {camelCaseObject} from '@/helpers/objects'
export default {
  mixins: [formatDate],
  props: {
    services: {
      required: true,
      type: Array
    },
    card: {
      required: true,
      type: Object
    }
  },
  data(){
    return{
      dialog: false,
      dateStop: ''
    }
  },
  computed: {
    getParent() {
      let parent = this.services.find(item => {
        return item.id == this.card.parent_id
      })
      return parent
    },
    getTimeType() {
      if (this.card.for_time_type === 'D') {
        return 'дн.'
      } else if (this.card.for_time_type === 'M') {
        return 'мес.'
      } else {
        return 'г.'
      }
    }
  },
  methods: {
    getDateColor(){
      var a = moment(new Date, 'DD.MM.YYYY');
      var b = moment(this.formatDate(this.card.d_on), 'DD.MM.YYYY');
      var diffDays = b.diff(a, 'days');

      if(diffDays > 0){
        return 'red--text'
      }else{
        return 'black--text'
      }
    },
    updateDateOff(){
      this.card.d_off = this.dateStop
      this.$store.dispatch("billing/addNewPackage", camelCaseObject(this.card)).then(() =>{
        this.dialog = false
      }).catch(()=> {
        this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
      })
    },
    deleteNulls(e, param){
      if(e[0] === '0' && e[1] === '0'){
        this[param] = '20' + this[param].substr(2)
      }else{
        return e
      }
    }
  }
}
</script>
