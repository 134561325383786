import store from "../store/index";

export default {
  methods:{
    checkAccess() {
            
      let actDiv = store.getters["auth/getDivisions"].find(item =>{
        return item.real_division_id == store.getters["auth/getUser"].user.activeDivisionId
      })
      let res = false

      if(!actDiv){
        return
      }
      if(actDiv.role_id == 2 || actDiv.role_id == 6 || actDiv.role_id == 7 || actDiv.role_id == 17 || actDiv.role_id == 18){
        res = true
      }else{
        res = false
      }

      return res
    }
  }
}
