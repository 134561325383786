import axios from "axios";
import {getUrl} from "@/helpers/journal";
function defaultState(){
  return {
    isUserFree:null,
  }
}

export default {
  namespaced:true,
  state: defaultState(),
  mutations:{
    SET_USER_FREE(state, {id, type}){
      typeof state.isUserFree === 'object' ? state.isUserFree = {
        ...state.isUserFree,
        [type]:id
      } : state.isUserFree = {
        [type]:id
      }
    },
    CLEAR_STATE(state){
      Object.assign(state, defaultState())
    },
    UNSET_USER_FREE(state, type) {
      typeof state.isUserFree === 'object' ?
        state.isUserFree = {...state.isUserFree, [type]: null} : state.isUserFree = null

    }
  },
  actions:{
    getFreeApplication({commit, rootState }){
      const userId = rootState?.auth?.user?.user?.id;
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      const url = getUrl(rootState.journal)
      return axios
        .post(`${url}order/freeCashRegister?userId=${userId}&divisionId=${divisionId}`)
        .then((res) => {
          if(res.data){
            commit("SET_USER_FREE", {id:res.data, type:rootState.journal.documentType});
          }
          return res
        })
    },
  },
  getters:{
    getUserFree: s => s.isUserFree,
  }
}
