<template>
  <section
    id="usual"
    class="py-6"
  >
    <v-container v-if="company">
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <router-link
            tag="button"
            to="/billing-companies"
            class="back-btn"
          >
            <img
              src="@/assets/img/arr.svg"
              alt=""
            >Назад
          </router-link>
          <h2 class="mt-4">
            {{ company.divisionName }}
          </h2>
        </v-col>
        <!-- <v-col cols="12" lg="4" class="mt-4">
                    <label for="">Менеджер</label>
                    <div class="d-flex justify-start align-center">
                        <v-select flat background-color="white" v-model="manager" :items="managers"
                        item-color="#32a1af" color="#32a1af" solo hide-details placeholder="Менеджер"
                        clearable outlined dense class="mr-2"/>
                        <v-btn color="#5CB7B1" dark height="38px">
                            Принять
                        </v-btn>
                    </div>
                </v-col> -->
      </v-row>
      <!-- <pre>{{ company }}</pre> -->
      <div class="grey-box">
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <div class="text-h5 font-weight-bold mb-0">
              Контактные данные администраторов
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="text-end"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="#fff"
                  dark
                  text
                  fab
                  small
                  v-on="on"
                >
                  <v-icon
                    color="#5CB7B1"
                    large
                  >
                    mdi-chat-processing
                  </v-icon>
                </v-btn>
              </template>
              Отправить уведомление
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          v-for="(admin, index) in admins"
          :key="index"
        >
          <v-col
            cols="12"
            lg="12"
          >
            <div class="text-h6 font-weight-bold mb-0">
              Администратор {{ index + 1 }}
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Фамилия</label>
            <v-text-field
              v-model="admin.last_name"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Имя</label>
            <v-text-field
              v-model="admin.first_name"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Отчество</label>
            <v-text-field
              v-model="admin.middle_name"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">E-mail</label>
            <v-text-field
              v-model="admin.email"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Номер телефона</label>
            <v-text-field
              v-model="admin.phone"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
        </v-row>
      </div>

      <div class="grey-box">
        <v-row>
          <v-col
            cols="12"
            lg="12"
          >
            <div class="text-h6 font-weight-bold mb-0">
              Номер и дата договора
            </div>
          </v-col>
          <!-- <v-col cols="12" lg="6" class="text-right">

                        <a :href="`${baseUrl}division/getOfferAgreement?divisionId=${id}`">
                            <v-btn color="#5CB7B1" dark small>
                                <v-icon small>mdi-download-outline</v-icon>
                                Скачать договор
                            </v-btn>
                        </a>
                    </v-col> -->
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">№ Договора оферты</label>
            <v-text-field
              v-model="company.docNumber"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Дата договора оферты</label>
            <v-text-field
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :value="formatDate(company.docDate)"
              disabled
            />
          </v-col>
        </v-row>
      </div>

      <div class="grey-box">
        <v-form ref="form1">
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <div class="text-h5 font-weight-bold mb-0">
                Наименование и Юридический адрес
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="text-right"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <a
                    :href="`${authLink}division/downloadDocuments?taxPayerNum=${company.taxPayerNum}`"
                    download
                  >
                    <v-btn
                      v-bind="attrs"
                      color="#fff"
                      dark
                      text
                      fab
                      small
                      v-on="on"
                    >
                      <v-icon
                        color="#5CB7B1"
                        large
                      >
                        mdi-file
                      </v-icon>
                    </v-btn>
                  </a>
                </template>
                Документы
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label for="">УНП</label>
              <v-text-field
                v-model="company.taxPayerNum"
                color="#32a1af"
                background-color="#fff"
                solo
                hide-details="auto"
                flat
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              lg="9"
            >
              <label for="">Наименование</label>
              <v-text-field
                v-model="company.divisionName"
                color="#32a1af"
                disabled
                background-color="#fff"
                solo
                hide-details="auto"
                flat
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label for="">Страна регистрации</label>
              <v-text-field
                v-model="company.countryName"
                color="#32a1af"
                disabled
                background-color="#fff"
                solo
                hide-details="auto"
                flat
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label for="">Почтовый индекс</label>
              <v-text-field
                v-model="company.postalIndex"
                color="#32a1af"
                disabled
                background-color="#fff"
                solo
                hide-details="auto"
                flat
              />
            </v-col>
            <v-col
              v-if="company.divisionType === 'TA'"
              cols="12"
              lg="3"
            >
              <label for="">Номер лицензии таможенного агентства</label>
              <v-text-field
                v-model="company.custAgentDocNum"
                color="#32a1af"
                disabled
                background-color="#fff"
                solo
                hide-details="auto"
                flat
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label for="">ИП</label>
              <v-checkbox
                v-model="company.individualPerson"
                label="Индивидуальный предприниматель"
                color="#333"
                hide-details
                disabled
              />
            </v-col>

            <v-col
              cols="12"
              lg="12"
            >
              <label for="">Адрес (регион, город, номер дома, номер помещения)</label>
              <v-textarea
                v-model="company.fullAddress"
                solo
                flat
                color="#32a1af"
                background-color="#fff"
                hide-details="auto"
                disabled
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div
        v-if="company.customsAgentClient"
        class="grey-box mb-4"
      >
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <div class="text-h6 font-weight-bold mb-0">
              <v-icon
                small
                color="#5CB7B1"
              >
                mdi-check-decagram
              </v-icon> Клиент таможенного представителя
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="text-right"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <a
                  :href="`${authLink}division/downloadCAClientContract?taxPayerNum=${company.taxPayerNum}`"
                  download
                >
                  <v-btn
                    v-bind="attrs"
                    color="#fff"
                    dark
                    text
                    fab
                    small
                    v-on="on"
                  >
                    <v-icon
                      color="#5CB7B1"
                      large
                    >
                      mdi-file
                    </v-icon>
                  </v-btn>
                </a>
              </template>
              Скачать договор
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Номера договора ТА с таможенным представителем</label>
            <v-text-field
              v-model="company.caClientContract"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Дата договора</label>
            <v-text-field
              v-model="company.caClientContractDate"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              class="mb-5"
              append-icon="mdi-calendar-blank-outline"
              type="date"
              disabled
            />
          </v-col>
        </v-row>
      </div>


      <div
        v-if="company.bankAccounts.length"
        class="grey-box mb-4"
      >
        <div class="text-h5 font-weight-bold mb-0">
          Реквизиты банка
        </div>
        <v-form ref="form3">
          <v-row
            v-for="(account, index) in company.bankAccounts"
            :key="index"
          >
            <v-col
              cols="12"
              lg="12"
            >
              <div class="text-body-1 font-weight-bold mb-0">
                Счет №{{ index+1 }}
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <label for="">Код Банка</label>
              <v-text-field
                v-model="account.bic_bank"
                color="#32a1af"
                background-color="#fff"
                solo
                hide-details="auto"
                flat
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              lg="9"
            >
              <label for="">Банк</label>
              <v-text-field
                v-model="account.bank_name"
                color="#32a1af"
                background-color="#fff"
                solo
                hide-details="auto"
                flat
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <label for="">IBAN</label>
              <v-text-field
                v-model="account.account_number"
                v-mask="'BY## AAAA #### #### #### #### ####'"
                color="#32a1af"
                background-color="#fff"
                solo
                hide-details="auto"
                flat
                placeholder="BY## #### #### #### #### #### ####"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <label for="">Счет по умолчанию</label>
              <v-checkbox
                v-model="account.default"
                label="Основной счет"
                color="#333"
                hide-details
                disabled
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div class="justify-end d-flex align-center">
        <div
          v-if="company.verified"
          class="text-body-1 mr-4"
        >
          <v-icon color="#5CB7B1">
            mdi-check-decagram
          </v-icon> Компания одобрена
        </div>
        <v-btn
          v-else
          color="#5CB7B1"
          class="mr-4"
          dark
          large
          @click="verifyCompany(true)"
        >
          <v-icon>mdi-check-decagram</v-icon>
          Одобрить
        </v-btn>

        <v-btn
          v-if="company.verified !== false"
          color="red"
          dark
          large
          @click="verifyCompany(false)"
        >
          Заблокировать
        </v-btn>
        <div
          v-if="company.verified === false"
          class="text-body-1 mr-4"
        >
          <v-icon color="red">
            mdi-close-circle
          </v-icon> Компания заблокирована
        </div>
      </div>
    </v-container>
  </section>
</template>


<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {AUTH} from '@/http-routes'
import formatDate from '@/mixins/format-date.js'

export default {
  mixins: [formatDate],
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data(){
    return{
      authLink: '',
      manager: '',
      managers: ['Вася', 'Лёша', 'Саша'],
    }
  },
  computed: {
    ...mapGetters({
      company: "billing/getSingleCompany",
      admins: "billing/getAdmins",
      user: 'auth/getUser',
    }),
    downloadDocuments(){
      let file = `http://10.128.8.142:7020/gateway/auth/division/downloadDocuments?taxPayerNum=${this.company.taxPayerNum}`
      return file
    },
    downloadCAClientContract() {
      let file = `http://10.128.8.142:7020/gateway/auth/division/downloadCAClientContract?taxPayerNum=${this.company.taxPayerNum}`
      return file
    }
  },
  created(){
    this.authLink = AUTH
    this.$store.dispatch("billing/loadAdmins", this.id)
    this.$store.dispatch("billing/loadSingleCompany", this.id)
  },
  methods: {
    verifyCompany(param){
      // console.log(this.user.user.id)
      // return
      // axios.post(`${AUTH}division/approveDivision?divisionId=${this.id}&sellerId=${this.user.user.id}`)
      axios.post(`${AUTH}division/approveDivision?divisionId=${this.id}&sellerId=${this.user.user.id}&approve=${param}`)
        .then(() =>{
          this.$store.dispatch("billing/loadSingleCompany", this.id)
          let requestUrl = `${AUTH}division/getAllOrganizations`
          this.$store.dispatch('billing/loadCompanies', requestUrl)
        })

    },
    // downloadFiles() {

    //   let anchor = document.createElement("a");
    //   document.body.appendChild(anchor);
    //   let file = `${AUTH}division/downloadDocuments?taxPayerNum=${this.company.taxPayerNum}`;


    //   axios.get(file, {responseType: 'blob'})
    //     .then(response => {
    //       let objectUrl = window.URL.createObjectURL(response.data);

    //       anchor.href = objectUrl;
    //       anchor.download = 'documents.zip';
    //       anchor.click();

    //       window.URL.revokeObjectURL(objectUrl);
    //     })
    // },
    // downloadCAClientContract() {

    //   let anchor = document.createElement("a");
    //   document.body.appendChild(anchor);
    //   let file = `${AUTH}division/downloadCAClientContract?taxPayerNum=${this.company.taxPayerNum}`;

    //   axios.get(file, {responseType: 'blob'})
    //     .then(response => {
    //       let objectUrl = window.URL.createObjectURL(response.data);

    //       anchor.href = objectUrl;
    //       anchor.download = 'documents.zip';
    //       anchor.click();

    //       window.URL.revokeObjectURL(objectUrl);
    //     })
    // },
  }
}
</script>
