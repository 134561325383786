<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Нетарифные меры</h3>
        <div class="body-1 text-right white--text font-weight-medium">
          Обновлено: {{ formateData(noTarifs.maxUpdated) }}
        </div>
      </div>
      <div
        v-if="noTarifs && noTarifs.notarifLists.length"
        class="tnved-slide-box-body"
      >
        <!-- Таможенный сбор -->
        <!-- <pre>{{noTarifs.maxUpdated}}</pre> -->
        <div
          v-for="(notarif, index) in noTarifs.notarifLists"
          :key="index"
          class="grey-box"
        >
          <h4 class="mb-0">
            {{ notarif.longName }}
          </h4>
          <v-divider class="my-4" />
          <div class="white-box mb-5">
            <div
              v-for="(item, id) in notarif.noTarifs"
              :key="id"
              class="d-sm-block d-md-flex d-lg-flex justify-start align-start mb-8"
            >
              <div class="code-txt mr-5">
                <span>{{ item.nomenclatureCode }}</span>
                <div
                  v-if="item.offDate"
                  class="red-date"
                >
                  до {{ formateData(item.offDate) }}
                </div>
              </div>
              <div class="light-txt mt-1">
                {{ item.longName }}
              </div>
              <v-tooltip
                v-if="item.document.name"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>{{ item.document.name }}</span>
              </v-tooltip>
            </div>
          </div>


          <div class="d-flex justify-space-between align-center">
            <v-tooltip
              v-if="notarif.requirements.length"
              max-width="600"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  class="body-1 px-5"
                  text
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                  <span class="text-decoration-underline">Требования</span>
                </div>
              </template>
              <div
                v-for="(req, ind) in notarif.requirements"
                :key="ind"
              >
                <div class="d-flex justify-start align-items-start">
                  <span class="font-weight-bold mr-4">{{ req.code }}</span>
                  <span>{{ req.name }}</span>
                </div>
                <span
                  v-if="ind !== notarif.requirements.length - 1"
                  class="my-2"
                >или</span>
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>

      <div
        v-else
        class="tnved-slide-box-body"
      >
        <p class="grey-txt">
          Нетарифные меры отсутствуют
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    noTarifs: {
      required: true,
      type: Object
    }
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
.code-txt:hover {
    text-decoration: none;
    color: #333;
}
@media (max-width: 512px) {
    .light-txt{
        width: 100%;
    }
}
</style>