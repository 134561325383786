<template>
  <section
    id="rega"
    class="py-15"
  >
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="grey-box text-center">
            <img
              src="../assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold mb-4">
              Регистрация клиента ТА
            </div>

            <v-sheet class="d-flex align-center justify-space-around rounded pa-2">
              <div
                v-for="(item, index) in steps"
                :key="index"
                class="text-center"
              >
                <v-btn
                  class="white--text mr-3 mb-3"
                  fab
                  dark
                  x-small
                  :color="actColor(index)"
                >
                  {{ index+1 }}
                </v-btn>
                <div class="text-body-2">
                  {{ item }}
                </div>
              </div>
            </v-sheet>


            <keep-alive>
              <v-sheet
                v-if="activeStep == 1"
                class="py-10 text-left"
                color="transparent"
              >
                <v-form
                  ref="step1"
                  @submit.prevent
                >
                  <v-row>
                    <v-col
                      cols="12"
                      lg="4"
                      sm="12"
                      offset-lg="4"
                      offset-sm="0"
                    >
                      <label for="">Номера договора с таможенным агентством</label>
                      <v-text-field
                        v-model="docNumber"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        class="mb-4"
                        flat
                        :rules="[
                          () => !!docNumber || 'Поле должно быть заполнено'
                        ]"
                      />

                      <label for="">Дата договора</label>
                      <v-text-field
                        v-model="contractDate"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        class="mb-5"
                        append-icon="mdi-calendar-blank-outline"
                        type="date"
                        :rules="inputRules"
                        @change="contractDate = deleteNulls($event)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="12"
                      class="text-center"
                    >
                      <v-btn
                        type="submit"
                        color="#333"
                        dark
                        x-large
                        :loading="load"
                        @click="goStep2()"
                      >
                        Далее
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>

              <!-- Step 2 -->




              <v-sheet
                v-if="activeStep == 2"
                class="py-10 text-left"
                color="transparent"
              >
                <v-form
                  ref="step3"
                  @submit.prevent
                >
                  <v-row>
                    <v-col
                      cols="12"
                      lg="6"
                      sm="12"
                      offset-lg="3"
                      offset-sm="0"
                    >
                      <label for="">Прикрепить договор с таможенным агентством (все листы)</label>
                      <v-file-input
                        v-model="customerAgentContract"
                        color="#32a1af"
                        solo
                        flat
                        multiple
                        :rules="fileRules"
                        placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      lg="12"
                      class="text-center mt-8"
                    >
                      <v-btn
                        color="#333"
                        dark
                        x-large
                        class="mr-15"
                        @click="activeStep = 1"
                      >
                        Назад
                      </v-btn>
                      <v-btn
                        color="#333"
                        dark
                        x-large
                        type="submit"
                        :loading="load"
                        @click="submitForm"
                      >
                        Зарегистрировать
                      </v-btn>
                      <div
                        v-if="errTxt"
                        class="text-body-2 red--text mt-4"
                      >
                        Произошла ошибка регистрации, попробуйте снова!
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>
            </keep-alive>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
import axios from 'axios'
import {AUTH} from '@/http-routes';
import {mapGetters} from 'vuex'
import { deleteNulls } from "@/helpers/inputs"

export default {
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() => ({
    steps: ['Номер договора с таможенным представителем', 'Подтверждение'],
    activeStep: 1,
    customerAgentContract: [],
    contractDate: '',
    inputRules: [
      v => !!v || 'Обязательное поле'
    ],
    fileRules: [
      v => !!v.length || 'Поле должно быть заполнено',
      files => !files || !files.some(file => file.size > 5e6) ||
                    'Файл не может превышать размер 5 MB!'
    ],
    load: false,
    errTxt: false,
    docNumber: null
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      dvsn: 'auth/getSingleDivision',
    })
  },
  mounted(){
    this.$store.dispatch('auth/loadSingleDivisions', this.id).then(() =>{
      if(this.dvsn.customsAgentClient){
        this.$router.replace(`/new-division/${this.id}`)
      }
    })
  },
  methods: {
    deleteNulls,
    async submitForm() {
      if (this.$refs.step3.validate()) {
        this.load = true

        let obj = {
          id: null,
          clientId: parseInt(this.id),
          contractNumber: this.docNumber,
          contractDate: this.contractDate,
          verified: false
        }

        await axios.post(`${AUTH}customsAgentClient/save`, obj)
          .then(res => {
            console.log(res)
            this.errTxt = false
            this.load = false
          })
          .catch(() => {
            this.errTxt = true
            this.load = false
          })

        var formData = new FormData();

        for (let index = 0; index < this.customerAgentContract.length; index++) {
          formData.append("customerAgentContract", this.customerAgentContract[index]);
        }
        await axios.post(`${AUTH}customsAgentClient/uploadContract/${this.dvsn.taxPayerNum}`, formData)
          .then(res =>{
            console.log(res)
            this.load = false
            this.$router.replace(`/new-division/${this.id}`)
            // this.dialog = true

          })
          .catch(() => {
            this.load = false
          })

      }
    },
    goStep2() {
      if (this.$refs.step1.validate()) {
        this.activeStep = 2

        // this.load = true
        // this.errTxt = false
      }
    },
    actColor(index) {
      if (index + 1 == this.activeStep) {
        return '#5CB7B1'
      } else {
        return '#9E9E9E'
      }
    }
  }
}
</script>

<style scoped>
    .v-radio:last-child {
        margin-bottom: 0 !important;
    }
</style>
