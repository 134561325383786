<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="sd-regions-viewer">
        <regions
          :filter="filter"
          :search="search"
          @select="closeOnSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import Regions from "@/components/catalogs/regions.vue";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { catalogValueSelected } from "@/events/statistics";
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
export default {
  components: {
    Regions,
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  props:{
    filter:{
      required:true,
      type:Object
    },
  },
  methods:{
    closeOnSelect(code){
      this.close()
      catalogValueSelected.trigger({code, field:this.field, id:this.id},this.field)
    }
  },
};
</script>
<style scoped>
.sd-regions-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
