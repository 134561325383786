<template>
  <div>
    <v-snackbar
      v-for="(snackbar,idx) in topSnackbars"
      :key="`snackbar-top-${idx}`"
      :color="snackbar.color"
      :top="snackbar.top"
      :right="snackbar.right"
      :style="getItemMargin(idx, snackbar)"
      :value="true"
      style="white-space:break-spaces"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="white"
          plain
          elevation="0"
          text
          v-bind="attrs"
          @click="remove(snackbar)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-for="(snackbar,idx) in bottomSnackbars"
      :key="`snackbar-bottom-${idx}`"
      :color="snackbar.color"
      :top="snackbar.top"
      :right="snackbar.right"
      :style="getItemMargin(idx, snackbar)"
      :value="true"
      style="white-space:break-spaces"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="white"
          plain
          elevation="0"
          text
          v-bind="attrs"
          @click="remove(snackbar)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { notification } from '@/events/common'
import {generateUUID} from '@/helpers/math'
export default {
  data:() => ({
    items:[],
  }),
  computed:{
    topSnackbars(){
      return this.items.filter(i => i.top === true)
    },
    bottomSnackbars(){
      return this.items.filter(i => i.top !== true)
    }
  },
  created(){
    notification.subscribe(this.onNotification)
  },
  beforeDestroy(){
    notification.unsubscribe()
  },  
  methods:{
    onNotification(params){
      this.addSnackbarItem(params)
    },
    addSnackbarItem({text = "Произошло событие", color = "blue", show = true, top = true, right=false, timeout=2000}){
      const id = generateUUID()
      const item = {
        id,
        value:true,
        color, text, show, top, right, timeout
      }
      this.items.push(item)

      //После отрисовки устанавливаем таймер для удаления
      this.$nextTick(() => {
        setTimeout(() => {
          this.remove(item)
        }, timeout);
      })
    },
    getItemMargin(index, item) {
      if(item.top === true){
        return {
          'margin-top': index *60 + 'px'
        }
      }
      return {
        'margin-bottom': index *60 + 'px'
      }
    },
    remove(item){
      const index = this.items.indexOf(item)
      return this.items.splice(index, 1)
    }
  },
};
</script>