<template>
  <div>
    <component
      :is="catalogs[selected].component"
      :id="id"
      :show.sync="show"
      :search="search"
      :field="field"
      :filter="filter"
      :action-date="actionDate"
      :z-index="zIndex"
      :custom-event-name="customEventName"
    />
  </div>
</template>
<script>

import ModalTNVED from "@/components/catalogs/modals/modal-tnved.vue";
import ModalExtraTnved from "@/components/catalogs/modals/modal-extra-tnved.vue";
import ModalDocumentTypes from "@/components/catalogs/modals/modal-document-types.vue";
import ModalDealTypes from "@/components/catalogs/modals/modal-deal-types.vue";
import ModalCurrencies from "@/components/catalogs/modals/modal-currencies.vue";
import ModalCountries from "@/components/catalogs/modals/modal-countries.vue";
import ModalRegions from "@/components/catalogs/modals/modal-regions.vue";
import ModalCorrectionReason from "@/components/catalogs/modals/modal-correction-reason.vue";
import ModalCancellationReason from "@/components/catalogs/modals/modal-cancellation-reason.vue";
import ModalIncoterms from "@/components/catalogs/modals/modal-incoterms.vue";
import { showCatalog } from "@/events/statistics";

export default {
  data:() => ({
    show:false,
    zIndex:10,
    field:null,
    id: -1,
    search:"",
    selected:'countries',
    customEventName:null,
    actionDate:null,
    filter:{},
    catalogs:{
      cancellation_reason:{
        component: ModalCancellationReason
      },
      correction_reason:{
        component: ModalCorrectionReason
      },
      countries:{
        component: ModalCountries
      },
      regions:{
        component: ModalRegions
      },
      currencies:{
        component: ModalCurrencies
      },
      dealtypes:{
        component: ModalDealTypes
      },
      doctypes:{
        component: ModalDocumentTypes
      },
      tnved:{
        component: ModalTNVED
      },
      tnved_extra:{
        component: ModalExtraTnved
      },
      incoterms: {
        component: ModalIncoterms
      },
      guarantee:{
        component: () => import('@/components/catalogs/modals/modal-guarantee.vue')
      },
      guarantee_types_so:{
        component: () => import('@/components/catalogs/modals/modal-guarantee-types-so.vue')
      },
      guarantee_reasons:{
        component:() => import('@/components/catalogs/modals/modal-guarantee-reasons.vue')
      },
      vehicles:{
        component:() => import('@/components/catalogs/modals/modal-vehicles.vue')
      },
      government_registrations:{
        component:() => import('@/components/catalogs/modals/modal-government-registrations.vue')
      },
      preferences:{
        component:() => import('@/components/catalogs/modals/modal-preferences.vue')
      },
      movement_features:{
        component:() => import('@/components/catalogs/modals/modal-movement-features.vue')
      },
      transport_category:{
        component: () => import('@/components/catalogs/modals/modal-transport-category.vue')
      }
    }
  }),
  created() {
    showCatalog.subscribe(this.selectCatalog);
  },
  beforeDestroy() {
    showCatalog.unsubscribe();
  },
  methods:{
    selectCatalog({type = null, id = -1, search = null, field = null, filter = {}, actionDate=null, zIndex = 10, customEventName = null}) {
      this.selected = type
      this.id = id
      this.search = search
      this.filter = filter
      this.field = field
      this.actionDate = actionDate
      this.show = true
      this.zIndex = zIndex
      this.customEventName = customEventName
    },
  }
}
</script>
