const routes = [
  {
    path: "/rs/:id",
    name: "RsMain",
    component: () => import('@/views/rs/rs-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]
export default routes;
