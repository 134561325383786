<template>
  <v-card
    color="grey"
    class="lighten-4 elevation-0 mx-3"
    width="100%"
  >
    <v-col>
      <v-row>
        <v-col cols="2">
          <label>
            Вес брутто
            <v-text-field
              outlined
              dense
              background-color="white"
              hide-details="auto"
            /></label>
        </v-col>
        <v-col cols="2">
          <label>
            Вес нетто
            <v-text-field
              outlined
              dense
              background-color="white"
              hide-details="auto"
            /></label>
        </v-col>
        <v-col cols="1">
          <label>
            Валюта
            <v-select
              outlined
              dense
              background-color="white"
              hide-details="auto"
            /></label>
        </v-col>
        <v-col cols="2">
          <label>
            Стоимость
            <v-text-field
              outlined
              dense
              background-color="white"
              hide-details="auto"
            /></label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>
            Документы
          </h4>
        </v-col>
      </v-row>
      <shipment-document />
    </v-col>
  </v-card>
</template>
<script>
import ShipmentDocument from './shipment-document.vue'
export default {
  components:{
    ShipmentDocument
  }
}
</script>
