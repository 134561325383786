import axios from 'axios'
import {AUTH} from '@/http-routes'

const getDefaultState = () => ({
  user: null,
  divisions: [],
  singleDivision: null,
  branches: [],
  divisionUsers: [],
  userPersonal: null,
  invites: [],
  purchasedServices: [],
  btsDivisions: [],
  btsChilds: [],
  btsUsers: [],
  eclientAndNcesData: null
})
const auth = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_AUTHORITIES(state, authorities) {
      state.user = {
        ...state.user,
        authorities
      }
    },
    SET_DIVISIONS(state, divisions) {
      state.divisions = divisions
    },
    SET_SINGLE_DIVIS(state, singleDivision) {
      state.singleDivision = singleDivision
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches
    },
    SET_DIVISION_USERS(state, divisionUsers) {
      state.divisionUsers = divisionUsers
    },
    SET_USER_PERSONAL(state, userPersonal) {
      state.userPersonal = userPersonal
    },
    SET_INVITES(state, invites) {
      state.invites = invites
    },
    SET_PURCHASED_SERVICES(state, purchasedServices) {
      state.purchasedServices = purchasedServices
    },
    SET_BTS_DIVIVSIONS(state, btsDivisions) {
      state.btsDivisions = btsDivisions
    },
    SET_BTS_CHILDS(state, btsChilds) {
      state.btsChilds = btsChilds
    },
    SET_BTS_USERS(state, btsUsers) {
      state.btsUsers = btsUsers
    },
    UPDATE_USER_DIVISION(state, id) {
      console.log('UPDATE_USER_DIVISION', id)
      state.user.user.activeDivisionId = id
    },
    SET_ECLIENT_AND_NCES(state, data) {
      state.eclientAndNcesData = data
    },
  },
  actions: {
    AUTH_REQUEST({commit}, payload) {
      return axios.post(`${AUTH}api/signin`, payload).then(res => {
        commit('SET_USER', res.data)
        return res
      })
    },
    SIGN_OUT({commit}) {
      commit('RESET_STATE')
    },
    loadDivisions({commit}) {
      return axios.get(`${AUTH}division/getDivisionListByUserId`)
        .then(res => {
          commit("SET_DIVISIONS", res.data)
          return res
        })
    },
    loadSingleDivisions({commit}, id) {
      return axios
        .get(`${AUTH}division/getById?id=${id}`)
        .then(res => {
          commit("SET_SINGLE_DIVIS", res.data)
          return res
        })
    },
    loadPurchasedServices({commit}, id) {
      return axios
        .get(`${AUTH}divisionService/getPurchasedServices?divisionId=${id}`)
        .then(res => {
          commit("SET_PURCHASED_SERVICES", res.data)
          return res
        })
    },
    setDivision({commit, dispatch, state}, divisionId) {
      return axios
        .post(`${AUTH}user/changeActiveDivisionId?userId=${state.user.user.id}&divisionId=${divisionId}`,)
        .then((res) => { // Приходит новый список прав для данной компании
          commit('SET_AUTHORITIES', res.data)
          commit('UPDATE_USER_DIVISION', divisionId)
          dispatch('loadDivisions', state.user)
          return res
        })
    },
    loadBranches({commit}, id) {
      return axios.get(`${AUTH}division/getSubdivisions?parentId=${id}`)
        .then(res => {
          commit("SET_BRANCHES", res.data)
          return res
        })
    },
    addBranch({state, dispatch}, branch) {
      return axios.post(`${AUTH}division/saveSubdivision?userId=${state.user.user.id}`, branch)
        .then(() => {
          dispatch('loadBranches', branch.parentId)
        })
    },
    updateBranch({dispatch}, branch) {
      return axios.post(`${AUTH}division/updateSubdivision`, branch)
        .then(() => {
          dispatch('loadBranches', branch.parentId)
        })
    },
    loadUsers({commit}, id) {
      return axios.get(`${AUTH}division/getUsers?userId=${id}`)
        .then(res => {
          commit("SET_DIVISION_USERS", res.data)
          return res
        })
    },
    updateDivisionUser({dispatch, state}, userItem) {
      return axios.post(`${AUTH}division/saveUser`, userItem)
        .then(() => {
          dispatch('loadUsers', state.user.user.id)
        })
    },
    inviteUser({dispatch, state}, invite) {
      return axios
        .post(`${AUTH}user/inviteUser?email=${invite.email}&divisionId=${invite.branch}&roleId=${invite.role}`)
        .then((res) => {
          dispatch('loadUsers', state.user.user.id)
          return res
        })
    },
    getUserPersonal({commit}, user) {
      return axios.get(`${AUTH}user/getUserPersonalData?userId=${user.user.id}`)
        .then(res => {
          commit("SET_USER_PERSONAL", res.data)
          return res
        })
    },
    updateUserPersonal(_, payload) {
      return axios.post(`${AUTH}user/saveUserPersonalData`, payload)
    },
    getUserPersonalById(_, id) {
      return axios.get(`${AUTH}user/getUserPersonalData?userId=${id}`)
    },
    loadInvites({commit, state}) {
      const id = state?.user?.user?.id
      if (!id) return Promise.reject('Пользователь не найден')
      return axios.get(`${AUTH}user/getInviteList?userId=${id}`)
        .then(res => {
          commit("SET_INVITES", res.data)
          return res
        })
    },
    loadBtsDivisions({commit}) {
      return axios.get(`${AUTH}division/getBtsDivisions`)
        .then(res => {
          commit("SET_BTS_DIVIVSIONS", res.data)
          return res
        })
    },
    loadDepartments(_, id) {
      return axios.get(`${AUTH}division/getChildsById?id=${id}`)
    },
    loadBtsChilds({commit}, id) {
      return axios.get(`${AUTH}division/getChildsById?id=${id}`)
        .then(res => {
          commit("SET_BTS_CHILDS", res.data)
          return res
        })
    },
    loadBtsUsers({commit}, id) {
      return axios.get(`${AUTH}user/getActiveUsersByDivisionId?divisionId=${id}`)
        .then(res => {
          commit("SET_BTS_USERS", res.data)
          return res
        })
    },
    fetchUsersByDivision(_, id) {
      return axios.get(`${AUTH}user/getActiveUsersByDivisionId?divisionId=${id}`)
    },
    fillDataFromEclientAndNces() {
      return axios.post(`${AUTH}user/fillDataFromEclientAndNces`)
    },
    attachPersonalCertificate(_, {userId, payload}) {
      return axios.post(`${AUTH}user/attachCertificate?userId=${userId}`, payload)
    },
    downloadPersonalCertificate(_, {userId}) {
      return axios.get(`${AUTH}user/downloadCertificate?userId=${userId}`, {
        responseType: "blob"
      })
    },
    sendSmsCode(_, user) {
      return axios.post(`${AUTH}user/sendSmsCode`, user)
    },
    registration(_, verifiedUser) {
      return axios.post(`${AUTH}user/registration`, verifiedUser)
    },
    activateAccount(_, {token, password}) {
      return axios.post(`${AUTH}user/activation`, {token, password})
    },
    sendResetPasswordLink(_, email) {
      return axios.post(`${AUTH}user/sendResetPasswordLink`, {email})
    },
    resetPassword(_, {token, password}) {
      return axios.post(`${AUTH}user/resetPassword`, {token, password})
    },
    fetchDivisionIdByUnp(_, unp){
      return axios.get(`${AUTH}division/getDivisionIdByUnp?unp=${unp}`)
    }
  },
  getters: {
    getInvites(state) {
      return state.invites
    },
    getUser(state) {
      return state.user
    },
    getDivisions(state) {
      return state.divisions
    },
    getSingleDivision(state) {
      return state.singleDivision
    },
    getBranches(state) {
      return state.branches
    },
    getDivisionUsers(state) {
      return state.divisionUsers
    },
    getUserPersonal(state) {
      return state.userPersonal
    },
    getPurchasedServices(state) {
      return state.purchasedServices
    },
    getBtsDivisions(state) {
      return state.btsDivisions
    },
    getBtsChilds(state) {
      return state.btsChilds
    },
    getBtsUsers(state) {
      return state.btsUsers
    },
    getAuthorities: (s) => s?.user?.authorities || [],
    getEclientAndNcesData(state) {
      return state.eclientAndNcesData
    }
  }
}

export default auth


