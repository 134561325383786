<template>
  <v-col cols="12">
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <v-card
          elevation="2"
          class="mb-6"
        >
          <v-toolbar flat>
            <v-card-title
              class="text-body-1 font-weight-medium "
              color="black"
            >
              Новый филиал
            </v-card-title>
          </v-toolbar>

          <div class="pa-7">
            <v-form
              ref="addBranch"
              @submit.prevent="addBranch"
            >
              <label for="">Наименование филиала</label>
              <v-text-field
                v-model="divisionName"
                color="#32a1af"
                background-color="grey lighten-3"
                solo
                hide-details="auto"
                flat
                placeholder="Введите наименование"
                class="mb-4"
                :rules="inputRules"
              />
              <label for="">Адрес</label>
              <v-text-field
                v-model="fullAddress"
                color="#32a1af"
                background-color="grey lighten-3"
                solo
                hide-details="auto"
                flat
                placeholder="Введите адрес"
                class="mb-4"
                :rules="inputRules"
              />
              <div class="text-center">
                <v-btn
                  dark
                  large
                  color="#333"
                  type="submit"
                >
                  + Добавить филиал
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <v-col
        v-for="(item, i) in branches"
        :key="i"
        cols="12"
        lg="4"
      >
        <branch-card :item="item" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {mapGetters} from 'vuex'
import BranchCard from '../components/profile/branch-card.vue'

export default {
  components: {BranchCard},
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() => ({
    divisionName: '',
    fullAddress: '',
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
  }),
  computed: {
    ...mapGetters({
      dvsn: 'auth/getSingleDivision',
      user: "auth/getUser",
      branches: "auth/getBranches"
    })
  },
  async mounted(){
    await this.$store.dispatch('auth/loadSingleDivisions', this.id)
    this.$store.dispatch("auth/setDivision", parseInt(this.id))
  },
  created(){
    this.$store.dispatch('auth/loadBranches', this.id)
  },
  methods: {
    addBranch(){
      if (this.$refs.addBranch.validate()) {
        let branch = {
          parentId: this.id,
          divisionType: this.dvsn.divisionType,
          divisionName: this.divisionName,
          fullAddress: this.fullAddress
        }
        this.$store.dispatch('auth/addBranch', branch).then(()=>{
          this.divisionName = ''
          this.fullAddress = ''
          this.$refs.addBranch.reset()
        })
      }
    }
  }
}
</script>
