export default {
  props:{
    show: {
      required: true,
      type: Boolean,
    },
  },
  created(){
    document.addEventListener('keydown',this.triggerClose)
  },
  beforeDestroy(){
    document.removeEventListener('keydown',this.triggerClose)
  },
  methods:{
    triggerClose(e){
      if(e.key === 'Escape'){
        if(this.show) this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
    },
  }
}