
// ware = {
//   id:number,
//   title: string,
//   module: string
// }
export default {
  namespaced:true,
  state: {
    documents:[],
    wares:[]
  },
  mutations:{
    ADD_WARE(state, ware){
      state.wares.push(ware)
    },
    REMOVE_WARE(state,id){
      const index = state.wares.findIndex(i => i.id === id)
      state.wares.splice(index, 1)
    },
    RESET_WARES(state){
      state.wares = []
    },
    ADD_DOCUMENT(state, id){
      state.documents.push(id)
    },
    REMOVE_DOCUMENT(state, id){
      const index = state.documents.findIndex(i => i === id)
      state.documents.splice(index, 1)
    },
    RESET_DOCUMENTS(state){
      state.documents = []
    }
  },
  getters:{
    getDocuments: s => s.documents,
    getWares:s => s.wares
  }
}