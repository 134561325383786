<template>
  <v-card
    color="#5CB7B1"
    class="rounded-lg"
  >
    <v-card-title>Калькулятор на 2023 год</v-card-title>
    <div class="pa-5">
      <v-row>
        <v-col cols="2">
          <label>
            Тип ТС
            <v-select
              outlined
              background-color="white"
              :items="types"
              item-value="value"
              item-text="name"
              hide-details="true"
            /></label>
        </v-col>
        <v-col cols="8">
          <component :is="activeComponent" />
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-end"
        >
          <v-btn
            dark
            x-large

            class="rounded-md px-10 mb-1"
          >
            Рассчитать
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
            color="#272727"
            label="Наличие льготы в соответствии с 140 Указом Президента"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>

export default {
  data:() => ({
    types:[
      {
        value:"vehice",
        name:"Какой-то тип авто",
        component: () => import('./vehicle.vue')
      }
    ]
  }),
  computed:{
    activeComponent(){
      return this.types[0].component
    }
  }
}
</script>
<style scoped>

.calculator{
    background: #5CB7B1;
    border-radius: 15px;
	padding:30px;
	margin-bottom: 20px;
	position: relative;
}
</style>
