
const DB_NAME = 'WEB_DECLARANT'
const STORE_NAME = 'APP_TNVED'
const VERSION = 1
const KEY_PATH = 'updated'

async function getState(key){
  const openRequest = await indexedDB.open(DB_NAME, VERSION)

  return new Promise((resolve, reject) => {
       
    openRequest.onupgradeneeded = () => {
      let db = openRequest.result
      if(!db.objectStoreNames.contains(STORE_NAME)){
        db.createObjectStore(STORE_NAME, {keyPath: KEY_PATH})
      }
      return resolve()
    }; 

    openRequest.onsuccess = () => {
      let db = openRequest.result  
      let transaction = db.transaction(STORE_NAME,'readwrite')
      const storage = transaction.objectStore(STORE_NAME)
      const request = storage.get(key)
      request.onsuccess = () => {
        const {value} = request?.result ?? {}
        return resolve(value)
      }
      request.onerror = (e) => reject(e)
    }
    openRequest.onerror = (e) => reject(e)
  })
}


async function patchState({updated, value}){

  const openRequest = await indexedDB.open(DB_NAME, VERSION)

  return new Promise((resolve, reject) => {
    openRequest.onsuccess = () => {
      let db = openRequest.result  
      let transaction = db.transaction(STORE_NAME,'readwrite')
      const storage = transaction.objectStore(STORE_NAME)
      const deleteRequest = storage.clear()
      deleteRequest.onsuccess = () => {
        const patchRequest = storage.put({
          updated,
          value
        })
        patchRequest.onsuccess = () => resolve()
        patchRequest.onerror = (e) => reject(e)
      }
      deleteRequest.onerror = (e) => reject(e)
    }
    openRequest.onerror = (e) => reject(e)
  })
}




export {getState, patchState}