<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-t-lg rounded-b-0 "
  >
    <v-card-title>Перевозчик</v-card-title>
    <v-col>
      <v-row>
        <v-col
          cols="1"
          class="pb-0"
        >
          <label>
            Страна
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Уникальный номер
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Наименование
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label>
            Индекс
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col>
          <label>
            Область
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col>
          <label>
            Город
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col>
          <label>
            Улица
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col>
          <label>
            Дом
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col>
          <label>
            № помещения
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>

export default {

}
</script>