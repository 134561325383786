import axios from 'axios'
import { NSI } from '@/http-routes'

const nsi = {
  namespaced: true,
  state: {
    nsiItems: null,
    updatedDocs: null,
    newNsi: null
  },
  mutations: {
    SET_NSI(state, nsiItems){
      state.nsiItems = nsiItems
    },
    SET_NEW_NSI(state, newNsi){
      state.newNsi = newNsi
    },
    SET_DOCS(state, updatedDocs){
      state.updatedDocs = updatedDocs
    }
  },
  actions: {
    loadNsi({commit}, chanel){
      axios
        .get(`${NSI}api/nsiUpdateLists?status=${chanel}`)
        .then(res =>{
          commit("SET_NSI", res.data)
        })
    },
    loadNewNsi({commit}, chanel){
      axios
        .get(`${NSI}api/nsiUpdateLists?status=${chanel}`)
        .then(res =>{
          commit("SET_NEW_NSI", res.data)
        })
    },
    loadUpdates({commit}, id){
      axios
        .get(`${NSI}/api/release?groupId=${id}`)
        .then(res =>{
          // console.log(res.data)
          commit("SET_DOCS", res.data)
        })
    },
    async pushNewUpdate(_, url){        
           
      try {
        const { data } = await axios.post(`${NSI}api/apply?${url}`)
        console.log(data)
        return data
      }
      catch (err) {
        return err.response
				
      }

      // await axios
      // .post(`${NSI}api/apply?${url}`, null, config)
      // .get('https://www.cbr-xml-daily.ru/daily_json.js')
      // .then(res =>{
      // console.log(res.data)
      // return res.data
      // })
    }
  },
  getters: {
    getNsiItems(state){
      return state.nsiItems
    },
    getNewNsiItems(state){
      return state.newNsi
    },
    getUpdatedDocs(state){
      return state.updatedDocs
    }
  }
}

export default nsi


