<template>
  <v-container class="activate-account-page">
    <v-row>
      <v-col
        lg="6"
        offset-lg="3"
      >
        <div
          class="grey-box text-center px-16"
        >
          <img
            src="@/assets/img/small-logo.svg"
            alt=""
          >
          <div class="text-h4 font-weight-bold mb-6">
            Придумайте пароль
          </div>
          <v-form ref="passwordForm">
            <v-row class="text-left">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Введите пароль</label>
                <v-text-field
                  v-model="password"
                  color="#32a1af"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  flat
                  :rules="inputRules"
                  tabindex="2"
                  :append-icon="hidePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="hidePassword ? 'password' : 'text'"
                  @click:append="() => (hidePassword = !hidePassword)"
                />
              </v-col>
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Повторите пароль</label>
                <v-text-field
                  v-model="passwordDuplicate"
                  color="#32a1af"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  flat
                  :rules="passwordDuplicateRules"
                  tabindex="3"
                  :append-icon="hideDuplicatePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="hideDuplicatePassword ? 'password' : 'text'"
                  @click:append="() => (hideDuplicatePassword = !hideDuplicatePassword)"
                />
              </v-col>
              <v-col
                lg="12"
                class="text-center"
              >
                <v-btn
                  color="#333"
                  dark
                  large
                  :loading="loading"
                  @click="save"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data(){
    return{
      inputRules:[
        v => !!v || 'Поле должно быть заполнено'
      ],
      password:null,
      passwordDuplicate:null,
      passwordDuplicateRules:[
        v => !!v || 'Поле должно быть заполнено',
        v => v === this.password || 'Пароли не совпадают'
      ],
      hideDuplicatePassword:true,
      hidePassword:true,
      loading:false,
      token:null
    }
  },
  created() {
    this.getToken()
  },
  methods:{
    getToken(){
      this.token = this.$route.query.token
      if(!this.token){
        this.$router.push('/404')
      }
    },
    save(){
      if(this.$refs.passwordForm.validate()){
        this.$store.dispatch("auth/activateAccount", {
          token: this.token,
          password: this.password
        }).then(() => {
          this.$success("Пароль установлен")
          this.$router.push('/login')
        }).catch(() => this.$error())
      }

    }
  }
}
</script>
<style scoped>
.activate-account-page{
  display: flex;
  align-items: center;
  min-height: 88vh;
}
</style>