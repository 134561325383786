
export default {
  namespaced:true,
  state:{
    snackbars:[],
    pinned:[]
  },
  mutations:{
    TOGGLE_PIN(state, name){
      if(state.pinned.includes(name)){
        const idx = state.pinned.indexOf(name)
        state.pinned.splice(idx, 1)
      }else{
        state.pinned.push(name)
      }
    },
  },
  getters:{
    getPinned: s => s.pinned
  }
}