import axios from "axios";
import { SD } from "@/http-routes";
import Vue from "vue";
import { downloadFile } from "@/helpers/documents";

export default {
  namespaced: true,
  state: {
    paymentErrors: [],
  },
  mutations: {
    SET_PAYMENT_ERRORS(state, value) {
      state.paymentErrors = value;
    },
    UNSET_PAYMENT_ERRORS(state) {
      state.paymentErrors = [];
    },
  },
  actions: {
    changeDeferredStatus(_, { documentId, status }) {
      return axios.post(
        `${SD}dtOrder/changeDeferredStatus?isDeferred=${status}&declarationId=${documentId}`
      );
    },
    changePerformer(_, { documentId, personId }) {
      return axios.post(
        `${SD}declaration/setUserId?declarationId=${documentId}&otherUserId=${personId}`
      );
    },
    changeRegnum(_, formData) {
      return axios.post(`${SD}dtOrder/attachPdf`, formData);
    },
    attachPaymentDocuments(_, { formData, id }) {
      return axios.post(
        `${SD}dtOrder/attachUsPayments?declarationId=${id}`,
        formData
      );
    },
    addComment(_, { documentId, message }) {
      return axios.post(
        `${SD}dtOrder/addComment?declarationId=${documentId}&comment=${message}`
      );
    },
    addDocuments(_, formData) {
      return axios.post(`${SD}dtOrder/attachDocuments`, formData);
    },
    getDocumentArchive(_, id) {
      axios
        .get(`${SD}dtOrder/downloadDocuments?declarationId=${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          if (res.data.size < 1)
            return Vue.prototype.$snackbar({ text: "Документы отсутствуют" });
          downloadFile({ data: res.data, type: "application/zip" });
        });
    },
  },
  getters: {
    getPaymentErrors: (s) => s.paymentErrors,
  },
};
