<template>
  <v-app>
    <app-header />
    <div
      class="to-top-btn"
      @click="$vuetify.goTo(target, options)"
    >
      <v-btn
        fab
        dark
        small
        color="#5CB7B1"
      >
        <v-icon color="white">
          mdi-arrow-up
        </v-icon>
      </v-btn>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <snackbar />
    <app-footer />
    <reload-app
      v-if="reload"
      :worker="worker"
    />
  </v-app>
</template>

<script>
import "./assets/css/style.css";
import AppFooter from "./components/ui/footer.vue";
import AppHeader from "./components/ui/header.vue";
import Snackbar from "./components/ui/snackbar.vue";
import ReloadApp from "./components/ui/reload-app.vue";
import {session} from "@/helpers/socket";
import {mapGetters} from 'vuex';
import {SIGN_IN_EVENT, SIGN_OUT_EVENT} from "@/helpers/broadcast";
import {disablePageScroll} from "@/events/common";

export default {
  components: {AppFooter, Snackbar, AppHeader, ReloadApp},
  data: () => ({
    socket: null,
    refreshing: false,
    worker: null,
    reload: false,
    target: "#app",
    options: {
      duration: 200,
      offset: 0,
      easing: "easeInOutCubic",
    },
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    }),
  },
  watch: {
    user: {
      handler(nv, ov) {
        const newUserId = nv?.user?.id
        if (newUserId && !ov) { // Подписка при авторизации + загрузка страницы
          this.socket = session.call(this)
          this.socket.subscribe()
        } else if (nv === null && this.socket && Object.keys(this.socket)) { // Если был пользователь и сессия завершилась
          this.socket.unsubscribe()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.subscribeBodyScrollActivator()
    this.subscribeBroadcastChannel()
    this.subscribeServiceWorker()
    this.subscribeOnControllerChange()
    this.greeting()
  },
  beforeDestroy() {
    this.unsubscribeBodyScrollActivator()
    this.unsubscribeBroadcastChannel()
    this.unsubscribeServiceWorker()
  },
  mounted() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((worker) => {
        this.worker = worker
        worker.active.postMessage({type: 'app-instance-created'})
      })
    }
  },
  methods: {
    toggleBodyScroll(disabled){
      const [html] = document.getElementsByTagName('html')
      html.style.overflow = disabled ? 'hidden' : 'scroll'
    },
    subscribeBodyScrollActivator(){
      disablePageScroll.subscribe(this.toggleBodyScroll)
    },
    unsubscribeBodyScrollActivator(){
      disablePageScroll.unsubscribe()
    },
    greeting() {
      console.log(
        "%c 🇧🇾 БЕЛТАМОЖСЕРВИС - СИЛА!",
        "padding: 8px 10px; margin: 10px 0; background-color: #5cb7b1; color: #333; font-weight:600; font-size: 13px;"
      );
    },
    subscribeOnControllerChange() {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
    },
    subscribeServiceWorker() {
      document.addEventListener('service-worker-updated', this.showNotification, {once: true})
    },
    unsubscribeServiceWorker() {
      document.removeEventListener('service-worker-updated', this.showNotification)
    },
    subscribeBroadcastChannel() {
      this.$broadcastChannel.onmessage = (event) => {
        const type = event.data.type
        switch (type) {
        case SIGN_OUT_EVENT:
          this.toLoginPage();
          break;
        case SIGN_IN_EVENT:
          this.toMainPage();
          break;
        }
      }
    },
    toLoginPage() {
      const LOGIN_PAGE = '/login'
      if (this.$route.path !== LOGIN_PAGE) {
        this.$router.replace(LOGIN_PAGE);
      }
    },
    toMainPage() {
      const MAIN_PAGE = '/'
      if (this.$route.path !== MAIN_PAGE) {
        this.$router.replace(MAIN_PAGE);
      }
    },
    unsubscribeBroadcastChannel() {
      this.$broadcastChannel.close()
    },
    showNotification() {
      this.reload = true
    },
  }
};
</script>
