import {capitalizeString} from '@/helpers/text'

function convertEmptyStringsToNull(data){
  if(Array.isArray(data)){
    return data.map(i => convertEmptyStringsToNull(i))
  }
  if(typeof data === 'object' && data !== null && !Array.isArray(data)){
    const obj = {}
    Object.keys(data).forEach((key) => {
      obj[key] = convertEmptyStringsToNull(data[key])
    })
    return obj
  }else{
    return data === "" ? null : data;
  }
}

function camelCaseObject(obj, divider = "_"){
  const result = {}
  Object.keys(obj).forEach(key => {
    const camelCaseKey = key.split(divider).map((part, index) => {
      return index === 0 ? part : capitalizeString(part)
    }).join('')
    result[camelCaseKey] = obj[key]
  })
  return result
}
export { convertEmptyStringsToNull, camelCaseObject };
