<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg my-5"
  >
    <v-card-title>Дополнительные сведения о товарах</v-card-title>
    <v-col>
      <goods-item />
    </v-col>
  </v-card>
</template>
<script>
import GoodsItem from './goods-item.vue'
export default {
  components:{
    GoodsItem
  }
}
</script>
