<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg "
  >
    <v-card-title>Транспортные средства</v-card-title>
    <v-col>
      <vehicle />
    </v-col>
  </v-card>
</template>
<script>
import Vehicle from './vehicle.vue'
export default {
  components:{
    Vehicle
  }
}
</script>
