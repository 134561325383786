<template>
  <v-col
    cols="12"
    lg="12"
  >
    <v-row class="mb-4">
      <v-col
        cols="12"
        lg="12"
      >
        <h2 class="mt-4">
          История транзакций
        </h2>
      </v-col>


      <v-col
        cols="12"
        lg="3"
      >
        <div class="d-flex justify-space-between align-center">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                readonly
                v-bind="attrs"
                color="#5CB7B1"
                background-color="white"
                solo
                hide-details="auto"
                flat
                class="mr-4"
                outlined
                dense
                placeholder="Выберите период"
                v-on="on"
                @click:append="menu = !menu"
              >
                <template #append>
                  <v-btn
                    v-if="dates.length"
                    text
                    fab
                    x-small
                    @click="clearDate"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-icon>mdi-calendar-blank-outline</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              range
              scrollable
              color="#5CB7B1"
            >
              <v-spacer />
              <v-btn
                text
                color="#5CB7B1"
                @click="menu = false"
              >
                ОТМЕНА
              </v-btn>
              <v-btn
                text
                color="#5CB7B1"
                @click="updatePage"
              >
                ОК
              </v-btn>
            </v-date-picker>
          </v-menu>
          <!-- datepicker -->
        </div>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        offset="6"
      >
        <v-btn
          dark
          color="#333"
          block
          class="py-0"
          @click="downloadAct"
        >
          Акт сверки
        </v-btn>
      </v-col>



      <v-col
        cols="12"
        lg="12"
      >
        <v-data-table
          :headers="headers"
          :items="billingHistory"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template #[`item.doc_date`]="{ item }">
            <div
              class="text-body-1"
              :class="item.trans_direction == 0 ? 'red--text' : 'black--text'"
            >
              {{ formatDate(item.doc_date) }}
            </div>
          </template>
          <!-- <template v-slot:item.service="{ item }">
                        <div class="text-body-1" :class="item.price < 0 ? 'red--text' : 'black--text'">
                            {{item.service}}
                        </div>
                    </template> -->
          <template #[`item.transaction_sum`]="{ item }">
            <div
              class="text-body-1"
              :class="item.trans_direction == 0 ? 'red--text' : 'black--text'"
            >
              <span v-if="item.trans_direction == 0">-</span>
              <span v-else>+</span>
              {{ !item.declaration_type ? item.transaction_sum : item.common_sum }} BYN
            </div>
          </template>

          <template #[`item.service`]="{ item }">
            <span
              v-if="item.service_type_id && !item.declaration_type"
              class="text-body-1"
              :class="item.price < 0 ? 'red--text' : 'black--text'"
            >
              <strong>{{ getServName(item.service_type_id) }}</strong> <br>
              {{ getPackageName(item.service_type_id) }}
              <!-- {{item.serviceTypeId}} -->
              <!-- servPackages -->
            </span>
            <span v-else-if="item.declaration_type === 'SD_EX'">
              Экспортная статистка: ({{ item.transaction_sum }} BYN/шт.)
            </span>
            <span v-else-if="item.declaration_type === 'SD_IM'">
              Импортная статистка ({{ item.transaction_sum }} BYN/шт.)
            </span>
            <span v-else>
              Пополнение баланса
            </span>
          </template>
          <!-- <template v-slot:item.act="{ item }">
                        <v-btn class="py-2 rounded" dark fab small v-if="item.act">
                            Акт
                        </v-btn>
                    </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import formatDate from '@/mixins/format-date.js'
import {mapGetters} from 'vuex'
import axios from 'axios'
import moment from 'moment'
import {AUTH} from '@/http-routes'

export default {
  mixins: [formatDate],
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() =>( {
    dates: [],
    menu: false,
    headers: [{
      text: 'Дата',
      value: 'doc_date'
    },
    {
      text: 'Стоимость',
      value: 'transaction_sum'
    },
    {
      text: 'Услуга',
      value: 'service',
      sortable: false
    }
    ],
  }),

  computed: {
    ...mapGetters({
      billingHistory: "billing/getBillingHistory",
      services: 'billing/getServices',
      servPackages: 'billing/getServPackages'
    }),
    dateRangeText() {
      if(!this.dates.length){
        return ''
      }else{
        return `${this.formatDate(this.dates[0])} - ${this.formatDate(this.dates[1])}`
      }
    }
  },
  created() {
    this.$store.dispatch('auth/loadSingleDivisions', this.id)
    this.$store.dispatch('billing/loadHistory', this.id)
    this.$store.dispatch("billing/loadServices")
    this.$store.dispatch("billing/loadServPackages")
  },
  methods: {
    downloadAct(){
      const payload = this.dates.map((i) => {
        return this.formatDate(i)
      })
      axios({
        url: `${AUTH}accountTransaction/downloadAct?divisionId=${this.id}&dateFrom=${payload[0]}&dateTo=${payload[1]}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `act_${payload[0]}-${payload[1]}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    clearDate(){
      this.dates = []
      this.$store.dispatch('billing/loadHistory', this.id)
    },
    updatePage() {
      let A = moment(this.dates[0]);
      let B = moment(this.dates[1]);
      if (A.diff(B, 'days') >= 1) {
        let newDate = this.dates[0]
        this.dates.push(newDate)
        this.dates.splice(0, 1)
      }

      this.$refs.menu.save(this.dates)
      let obj = {
        id: this.id,
        dateFrom: this.formatDate(this.dates[0]),
        dateTo: this.formatDate(this.dates[1])
      }
      this.$store.dispatch('billing/loadHistoryDates', obj)
    },
    getPackageName(id) {
      let res = this.servPackages.find(item => {
        return item.id == id
      })
      return res.name
    },
    getServName(id) {
      let res = this.servPackages.find(item => {
        return item.id == id
      })
      let serv = this.services.find(item => {
        return item.id == res.parent_id
      })
      return serv.name
    }
  },

}
</script>
