<template>
  <section
    id="usual"
    class="pb-16"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="8"
        >
          <div class="grey--text">
            Кабинет организации
          </div>
          <!-- <v-breadcrumbs
            class="pa-0"
            :items="breadcrumbs"
          /> -->
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <div
            v-if="dvsn"
            class="text-body-2 grey--text text-right"
          >
            Последние изменения: {{ formatUpdatedDate(dvsn.updated) }}
          </div>
        </v-col>

        <v-col
          v-if="dvsn"
          cols="12"
          lg="12"
          class="text-right"
        >
          <router-link
            v-for="(nav, index) in navs"
            :key="index"
            tag="a"
            :to="`/new-division/${id}/${nav.link}`"
          >
            <!-- <v-btn elevation="0" class="ml-2" v-if="!nav.active">{{nav.txt}}</v-btn> -->
            <v-btn
              elevation="0"
              class="ml-2"
              color="#eeeeee"
            >
              {{ nav.txt }}
            </v-btn>
          </router-link>
        </v-col>

        <!--  -->
        <router-view />
        <!--  -->
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDate from '@/mixins/format-date'

export default {
  mixins: [formatDate],
  props:{
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  computed: {
    ...mapGetters({
      dvsn: 'auth/getSingleDivision'
    }),
    breadcrumbs(){
      let breadcrumbs = [
        {
          text: 'Главная',
          disabled: false,
          href: '/',
        },
        {
          text: 'Список организаций',
          disabled: false,
          href: '/new-division',
        },
        {
          text: this.$route.name ,
          disabled: true,
        },
      ]
      return breadcrumbs
    },
    navs(){
      let navs = []
      if(this.dvsn.divisionType === 'IMP'){
        navs = [
          {txt: 'Информация', link: ''},
          {txt: 'Пользователи', link: 'users'},
          {txt: 'Услуги', link: 'billing-buy'},
          {txt: 'Биллинг', link: 'billing-history'},
        ]
      }else{
        navs = [
          {txt: 'Информация', link: ''},
          {txt: 'Филиалы', link: 'branches'},
          {txt: 'Пользователи', link: 'users'},
          {txt: 'Услуги', link: 'billing-buy'},
          {txt: 'Биллинг', link: 'billing-history'},
        ]
      }
      return navs
    }
  }
}
</script>

<style scoped>
.router-link-exact-active .v-btn{
    color: #fff;
    background-color: #5cb7b1 !important;
}
#usual{
    min-height: calc(100vh - 129px);
}
</style>
