<template>
  <div
    v-if="updatedDocs"
    style="min-height: calc(100vh - 129px);"
  >
    <section id="usual">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="12"
          >
            <button
              class="back-btn mt-2"
              @click="$router.go(-1)"
            >
              <img src="../../assets/img/arr.svg">Назад
            </button>
          </v-col>
          <v-col
            cols="12"
            lg="12"
          >
            <div class="text-h4 font-weight-medium mb-3">
              {{ updatedDocs[Object.keys(updatedDocs)].table_description.table_name }}
            </div>
            <div class="text-body-1">
              {{ updatedDocs[Object.keys(updatedDocs)].updated_records_count[2] }} удаление,
              {{ updatedDocs[Object.keys(updatedDocs)].updated_records_count[1] }} изменение,
              {{ updatedDocs[Object.keys(updatedDocs)].updated_records_count[0] }} добавление
            </div>
          </v-col>
          <!-- <v-col cols="12" lg="12">

                        <router-link tag="a" :to="`/nsi/${tag.id}`" :key="index" v-for="(tag, index) in nsiItems">
                            <v-chip class="mr-5" :class="{activeNsi : tag.id == id}">
                                {{ tag.tableName }} - {{formateData(tag.updated)}}
                            </v-chip>
                        </router-link>

                        <v-divider class="mt-6"></v-divider>
                    </v-col> -->
        </v-row>
      </v-container>
    </section>




    <div style="min-height: calc(100vh - 269px) ;">
      <v-container>
        <codes-tnved
          v-if="updatedDocs.nsi_tn_ved"
          :tnved="updatedDocs.nsi_tn_ved"
        />

        <nsi-documents
          v-if="updatedDocs.nsi_document"
          :docs="updatedDocs.nsi_document"
        />

        <intellectual
          v-if="updatedDocs.nsi_intellectual_property"
          :docs="updatedDocs.nsi_intellectual_property"
        />

        <tariffs
          v-if="updatedDocs.nsi_tarif"
          :docs="updatedDocs.nsi_tarif"
        />

        <no-tarifs
          v-if="updatedDocs.nsi_notarif"
          :docs="updatedDocs.nsi_notarif"
        />

        <tariffs-list
          v-if="updatedDocs.nsi_tarif_list"
          :docs="updatedDocs.nsi_tarif_list"
        />
      </v-container>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex'
import CodesTnved from '../../components/nsi/codes-tnved.vue'
import moment from "moment";
import NsiDocuments from '../../components/nsi/nsi-documents.vue'
import Intellectual from '../../components/nsi/intellectual.vue'
import Tariffs from '../../components/nsi/tariffs.vue'
import TariffsList from '../../components/nsi/tariffs-list.vue'
import NoTarifs from '../../components/nsi/no-tarifs.vue'

export default {
  components: {CodesTnved, NsiDocuments, Intellectual, Tariffs, TariffsList, NoTarifs},
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  computed: {
    ...mapGetters({
      updatedDocs: "nsi/getUpdatedDocs",
      nsiItems: "nsi/getNsiItems"
    }),
    getTitle(){
      let titleObj = this.nsiItems.find(item =>{
        return item.id == this.id
      })
      return titleObj
    }
  },
  watch:{
    id(){
      this.$store.dispatch("nsi/loadUpdates", this.id)
    }
  },
  created(){
    this.$store.dispatch("nsi/loadUpdates", this.id)
    this.$store.dispatch("nsi/loadNsi")
    this.activeIndex = this.id
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
.activeNsi{
    background: #5CB7B1 !important;
    color: #fff !important;
}
</style>
