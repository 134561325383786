const getRandomNumber = (min = 1000, max = 9999) => Math.floor(Math.random() * (max - min + 1)) + min;

const STAT_COST_EMPTY_VALUE = null
const CUSTOMS_COST_EMPTY_VALUE = null
const CURRENCY_RATE_NOT_FOUND = "Отсутствует курс валюты на указаную дату."
const EMPTY_DATE = "Укажите дату загрузки курса"
const EMPTY_CURRENCY_CODE = "Укажите код валюты для загрузки курса"
const CURRENCY_REQUEST_ERROR = "Возникла ошибка при загрузке курсов"

function validateArguments({date, code, cost}) {
  if (!date) {
    this.$warning(EMPTY_DATE);
    return Promise.reject()
  }
  if (!code) {
    this.$warning(EMPTY_CURRENCY_CODE);
    return Promise.reject()
  }
  return Promise.resolve({date, code, cost})
}

function fetchCurrency({date, code}) {
  return this.$store.dispatch("currency/fetchRate", {
    code,
    date,
  }).then(res => {
    const [currency] = res.data
    return currency
  }).catch((err) => {
    this.$error(CURRENCY_REQUEST_ERROR)
    throw err
  })
}

function calculateStatCostFromBYN({date, cost}) {
  return fetchCurrency.call(this, {date, code: "USD"}).then((currency) => {
    if (!currency) {
      this.$warning(CURRENCY_RATE_NOT_FOUND);
      return Promise.reject()
    }
    const statCostInByn = (cost * (1 / currency.rate)).toFixed(2)
    return Promise.resolve(statCostInByn);
  })
}

function calculateStatCostFromOtherCurrency({date, code, cost}) {
  const fetchCurrencyBinding = fetchCurrency.bind(this)
  return Promise.all([
    fetchCurrencyBinding({date, code}),
    fetchCurrencyBinding({date, code: "USD"})
  ]).then(([rate, usd]) => {
    if (!rate || !usd) {
      this.$warning(CURRENCY_RATE_NOT_FOUND);
      return Promise.reject()
    }
    const costInBYN = cost * (rate.rate / rate.currency_scale);
    const statCostInByn = (costInBYN * (1 / usd.rate)).toFixed(2)
    return Promise.resolve(statCostInByn);
  })
}

function defineCurrencyAndCalculateStateCost({date, code, cost}) {
  if (code === 'USD') return Promise.resolve(cost);
  if (code === 'BYN') return calculateStatCostFromBYN.call(this, {date, code, cost})
  return calculateStatCostFromOtherCurrency.call(this, {date, code, cost})
}


function getStatisticalCost({date = null, code, cost = 0}) {
  return validateArguments.call(this, {date, code, cost})
    .then((initialArguments) => defineCurrencyAndCalculateStateCost.call(this, initialArguments))
    .catch(() => STAT_COST_EMPTY_VALUE)
}

function defineCurrencyAndCalculateCustomsCost({date, code, cost}) {
  if (code === 'BYN') return Promise.resolve(cost)
  return calculateCustomsCost.call(this, {date, code, cost})
}

function calculateCustomsCost({date, code, cost}) {
  return fetchCurrency.call(this, {date, code})
    .then((currency) => {
      if (!currency) {
        this.$warning(CURRENCY_RATE_NOT_FOUND);
        return Promise.reject()
      }
      const customsCost = (cost * (currency.rate / currency.currency_scale)).toFixed(2);
      return Promise.resolve(customsCost);
    })
}

function getCustomsCost({date, code, cost = 0}) {
  return validateArguments.call(this, {date, code, cost})
    .then((initialArguments) => defineCurrencyAndCalculateCustomsCost.call(this, initialArguments))
    .catch(() => CUSTOMS_COST_EMPTY_VALUE)
}

function generateUUID() {
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function normalizeFloat({value = "", afterPoint = null}) {
  if (value.toString().includes('.')) { //Если не целое число
    const float = Number.parseFloat(value)
    if (afterPoint !== null) { // Если указано точное кол-во символов после точки
      return float.toFixed(afterPoint).replace(/0*$/, "").replace(/\.*$/, "")
    }
    if (value >= 0.001) {
      return float.toFixed(3).replace(/0*$/, "").replace(/\.*$/, "") // Убираем лишние нули в конце и если нужно точку
    } else {
      return float.toFixed(6).replace(/0*$/, "").replace(/\.*$/, "")
    }
  }
  return value
}

function toPrettyFloat(value){
  return value ? prettifyNumber(Number.parseFloat(value).toFixed(2)) : ""
}
function prettifyNumber(value) {
  if(value === 0) return value
  if (!value) return null
  const [integer] = value.toString().split(".");
  const str = value.toString();
  const isNegative = str.startsWith("-");
  const positive = str.replace("-", "")
  const remainder = integer.length % 3;
  const prettyValue = (positive.substr(0, remainder) + positive.substr(remainder).replace(/(\d{3})/g, " $1"))
    .replace(". ", ".")
    .trim();
  return isNegative ? "-".concat(prettyValue) : prettyValue;
}

function getWaresParamTotal(wares, field) {
  if (wares) {
    const value = wares.reduce((acc, current) => {
      return acc + (current[field] || 0)
    }, 0)
    return normalizeFloat({value})
  }
  return ""
}

function exciseQuantityAfterPoint(value, unitLetter) {
  if (unitLetter === "Т") {
    return value >= 0.1 ? 2 : 4;
  } else if (unitLetter === "КГ") {
    return value >= 0.001 ? 3 : 6;
  } else if (["ШТ", "ПАР"].includes(unitLetter)) {
    return 0
  } else {
    return 2
  }
}

export {
  exciseQuantityAfterPoint,
  getRandomNumber,
  getStatisticalCost,
  getCustomsCost,
  generateUUID,
  normalizeFloat,
  getWaresParamTotal,
  prettifyNumber,
  toPrettyFloat,
  fetchCurrency
}