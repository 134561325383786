<template>
  <div>
    <!-- <TopBar /> -->
    <section
      id="usual"
      class="pa-1"
    >
      <div class="container px-0">
        <div class="row">
          <div class="col-lg-12">
            <!-- <v-skeleton-loader dark type="article" ></v-skeleton-loader> -->

            <v-form
              ref="searchForm"
              @submit.prevent="goSearch()"
            >
              <div class="search2 d-flex justify-start align-start">
                <v-text-field
                  ref="searchBox"
                  v-model="searchBox"
                  color="#32a1af"
                  background-color="#fff"
                  filled
                  label="Введите код, слово, комбинацию"
                  :rules="[rules.req]"
                  :error-messages="searchErr"
                  required
                  hide-details="auto"
                >
                  <template #append>
                    <div class="d-none d-lg-flex justify-end align-center">
                      <div class="search-mums mr-2">
                        {{ hgActive }}\{{ hgLength }}
                      </div>
                      <v-btn
                        icon
                        small
                        @click="scrollToPrevHl"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        @click="scrollToNextHl"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        @click="clearSearch()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-text-field>
                <v-btn
                  color="#5CB7B1"
                  dark
                  x-large
                  @click="goSearch()"
                >
                  <v-icon
                    left
                    :color="'#fff'"
                  >
                    mdi-magnify
                  </v-icon>Поиск
                </v-btn>
              </div>
            </v-form>



            <div class="d-lg-flex align-center">
              <v-checkbox
                v-model="shortSearch"
                label="Разворачивать по порядку"
                color="#5CB7B1"
                hide-details
                class="mr-5 d-none d-md-flex d-lg-flex"
                dark
              />

              <v-chip-group
                v-model="activeIndex"
                active-class="active-tnved-tag"
                column
                mandatory
              >
                <v-chip
                  v-for="(tag, index) in tags"
                  :key="index"
                  v-scroll-to="'header'"
                  class="mr-2"
                  :value="index"
                  style="overflow: visible;"
                  @click="changePage(tag.link)"
                >
                  <span>{{ tag.name }}</span>
                </v-chip>
              </v-chip-group>
            </div>

            <v-progress-linear
              v-if="preloader"
              indeterminate
              :color="'#5CB7B1'"
            />
          </div>
        </div>
      </div>
    </section>





    <section id="tvedResult">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 px-0">
            <notes :notes="notes" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import TopBar from '../../components/tnved/TopBar.vue'

import {mapGetters} from 'vuex'
import moment from "moment";
import { TNVED } from '@/http-routes';
import Notes from '@/components/tnved/notes.vue'

export default {
  components: {Notes},
  data(){
    return{
      url: '',
      urlExamples: '',
      urlNotes: '',
      urlExplanations: '',
      activeIndex: 3,
      exmplParam: '',
      exmplCode: '',
      opened: [1,2008,2972,3243,5082,5512,7499,8079,8412,9029,9377,11249,11447,11949,12080,14000,17221,18617,19184,19222,19711],
      firstChilds: [
        1,2008,2972,3243,5082,5512,7499,8079,8412,9029,9377,11249,11447,11949,12080,14000,17221,18617,19184,19222,19711],
      tags: [
        {name: 'Примеры декларирования', link: '/examples'},
        {name: 'ЕТТ', link: '/tnved'},
        {name: 'Пояснения и примечания том I-V', link: '/explanations'},
        {name: 'Том VI пояснений', link: '/notes'},
      ],
      searchErr: '',
      shortSearch: false,
      hgLength: 0,
      hgActive: 0,
      search: '',
      highlights: [],
      dataBase: [],
      lastOpen: [],
      allOpened: false,
      rowId: null,
      preloader: false,
      searchBox: '',
      caseSensitive: false,
      noResults: null,
      rules:{
        // onlyRus: v => /^[?!,.а-яА-ЯёЁ0-9\s]+$/.test(v) || 'Поле может содержать только кириллический шрифт!',
        req: v => !!v && this.searchBox.length >= 3 || 'Минимальое кол-во символов: 3'

        // !searchBox && searchBox.length >= 3 || 'Минимальое кол-во символов: 3'
      },
      swiperOptions: {
        slidesPerView: 1,
        createdSlides: true,
        touchRatio: 0,
        spaceBetween: 30,
        draggable: true,
        simulateTouch: false,
        loop: false,
        infinite: true,
        speed: 600,
        navigation: {
          nextEl: '.bigNext',
          prevEl: '.bigPrev',
        },
      },

      highliten: []
    }
  },
  computed: {
    ...mapGetters({
      notes: "tnved/getNotes",
      saveSearch: "tnved/getSaveSearch"
    })
  },
  mounted(){
    if(this.saveSearch !== ''){
      this.searchBox = this.saveSearch
    }
    this.$store.dispatch("tnved/loadTnved")

    if(this.saveSearch === ''){
      return
    }
    let hlElem = document.querySelectorAll('.ex-highlight')
    this.hgLength = hlElem.length
    this.hgActive = 1
    let options = {offset: -150,force: true}
    this.$scrollTo(hlElem[this.hgActive - 1] , 200, options)
  },
  methods: {
    changePage(link){
      this.$router.replace(link)
    },
    checkOpened(id){
      // return true
      if(this.highliten.includes(id)){
        return true
      }else{
        return false
      }

    },
    goToCode(code){
      let routeData = this.$router.resolve({ path: `/tnved/${code}` })
      window.open(routeData.href, '_blank');
    },
    formateData(d){
      return moment(d).format('DD.MM.YYYY')
    },
    clearSearch(){
      this.hgLength = 0
      this.hgActive = 0
      this.highlights = []
      this.$refs.searchForm.reset()
      console.log(this.$refs.searchBox)
      // this.$refs.searchBox.classes.v_input__is_focused = false
      // v-input--is-focused
      this.searchErr = ''
      this.opened = this.firstChilds
      setTimeout(()=>{
        let options = {offset: -260,force: true} // 260
        this.$scrollTo('.tnved-slide-box', 200, options)
      }, 120)
    },
    scrollToPrevHl(){
      if(this.hgActive <= 1){
        return
      }

      this.hgActive += -1
      let hlElem = document.querySelectorAll('.ex-highlight');

      let options = {offset: -150,force: true}
      this.$scrollTo(hlElem[this.hgActive -1] , 200, options)

      hlElem.forEach(item =>{
        item.classList.remove('yellHl')
      })
      hlElem[this.hgActive - 1].classList.add('yellHl')
    },
    scrollToNextHl(){
      let hlElem = document.querySelectorAll('.ex-highlight')

      if(this.hgActive == this.hgLength){
        return
      }
      this.hgActive += 1
      let options = {offset: -150,force: true}
      this.$scrollTo(hlElem[this.hgActive - 1] , 200, options)

      hlElem.forEach(item =>{
        item.classList.remove('yellHl')
      })
      hlElem[this.hgActive - 1].classList.add('yellHl')
    },
    normalizeString(s){
      // удаление пробелов
      let prevS = '';
      while(prevS !== s){
        prevS = s; s = s.replace(/([0-9]+) ([0-9]+)/, '$1$2');
      }

      return s
    },
    goSearch(){
      this.searchBox = this.normalizeString(this.searchBox)
      this.hgActive = 0
      this.hgLength = 0


      if(!this.$refs.searchForm.validate()){
        return
      }

      this.preloader = true

      var num = this.searchBox.match(/\d+/g);
      var letr = this.searchBox.match(/[а-яA-Я]+/g);

      var unwrap = 1
      if(this.shortSearch){
        unwrap = 0
      }

      this.exmplCode = ''
      this.exmplParam = ''



      if(num != null && letr == null){
        this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?code=${num.join('')}&&unwrap=${unwrap}`
        this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?code=${num.join('')}`
        this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?code=${num.join('')}`
        this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?code=${num.join('')}`
        this.searchBox = this.searchBox.replace(/\s/g, '');
        // this.searchBox = this.searchBox.replace(/([0-9]+) ([0-9]+)/, '$1$2');
        this.highlights = this.searchBox.trim().split(" ")

        this.exmplCode = num.join('')

      }else if(letr != null && num == null){
        this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?&description=${letr.join(' ')}&unwrap=${unwrap}`
        this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?&description=${letr.join(' ')}`
        this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?&description=${letr.join(' ')}`
        this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?&description=${letr.join(' ')}`
        this.highlights = this.searchBox.trim().split(" ")

        // параметр для примеров
        this.exmplParam = letr.join(' ')

      }else{
        this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?code=${num[0]}&description=${letr.join(' ')}&unwrap=${unwrap}`
        this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?code=${num[0]}&description=${letr.join(' ')}`
        this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?code=${num[0]}&description=${letr.join(' ')}`
        this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?code=${num[0]}&description=${letr.join(' ')}`
        this.highlights = this.searchBox.trim().split(" ")




        // параметр для примеров
        this.exmplParam = letr.join(' ')
        this.exmplCode = num[0]
      }

      this.$store.dispatch('tnved/saveSearch', this.searchBox)


      this.$store.dispatch("tnved/loadNotes", this.urlNotes).then(()=>{
        this.preloader = false
        setTimeout(()=>{
          // ex-highlight
          let hlElem = document.querySelectorAll('.ex-highlight')
          this.hgLength = hlElem.length
          this.hgActive = 1
          let options = {offset: -150,force: true}
          this.$scrollTo(hlElem[this.hgActive - 1] , 200, options)

          hlElem[this.hgActive - 1].classList.add('yellHl')
        }, 120)
      })
      this.$store.dispatch("tnved/loadExplanations", this.urlExplanations)
      this.$store.dispatch("tnved/loadExamples", this.urlExamples)
    }
  }
}
</script>

<style scoped>
.search{
    width: 100%;
}

#usual{
  background: #333333;
	position: sticky;
	z-index: 8;
	top: 0;
  margin-bottom: 20px;
	left: 0;
  padding: 15px;
}
@media (min-width: 1904px){
    #usual .container {
        max-width: 1300px;
    }
}
#tvedResult .black-txt{
    margin-bottom: 0;
}
#tvedResult h2{
    margin-bottom: 0;
}
.tnved-slide{
    opacity: 1;
    margin-top: 0;
}
</style>
