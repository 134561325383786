import axios from "axios";
import Vue from "vue";
import { RS } from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";
import { downloadFile } from "@/helpers/documents";

const documentActions = documentActionsFactory(RS);
export default {
  namespaced: true,
  state: {
    selected: {},
    selectedWares: [],
    editableWare: { id: null, index: null },
    selectedId: null,
    requests: [],
    control: [],
  },
  mutations: {
    ...documentMutations,
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    UPDATE_DECLARATION_PAYMENT(state, items) {
      if (items.length === state.selected.declaration_payments.length) {
        state.selected.declaration_payments.splice(
          0,
          state.selected.declaration_payments.length,
          ...items
        );
      } else {
        items.forEach((item) => {
          const { id } = item;
          const current = state.selected.declaration_payments.find(
            (i) => i.id === id
          );
          const index = state.selected.declaration_payments.indexOf(current);
          state.selected.declaration_payments.splice(index, 1, item);
        });
      }
    },
    ADD_PAYMENTS_ITEM(state, { item, nextItemPosition }) {
      state.selected.declaration_payments.splice(nextItemPosition, 0, item);
    },
    UPDATE_DECLARATION_PAYMENTS(state, payload) {
      state.selected.declaration_payments = payload;
    },
    SET_CONTROL(state, value) {
      state.control = value;
    },
    ADD_REQUEST(state, promise) {
      state.requests.push(promise);
    },
    REMOVE_REQUEST(state, promise = null) {
      if (promise) {
        const idx = state.requests.indexOf(promise);
        state.requests.splice(idx, 1);
      } else {
        state.requests = [];
      }
    },
  },
  actions: {
    ...documentActions,

    getPtdXml({ rootState }, id) {
      const userId = rootState.auth.user.user.id;
      return axios.get(
        `${RS}declaration/exportPtd?declarationId=${id}&userId=${userId}`
      );
    },
    // ОБНОВИТЬ ПОДСЧЕТЫ
    updateDeclarationPayment({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${RS}declarationPayment/updateAll`, data)
        .then((res) => {
          commit("UPDATE_DECLARATION_PAYMENT", data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // СОЗДАТЬ ПОДСЧЕТЫ
    createNewPayment({ commit, state }) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selected.declaration_payments.length !== 0) {
        lastElement =
          state.selected.declaration_payments[
            state.selected.declaration_payments.length - 1
          ];
        if (!lastElement.sort_index) {
          console.log("SORT INDEX NOT FOUND");
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selected.declaration_payments.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      return axios
        .post(
          `${RS}declarationPayment/create?declarationId=${state.selectedId}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_PAYMENTS_ITEM", { item: res.data, nextItemPosition });
          return res;
        });
    },
    // УДАЛИТЬ ПОДСЧЕТЫ
    // deletePaymentItem({ commit, state }, { id }) {
    //   return axios
    //     .post(`${RS}declarationPayment/deleteByIds?declarationId=${state.selectedId}`, [id])
    //     .then((res) => {
    //       commit('REPLACE_WARES_IN_DECLARATION', res.data)
    //       return res
    //     })
    // },
    deletePaymentItem({ commit, state }, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${RS}declarationPayment/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then((res) => {
          commit("UPDATE_DECLARATION_PAYMENTS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    fillerFromDeclarant({ dispatch }, id) {
      return axios
        .post(`${RS}declaration/fillerFromDeclarant?declarationId=${id}`)
        .then((res) => {
          if (res.status === 200) {
            dispatch("getDocumentById", id);
            return res.status;
          }
        });
    },
    fillerFromCab({ dispatch, rootState }, id) {
      const userId = rootState.auth.user.user.id;
      return axios
        .post(
          `${RS}declaration/setUserId?declarationId=${id}&otherUserId=${userId}`
        )
        .then((res) => {
          dispatch("getDocumentById", id);
          return res.status;
        });
    },
    // платежи
    validatePayments({ state, commit }) {
      return axios
        .get(
          `${RS}declaration/validatePayments?declarationId=${state.selected.id}`
        )
        .then((res) => {
          if (res.data) {
            commit("documents/SET_PAYMENT_ERRORS", res.data, { root: true });
          }
          return res;
        });
    },
    calculatePayments({ state }) {
      return axios.post(
        `${RS}declaration/calculatePayments?declarationId=${state.selected.id}`
      );
    },
    // добавление паспорта
    createDocForPassport({ commit, state }, passport_data) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selected.presented_documents.length !== 0) {
        lastElement =
          state.selected.presented_documents[
            state.selected.presented_documents.length - 1
          ];
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selected.presented_documents.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      axios
        .post(
          `${RS}presentedDocument/createDocForPassport?declarationId=${state.selected.id}&sortIndex=${index}&docNumber=${passport_data.docNumber}&docDate=${passport_data.docDate}`
        )
        .then((res) => {
          commit("ADD_DOCUMENT", { item: res.data, nextItemPosition });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadPdf(_, id) {
      return axios.get(`${RS}declaration/pdf?id=${id}`, {
        responseType: "blob",
      });
    },
    orderPdf(_, id) {
      return axios.get(`${RS}declaration/orderPdf?id=${id}`, {
        responseType: "blob",
      });
    },
    sendToEclient(_, { xml, declarationId, userId, divisionId, ptoNumber }) {
      return axios.post(
        `${RS}declaration/sendToEclient?userId=${userId}&divisionId=${divisionId}&declarationId=${declarationId}&ptoId=${ptoNumber}`,
        xml,
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
    },
    fetchGoods(_, id) {
      return axios.get(`${RS}ware/getByDeclarationId?declarationId=${id}`);
    },
    // copyDocument(_, id) {
    //   return axios.post(
    //     `${RS}declaration/copyDeclaration?declarationToCopyId=${id}`
    //   );
    // },
    copyDocument(
      { rootState },
      {
        id,
        copyDocuments,
        copyDeclarant,
        wareIds,
        copyAllWares,
        clearPayments,
        options,
      }
    ) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${RS}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&userId=${uid}` +
        `&copyDeclarant=${copyDeclarant}` +
        `&copyDocuments=${copyDocuments}` +
        `&copyAllWares=${copyAllWares}`+
        `&clearPayments=${clearPayments}`;
      Object.keys(options).forEach((key) => {
        if (options[key] === null) {
          options[key] = false;
        }
        url += `&${key}=${options[key]}`;
      });
      return axios.post(url, wareIds);
    },
    toTrash(_, { id, value }) {
      return axios.post(`${RS}declaration/setTrash?id=${id}&trash=${value}`);
    },
    changePerformer(_, { documentId, personId }) {
      return axios.post(
        `${RS}declaration/setUserId?declarationId=${documentId}&otherUserId=${personId}`
      );
    },
    getDocumentArchive(_, id) {
      axios
        .get(`${RS}order/downloadDocuments?declarationId=${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          if (res.data.size < 1)
            return Vue.prototype.$snackbar({ text: "Документы отсутствуют" });
          downloadFile({ data: res.data, type: "application/zip" });
        });
    },
    changeDeferredStatus(_, { documentId, status }) {
      return axios.post(
        `${RS}order/changeDeferredStatus?isDeferred=${status}&declarationId=${documentId}`
      );
    },
    getOrderId(_, declarationId){
      return axios.get(`${RS}order/getOrderIdByDeclarationId?declarationId=${declarationId}`)
    },
    createPtd({state, rootState}) {
      const declarationId = state.selected.id;
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${RS}declaration/createPtd?declarationId=${declarationId}&userId=${userId}`)
    },
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    // getNsiTransportMarks:(s) => s.nsiTransportMarks,
    getRequests: (s) => s.requests,
    getControl: (s) => s.control,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getEditableWare: (s) => s.editableWare
  },
};
