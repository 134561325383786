<template>
  <v-sheet
    elevation="1"
    rounded
    class="px-4 py-0 mb-3"
    style="position:relative;"
    @dblclick="showSingleCompany"
  >
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <div class="text-body-1 font-weight-medium d-flex align-start">
          <v-icon
            v-if="card.verified"
            color="#03963E"
            small
            class="mt-1 mr-2"
          >
            mdi-check-circle-outline
          </v-icon>
          <v-icon
            v-else-if="card.verified === null"
            color="orange"
            small
            class="mt-1 mr-2"
          >
            mdi-lock
          </v-icon>
          <v-icon
            v-else
            color="red"
            small
            class="mt-1 mr-2"
          >
            mdi-cancel
          </v-icon>
          {{ card.division_name }}
        </div>
        <span
          v-if="card.is_customs_agent_client"
          class="blue--text ml-6 text-body-2"
        >Клиент ТА</span>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <!-- <div class="text-body-2 font-weight-bold">
                    <div class="text-body-2 font-weight-bold">
                        СД (01.01.2022), ДТ (01.05.2022), РС (01.05.2022), API (01.05.2022)
                    </div>
                 </div> -->
        <div
          class="company-card-wrapper"
          style="height:inherit;max-height:100px;min-height:60px;"
        >
          <div
            v-for="(item, i) in card.services"
            :key="i"
            class=" font-weight-bold"
          >
            {{ item }}
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        lg="2"
        class="text-center"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip
              class="font-weight-medium"
              :color="card.sum <= 0 ? 'red' || card.sum === null : '#5CB77B'"
              dark
              v-bind="attrs"
              v-on="on"
              @click="showBalaceHistory(card.id)"
            >
              {{ card.sum === null ? '0' : card.sum.toLocaleString() }} BYN
            </v-chip>
          </template>
          Посмотерть баланс организации
        </v-tooltip>
      </v-col>

      <v-col
        cols="12"
        lg="3"
      >
        <div class="text-body-2 grey--text">
          Менеджер:
        </div>
        <div class="text-body-2 font-weight-bold">
          {{ card.manager }}
        </div>
      </v-col>

            

      <v-col
        lg="1"
        class="text-right"
      >
        <div class="d-flex justify-end align-center">
          <v-btn
            color="#eee"
            elevation="0"
            class="mr-2"
            x-small
            fab
          >
            <v-icon small>
              mdi-printer-outline
            </v-icon>
          </v-btn>
          <v-btn
            color="#eee"
            elevation="0"
            class="mr-2"
            x-small
            fab
            @click="replenishBalance"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-checkbox
            v-model="card.selected"
            class="py-0"
            color="#333"
            hide-details
          />
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    card: {
      required: true,
      type: Object
    }
  },
  methods: {
    showBalaceHistory(id){
      this.$emit('showBalaceHistory', id)
    },
    showSingleCompany(){
      this.$emit('showSingleCompany')
    },
    replenishBalance(){
      this.$emit('replenishBalance')
    }
  }
}
</script>