<template>
  <v-data-table
    :items="items"
    :headers="headers"
    disable-sort
    :items-per-page="15"
    :options="options"
    :server-items-length="total"
    :footer-props="{
      'items-per-page-options':[15,30,50],
      'show-first-last-page':false,
    }"
    @update:items-per-page="setParamsSize"
    @update:page="setParamsPage"
  >
    <template #[`item.user`]="{item}">
      <payment-user
        :payment-user-id="item.user_id"
        :payment-user="item.user_details"
        :current-user-id="user.user.id"
      />
    </template>
    <template #[`item.payment_doc_number`]="{item}">
      <span class="teal--text">{{ item.payment_doc_number }}</span>
    </template>
    <template #[`item.bank`]="{item}">
      <payment-order-bank
        :item="item"
        :banks="banks"
      />
    </template>
    <template #[`item.debitList`]="{item}">
      <debit-table-button :item="item" />
    </template>
    <template #[`item.actions`]="{item}">
      <payment-order-actions :item="item" />
    </template>
  </v-data-table>
</template>
<script>


import PaymentOrderActions from "@/components/payment-orders/payment-order-actions.vue";
import {mapGetters} from "vuex";
import PaymentUser from "@/components/payment-orders/payment-user.vue";
import DebitTableButton from "@/components/payment-orders/debit-table-button.vue"
import PaymentOrderBank from "@/components/payment-orders/payment-order-bank.vue";
export default {
  components: {PaymentOrderBank, PaymentUser, PaymentOrderActions, DebitTableButton},
  props: {
    items: {
      required: true,
      type: Array
    },
    params:{
      required:true,
      type:Object,
    },
    total:{
      required: true,
      type: Number
    },
    banks:{
      required: true,
      type: Array
    }
  },
  data() {
    return {
      paymentOrders: [],
      headers: [
        {
          text: "",
          value: "user"
        },
        {
          text: "Вид",
          value: "code"
        },
        {
          text: "Номер",
          value: "payment_doc_number"
        },
        {
          text: "Дата",
          value: "payment_date_pretty"
        },
        {
          text: "СП",
          value: "payment_type"
        },
        {
          text: "Сумма ПП",
          value: "sumText"
        },
        {
          text: "Остаток",
          value: "remainsText"
        },
        {
          text: "УНП (Личн.номер)",
          value: "owner"
        },
        {
          text: "Банк",
          value: "bank"
        },
        {
          text: "Списания",
          value: "debitList"
        },
        {
          text: "",
          value: "actions"
        },
      ],
    }
  },
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    }),
    options(){
      return {
        page: this.params.page,
        itemsPerPage: this.params.size,
      }
    }
  },
  methods: {
    setParamsSize(size){
      this.$emit('update', {...this.params, size})
    },
    setParamsPage(page){
      this.$emit('update', {...this.params, page})
    },
  }
}
</script>
