<template>
  <v-col
    v-if="dvsn"
    cols="12"
  >
    <v-dialog
      v-model="dialog"
      width="1000"
      persistent
    >
      <v-card color="#EDEDED">
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Загрузка документов
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          <v-form ref="fileForm">
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <label for="">Доверенность или Приказ</label>
                <v-file-input
                  v-model="attorney"
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  :rules="fileRules"
                  clearable
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <label for="">Свидетельство о государственной регистрации</label>
                <v-file-input
                  v-model="registrationCertificate"
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  :rules="fileRules"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <label for="">Устав (4 стр.)</label>
                <v-file-input
                  v-model="charter"
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  :rules="fileRules"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>

              <v-col
                v-if="dvsn.custAgentDocNum"
                cols="12"
                lg="6"
              >
                <label for="">Лицензия таможенного агентства</label>
                <v-file-input
                  v-model="customsAgentCertificate"
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  :rules="fileRules"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#5cb7b1"
            dark
            elevation="0"
            :loading="load"
            @click="uploadDocuments"
          >
            Загрузить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- <pre>{{dvsn}}</pre> -->
    <div class="grey-box">
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <div class="text-h6 font-weight-bold mb-0">
            Номер и дата договора
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="text-right"
        >
          <a :href="`${baseUrl}division/getOfferAgreement?divisionId=${id}`">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="#5CB7B1"
                  dark
                  fab
                  x-small
                  v-on="on"
                >
                  <v-icon
                    color="#fff"
                  >
                    mdi-download-outline
                  </v-icon>
                </v-btn>
              </template>
              Скачать договор
            </v-tooltip>
          </a>
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <label for="">№ Договора оферты</label>
          <v-text-field
            v-model="dvsn.docNumber"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            disabled
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <label for="">Дата договора оферты</label>
          <v-text-field
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            :value="formatDate(dvsn.docDate)"
            disabled
          />
        </v-col>
      </v-row>
    </div>


    <div class="grey-box">
      <v-form ref="form1">
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <div class="text-h6 font-weight-bold mb-0">
              Наименование и Юридический адрес
              <v-btn
                v-if="disabled1"
                color="grey"
                elevation="0"
                dark
                x-small
                fab
                class="ml-2"
                @click="disabled1 = false"
              >
                <v-icon small>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
              <div v-else>
                <v-btn
                  color="grey"
                  elevation="0"
                  dark
                  x-small
                  class="mr-2"
                  @click="disabled1 = true, refreshDvsn()"
                >
                  отмена
                </v-btn>
                <v-btn
                  color="#5cb7b1"
                  elevation="0"
                  dark
                  x-small
                  @click="updateAdress"
                >
                  Сохранить
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="text-right"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="#333"
                  class="mr-2"
                  dark
                  fab
                  x-small
                  v-on="on"
                  @click="downloadFiles"
                >
                  <v-icon
                    color="#fff"
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              Загрузить документы
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="#5CB7B1"
                  dark
                  fab
                  x-small
                  v-on="on"
                  @click="downloadFiles"
                >
                  <v-icon
                    color="#fff"
                  >
                    mdi-download-outline
                  </v-icon>
                </v-btn>
              </template>
              Cкачать документы
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">УНП</label>
            <v-text-field
              v-model="dvsn.taxPayerNum"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="9"
          >
            <label for="">Наименование</label>
            <v-text-field
              v-model="dvsn.divisionName"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :disabled="disabled1"
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">Страна регистрации</label>
            <v-text-field
              v-model="dvsn.countryName"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :disabled="disabled1"
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">Почтовый индекс</label>
            <v-text-field
              v-model="dvsn.postalIndex"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :disabled="disabled1"
              :rules="inputRules"
            />
          </v-col>
          <v-col
            v-if="dvsn.divisionType === 'TA'"
            cols="12"
            lg="3"
          >
            <label for="">Номер лицензии таможенного агентства</label>
            <v-text-field
              v-model="dvsn.custAgentDocNum"
              v-mask="'ТА-####/#######'"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :disabled="disabled1"
              :rules="licenseRules"
              placeholder="ТА-XXXX/XXXXXXX"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">ИП</label>
            <v-checkbox
              v-model="dvsn.individualPerson"
              label="Индивидуальный предприниматель"
              :disabled="disabled1"
              color="#333"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">Реестр</label>
            <v-text-field
              v-model="dvsn.custAgentDocNum"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              :disabled="disabled1"
            />
          </v-col>
          <v-col
            cols="12"
            lg="12"
          >
            <label for="">Адрес (регион, город, номер дома, номер помещения)</label>
            <v-textarea
              v-model="dvsn.fullAddress"
              solo
              flat
              color="#32a1af"
              background-color="#fff"
              hide-details="auto"
              no-resize
              :disabled="disabled1"
              :rules="inputRules"
            />
          </v-col>
          <!-- <v-col
            cols="12"
            lg="12"
            v-if="!dvsn.customsAgentClient"
          >
            <v-btn
              color="#5CB7B1"
              dark
              :to="`/registration-client/${id}`"
            >
              Регистрация клиента ТА
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            v-else
          >
            <div class="text-h6 font-weight-bold mb-4">
              <v-icon
                small
                color="#5CB7B1"
              >
                mdi-check-decagram
              </v-icon> Клиент ТА
            </div>
            <label for="">Номера договора ТА с таможенным агентством</label>
            <v-text-field
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              v-model="dvsn.caClientContract"
              disabled
            />
          </v-col> -->
        </v-row>
      </v-form>
    </div>


    <div
      class="grey-box mb-4"
    >
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <div class="text-h6 font-weight-bold mb-0">
            <v-icon
              v-if="dvsn.customsAgentClient"
              small
              color="#5CB7B1"
            >
              mdi-check-decagram
            </v-icon> Клиент таможенного представителя
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="text-right"
        />
        <v-col
          v-if="dvsn.customsAgentClient"
          cols="12"
          lg="4"
        >
          <label for="">Номера договора ТА с таможенным представителем</label>
          <v-text-field
            v-model="dvsn.caClientContract"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            disabled
          />
        </v-col>
        <v-col
          v-if="dvsn.customsAgentClient"
          cols="12"
          lg="4"
        >
          <label for="">Дата договора</label>
          <v-text-field
            v-model="dvsn.caClientContractDate"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            class="mb-5"
            append-icon="mdi-calendar-blank-outline"
            type="date"
            disabled
          />
        </v-col>
        <v-col
          v-if="!dvsn.customsAgentClient"
          cols="12"
          lg="4"
        >
          <v-btn
            color="#5CB7B1"
            dark
            :to="`/registration-client/${id}`"
          >
            Регистрация
          </v-btn>
        </v-col>
      </v-row>
    </div>


    <div class="grey-box mb-16">
      <div class="text-h6 font-weight-bold mb-0">
        Реквизиты банка
        <v-btn
          v-if="disabled2"
          color="grey"
          elevation="0"
          dark
          x-small
          fab
          class="ml-2"
          @click="disabled2 = false"
        >
          <v-icon small>
            mdi-pencil-outline
          </v-icon>
        </v-btn>
        <div v-else>
          <v-btn
            color="grey"
            elevation="0"
            dark
            x-small
            class="mr-2"
            @click="disabled2 = true, refreshDvsn()"
          >
            отмена
          </v-btn>
          <v-btn
            color="#5cb7b1"
            elevation="0"
            dark
            x-small
            @click="updateBank"
          >
            Сохранить
          </v-btn>
        </div>
      </div>

      <v-form
        v-if="dvsn.bankAccounts.length"
        ref="form2"
      >
        <v-row
          v-for="(account, index) in dvsn.bankAccounts"
          :key="index"
        >
          <v-col
            cols="12"
            lg="12"
          >
            <div class="text-body-1 font-weight-bold mb-0">
              Счет №{{ index+1 }}
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <label for="">Код Банка</label>
            <v-autocomplete
              v-if="banks.length"
              v-model="account.bic_bank"
              solo
              :items="banks"
              hide-details="auto"
              flat
              item-text="CDBank"
              item-color="#32a1af"
              color="#32a1af"
              :disabled="disabled2"
              :rules="inputRules"
              @change="setBankName(index)"
            />
          </v-col>
          <v-col
            cols="12"
            lg="9"
          >
            <label for="">Банк</label>
            <v-text-field
              v-model="account.bank_name"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">IBAN</label>
            <v-text-field
              v-model="account.account_number"
              v-mask="'BY## AAAA #### #### #### #### ####'"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              placeholder="BY## #### #### #### #### #### ####"
              :disabled="disabled2"
              :rules="ibanRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <label for="">Счет по умолчанию</label>
            <v-checkbox
              v-model="account.default"
              label="Основной счет"
              color="#333"
              hide-details
              :disabled="disabled2"
              @change="changeDefaultAccount(index)"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-col
        cols="12"
        lg="12"
        class="text-end"
      >
        <v-btn
          color="#333"
          :disabled="disabled2"
          dark
          class="mt-2"
          @click="addNewAccount()"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Добавить банковский счет
        </v-btn>
      </v-col>
    </div>

    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
    >
      Произошла ошибка
      <template #action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>


    <!-- <v-btn elevation="0" color="#5cb7b1" dark large>Сохранить изменения</v-btn> -->
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import {AUTH} from '@/http-routes'
import axios from 'axios'
import formatDate from '../mixins/format-date.js'

export default {
  mixins: [formatDate],
  props:{
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() => ({
    attorney: [],
    registrationCertificate: [],
    charter: [],
    customsAgentCertificate: [],
    load: false,
    //
    disabled1: true,
    disabled2: true,
    snackbar: false,
    dialog: false,
    //
    banks: [],
    //
    baseUrl: '',
    //
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
    licenseRules: [
      v => v !== null && v.length >= 15 || 'Некорректный номер лицензии'
    ],
    ibanRules: [
      v => v !== null && v.length >= 34 || 'Некорректный номер IBAN'
    ],
    fileRules: [
      // v => !!v.length || 'Поле должно быть заполнено',
      files => !files || !files.some(file => file.size > 5e6) ||
                    'Файл не может превышать размер 5 MB!'
    ],
  }),
  computed: {
    ...mapGetters({
      dvsn: 'auth/getSingleDivision',
      user: "auth/getUser",
    })
  },
  watch: {
    id(){
      this.$store.dispatch('auth/loadSingleDivisions', this.id)
    }
  },
  created(){
    this.baseUrl = AUTH

    const uninterceptedAxiosInstance = axios.create();
    uninterceptedAxiosInstance.get('https://www.nbrb.by/api/bic')
      .then(res =>{
        this.banks = res.data
      })

  },
  mounted(){
    this.$store.dispatch('auth/loadSingleDivisions', this.id)
    this.$store.dispatch("auth/setDivision", parseInt(this.id))
  },
  methods: {
    addNewAccount(){
      let newAcc = {
        default: this.dvsn.bankAccounts.length ? false : true,
        bic_bank: null,
        bank_name: null,
        account_number: null
      }
      this.dvsn.bankAccounts.push(newAcc)
    },
    changeDefaultAccount(i){
      this.dvsn.bankAccounts.forEach(item =>{
        item.default = false
      })
      this.dvsn.bankAccounts[i].default = true
    },
    uploadDocuments(){
      if (this.$refs.fileForm.validate()) {
        this.load = true

        var formData = new FormData();

        for (let index = 0; index < this.attorney.length; index++) {
          formData.append("attorney", this.attorney[index]);
        }
        for (let index = 0; index < this.registrationCertificate.length; index++) {
          formData.append("registrationCertificate", this.registrationCertificate[index]);
        }
        for (let index = 0; index < this.charter.length; index++) {
          formData.append("charter", this.charter[index]);
        }

        if(this.dvsn.custAgentDocNum){
          for (let index = 0; index < this.customsAgentCertificate.length; index++) {
            formData.append("customsAgentCertificate", this.customsAgentCertificate[index]);
          }
        }


        axios.post(`${AUTH}division/uploadDocuments/${this.dvsn.taxPayerNum}`, formData, {
          headers: {
            'Authorization': `Bearer ${this.user.jwtToken}`,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res =>{
            console.log(res)
            this.load = false
            this.dialog = false

            this.attorney = []
            this.registrationCertificate = []
            this.charter = []
            this.customsAgentCertificate = []

          })
          .catch(() => {
            this.load = false
            this.dialog = false
            this.snackbar = true

            this.attorney = []
            this.registrationCertificate = []
            this.charter = []
            this.customsAgentCertificate = []
          })
      }
    },
    downloadFiles() {

      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      let file = `${AUTH}division/downloadDocuments?taxPayerNum=${this.dvsn.taxPayerNum}`;

      let headers = {
        'Authorization': `Bearer ${this.user.jwtToken}`,
      }


      axios.get(file, {headers, responseType: 'blob'}, {})
        .then(response => {
          let objectUrl = window.URL.createObjectURL(response.data);

          anchor.href = objectUrl;
          anchor.download = 'documents.zip';
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        })
    },
    updateInfo(){
      axios.post(`${AUTH}division/saveDivision`, this.dvsn)
        .then(() =>{})
        .catch(() => {
          this.snackbar = true
        })
    },
    updateAdress(){
      if(this.$refs.form1.validate()){
        this.disabled1 = true
        this.updateInfo();
      }
    },
    updateBank(){
      if(this.$refs.form2.validate()){
        this.disabled2 = true
        this.updateInfo();
      }
    },
    setBankName(i){
      let result = this.banks.find(item =>{
        return item.CDBank === this.dvsn.bankAccounts[i].bic_bank
      })

      if(result){
        this.dvsn.bankAccounts[i].bank_name = result.NmBankShort
      }
    },
    refreshDvsn(){
      this.$store.dispatch('auth/loadSingleDivisions', this.id)
    }
  },

}
</script>
