<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Тарифные меры</h3>
        <div class="body-1 white--text font-weight-medium">
          Обновлено: {{ formateData(codePage.maxUpdated) }}
        </div>
      </div>
      <div class="tnved-slide-box-body">
        <!-- Таможенный сбор -->
        <div
          v-if="codePage.commonTarif.sbor.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.sbor[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.sbor"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Пошлина -->
        <div
          v-if="codePage.commonTarif.poshlina.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.poshlina[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.poshlina"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Акциз -->
        <div
          v-if="codePage.commonTarif.akciz.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.akciz[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.akciz"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>
                

        <!-- НДС -->
        <div
          v-if="codePage.commonTarif.nds.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.nds[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.nds"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Антидемпинг -->
        <div
          v-if="codePage.commonTarif.antidemping.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.antidemping[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.antidemping"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>


        <!-- Специальная -->
        <div
          v-if="codePage.commonTarif.special.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.special[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.special"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>

        
        <!-- Обеспечение -->
        <div
          v-if="codePage.commonTarif.obespechenie.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.obespechenie[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.obespechenie"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>


        <!-- Гарантия -->
        <div
          v-if="codePage.commonTarif.garantiya.length"
          class="grey-box"
        >
          <h2 class="mb-0">
            {{ codePage.commonTarif.garantiya[0].nsiTarifList.paymentTypeDetails }}
          </h2>
          <v-divider class="my-3" />
          <div
            v-for="(item, index) in codePage.commonTarif.garantiya"
            :key="index"
            class="d-sm-block d-md-flex d-lg-flex justify-space-between align-start mb-5"
          >
            <div class="t-box1">
              <v-tooltip
                v-if="item.nsiTarifList.longDesc"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt"
                    :class="{caption : index != 0}"
                    v-on="on"
                  >
                    {{ item.longDesc }}
                  </p>
                </template>
                <span>{{ item.nsiTarifList.longDesc }}</span>
              </v-tooltip>
              <p
                v-else
                class="black-txt"
                :class="{caption : index != 0}"
              >
                {{ item.longDesc }}
              </p>
            </div>
            <div class="t-box2 text-center">
              <div class="body-2 py-2">
                {{ item.nsiTarifList.country }}
              </div>
            </div>
            <div class="t-box3">
              <v-tooltip
                v-if="item.documentDetails"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-h5 font-weight-bold"
                    :class="{'text-h6' : index != 0}"
                    v-on="on"
                    v-html="item.rate.fullDesc"
                  />
                </template>
                <span>{{ item.documentDetails }}</span>
              </v-tooltip>
              <div
                v-else
                class="text-h5 font-weight-bold"
                :class="{'text-h6' : index != 0}"
                v-html="item.rate.fullDesc"
              />
                            
              <div
                v-if="item.offDate"
                class="red-date"
              >
                до {{ formateData(item.offDate) }}
              </div>
            </div>
          </div>
        </div>

                


        <!-- <pre>{{codePage}}</pre>       -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    codePage: {
      required: true,
      type: Object
    }
  },
  methods:{
    formateData(d){
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>


<style scoped>
.t-box1{
    width: 50%;
}
.t-box2{
    width: 30%;
}
.t-box3{
    width: 20%;
}
.t-box1 .black-txt{
    width: fit-content;
}
@media (max-width: 512px) {
    .t-box1{
        width: 100%;
    }
}

</style>