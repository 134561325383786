<template>
  <v-col
    cols="12"
    lg="12"
  >
    <v-row>
      <v-col
        cols="12"
        lg="8"
      >
        <v-alert
          color="#F5F5F5"
          icon="mdi-alert"
          elevation="2"
        >
          <div class="text-body-1 black--text font-weight-bold mb-2">
            ВНИМАНИЕ!
          </div>
          <div class="text-body-1 black--text">
            Для расчета и выбора необходимого пакета выберете в Услуге нужное Вам количество и период действия. <br><br>
            <strong>Пакет прекращает свое действие при окончании одного из условий.</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-card
          v-if="balance !== null && dvsn"
          color="#F5F5F5"
          class="pa-4 mb-4 d-flex justify-space-between align-center"
        >
          <div class="text-body-1 black--text">
            Баланс: <span
              style="color:#5cb7b1;"
              class="text-h5 font-weight-bold"
            >{{ balance.toLocaleString() }}  BYN</span>
          </div>
          <v-btn
            class="py-2 rounded"
            dark
            fab
            small
            @click="startAddBalance"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card>
        <!-- <pre>{{activeInvoices}}</pre> -->
        <transition
          name="fade"
          mode="out-in"
        >
          <v-card
            v-if="activeInvoices.length"
            color="#F5F5F5"
            class="pa-4 d-flex justify-space-between align-center"
          >
            <div class="text-body-1  black--text">
              Счет №{{ activeInvoices[activeInvoices.length - 1].invoice_json.number }}
            </div>
            <div>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="py-2 mr-2 rounded"
                    dark
                    fab
                    small
                    color="#5cb7b1"
                    v-bind="attrs"
                    v-on="on"
                    @click="newPdf()"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Скачать</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="py-2 mr-2 rounded"
                    dark
                    fab
                    small
                    color="#5cb7b1"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogPayed = true"
                  >
                    <v-icon>mdi-file-check</v-icon>
                  </v-btn>
                </template>
                <span>Оплачено</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="py-2 rounded"
                    dark
                    fab
                    small
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogDelete = true"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </div>
          </v-card>
        </transition>
        <!-- <pre>{{activeInvoices[activeInvoices.length - 1].invoice_json}}</pre> -->
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      class="mt-8"
      color="#5CB7B1"
      background-color="#f4f4f4"
    >
      <v-tab>
        <v-icon
          left
          small
          class="mr-3"
        >
          mdi-star-check
        </v-icon> Купленные
      </v-tab>
      <v-tab>
        <v-icon
          left
          small
          class="mr-3"
        >
          mdi-archive-plus
        </v-icon> Купить
      </v-tab>
      <v-tab>
        <v-icon
          left
          small
          class="mr-3"
        >
          mdi-trash-can
        </v-icon> Архив
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <services-available />
      </v-tab-item>
      <v-tab-item>
        <services-for-buy @slideToAvailable="slideToAvailable()" />
      </v-tab-item>
      <v-tab-item>
        <services-archived @slideToAvailable="slideToAvailable()" />
      </v-tab-item>
    </v-tabs-items>




    <!-- сумма баланса -->
    <v-dialog
      v-model="dialogMoney"
      width="350"
    >
      <v-form
        ref="paymentAmount"
        @submit.prevent
      >
        <v-card>
          <v-toolbar
            color="#333"
            dark
          >
            <div class="text-h5">
              Пополнение баланса
            </div>
          </v-toolbar>
          <div class="pa-6">
            <label for="">Введите сумму</label>
            <v-text-field
              v-model="summa"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              outlined
              dense
              suffix="BYN"
              :rules="inputRules"
            />
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333"
              text
              @click="dialogMoney = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="#32a1af"
              dark
              @click="acceptBank"
            >
              Далее
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>




    <v-dialog
      v-model="dialogDelete"
      width="550"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Подтвердить действие
          </div>
        </v-toolbar>
        <div class="pa-6">
          <div
            v-if="activeInvoices.length"
            class="text-body-1 black--text"
          >
            Удалить счет <strong>№{{ activeInvoices[activeInvoices.length - 1].invoice_json.number }}</strong>?
          </div>
        </div>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialogDelete = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="red"
            dark
            @click="changeInvoice(false)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- dialogPayed -->
    <v-dialog
      v-model="dialogPayed"
      width="550"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Подтвердить действие
          </div>
        </v-toolbar>
        <div class="pa-6">
          <div
            v-if="activeInvoices.length"
            class="text-body-1 black--text"
          >
            Подтвердить оплату счета <strong>№{{ activeInvoices[activeInvoices.length - 1].invoice_json.number }}</strong>?
          </div>
        </div>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialogPayed = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#5cb7b1"
            dark
            @click="changeInvoice(true)"
          >
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>






    <!-- replenish the balance -->
    <v-dialog
      v-model="dialog"
      width="550"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Уточнение данных
          </div>
        </v-toolbar>
        <div class="pa-6">
          <v-row>
            <v-col cols="12">
              <div class="text-body-1 black--text">
                Для формирования счета, пожалуйста уточните некоторые сведения.
              </div>
            </v-col>
            <v-col
              v-if="dvsn"
              cols="12"
              lg="12"
            >
              <label for="">Код Банка</label>
              <v-select
                v-model="selectedAccount"
                :items="dvsn.bankAccounts"
                solo
                color="#32a1af"
                item-color="#32a1af"
                background-color="white"
                flat
                class="mb-2"
                hide-details="auto"
                outlined
                dense
                :rules="inputRules"
                item-text="account_number"
                item-value="account_number"
              />
            </v-col>
            <!-- <v-col cols="12" lg="6">
                                    <label for="">Банк</label>
                                    <v-text-field color="#32a1af" background-color="#fff" solo hide-details="auto" flat outlined dense />
                                </v-col>
                                <v-col cols="12" lg="12">
                                    <label for="">IBAN</label>
                                    <v-text-field color="#32a1af" background-color="#fff" solo hide-details="auto" flat outlined dense />
                                </v-col>
                                <v-col cols="12" lg="12">
                                    <v-checkbox color="#333" label="Реквизиты по умолчанию" />
                                </v-col> -->
          </v-row>
        </div>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#5CB7B1"
            dark
            :loading="load"
            @click="replenishBalance()"
          >
            Пополнить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-snackbar
      v-model="snackbarNoBank"
      color="#333"
    >
      Для пополнения баланса необходимо <br> указать банковский счет!
      <template #action="{ attrs }">
        <v-btn
          color="#5cb7b1"
          text
          v-bind="attrs"
          :to="`/new-division/${user.user.activeDivisionId}`"
          @click="snackbar = false"
        >
          Указать
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarPayed"
      color="#333"
    >
      <v-icon color="#5cb7b1">
        mdi-check
      </v-icon>
      Оплата сформирована
    </v-snackbar>

    <v-snackbar
      v-model="snackbarNewPackage"
      color="#333"
    >
      <v-icon color="#5cb7b1">
        mdi-check
      </v-icon>
      Услуга успешно добавлена, она будет активирована при первом обращении к ней
    </v-snackbar>

    <v-snackbar
      v-model="snackbarNullBill"
      color="red"
    >
      <v-icon color="#fff">
        mdi-alert
      </v-icon>
      <span v-if="activeInvoices.length">
        Не возможно создать новый счет. Пожалуйста, обработайте счет № {{ activeInvoices[activeInvoices.length - 1].invoice_json.number }} - удалите его или нажмите "оплачено".
      </span>
    </v-snackbar>
  </v-col>
</template>

<script>
import formatDate from '@/mixins/format-date.js'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ServicesForBuy from '@/components/billing/services-for-buy.vue'
import ServicesAvailable from '@/components/billing/services-available.vue'
import ServicesArchived from '@/components/billing/services-archived.vue'
import {AUTH} from '@/http-routes'


var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {ServicesForBuy, ServicesAvailable, ServicesArchived},
  mixins: [formatDate],
  data:() => ({
    snackbarNullBill: false,
    snackbarNewPackage: false,
    snackbarNoBank: false,
    snackbarPayed: false,
    summa: '',
    tab: 0,
    dialog: false,
    dialogDelete: false,
    dialogPayed: false,
    dialogMoney: false,
    amounts: ['600', '800', '1000'],
    amount: '',
    period: '',
    periods: ['6 месяцев', '1 год', '2 года'],
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
    selectedAccount: '',
    load: false
  }),
  computed: {
    ...mapGetters({
      servicesForBuy: "billing/getServicesForBuy",
      user: "auth/getUser",
      balance: "billing/getBalance",
      dvsn: "auth/getSingleDivision",
      activeInvoices: "billing/getActiveInvoices"
    })
  },
  created(){
    this.$store.dispatch('auth/loadSingleDivisions', this.user.user.activeDivisionId)
    this.$store.dispatch("billing/loadInvoices", this.user.user.activeDivisionId)
    this.$store.dispatch("billing/loadPackagesForBuyer")
    this.$store.dispatch("billing/loadBallance", this.user.user.activeDivisionId)
    this.$store.dispatch("billing/loadPurchasedPackages", this.user.user.activeDivisionId)
  },
  methods: {
    startAddBalance(){
      if(this.dvsn.bankAccounts.length){
        // this.activeInvoices[this.activeInvoices.length - 1].active === null)
        if(!this.activeInvoices.length){
          this.dialogMoney = true
        }else{
          this.snackbarNullBill = true
        }
      }else{
        this.snackbarNoBank = true
      }
    },
    newPdf(){
      let lastInvoice = this.activeInvoices[this.activeInvoices.length - 1].invoice_json
      var newPdf = {
        content: [
          { text: `Счет № ${lastInvoice.number}`, bold: true, fontSize: 14, marginBottom: 20, alignment: 'center' },
          {
            columns: [
              {
                text: `${this.formatDate(lastInvoice.action_date)}`, marginBottom: 20, fontSize: 10
              },
              {
                text: `г. Минск, Республика Беларусь`, alignment: 'right', marginBottom: 20, fontSize: 10
              },
            ]
          },
          {
            text: [
              {text: 'Является неотъемлемым приложением к', fontSize: 10},
              { text: ` Договору оферты № ${this.dvsn.docNumber} от ${this.formatDate(this.dvsn.docDate)}.`, bold: true, fontSize: 10 }
            ]
          },
          { text: `${lastInvoice.bank_requisites}`, marginBottom: 20, fontSize: 10, marginTop: 20},
          { text: `Стоимость услуг`, bold: true, fontSize: 12, marginBottom: 20, alignment: 'center' },
          {
            table: {
              widths: [ '*', 'auto' ],
              body: [
                [ 'Услуга', 'Стоимость' ],
                [ 'Пополнение баланса', `${lastInvoice.sum} BYN` ]
              ]
            }
          },
          { text: `ИТОГО К ОПЛАТЕ: ${lastInvoice.sum} BYN (${lastInvoice.sum_letter})`,
            bold: true, fontSize: 10, marginBottom: 20, marginTop: 20},
          { text: `НАЗНАЧЕНИЕ ПЛАТЕЖА:  пополнение баланса согласно Договору оферты № ${this.dvsn.docNumber} от ${this.formatDate(this.dvsn.docDate)}.`,
            bold: true, fontSize: 10, marginBottom: 20},
        ]
      }
      pdfMake.createPdf(newPdf).open();
    },
    slideToAvailable(){
      this.tab = 0
      this.snackbarNewPackage = true
    },
    changeInvoice(status){
      let invoice = this.activeInvoices[this.activeInvoices.length - 1]
      invoice.active = status
      axios.post(`${AUTH}divisionInvoice/save`, invoice)
        .then(() =>{
          this.activeInvoices.pop()
          this.dialogDelete = false
          this.dialogPayed = false
          setTimeout(()=>{
            this.$store.dispatch("billing/loadInvoices", this.user.user.activeDivisionId)
          }, 400)
        })

    },
    replenishBalance(){
      this.load = true
      axios.post(`${AUTH}divisionInvoice/create?divisionId=${this.user.user.activeDivisionId}&sum=${this.summa}`)
        .then(() =>{
          this.load = false
          this.snackbarPayed = true
          this.dialog = false
          this.$store.dispatch("billing/loadInvoices", this.user.user.activeDivisionId)
        })
    },
    acceptBank(){
      if (this.$refs.paymentAmount.validate()) {
        this.dialogMoney = false
        this.dialog = true
        let defaultAcc = this.dvsn.bankAccounts.find(item =>{
          return item.default === true
        })
        if(defaultAcc){
          this.selectedAccount = defaultAcc.account_number
        }
      }
    }
  }
}
</script>

<style scoped>
#usual{
    min-height: calc(100vh - 129px);
}
.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  transform: translateX(20px);
}
</style>
