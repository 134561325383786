<template>
  <section
    id="usual"
    class="pb-16"
  >
    <v-container v-if="services.length">
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <v-breadcrumbs
            class="pa-0"
            :items="breadcrumbs"
          />
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <h2>Услуги</h2>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="filteredServ"
            flat
            background-color="white"
            :items="services"
            item-color="#32a1af"
            color="#32a1af"
            solo
            hide-details
            placeholder="Услуга"
            clearable
            outlined
            dense
            item-text="name"
            item-value="id"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="filteredActivation"
            flat
            background-color="white"
            :items="activations"
            item-color="#32a1af"
            color="#32a1af"
            solo
            hide-details
            placeholder="Активация"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
          class="text-end"
        >
          <!-- смена вида -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                elevation="0"
                max-width="40px"
                min-width="40px"
                height="40px"
                v-bind="attrs"
                @click="listView = !listView"
                v-on="on"
              >
                <v-icon v-if="!listView">
                  mdi-format-list-bulleted
                </v-icon>
                <v-icon v-else>
                  mdi-view-grid-outline
                </v-icon>
              </v-btn>
            </template>
            <span v-if="!listView">Список</span>
            <span v-else>Плитка</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!--  -->
      <v-row v-if="listView">
        <v-col
          cols="12"
          lg="12"
          sm="12"
        >
          <new-service-list />
        </v-col>
        <v-col
          v-for="(card, i) in servPackages"
          :key="i"
          cols="12"
          lg="12"
          :class="[(!filterByServ(card) ? 'hidden' : '' ), (!filterByActive(card) ? 'hidden' : '' )]"
        >
          <service-card-list
            :card="card"
            :services="services"
            @openPop="openPop(i)"
          />
        </v-col>
      </v-row>


      <v-row v-else>
        <v-col
          cols="12"
          lg="4"
          sm="6"
        >
          <new-service />
        </v-col>
        <v-col
          v-for="(card, i) in servPackages"
          :key="i"
          cols="12"
          lg="4"
          sm="6"
          :class="[(!filterByServ(card) ? 'hidden' : '' ), (!filterByActive(card) ? 'hidden' : '' )]"
        >
          <service-card
            :card="card"
            :services="services"
            @openPop="openPop(i)"
          />
          <!-- {{card}} -->
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import formatDate from '@/mixins/format-date.js'
import NewService from '@/components/billing/new-service.vue'
import NewServiceList from '@/components/billing/new-service-list.vue'
import ServiceCard from '@/components/billing/service-card.vue'
import ServiceCardList from '@/components/billing/service-card-list.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'


export default {
  components: {NewService, ServiceCard, NewServiceList, ServiceCardList},
  mixins: [formatDate],
  data(){
    return{
      listView: false,
      filteredServ: null,
      filteredActivation: null,
      activations: ['Еще неактивна', 'Активна', 'Архив']
    }
  },
  computed: {
    ...mapGetters({
      services: 'billing/getServices',
      servPackages: 'billing/getServPackages'
    }),
    breadcrumbs(){
      let breadcrumbs = [
        {
          text: 'Главная',
          disabled: false,
          href: '/',
        },
        {
          text: 'Услуги',
          disabled: true,
        },
      ]
      return breadcrumbs
    },
  },
  created(){
    this.$store.dispatch("billing/loadServices")
    this.$store.dispatch("billing/loadServPackages")
  },
  methods: {
    filterByServ(card){
      if(this.filteredServ === null){
        return true
      }
      if(card.parent_id == this.filteredServ){
        return true
      }else{
        return false
      }
    },
    filterByActive(card){
      var a = moment(new Date, 'DD.MM.YYYY');
      var b = moment(this.formatDate(card.d_on), 'DD.MM.YYYY');
      var c = moment(this.formatDate(card.d_off), 'DD.MM.YYYY');
      var diffDays = b.diff(a, 'days');
      var diffDaysOff = c.diff(a, 'days');

      if(this.filteredActivation === null){
        return true
      }

      if(this.filteredActivation === 'Архив'){
        if(card.d_off && diffDaysOff < 0){
          return true
        }else{
          return false
        }

      }else if(this.filteredActivation === 'Еще неактивна'){
        if(diffDays > 0){
          return true
        }else{
          return false
        }
      }else{
        if(diffDays > 0){
          return false
        }else{
          return true
        }
      }
    }
  },
}
</script>

<style scoped>
#usual{
    min-height: calc(100vh - 129px);
}
</style>
