import './global'
import 'prismjs'
import 'prismjs/themes/prism.css'
import * as Sentry from "@sentry/vue";

import './http-common';

import "./register-service-worker";

import Vue from 'vue'
import App from './app.vue'

import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTheMask from 'vue-the-mask'
import VueScrollTo from 'vue-scrollto'
import TextHighlight from 'vue-text-highlight';
import VueSocialSharing from 'vue-social-sharing'
import Portal from 'portal-vue'
import VueClipboard from 'vue-clipboard2'
import Vuetify from 'vuetify'
import Directives from "./plugins/directives.plugin"
import 'vuetify/dist/vuetify.min.css'
import {setError, setInfo, setSnackbar, setSuccess, setWarning} from './helpers/ui'
import {checkAuthority, isAllowed} from './helpers/authorities'
import { broadcastChannelFactory } from "@/helpers/broadcast";

Vue.config.productionTip = false

Vue.prototype.$snackbar = setSnackbar
Vue.prototype.$success = setSuccess
Vue.prototype.$info = setInfo
Vue.prototype.$error = setError
Vue.prototype.$warning= setWarning
Vue.prototype.$can = checkAuthority // Одиночная запись
Vue.prototype.$allowed = isAllowed // Массив - все должны быть true
Vue.prototype.$broadcastChannel = broadcastChannelFactory()
import 'swiper/css/swiper.css'

Vue.use(Directives)
Vue.use(VueTheMask)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuetify)
Vue.component('TextHighlight', TextHighlight);
Vue.use(VueScrollTo)
Vue.use(VueSocialSharing);
Vue.use(VueClipboard)
Vue.use(Portal)

import ru from 'vuetify/es5/locale/ru'

if(process.env.NODE_ENV === 'production'){
  Sentry.init({
    Vue,
    dsn: "https://fb7d2a44a40245289e6aa3099352ceb0@o4504961684733952.ingest.sentry.io/4504961686765568",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["10.128.8.142","web.declarant.by"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}




new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru'
    }
  }),
  render: h => h(App)
}).$mount('#app')
