<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Интеллектуальная собственность</h3>
        <div class="body-1 white--text font-weight-medium">
          Обновлено: {{ formateData(intellectual.maxUpdated) }}
        </div>
      </div>
      <!-- <pre>{{intellectual}}</pre> -->
      <div
        v-if="intellectual.intellectualProperties.length"
        class="tnved-slide-box-body"
      >
        <div
          v-for="(item, index) in intellectual.intellectualProperties"
          :key="index"
          class="grey-box"
        >
          <div class="row">
            <div class="col-lg-12 py-0">
              <h2 class="mb-2 ">
                {{ item.id }}
              </h2>
            </div>
          </div>
                        

          <div class="row px-3">
            <div class="col-lg-4">
              <p class="black-txt">
                {{ item.regNumber }}
              </p>
            </div>
            <div class="col-lg-4">
              <p class="black-txt">
                Дата включения в реестр: {{ formateData(item.dOn) }}
              </p>
            </div>
            <div class="col-lg-4">
              <v-tooltip
                v-if="item.copyrightHolderInfo"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt text-decoration-underline font-weight-bold"
                    v-on="on"
                  >
                    Сведения о правообладателе
                  </p>
                </template>
                <span>{{ item.copyrightHolderInfo }}</span>
              </v-tooltip>
            </div>

            <div class="col-lg-4">
              <p class="black-txt">
                {{ item.certificateInfo }}
              </p>
            </div>
            <div class="col-lg-4">
              <p class="black-txt">
                Дата окончания срока: {{ formateData(item.dOff) }}
              </p>
            </div>
            <div class="col-lg-4">
              <v-tooltip
                v-if="item.representCopyrightInfo"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    class="black-txt text-decoration-underline font-weight-bold"
                    v-on="on"
                  >
                    Сведения о представителях правообладателя
                  </p>
                </template>
                <span>{{ item.representCopyrightInfo }}</span>
              </v-tooltip>
            </div>
          </div>
                    

          <v-sheet
            rounded="lg"
            class=" px-5 py-8 mb-5"
          >
            <div class="d-sm-block d-md-flex d-lg-flex justify-start align-start">
              <img
                v-if="item.image"
                :src="`data:image/png;base64, ${item.image}`"
                width="100"
                class="mr-5"
              >
              <div
                v-if="item.image == null"
                class="mr-5 font-weight-bold"
                style="width:100px;"
              >
                {{ item.shortDescription }}
              </div>
              <div class="light-txt">
                <div class="body-1 mb-6">
                  {{ item.goodsList.substr(0, getOpenedIndex(index)) }}
                  <v-btn
                    v-if="item.goodsList.length > 500"
                    x-small
                    class="px-0" 
                    color="#EDEDED"
                    @click="openGL(index)"
                  >
                    <v-icon v-if="!openedGoodLists.includes(index)">
                      mdi-dots-horizontal
                    </v-icon>
                    <v-icon v-else>
                      mdi-eye-off-outline
                    </v-icon>
                  </v-btn>
                </div>
                                 
                <div class="body-1">
                  {{ item.tnVedCodesList.replaceAll(',', ', ').substr(0, getOpenedCodes(index)) }}
                  <v-btn
                    v-if="item.goodsList.length > 500"
                    x-small
                    class="px-0" 
                    color="#EDEDED"
                    @click="openCL(index)"
                  >
                    <v-icon v-if="!openedCodes.includes(index)">
                      mdi-dots-horizontal
                    </v-icon>
                    <v-icon v-else>
                      mdi-eye-off-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-sheet>

          <v-chip-group
            v-if="item.tagsList"
            column
          >
            <v-chip
              v-for="tag in item.tagsList.split(', ')"
              :key="tag"
              label
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>

      <div
        v-else
        class="tnved-slide-box-body"
      >
        <p class="grey-txt">
          Интеллектуальная собственность отсутствует
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    intellectual: {
      required: true,
      type: Object
    }
  },
  data(){
    return{
      openedGoodLists: [],
      openedCodes: []
    }
  },
  methods:{
    formateData(d){
      return moment(d).format('DD.MM.YYYY')
    },
    getOpenedIndex(index){
      if(this.openedGoodLists.includes(index)){
        return 2000000
      }else{
        return 500
      }
    },
    getOpenedCodes(index){
      if(this.openedCodes.includes(index)){
        return 2000000
      }else{
        return 500
      }
    },
    openGL(index){
      if(this.openedGoodLists.includes(index)){
        let delInd = this.openedGoodLists.indexOf(index)
        this.openedGoodLists.splice(delInd, 1)
        return
      }
      this.openedGoodLists.push(index)
    },
    openCL(index){
      if(this.openedCodes.includes(index)){
        let delInd = this.openedCodes.indexOf(index)
        this.openedCodes.splice(delInd, 1)
        return
      }
      this.openedCodes.push(index)
    }
  }
}
</script>

<style scoped>
.code-txt:hover {
    text-decoration: none;
    color: #333;
}
.col-lg-4{
    padding: 0;
}
@media (max-width: 512px) {
    .light-txt{
        width: 100%;
    }
}
</style>