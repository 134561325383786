import axios from "axios";
import {DT} from "@/http-routes";

export default {
  createPaymentOrder({rootState}, payload) {
    const userId = rootState?.auth?.user?.user?.id;
    return axios.post(`${DT}paymentOrder/create?userId=${userId}`, payload)
  },
  getPaymentOrderById(_, id){
    return axios.get(`${DT}paymentOrder/getById?id=${id}`)
  },
  getPaymentOrderList(_, {
    divisionId, owner, currencyLetter, code
  }) {
    return axios.get(`${DT}paymentOrder/getByDivisionId?divisionId=${divisionId}&owner=${owner}&currencyLetter=${currencyLetter}&code=${code}`)
  },
  createPaymentOrderDebit(_, payload) {
    return axios.post(`${DT}paymentOrderDebiting/create`, payload)
  },
  getDebitPaymentsList({state}) {
    const declarationId = state.selected.id;
    return axios.get(`${DT}paymentOrderDebiting/getByDeclarationId?declarationId=${declarationId}`)
  },
  deleteDebitPayment(_, id) {
    return axios.post(`${DT}paymentOrderDebiting/delete?id=${id}`)
  },
  downloadPaymentsPdf(_, id) {
    return axios.get(`${DT}declaration/paymentsPdf?declarationId=${id}`, {
      responseType: "blob"
    })
  },
  getJournal(_, params) {
    let url = `${DT}paymentOrder/getJournal`
    Object.entries(params)
      .filter(([,value]) => value !== "" && value !== null)
      .forEach(([key, value], index) => {
        const specialChar = index === 0 ? "?" : "&"
        if(key === "search"){
          if(value.value){
            url += `${specialChar}${value.condition}=${value.value}`
          }
        }else {
          url += `${specialChar}${key}=${value}`
        }
      })
    return axios.get(url)
  },
  deletePaymentOrder(_, id) {
    return axios.get(`${DT}paymentOrder/deleteById?id=${id}`)
  }
}
