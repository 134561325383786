<template>
  <div
    class="pb-16 mx-auto mt-10 px-5"
    style="max-width: 1440px"
  >
    <notification-form />
  </div>
</template>
<script>
import NotificationForm from "@/components/notification/form/index.vue";
export default {
  components: {
    NotificationForm,
  },
};
</script>
