<template>
  <v-menu
    v-if="item.bank_name"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <span
        v-bind="attrs"
        class="font-weight-medium"
        v-on="on"
        @click="getDetails(item.bank_name)"
      >
        {{ item.bank_name }}
      </span>
    </template>
    <v-list
      v-if="details"
      max-width="350"
    >
      <v-list-item class="text-subtitle-2">
        {{ details.NmBankShort | quotes }}
      </v-list-item>
      <v-list-item class="text-sm-caption">
        БИК: {{ item.bank_name }} <br>
        Адрес: {{ details.AdrBank }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>

export default {
  filters:{
    quotes(text){
      return text.replaceAll("'", "\"")
    }
  },
  props:{
    item:{
      type:Object,
      required:true
    },
    banks:{
      type:Array,
      required:true
    }
  },
  data(){
    return{
      details:null
    }
  },
  methods:{
    getDetails(bankName){
      this.details = this.banks.find(bank => bank.CDBank === bankName)
    }
  }
}
</script>