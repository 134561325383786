<template>
  <section id="login">
    <v-container>
      <v-row>
        <v-col
          lg="5"
          style="margin: 0 auto;"
        >
          <div class="grey-box text-center px-16">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Войти
            </div>

            <v-form
              ref="form"
              @submit.prevent="submit"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Логин</label>
                  <v-text-field
                    v-model.trim="login"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    placeholder="Введите логин"
                    tabindex="1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Пароль</label>
                  <v-text-field
                    v-model.trim="password"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    placeholder="Введите пароль"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="showPassword ? 'text' : 'password'"
                    tabindex="2"
                    @click:append="() => (showPassword = !showPassword)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center mt-2"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="loading"
                    block
                    tabindex="3"
                  >
                    Войти
                  </v-btn>

                  <div
                    v-if="errorMessage"
                    class="red--text mt-2"
                  >
                    {{ errorMessage }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <div class="d-flex justify-space-between">
                    <router-link
                      tag="a"
                      to="/registration"
                      class="blue-link"
                    >
                      Регистрация
                    </router-link>
                    <router-link
                      tag="a"
                      to="/recovery"
                      class="blue-link"
                    >
                      Забыли пароль?
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
import {SIGN_IN_EVENT} from "@/helpers/broadcast";

export default {
  data: () => ({
    errorMessage: "",
    capsAlert: false,
    showPassword: false,
    loading: false,
    login: '',
    password: '',
    ecp: false,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      divisions: 'auth/getDivisions',
    })
  },
  methods: {
    changeEcp(param) {
      this.ecp = param
    },
    notifyOtherTabs() {
      this.$broadcastChannel.postMessage({
        type: SIGN_IN_EVENT,
      })
    },
    getErrorMessage(err) {
      const defaultMessage = 'Ошибка авторизации'
      if([400, 401].includes(+err.response.status)){
        return err?.response?.data?.message
        && typeof err.response.data.message === 'string'
          ? err.response.data.message
          : defaultMessage
      }
      return defaultMessage
    },
    submit() {
      this.errorMessage = ''
      this.loading = true
      const payload = {
        email: this.login,
        password: this.password,
      }
      this.$store.dispatch('auth/AUTH_REQUEST', payload).then(() => {
        this.loading = false
        this.$store.dispatch('auth/loadInvites')
        this.$store.dispatch('auth/loadDivisions')
        this.$router.replace("/");
        this.notifyOtherTabs()
      }).catch(err => {
        this.loading = false
        this.errorMessage = this.getErrorMessage(err)
      });
    }
  },

}
</script>