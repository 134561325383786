<template>
  <div>
    <!-- <TopBar /> -->
    <section
      id="usual"
      class="pa-1"
    >
      <div class="container px-0">
        <div class="row">
          <div class="col-lg-12">
            <v-skeleton-loader
              v-if="!tnved.length"
              dark
              type="article"
            />

            <v-form
              v-if="tnved.length"
              ref="searchForm"
              @submit.prevent="goSearch()"
            >
              <div class="search2 d-flex justify-start align-start">
                <v-text-field
                  ref="searchBox"
                  v-model="searchBox"
                  color="#32a1af"
                  background-color="#fff"
                  filled
                  label="Введите код, слово, комбинацию"
                  :rules="[rules.req]"
                  :error-messages="searchErr"
                  required
                  hide-details="auto"
                >
                  <template #append>
                    <div class="d-none d-lg-flex justify-end align-center">
                      <div class="search-mums mr-2">
                        {{ hgActive }}\{{ hgLength }}
                      </div>
                      <v-btn
                        icon
                        small
                        @click="scrollToPrevHl"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        @click="scrollToNextHl"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        @click="clearSearch()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-text-field>
                <v-btn
                  color="#5CB7B1"
                  dark
                  x-large
                  @click="goSearch()"
                >
                  <v-icon
                    left
                    :color="'#fff'"
                  >
                    mdi-magnify
                  </v-icon>Поиск
                </v-btn>
              </div>
            </v-form>

            <div
              v-if="showChips && tnved.length"
              class="d-lg-flex align-center"
            >
              <v-checkbox
                v-model="shortSearch"
                label="Разворачивать по порядку"
                color="#5CB7B1"
                hide-details
                class="mr-5 d-none d-md-flex d-lg-flex"
                dark
              />

              <v-chip-group
                v-model="activeIndex"
                active-class="active-tnved-tag"
                column
                mandatory
              >
                <v-chip
                  v-for="(tag, index) in tags"
                  :key="index"
                  v-scroll-to="'header'"
                  class="mr-2"
                  :value="index"
                  style="overflow: visible"
                  @click="changePage(tag.link)"
                >
                  <span>{{ tag.name }}</span>
                </v-chip>
              </v-chip-group>
            </div>

            <v-progress-linear
              v-if="preloader"
              indeterminate
              :color="'#5CB7B1'"
              class="my-2"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="tvedResult">
      <div class="container">
        <div
          v-if="tnved.length"
          class="row"
        >
          <div class="col-lg-12 px-0">
            <div class="tnved-slide">
              <div class="tnved-slide-box">
                <div
                  class="
                    tnved-slide-box-top
                    d-flex
                    justify-space-between
                    align-center
                  "
                >
                  <h3>ETT</h3>
                </div>
              </div>
              <div class="tnved-slide-box-body">
                <v-treeview
                  ref="tnved"
                  :items="tnved"
                  item-key="id"
                  open-on-click
                  :open.sync="opened"
                  class="piz123"
                >
                  <template
                    #prepend="{ item }"
                  >
                    <div
                      v-if="firstChilds.includes(item.id)"
                      :id="`r${item.id}`"
                      @click.stop
                    >
                      <h2>РАЗДЕЛ {{ item.section }}. {{ item.description }}</h2>
                    </div>
                    <div
                      v-else-if="item.code.length == 2"
                    >
                      <div
                        class="d-flex justify-start align-center mb-3 mt-3"
                      >
                        <div
                          ref="razdelCode"
                          class="razdel-code"
                        >
                          {{ item.code }}
                        </div>
                        <h4
                          :id="`r${item.id}`"
                          :class="{ hlRow: checkOpened(item.id) }"
                        >
                          <text-highlight
                            :queries="newHigh"
                          >
                            {{ item.description }}
                          </text-highlight>
                        </h4>
                      </div>
                    </div>
                    <div
                      v-else
                      :id="`r${item.id}`"
                      class="
                        black-txt
                        d-flex
                        flex-wrap
                        justify-start
                        align-flex-start
                        flex-lg-nowrap
                      "
                      :class="{ hlRow: checkOpened(item.id) }"
                    >
                      <div class="txt-box">
                        <div
                          v-if="checkOpened(item.id)"
                          class="code-txt mr-5"
                          @click.stop
                          @click="goToCode(item.code)"
                        >
                          <!-- :class="{addRed : checkOpened(item.id)}" -->
                          <div
                            v-if="item.code === ''"
                            class="green-minus"
                            @click.stop
                          />
                          <text-highlight
                            v-else
                            :queries="newHigh"
                          >
                            {{ customSpaces(item.code) }}
                          </text-highlight>
                        </div>
                        <div
                          v-else
                          class="code-txt mr-5"
                          @click.stop
                          @click="goToCode(item.code)"
                        >
                          <div
                            v-if="item.code === ''"
                            class="green-minus"
                            @click.stop
                          />
                          <span>{{ customSpaces(item.code) }}</span>
                        </div>
                        <div
                          v-if="checkOpened(item.id)"
                          class="light-txt mt-1"
                        >
                          <text-highlight :queries="newHigh">
                            {{ item.description }}
                          </text-highlight>
                        </div>
                        <div
                          v-else
                          class="light-txt mt-1"
                        >
                          {{ item.description }}
                        </div>
                      </div>

                      <div
                        v-if="item.ettRate"
                        class="stavk d-flex justify-space-between align-start"
                      >
                        <div>
                          <span> {{ item.unitDescription }}</span>
                          <span v-if="item.addUnitDescription">,</span>
                          <span> {{ item.addUnitDescription }}</span>
                        </div>
                        <v-divider
                          v-if="item.unitDescription"
                          vertical
                        />
                        <div>
                          <div v-html="item.ettRate" />
                          <div
                            v-if="item.ettDateOff"
                            class="red-date"
                          >
                            до {{ formateData(item.ettDateOff) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-treeview>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-lg-2 text-center mx-auto">
            <h4 class="mb-4">
              Загружаем ТН ВЭД
            </h4>
            <v-progress-linear
              color="#32a1af"
              indeterminate
              rounded
              height="6"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import TopBar from '../../components/tnved/TopBar.vue'
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
import { TNVED } from "@/http-routes";
// import Examples from '@/components/tnved/Examples.vue'
// import Explanations from '../../components/tnved/Explanations.vue'
// import Notes from '@/components/tnved/notes.vue'

export default {
  // components: {Examples, Notes, Explanations},
  props: {
    openOnClick: {
      type: Boolean,
      default: true,
    },
    defaultSearch: {
      type: String,
      default: "",
    },
    defaultShortSearch: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      type: String,
      default: "body",
    },
    showChips: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      idToYellw: "",
      url: "",
      urlExamples: "",
      urlNotes: "",
      urlExplanations: "",
      activeIndex: 1,
      exmplParam: "",
      exmplCode: "",
      opened: [
        1, 2008, 2972, 3243, 5082, 5512, 7499, 8079, 8412, 9029, 9377, 11249,
        11447, 11949, 12080, 14000, 17221, 18617, 19184, 19222, 19711,
      ],
      firstChilds: [
        1, 2008, 2972, 3243, 5082, 5512, 7499, 8079, 8412, 9029, 9377, 11249,
        11447, 11949, 12080, 14000, 17221, 18617, 19184, 19222, 19711,
      ],
      tags: [
        { name: "Примеры декларирования", link: "/examples" },
        { name: "ЕТТ", link: "/tnved" },
        { name: "Пояснения и примечания том I-V", link: "/explanations" },
        { name: "Том VI пояснений", link: "/notes" },
      ],
      searchErr: "",
      shortSearch: false,
      hgLength: 0,
      hgActive: 0,
      //   search: "",  НАДО УДАЛИТЬ ТК НЕ ИСПОЛЬЗУЕТСЯ
      highlights: [],
      dataBase: [],
      lastOpen: [],
      allOpened: false,
      rowId: null,
      preloader: false,
      searchBox: "",
      caseSensitive: false,
      noResults: null,
      searchIds: [],
      rules: {
        // onlyRus: v => /^[?!,.а-яА-ЯёЁ0-9\s]+$/.test(v) || 'Поле может содержать только кириллический шрифт!',
        req: (v) =>
          (!!v && this.searchBox.length >= 3) ||
          "Минимальое кол-во символов: 3",

        // !searchBox && searchBox.length >= 3 || 'Минимальое кол-во символов: 3'
      },
      swiperOptions: {
        slidesPerView: 1,
        createdSlides: true,
        touchRatio: 0,
        spaceBetween: 30,
        draggable: true,
        simulateTouch: false,
        loop: false,
        infinite: true,
        speed: 600,
        navigation: {
          nextEl: ".bigNext",
          prevEl: ".bigPrev",
        },
      },

      highliten: [],
      newHigh: [],
      loadingPromise:null
    };
  },
  computed: {
    ...mapGetters({
      tnved: "tnved/getDataBase",
      declarationExamples: "tnved/getExamples",
      notes: "tnved/getNotes",
      explanations: "tnved/getExplanations",
      saveSearch: "tnved/getSaveSearch",
    }),
  },
  watch: {
    tnved() {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        var razdel = tvedResult.querySelectorAll(".razdel-code");

        razdel.forEach((item) => {
          let parDiv = item.parentNode.parentNode.parentNode.parentNode;
          parDiv.parentNode.classList.add("razdel-code-btn");
        });
      }, 300);
    },
    opened() {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        var razdel = tvedResult.querySelectorAll(".razdel-code");

        razdel.forEach((item) => {
          let parDiv = item.parentNode.parentNode.parentNode.parentNode;
          parDiv.parentNode.classList.add("razdel-code-btn");
        });
      }, 10);
    },
  },
  created() {
    //Если было значение по умолчанию то подставляем его в поиск
    this.shortSearch = this.defaultShortSearch;
    this.loadingPromise = this.$store.dispatch("tnved/loadTnved");
  },
  mounted() {
    if (!["", null].includes(this.defaultSearch)) { // Если есть переданное значение через пропс (обычно справочник)
      this.searchBox = this.defaultSearch
      if(this.loadingPromise){
        this.loadingPromise.then(() => {
          this.goSearch()
          this.loadingPromise = null
        }).catch(() => {
          this.preloader = false
        })
      }
    }else{ // Если нет то проверяем на наличие сохраненного поиска и ищем значение если есть
      this.searchBox = this.saveSearch
      if(this.searchBox){
        this.goSearch()
      }
    }
  },
  methods: {
    customSpaces(code) {
      // 1513 21 900 2
      let space1 = code.substr(0, 4);
      let space2 = code.substr(4, 2);
      let space3 = code.substr(6, 3);
      let space4 = code.substr(9, 1);

      return space1 + " " + space2 + " " + space3 + " " + space4;
    },
    changePage(link) {
      this.$router.replace(link);
    },
    checkOpened(id) {
      // return true
      if (this.searchIds.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    goToCode(code) {
      if (code === "") {
        return;
      }
      if (this.openOnClick) {
        let routeData = this.$router.resolve({ path: `/tnved/${code}` });
        window.open(routeData.href, "_blank");
      } else {
        this.$emit("select", code);
      }
    },
    formateData(d) {
      return moment(d).format("DD.MM.YYYY");
    },
    clearSearch() {
      this.hgLength = 0;
      this.hgActive = 0;
      this.highlights = [];
      this.$refs.searchForm.reset();
      // this.$refs.searchBox.classes.v_input__is_focused = false
      // v-input--is-focused
      this.searchErr = "";
      this.opened = this.firstChilds;
      setTimeout(() => {
        let options = {
          container: this.scrollContainer,
          offset: -260,
          force: true,
        }; // 260
        this.$scrollTo(".tnved-slide-box", 200, options);
      }, 120);
    },
    scrollToPrevHl() {
      if (this.hgActive <= 1) {
        return;
      }
      this.hgActive += -1;
      let options = {
        container: this.scrollContainer,
        offset: -170,
        force: true,
      }; // 260
      this.$scrollTo(`#r${this.searchIds[this.hgActive - 1]}`, 200, options);

      let hlRow = document.querySelectorAll(".hlRow");
      hlRow.forEach((item) => {
        item.classList.remove("yellRow");
      });
      hlRow[this.hgActive - 1].classList.add("yellRow");
    },
    scrollToNextHl() {
      if (this.hgActive == this.hgLength) {
        return;
      }
      this.hgActive += 1;
      let options = {
        container: this.scrollContainer,
        offset: -170,
        force: true,
      }; // 260
      this.$scrollTo(`#r${this.searchIds[this.hgActive - 1]}`, 200, options);

      let hlRow = document.querySelectorAll(".hlRow")
      hlRow.forEach((item) => {
        item?.classList.remove("yellRow");
      });
      hlRow[this.hgActive - 1]?.classList.add("yellRow");
    },
    normalizeString(s) {
      // return s?.replace(/ /g,'') || ""
      // удаление пробелов
      let prevS = "";
      while (prevS !== s) {
        prevS = s;
        s = s.replace(/([0-9]+) ([0-9]+)/, "$1$2");
      }
      return s;
    },
    goSearch() {
      try{
        if(!this.searchBox) return; // Добавил проверку - если пустое значение то запроса не будет - ломается в блоке else
        this.newHigh = [];
        this.searchIds = [];
        this.searchBox = this.normalizeString(this.searchBox);
        this.hgActive = 0;
        this.hgLength = 0;

        // if(!this.$refs.searchForm.validate()){
        //     return
        // }

        this.preloader = true;

        var num = this.searchBox.match(/\d+/g);
        var letr = this.searchBox.match(/[а-яA-Я]+/g);

        var unwrap = 1;
        if (this.shortSearch) {
          unwrap = 0;
        }

        this.exmplCode = "";
        this.exmplParam = "";

        if (num != null && letr == null) {
          this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?code=${num.join(
            ""
          )}&unwrap=${unwrap}`;
          this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?code=${num.join(
            ""
          )}`;
          this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?code=${num.join("")}`;
          this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?code=${num.join(
            ""
          )}`;
          this.searchBox = this.searchBox.replace(/\s/g, "");
          // this.searchBox = this.searchBox.replace(/([0-9]+) ([0-9]+)/, '$1$2');
          this.highlights = this.searchBox.trim().split(" ");

          this.exmplCode = num.join("");
        } else if (letr != null && num == null) {
          this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?&description=${letr.join(
            " "
          )}&unwrap=${unwrap}`;
          this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?&description=${letr.join(
            " "
          )}`;
          this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?&description=${letr.join(
            " "
          )}`;
          this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?&description=${letr.join(
            " "
          )}`;
          this.highlights = this.searchBox.trim().split(" ");

          // параметр для примеров
          this.exmplParam = letr.join(" ");
        } else {
          this.url = `${TNVED}api/read/nsiTnVedsJoinedByCodeAndDescription?code=${
            num[0]
          }&description=${letr.join(" ")}&unwrap=${unwrap}`;
          this.urlExamples = `${TNVED}api/read/nsiTnVedVariantNamesWithGroup?code=${
            num[0]
          }&description=${letr.join(" ")}`;
          this.urlNotes = `${TNVED}api/read/nsiTnVedNotes?code=${
            num[0]
          }&description=${letr.join(" ")}`;
          this.urlExplanations = `${TNVED}api/read/nsiTnVedExplanations?code=${
            num[0]
          }&description=${letr.join(" ")}`;
          this.highlights = this.searchBox.trim().split(" ");

          // параметр для примеров
          this.exmplParam = letr.join(" ");
          this.exmplCode = num[0];
        }

        this.$store.dispatch("tnved/saveSearch", this.searchBox);

        // запрос поиска по тнвэд
        axios.get(this.url).then((res) => {
          let ids = [
            1, 2008, 2972, 3243, 5082, 5512, 7499, 8079, 8412, 9029, 9377, 11249,
            11447, 11949, 12080, 14000, 17221, 18617, 19184, 19222, 19711, 6799,
          ];

          if (!res.data.tnVeds.length) {
            this.preloader = false;
            this.searchErr = "К сожалению ничего не найдено, попробуйте еще раз.";
            return;
          }

          const vm = this;

          function parseObjectKeys(obj) {
            for (var prop in obj) {
              var sub = obj[prop];
              if (prop == "id") {
                ids.push(obj[prop]);
              }
              if (prop == "highlights") {
              // console.log(obj[prop])

                if (obj[prop].length) {
                  obj[prop].forEach((item) => {
                    vm.newHigh.push(item);
                  });
                }
              }
              if (typeof sub == "object") {
                parseObjectKeys(sub);
              }
            }
          }


          parseObjectKeys(res.data.tnVeds);

          this.opened = ids;
          this.highliten = ids;

          this.searchIds = res.data.highlightIds;


          // создание фейковых пробелов для хайлайта
          let fakeHigh = this.newHigh;

          for (let i = 0; i < fakeHigh.length; i++) {
            let isnum = /^\d+$/.test(fakeHigh[i]);
            if (isnum) {
              fakeHigh[i] = this.customSpaces(fakeHigh[i]);
            }
          }

          this.newHigh = fakeHigh;

          // fakeHigh.forEach(item =>{
          //   let isnum = /^\d+$/.test(item);
          //   if(isnum === true){
          //     console.log(item)
          //     item.replace('0', 'f')
          //   }
          // })
          // создание фейковых пробелов для хайлайта

          setTimeout(() => {
            // let hlItem = document.querySelectorAll('.text__highlight');
            this.searchErr = "";
            this.hgLength = this.searchIds.length;
            if (this.searchIds.length) {
              this.hgActive = 1;
            }

            let options = {
              container: this.scrollContainer,
              offset: -250,
              force: true,
            }; // 260

            this.$scrollTo(
              `#r${this.searchIds[this.hgActive - 1]}`,
              200,
              options
            );
            this.preloader = false;

            let hlRow = document.querySelectorAll(".hlRow");

            hlRow[this.hgActive - 1]?.classList.add("yellRow");
          }, 120);
        })

        this.$store.dispatch("tnved/loadExamples", this.urlExamples);
        this.$store.dispatch("tnved/loadNotes", this.urlNotes);
        this.$store.dispatch("tnved/loadExplanations", this.urlExplanations);
      }catch(e){
        this.preloader = false
      }
    },
  },
};
</script>

<style scoped>
.search {
  width: 100%;
}

#usual {
  background: #333333;
  position: sticky;
  z-index: 8;
  top: 0;
  margin-bottom: 20px;
  left: 0;
  padding: 15px;
}
@media (min-width: 1904px) {
  #usual .container {
    max-width: 1300px;
  }
}
#tvedResult .black-txt {
  margin-bottom: 0;
}
#tvedResult h2 {
  margin-bottom: 0;
}
.tnved-slide {
  opacity: 1;
  margin-top: 0;
}
</style>
