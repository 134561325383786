<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3> Льготы и преференции </h3>
        <div class="body-1 white--text font-weight-medium">
          Обновлено: {{ formateData(preferencies.maxUpdated) }}
        </div>
      </div>
      <div class="tnved-slide-box-body">
        <div
          v-for="(pref, index) in preferencies.specificLists"
          :key="index"
          class="grey-box"
        >
          <h4 class="mb-0">
            {{ pref.longDesc }}
          </h4>
          <v-divider class="my-4" />
          <div class="white-box mb-5">
            <div
              v-for="(item, id) in pref.specifics"
              :key="id"
              class="d-sm-block d-md-flex d-lg-flex justify-start align-start mb-8"
            >
              <div class="code-txt mr-5">
                <span>{{ item.nomenclatureCode }}</span>
              </div>
              <div class="light-txt mt-1">
                {{ item.longDesc }}
              </div>
            </div>
          </div>


          <div class="d-flex justify-space-between align-center">
            <v-tooltip
              v-if="pref.documents.length"
              max-width="600"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  class="body-1 px-5"
                  text
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                  <span class="text-decoration-underline">Нормативные документы</span>
                </div>
              </template>
              <div
                v-for="(req, ind) in pref.documents"
                :key="ind"
              >
                <div class="d-flex justify-start align-items-start">
                  <span class="font-weight-bold mr-4">{{ req.code }}</span>
                  <span>{{ req }}</span>
                </div>
                <span
                  v-if="ind !== pref.documents.length - 1"
                  class="my-2"
                >или</span>
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    preferencies: {
      required: true,
      type: Object
    }
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
.code-txt:hover {
    text-decoration: none;
    color: #333;
}
@media (max-width: 512px) {
    .light-txt{
        width: 100%;
    }
}
</style>