const routes = [
  {
    path: "/dts/:id",
    name: "DtsMain",
    component: () => import('@/views/dts/dts-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]
export default routes;
