<template>
  <v-btn
    v-if="authorized"
    dark
    text
    @click="getSignService"
  >
    SignService
  </v-btn>
</template>
<script>
import axios from "axios";
import {AUTH} from "@/http-routes";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  computed:{
    authorized(){
      return !!this.$store.getters['auth/getUser']?.user?.id
    }
  },
  methods:{
    getSignService(){
      axios.get(`${AUTH}user/downloadSignService`, {responseType:'blob'})
        .then(res => {
          const name = getFilenameFromContentDisposition(res)
          downloadFile({
            data:res.data,
            type:'application/x-msdownload',
            name
          })
        }).catch(() => this.$error("Не удалось скачать файл"))
    },
  }
}
</script>
