<template>
  <v-card
    elevation="2"
    class="mb-6"
  >
    <div class="pa-7">
      <v-row>
        <v-col
          cols="12"
          lg="9"
          @dblclick="showSingleCompany"
        >
          <div
            class="text-body-2 font-weight-medium"
            :class="{'mb-6' : !card.is_customs_agent_client}"
          >
            {{ card.division_name.substr(0, 27) }}
            <span v-if="card.division_name.length > 28">...</span>
            <v-icon
              v-if="card.verified"
              color="#03963E"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon
              v-else-if="card.verified === null"
              color="orange"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-else
              color="red"
            >
              mdi-cancel
            </v-icon>
          </div>
          <span
            v-if="card.is_customs_agent_client"
            class="blue--text text-body-2"
          >Клиент таможенного представителя</span>
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <div class="d-flex justify-end align-center">
            <v-icon @click="printIt">
              mdi-printer-outline
            </v-icon>
            <v-checkbox
              v-model="card.selected"
              class="py-0 ml-2"
              color="#333"
              hide-details
              @click.stop
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        style="min-height:184px;"
        @dblclick="showSingleCompany"
      >
        <!-- <pre>{{card}}</pre> -->
        <!-- <v-col cols="12" lg="3">
                    <div class="text-body-2">Услуги:</div>
                </v-col>
                <v-col cols="12" lg="9">
                    <div class="text-body-2 font-weight-bold" v-for="(item, i) in card.services" :key="i">
                        {{item}}
                    </div>
                </v-col> -->
        <v-col
          cols="12"
          lg="12"
        >
          <div class="d-flex align-start justify-space-between">
            <div class="text-body-2 mr-10">
              Услуги:
            </div>
            <div
              class="company-card-wrapper"
              style="height:100px;"
            >
              <div
                v-for="(item, i) in card.services"
                :key="i"
                class=" font-weight-bold"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <div class="text-body-2">
            Баланс:
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="8"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-chip
                class="font-weight-medium"
                :color="card.sum <= 0 ? 'red' || card.sum === null : '#5CB77B'"
                dark
                v-bind="attrs"
                v-on="on"
                @click="showBalaceHistory(card.id)"
              >
                {{ card.sum === null ? '0' : card.sum.toLocaleString() }} BYN
              </v-chip>
            </template>
            Посмотерть баланс организации
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <div class="text-body-2">
            Менеджер:
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="8"
        >
          <div class="text-body-2 font-weight-bold">
            {{ card.manager }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <v-btn
            dark
            block
            x-large
            color="#333333"
            type="submit"
            outlined
            @click="replenishBalance"
          >
            + Пополнить баланс
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    card: {
      required: true,
      type: Object
    }
  },
  methods: {
    showBalaceHistory(id){
      this.$emit('showBalaceHistory', id)
    },
    showSingleCompany(){
      this.$emit('showSingleCompany')
    },
    printIt(){
      console.log(123)
    },
    replenishBalance(){
      this.$emit('replenishBalance')
    }
  }
}
</script>