<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
      md="6"
      sm="6"
    >
      <h2>До обновления</h2>
    </v-col>
    <v-col
      cols="12"
      lg="6"
      md="6"
      sm="6"
    >
      <h2>После обновления</h2>
    </v-col>

    <v-col
      v-for="(item, index) in tnved.items"
      :key="index"
      cols="12"
      lg="6"
      md="6"
      sm="6"
      :offset="getOffset(item.update_type)"
      :class="{offDelete : item.update_type === 'delete'}"
    >
      <update-card
        :card-item="item"
        :field="tnved.table_description.field_description"
        :title="getTitle(item.update_type)"
        :item-before="item.update_type === 'update_after' ? tnved.items[index - 1] : null"
      />
    </v-col>
  </v-row>
</template>

<script>
import UpdateCard from './update-card.vue'

export default {
  components: {UpdateCard},
  props: {
    tnved: {
      required: true,
      type: Object
    }
  },
  computed: {
    updatedBefore(){
      let arr = []
      this.tnved.items.forEach(item =>{
        if(item.update_type === 'update_before'){
          arr.push(item)
        }
      })
      return arr
    },
    updatedAfter(){
      let arr = []
      this.tnved.items.forEach(item =>{
        if(item.update_type === 'update_after'){
          arr.push(item)
        }
      })
      return arr
    },
    insert(){
      let arr = []
      this.tnved.items.forEach(item =>{
        if(item.update_type === 'insert'){
          arr.push(item)
        }
      })
      return arr
    },
  },
  watch: {
    // tnved(){
    //     alert(123)
    //     this.tnved.items.forEach(item =>{
    //         if(item === 'update_before'){
    //             this.updatedBefore.push(item)
    //         }
    //     })
    // }
  },
  methods: {
    getOffset(type){
      if(type === 'insert'){
        return 6
      }else{
        return 0
      }
    },
    getTitle(type){
      if(type === 'update_before'){
        return 'Изменение (Было)'
      }else if(type === 'update_after'){
        return 'Изменение (Стало)'
      }else if(type === 'insert'){
        return 'Добавление'
      }else{
        return 'Удаление'
      }
    }
  }
}
</script>
