<template>
  <section
    id="usual"
    class="pb-16"
  >
    <v-container>
      <v-row>
        <!-- <pre>{{allSellers}}</pre> -->
        <v-col
          cols="12"
          lg="6"
        >
          <v-breadcrumbs
            class="pa-0"
            :items="breadcrumbs"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="search"
            color="#32a1af"
            background-color="white"
            solo
            hide-details="auto"
            flat
            placeholder="Название / УНП / № Договора оферты"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            clearable
            @input="goFilter()"
            @change="goFilter()"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <h2>Компании</h2>
        </v-col>

        <!--  -->

        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="status"
            flat
            background-color="white"
            :items="statuses"
            item-color="#32a1af"
            color="#32a1af"
            solo
            hide-details
            placeholder="Статус"
            outlined
            dense
            item-text="txt"
            item-value="val"
            @change="goFilter()"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="manager"
            flat
            background-color="white"
            :items="allSellers"
            item-color="#32a1af"
            color="#32a1af"
            solo
            hide-details
            placeholder="Менеджер"
            clearable
            outlined
            dense
            item-text="lastName"
            item-value="id"
            @change="goFilter()"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                clearable
                append-icon="mdi-calendar-blank-outline"
                readonly
                v-bind="attrs"
                color="#5CB7B1"
                background-color="white"
                solo
                hide-details="auto"
                flat
                outlined
                dense
                placeholder="Выберите период"
                @click:clear="clearDate()"
                v-on="on"
                @click:append="menu = !menu"
              />
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              range
              scrollable
              color="#5CB7B1"
            >
              <v-spacer />
              <v-btn
                text
                color="#5CB7B1"
                @click="menu = false"
              >
                ОТМЕНА
              </v-btn>
              <v-btn
                text
                color="#5CB7B1"
                @click="updateDate"
              >
                ОК
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="servType"
            flat
            background-color="white"
            :items="servTypes"
            item-color="#32a1af"
            color="#32a1af"
            solo
            hide-details
            placeholder="Вид услуги"
            clearable
            outlined
            dense
            item-text="txt"
            item-value="val"
            :disabled="!dates.length"
            @change="goFilter()"
          />
        </v-col>


        <v-col
          cols="12"
          lg="1"
          class="text-right"
        >
          <!-- смена вида -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                elevation="0"
                max-width="40px"
                min-width="40px"
                height="40px"
                color="#≈"
                v-bind="attrs"
                @click="listView = !listView"
                v-on="on"
              >
                <v-icon v-if="!listView">
                  mdi-format-list-bulleted
                </v-icon>
                <v-icon v-else>
                  mdi-view-grid-outline
                </v-icon>
              </v-btn>
            </template>
            <span v-if="!listView">Список</span>
            <span v-else>Плитка</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          lg="10"
        >
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <v-select
                v-model="action"
                :items="actions"
                solo
                item-color="#5CB7B1"
                color="#fff"
                background-color="#32a1af"
                flat
                class="mb-2"
                dark
                hide-details="auto"
                outlined
                dense
                placeholder="Действия"
                item-value="val"
                item-text="txt"
                clearable
              >
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  {{ data.item.txt }}
                </template>
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <div
                    :class="{'dividedAction py-2' : data.item.divided}"
                    style="width:100%;"
                  >
                    <v-icon
                      small
                      class="mr-2"
                      :color="data.item.color"
                    >
                      mdi-{{ data.item.icon }}
                    </v-icon>
                    {{ data.item.txt }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="action === 1"
              cols="12"
              lg="2"
            >
              <v-select
                v-model="manager2"
                flat
                background-color="white"
                :items="allSellers"
                item-color="#32a1af"
                color="#32a1af"
                solo
                hide-details
                placeholder="Менеджер"
                clearable
                outlined
                dense
                item-text="lastName"
                item-value="id"
              />
            </v-col>
            <v-col
              cols="12"
              lg="2"
            >
              <v-btn
                v-if="action"
                color="#333"
                dark
                height="40"
                :disabled="checkDisablity()"
                @click="getSelectedAction"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          class="py-0 pl-16"
        >
          <v-checkbox
            v-model="checkAll"
            color="#333"
            label="Выбрать все"
            class="mt-4"
            @change="checkAllCompanies"
          />
        </v-col>
      </v-row>

      <!-- <pre>{{companies}}</pre> -->
      <v-row v-if="!listView">
        <v-col
          v-for="(card, index) in companies"
          :key="index"
          cols="12"
          lg="4"
        >
          <company-card
            :card="card"
            @replenishBalance="replenishBalance(card)"
            @showSingleCompany="showSingleCompany(card.id)"
            @showBalaceHistory="showBalaceHistory($event)"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="(card, index) in companies"
          :key="index"
          cols="12"
          lg="12"
        >
          <company-card-list
            :card="card"
            @replenishBalance="replenishBalance(card)"
            @showSingleCompany="showSingleCompany(card.id)"
            @showBalaceHistory="showBalaceHistory($event)"
          />
        </v-col>
      </v-row>

      <history-dialog
        :history-dialog="historyDialog"
        @closeHistory="closeHistory"
      />
      <!-- replenish the balance -->
      <v-dialog
        v-model="dialog"
        width="650"
      >
        <v-card>
          <v-toolbar
            color="#333"
            dark
          >
            <div class="text-h5">
              Пополнение баланса
            </div>
          </v-toolbar>
          <div class="pa-6">
            <v-form ref="balance">
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <label for="">№ Платежного поручения</label>
                  <v-text-field
                    v-model="docNumber"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    outlined
                    dense
                    :rules="inputRules"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <label for="">Дата платежа</label>
                  <v-text-field
                    v-model="docDate"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    type="date"
                    outlined
                    dense
                    :rules="inputRules"
                    @change="docDate = deleteNulls($event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <label for="">Сумма</label>
                  <v-text-field
                    v-model="sum"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    outlined
                    dense
                    :rules="inputRules"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Загрузить изображение</label>
                  <v-file-input
                    v-model="files"
                    color="#32a1af"
                    solo
                    flat
                    multiple
                    placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333"
              text
              @click="closeDialog"
            >
              Отмена
            </v-btn>
            <v-btn
              color="#5CB7B1"
              dark
              :loading="load"
              @click="submitBalance"
            >
              Пополнить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- replenish the balance -->
      <v-snackbar
        v-model="paymentError"
        color="red"
      >
        <v-icon color="#fff">
          mdi-alert
        </v-icon>
        <span>
          Ошибка пополнения баланса!
        </span>
      </v-snackbar>
    </v-container>
  </section>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import CompanyCard from '@/components/billing/company-card.vue'
import CompanyCardList from '@/components/billing/company-card-list.vue'
import { AUTH } from '@/http-routes'
import formatDate from '@/mixins/format-date.js'
import HistoryDialog from '@/components/billing/history-dialog.vue'
import axios from 'axios'
import moment from 'moment'
import { deleteNulls } from "@/helpers/inputs";

export default {
  components: {
    CompanyCard,
    CompanyCardList,
    HistoryDialog
  },
  mixins: [formatDate],
  data:() => ({

    dialog: false,
    load: false,
    paymentError: false,
    historyDialog: false,
    activeHistoryId: null,
    checkAll: false,
    listView: true,
    action: null,
    actions: [{
      txt: 'Заменить менеджера',
      icon: 'account',
      val: 1,
      color: '#F88C0D',
      divided: true
    },
    {
      txt: 'Уведомление в виде уведомления',
      icon: 'message-text',
      val: 2,
      color: '#087FD5',
      divided: false
    },
    {
      txt: 'Уведомление в виде письма на указанную почту',
      icon: 'email',
      val: 3,
      color: '#CD461B',
      divided: true
    },
    {
      txt: 'Печатать акты',
      icon: 'printer',
      val: 4,
      color: '#32a1af',
      divided: false
    },
      // {
      //   txt: 'Сформировать отчет',
      //   icon: 'chart-pie',
      //   val: 5,
      //   color: '#4B9A26',
      //   divided: false
      // },
    ],
    servTypes: [{
      txt: 'Пакеты',
      val: 'packages'
    },
    {
      txt: 'Транзакции',
      val: 'transactions'
    }
    ],
    servType: null,
    manager: '',
    manager2: null,
    search: '',
    files: [],
    docNumber: '',
    docDate: '',
    sum: '',
    divisionId: null,
    status: '',
    dates: [],
    menu: false,
    dates2: [],
    menu2: false,
    statuses: [{
      txt: 'Все',
      val: ''
    },
    {
      txt: 'Одобрено',
      val: true
    },
    {
      txt: 'Заблокировано',
      val: false
    },
    {
      txt: 'Ждёт одобрения',
      val: null
    },
    ],
    breadcrumbs: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Компании',
      disabled: true,
    },

    ],
    fileRules: [
      v => !!v.length || 'Поле должно быть заполнено',
      files => !files || !files.some(file => file.size > 5e6) ||
          'Файл не может превышать размер 5 MB!'
    ],
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ]

  }),
  computed: {
    ...mapGetters({
      companies: 'billing/getCompanies',
      user: 'auth/getUser',
      allSellers: 'billing/getAllSellers'
    }),
    dateRangeText() {
      if (!this.dates.length || !this.dates) {
        return ''
      }
      return `${this.formatDate(this.dates[0])} - ${this.formatDate(this.dates[1])}`
    },
    dateRangeText2() {
      if (!this.dates2.length || !this.dates2) {
        return ''
      }
      return `${this.formatDate(this.dates2[0])} - ${this.formatDate(this.dates2[1])}`
    }
  },
  created() {
    // `${AUTH}division/getAllOrganizations?sellerId=${this.user.user.id}&someField=тамож&verified=true&serviceType=transactions&date1=2022-08-03&date2=2022-08-30`
    this.$store.dispatch('billing/loadSellers')
    this.manager = this.user.user.id
    this.goFilter();
  },
  methods: {
    submitBalance(){
      if (this.$refs.balance.validate()) {
        this.load = true
        let obj = {
          divisionId: this.divisionId,
          sum: this.sum,
          docNumber: this.docNumber,
          docDate: moment(this.docDate).format('DD.MM.YYYY'),
          sellerId: this.user.user.id
        }
        var form = new FormData();
        for (var field in obj){
          form.append(field, obj[field]);
        }
        if(this.files.length){
          for(let i = 0; i < this.files.length; i++){
            form.append("payment", this.files[i]);
          }
        }

        axios.post(`${AUTH}accountTransaction/fillFromSeller`, form)
          .then(() =>{
            this.load = false
            this.$refs.balance.reset()
            this.dialog = false
            this.goFilter()
          })
          .catch(err =>{
            console.log(err)
            this.load = false
            this.paymentError = true
            this.$refs.balance.reset()
            this.dialog = false
          })
      }
    },
    closeDialog(){
      this.dialog = false
      this.$refs.balance.reset()
    },
    deleteNulls,
    getSelectedAction() {
      if (this.action === 1) {
        // url = `${AUTH}division/getABCAnalysis?date1=${this.formatDate(this.dates2[0])}&date2=${this.formatDate(this.dates2[1])}`
        // this.$store.dispatch('billing/loadSelectedAction', url)
        let selectedCompanies = this.companies.filter(item => item.selected)
        let selectedCompaniesIds = []
        selectedCompanies.forEach(item => {
          selectedCompaniesIds.push(item.id)
        })
        axios.post(`${AUTH}division/setSeller?divisionIds=${selectedCompaniesIds.join()}&sellerId=${this.manager2}`)
          .then(() => {
            this.goFilter()
          })
      }
      if(this.action === 4){
        this.createReport()
      }


      // http://10.128.8.142:7020/gateway/auth/division/setSeller?divisionIds=104,113,117&sellerId=105
    },
    createReport(){
      axios.get(`${AUTH}division/downloadAct?someField=${this.search}${this.status !== '' ? '&verified=' + this.status : ''}${this.manager ? '&sellerId=' + this.manager : ''}&date1=${this.formatDate(this.dates[0])}&date2=${this.formatDate(this.dates[1])}&serviceType=${this.servType}`)
        .then((res) => {
          if(res.status === 200){
            this.downloadExcel()
          }
        })
    },
    downloadExcel(){
      const link = document.createElement("a");
      link.href = `${AUTH}division/downloadAct?someField=${this.search}${this.status !== '' ? '&verified=' + this.status : ''}${this.manager ? '&sellerId=' + this.manager : ''}&date1=${this.formatDate(this.dates[0])}&date2=${this.formatDate(this.dates[1])}&serviceType=${this.servType}`
      link.click()
      link.remove()
    },
    showBalaceHistory(id) {
      this.activeHistoryId = id
      this.historyDialog = true
      this.$store.dispatch('billing/loadHistory', this.activeHistoryId)
    },
    closeHistory() {
      this.activeHistoryId = null
      this.historyDialog = false
    },
    clearDate() {
      this.dates = []
      this.servType = null
      this.goFilter();
    },
    clearDate2() {
      this.dates2 = []
    },
    updateDate() {
      this.$refs.menu.save(this.dates)
      if (this.dates.length == 2) {
        this.goFilter();
      }
    },
    updateDate2() {
      let A = moment(this.dates2[0]);
      let B = moment(this.dates2[1]);
      if (A.diff(B, 'days') >= 1) {
        let newDate = this.dates2[0]
        this.dates2.push(newDate)
        this.dates2.splice(0, 1)
      }

      this.$refs.menu2.save(this.dates2)
    },
    goFilter() {
      let requestUrl =
          `${AUTH}division/getAllOrganizations?${this.manager !== '' && this.manager ? 'sellerId=' + this.manager : ''}${this.search !== '' && this.search !== null ? '&someField=' + this.search : ''}${this.status !== '' ? '&verified=' + this.status : ''}${this.servType ? '&serviceType=' + this.servType : ''}${this.dates.length == 2 ? '&date1=' + this.formatDate(this.dates[0]) +'&date2=' + this.formatDate(this.dates[1]) : ''}`
      this.$store.dispatch('billing/loadCompanies', requestUrl)
    },
    showSingleCompany(id) {
      this.$router.push(`/billing-companies/${id}`)
    },
    checkAllCompanies() {
      this.companies.forEach(item => {
        item.selected = this.checkAll
      })
    },
    replenishBalance(card) {
      this.dialog = true
      this.divisionId = card.id
    },
    checkSelected() {
      let checked = this.companies.filter(item => item.selected)
      if (checked.length) {
        return false
      } else {
        return true
      }
    },
    checkDisablity() {
      if (this.action === 1) {
        // проверка на выбранные
        let selectedItems = this.companies.filter((item) => {
          return item.selected === true
        })

        if (this.manager2 && selectedItems.length) {
          return false
        } else {
          return true
        }
      }
      if (this.action === 4){
        if(this.manager !== '' && this.dates.length == 2 && this.servType){
          return false
        }else{
          return true
        }
      }
    }
  },
}
</script>

<style scoped>
  #usual {
    min-height: calc(100vh - 129px);
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: hsl(0deg 0% 50% / 30%) !important;
  }
</style>
