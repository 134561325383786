const routes = [
  {
    path: "/ptd/:id",
    name: "PassengerMain",
    component: () => import('@/views/passenger/passenger-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]
export default routes;
