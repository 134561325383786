export const readyToUpdateInjector = Symbol("readyToUpdate")
export const patchStateInjector = Symbol("patchState")

export const addPromiseInjector = Symbol("addPromise")

export const uploadDataInjector = Symbol("uploadData")

export const isOpenInjector = Symbol("isOpen")

export const fetchPaymentOrdersInjector = Symbol("fetchPaymentOrders")

export const resetTriggersInjector = Symbol("resetTriggers")

export const isDirtyInjector = Symbol("isDirty")

export const showDynamicComponentInjector = Symbol("showDynamicComponent")