import axios from "axios"
import { CATALOGS } from "@/http-routes";
import moment from "moment";

export default {
  namespaced: true,
  state:{
    rates:[]
  },
  mutations:{
    SET_RATES(state, items){
      state.rates = items
    }
  },
  actions:{
    fetchCurrentRates({commit}){
      const today = moment().format('DD.MM.YYYY')
      return axios.get(`${CATALOGS}currencyRates?date=${today}`).then(res => {
        commit('SET_RATES', res.data)
        return res
      })
    },
    fetchRate(_, {code, date}){
      const d = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      return axios.get(`${CATALOGS}currencyRates?date=${d}&letterCode=${code}`)
    }
  },
  getters:{
    getRates: s => s.rates,
  }
    
}
