<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Примеры декларирования</h3>
        <div
          v-if="declarationExamples"
          class="body-1 white--text font-weight-medium"
        >
          Найдено: {{ declarationExamples.totalCount }}
        </div>
      </div>

      <div
        v-if="declarationExamples"
        class="tnved-slide-box-body"
      >
        <div
          v-for="(example, index) in declarationExamples.tnVedVariantNameWithGroups"
          :key="index"
          class="grey-box"
        >
          <div class="d-flex justify-space-between align-center">
            <div style="max-width:calc(100% - 100px);">
              <h2 class="mb-0">
                {{ example.groupCode }}
              </h2>
              <h4>{{ example.groupDescription }}</h4>
            </div>
            <!-- <pre>{{example}}</pre> -->
            <v-chip
              label
              color="#333"
              dark
              large
            >
              {{ example.percent }}
            </v-chip>
          </div>
          <v-divider class="my-3" />

          <div class="white-box mb-5">
            <div
              v-for="(item, id) in example.tnVedVariantNames"
              :key="id"
              class="d-sm-block d-lg-flex justify-start align-start mb-8"
            >
              <div
                class="code-txt mr-5"
                @click="goToCode(item.code)"
              >
                <span>{{ customSpaces(item.code) }}</span>
              </div>
              <div class="light-txt mt-1">
                {{ item.description }}
              </div>
            </div>
          </div>

          <div class="text-center">
            <v-btn
              v-if="example.count > 5 && example.tnVedVariantNames.length <= 5"
              depressed
              color="#333"
              :loading="checkLoad(index)"
              dark 
              @click="showAll(example.groupCode, exmplParam, index)"
            >
              Просмотреть все ({{ example.count }})
            </v-btn>
          </div>

          <div class="text-center">
            <v-btn
              v-if="example.count > 5 && example.tnVedVariantNames.length > 5"
              depressed
              color="#333"  
              dark
              @click="hideAll(example.groupCode, exmplParam, index)"
            >
              Свернуть 
            </v-btn>
          </div>
        </div>   
      </div>

      <div
        v-else
        class="tnved-slide-box-body"
      >
        <p class="grey-txt">
          Примеры декларирования отсутствуют
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    declarationExamples: {
      required: false,
      type: Object,
      default:null
    },
    exmplParam: {
      type: String,
      required: true
    },
    exmplCode: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      btnLoad: false
    }
  },
  methods:{
    customSpaces(code){
      // 1513 21 900 2
      let space1 = code.substr(0, 4)
      let space2 = code.substr(4, 2)
      let space3 = code.substr(6, 3)
      let space4 = code.substr(9, 1)

      return space1 + ' ' + space2 + ' ' + space3 + ' ' + space4
        
    },
    checkLoad(index){
      if(this.btnLoad === index){
        return true
      }else{
        return false
      }
    },
    hideAll(code, txt, index){
      let allParams = {}
      if(this.exmplCode !== ''){
        allParams = {
          code: this.exmplCode,
          txt: txt,
          index: index
        }
      }else{
        allParams = {
          code: code,
          txt: txt,
          index: index
        }
      }

      this.$store.dispatch("tnved/hideAllExamples", allParams)

    },
    showAll(code, txt, index){
      this.btnLoad = index
      let allParams = {}
      if(this.exmplCode !== ''){
        allParams = {
          code: this.exmplCode,
          txt: txt,
          index: index
        }
      }else{
        allParams = {
          code: code,
          txt: txt,
          index: index
        }
      }

      this.$store.dispatch("tnved/loadAllExamples", allParams).then(() =>{
        this.btnLoad = null
      })
    },
    goToCode(code){
      let routeData = this.$router.resolve({ path: `/tnved/${code}` })
      window.open(routeData.href, '_blank');
    }
  }
}
</script>


<style scoped>
@media (max-width: 512px) {
    .light-txt{
        width: 100%;
    }
}
</style>