<template>
  <v-tooltip
    max-width="400"
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-chip
        label
        v-bind="attrs"
        class="py-6 d-none d-md-flex d-lg-flex"
        v-on="on"
        @click="copyUrl"
      >
        <div style="max-width: 200px; overflow:hidden">
          <span
            v-for="(item, index) in copied"
            :key="index"
          >
            {{ item.description.toUpperCase().replace(/\–/g, '') }}
            <span v-if="index !== copied.length - 1" />
          </span>
        </div>
        <v-icon class="ml-2">
          mdi-checkbox-multiple-blank-outline
        </v-icon>
      </v-chip>
    </template>
    <span
      v-for="(item, index) in copied"
      :key="index"
    >
      {{ item.description.toUpperCase().replace(/\–/g, '') }}
      <span v-if="index !== copied.length - 1" />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    copied: {
      type: Array,
      required: true
    }
  },
  methods: {
    copyUrl(){
      let param = []
      this.copied.forEach(item =>{
        param.push(item.description)
      })
      let result = param.join(' ').toUpperCase().replace(/–/g, '')
      this.$copyText(result)
    } 
  }
}
</script>