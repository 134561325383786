<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="sd-tnved-viewer">
        <t-n-v-e-d
          v-if="show"
          :open-on-click="false"
          :default-search="search"
          :default-short-search="true"
          :scroll-container="'.sd-tnved-viewer'"
          :show-chips="false"
          @select="closeOnSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import TNVED from "@/views/tnved/tnved.vue";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { tnvedCodeSelected } from "@/events/statistics";
import {eventBus} from "@/bus";
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
export default {
  components: {
    TNVED,
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    closeOnSelect(code) {
      const payload = { code, id: this.id }
      if(this.customEventName){
        eventBus.$emit(this.customEventName, payload)
      }else {
        tnvedCodeSelected.trigger(payload);
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.sd-tnved-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
