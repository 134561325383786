<template>
  <v-col cols="12">
    <portal-target :name="'tnved-extra-modal-'+itemId" />
    <div class="d-flex">
      <v-text-field
        :value="title"
        disabled
        hide-details="auto"
        background-color="#EDEDED"
        class="ba-0 rounded-l rounded-r"
        filled
        rounded
        full-width
        placeholder="Поиск"
        clearable
        @click:clear="() => (searchValue = '')"
      />
      <v-btn
        v-if="isCatalogAvailable"
        dark
        class="ml-4"
        x-large
        width="56px"
        min-width="56px"
        @click="showCatalog()"
      >
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
    </div>

    <v-treeview
      item-key="key"
      open-on-click
      :items="items"
      :open.sync="opened"
      class="piz123"
    >
      <template
        #prepend="{item}"
      >
        <div
          class="black-txt"
        >
          <div class="d-flex justify-space-between">
            <div
              class="code-txt mr-5"
            >
              <span @click="select(item.fullCode)">{{ item.fullCode }}</span>
            </div>
            <div class="d-flex align-between name">
              {{ item.name }}
            </div>
            <v-icon
              v-if="item.including !== null"
              class=""
              :color="item.including === '1' ? 'green': 'red'"
            >
              {{ item.including === '1'? 'mdi-check': 'mdi-cancel' }}
            </v-icon>
          </div>
        </div>
      </template>
    </v-treeview>
  </v-col>
</template>
<script>
import { normalize } from "@/helpers/catalogs";
import moment from "moment";
import {PortalTarget} from "portal-vue";

export default {
  components: {PortalTarget},
  props: {
    itemId:{
      required: false,
      type:Number,
      default: 0
    },
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
    actionDate:{
      required:true,
      validator: (prop) => typeof prop === "string" || prop === null,
    }
  },
  data: () => ({
    opened:[],
    searchValue: "",
    items:[],
    catalogType:null,
    description:null
  }),
  computed:{
    isCatalogAvailable(){
      return !!this.catalogType?.listId
    },
    title(){
      if(this.description){
        return `${this.searchValue} -  ${this.description}`
      }
      return this.searchValue

    }
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
        this.getCatalogType()
      },
      immediate: true,
    },
  },
  created() {
    const format = 'DD.MM.YYYY'
    if (this.searchValue.length) {
      this.setDescription()
      const actionDate = this.actionDate ?? moment().format(format)
      this.$store.dispatch("catalogs/getExtraTnved", {code:this.searchValue, actionDate}).then(res => {
        this.items = normalize({data:res.data})
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при загрузке справочника', color:'red', top:false, right: false})
      });
    }
  },
  methods: {
    setDescription(){
      this.$store.dispatch('tnved/getSingleCode', {
        code:this.searchValue,
        procedure:'IMPORT',
        date: moment().format('YYYY-MM-DD')
      }).then(res => {
        this.description = this.getLastChild(res.data.tnVed).replaceAll('–', '').trim()
      })
    },
    getLastChild(tnved){
      if(tnved.children.length){
        return this.getLastChild(tnved.children[0])
      }else{
        return tnved?.description ?? ""
      }
    },
    getCatalogType(){
      this.$store.dispatch('catalogs/getBrandCatalogByCode', {code:this.searchValue}).then(res => {
        this.catalogType = res.data
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при получении типа справочника', color:'red', top:false, right: false})
      })
    },
    showCatalog(){
      if(this.catalogType && this.catalogType?.enName ){
        const route = this.$router.resolve({ path: `/catalogs?list=brands&type=${this.catalogType.enName}`});
        window.open(route.href, "_blank");
      }
    },
    select(code) {
      if(code.length === 4){
        this.$emit("select", code);
      }

    },
  },
}
</script>
<style scoped>

.name{
  color: #333 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    width: calc(100% - 236px);
}
</style>