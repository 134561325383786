<template>
  <section
    id="rega"
    class="py-15"
  >
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card v-if="step1">
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Уведомление
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Для того, чтобы зарегестирировать новую компанию, Вам необходимо быть уполномеченным на создание.
          Приложите следующие документы: <strong>Свидетельство о государственной регистрации,  Доверенность или приказ для директора, 4 первых листа Устава.</strong>
          <br>
          Для ИП только <strong>Свидетельство о государственной регистрации.</strong>
          <br><br>

          <strong>Если полномочий на подписание договора у вас нет - выберите “Нет полномочий”, в следующем окне введите e-mail уполномоченного. Ему придет ссылка для регистрации себя и компании.</strong>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            dark
            class="mr-2"
            @click="step1 = false"
          >
            Нет полномочий
          </v-btn>
          <router-link
            tag="a"
            to="/registration-ul"
          >
            <v-btn
              color="#5CB7B1"
              dark
            >
              Есть полномочия
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Уведомление
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Введите e-mail уполномоченного. Ему придет ссылка для регистрации себя и компании.
        </v-card-text>

        <v-form
          ref="form"
          class="pa-6 pt-0 pb-10"
          @submit.prevent
        >
          <label for="">E-mail</label>
          <v-text-field
            v-model="email"
            color="#5cb7b1"
            background-color="#EDEDED"
            solo
            hide-details="auto"
            flat
            placeholder="example@mail.by"
            :rules="emailRules"
          />
        </v-form>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="step1 = true"
          >
            Назад
          </v-btn>
          <v-btn
            color="#5CB7B1"
            dark
            :loading="load"
            @click="submit"
          >
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-container>
      <!-- <v-row v-if="loadCompany">
                <v-col cols="12" lg="4" class="text-center mx-auto">
                    <h4 class="mb-4">Загружаем компании</h4>
                    <v-progress-linear color="#32a1af" indeterminate rounded height="6"></v-progress-linear>
                </v-col>
            </v-row> -->
      <v-row>
        <v-col
          cols="12"
          lg="4"
        >
          <v-card
            elevation="2"
            class="mb-6"
          >
            <v-toolbar
              color="grey lighten-3"
              flat
            >
              <v-card-title
                class="text-body-1 font-weight-medium"
                color="black"
              >
                Добавить компанию
              </v-card-title>
            </v-toolbar>

            <div
              class="pa-7 text-center"
              @click="dialog = true"
            >
              <img
                src="../assets/img/division.svg"
                class="divivsion-btn"
                alt=""
              >
            </div>
          </v-card>
        </v-col>
        <!-- компании -->
        <v-col
          v-for="(item, i) in divisions"
          :key="i"
          cols="12"
          lg="4"
        >
          <v-card
            v-if="divisions.length"
            elevation="2"
            class="mb-6"
            :to="checkAccess(item.role_id) ? `/new-division/${item.real_division_id}` : `/new-division` "
            :disabled="!item.is_active_user_in_division || !item.is_active_division"
          >
            <v-toolbar
              :color="item.is_active_user_in_division ? '#5cb7b1' : 'red'"
              flat
              dark
            >
              <v-card-title
                class="text-body-1 font-weight-medium"
                color="black"
              >
                {{ item.division_name }}
              </v-card-title>
            </v-toolbar>

            <div class="pa-8">
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-badge
                    :color="item.is_active_division ? 'green' : 'red'"
                    left
                    dot
                    offset-x="-2"
                    offset-y="15"
                    class="ml-3"
                  >
                    <div
                      v-if="item.is_active_division"
                      class="text-body-2 pl-1"
                    >
                      Подтверждена
                    </div>
                    <div
                      v-else
                      class="text-body-2 pl-1"
                    >
                      Не подтверждена
                    </div>
                  </v-badge>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                >
                  УНП:
                </v-col>
                <v-col
                  cols="12"
                  lg="9"
                  class="font-weight-medium"
                >
                  {{ item.tax_payer_num }}
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                >
                  Тип:
                </v-col>
                <v-col
                  cols="12"
                  lg="9"
                  class="font-weight-medium"
                >
                  {{ item.type_id }}
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                >
                  Услуги:
                </v-col>
                <v-col
                  cols="12"
                  lg="9"
                  class="font-weight-medium"
                >
                  API ТН ВЭД (600 запросов на 1 год), СД, ДТ, ЗВТ
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row v-else>
                <v-col cols="12" lg="4" v-for="(item, i) in 3" :key="i">
                    <v-card>
                        <v-skeleton-loader
                        type="image, list-item-avatar-three-line, actions"
                        ></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row> -->
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import {AUTH} from '@/http-routes';
import { mapGetters } from 'vuex';

export default {
  data(){
    return{
      dialog: false,
      step1: true,
      load: false,
      loadCompany: true,
      email: '',
      emailRules: [
        v => !!v || 'Поле должно быть заполнено',
      ]
    }
  },
  computed: {
    ...mapGetters({
      divisions: 'auth/getDivisions',
      user: 'auth/getUser'
    })
  },
  watch: {
    dialog(){
      if(!this.dialog){
        this.step1 = true
      }
    }
  },
  created(){
    this.$store.dispatch('auth/loadDivisions').then(()=>{
      this.loadCompany = false
    })

  },
  methods: {
    checkAccess(role_id){
      if(role_id == 2 || role_id == 6 || role_id == 7 || role_id == 17 || role_id == 18){
        return true
      }else{
        return false
      }
    },
    submit(){
      if(this.$refs.form.validate()){

        this.load = true
        const headers = {
          'Authorization': `Bearer ${this.user.jwtToken}`
        }


        // http://10.128.8.142/api/auth/division/designeePerson?email=semenzv@gmail.com

        axios
          .post(`${AUTH}division/designeePerson?email=${this.email}`, {}, {headers} )
          .then(() =>{
            this.load = false
            this.dialog = false
          })
          .catch(() =>{
            this.load = false
            throw 'Unable to fetch current user'
          })
      }
    }
  }
}
</script>

<style scoped>
.divivsion-btn{
    cursor: pointer;
    transition: all .3s ease;
    height: 133px;
    margin: 30px 0;
}
.divivsion-btn:hover{
    opacity: .8;
}
</style>
