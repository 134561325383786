import Tnved from "@/views/tnved/tnved.vue";
import Examples from "@/views/tnved/examples.vue";
import Explanations from "@/views/tnved/explanations.vue";
import Notes from "@/views/tnved/notes.vue";
import ErrorPage from "@/views/tnved/error-page.vue";
import TnvedCodePage from "@/views/tnved/tnved-code-page.vue";

const routes = [
  {
    path: "/tnved",
    component: Tnved,
    meta: { title: "Поиск по ТН ВЭД" },
  },
  {
    path: "/examples",
    component: Examples,
  },
  {
    path: "/explanations",
    component: Explanations,
  },
  {
    path: "/notes",
    component: Notes,
  },
  {
    path: "/tnvederror/:id",
    component: ErrorPage,
    props: true,
  },
  {
    path: "/tnved/:id",
    component: TnvedCodePage,
    props: true,
  },
]

export default routes
