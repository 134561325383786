import axios from "axios";
import {DTS, CATALOGS, TNVED} from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";
import wareDetailsActionsFactory from "@/store/utils/ware-details-actions";
const documentActions = documentActionsFactory(DTS);
const wareDetailsActions = wareDetailsActionsFactory(DTS)

export default {
  namespaced: true,
  state: {
    selected: {},
    selectedWares: [],
    editableWare: { id: null, index: null },
    requests: [],
    aeos: [],
    nsiTransportMarks: [],
    control: {},
    extraTnvedOptions: {
      firstOptions:[],
      secondOptions:[],
      thirdOptions:[],
      fourthOptions:[]
    },
    paymentTypes:[]
  },
  mutations: {
    ...documentMutations,
    SET_EXTRA_TNVED_OPTIONS(state, preparedOptions){
      state.extraTnvedOptions = preparedOptions
    },
    SET_NSI_AEOS(state, aeos) {
      state.aeos = aeos;
    },
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    ADD_REQUEST(state, promise) {
      state.requests.push(promise);
    },
    REMOVE_REQUEST(state, promise = null) {
      if (promise) {
        const idx = state.requests.indexOf(promise);
        state.requests.splice(idx, 1);
      } else {
        state.requests = [];
      }
    },
    UPDATE_DECLARATION_PAYMENT(state, items) {
      if (items.length === state.selected.declaration_payments.length) {
        state.selected.declaration_payments.splice(
          0,
          state.selected.declaration_payments.length,
          ...items
        );
      } else {
        items.forEach((item) => {
          const { id } = item;
          const current = state.selected.declaration_payments.find(
            (i) => i.id === id
          );
          const index = state.selected.declaration_payments.indexOf(current);
          state.selected.declaration_payments.splice(index, 1, item);
        });
      }
    },
    UPDATE_DECLARATION_PAYMENTS(state, ids) {
      const new_arr = state.selected.declaration_payments.filter((i) => !ids.includes(i.id))
      state.selected.declaration_payments = new_arr;
    },
    ADD_PAYMENTS_ITEM(state, item) {
      state.selected.declaration_payments.push(item);
    },
    SET_NSI_TRANSPORT_MARKS(state, nsiTransportMarks) {
      state.nsiTransportMarks = nsiTransportMarks;
    },
    RESET_GOODS_AND_DOCS(state, payload) {
      state.selectedWares = payload.wares;
      if (payload.presented_documents) {
        state.selected.presented_documents = payload.presented_documents;
      }
    },
    SET_PAYMENT_TYPES(state, payload){
      state = payload.list.map((i) => {
        return {
          ...i,
          search: `${i.code}-${i.name}`
        }
      })
    }
  },
  actions: {
    ...documentActions,
    ...wareDetailsActions,
    fetchNsiAeos({ commit }) {
      axios.get(`${CATALOGS}nsiAeos`).then((res) => {
        commit("SET_NSI_AEOS", res.data.list);
      });
    },
    updateDeclarationPayment({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${DTS}declarationPayment/updateAll`, data)
        .then((res) => {
          commit("UPDATE_DECLARATION_PAYMENT", data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deletePaymentItem({ commit, state }, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${DTS}declarationPayment/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then(() => {
          commit("UPDATE_DECLARATION_PAYMENTS", data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    createNewPayment({ commit, state }) {
      axios
        .post(`${DTS}declarationPayment/create?declarationId=${state.selectedId}`)
        .then((res) => {
          commit("ADD_PAYMENTS_ITEM", res.data);
        });
    },
    copyDocument({ rootState }, { id, copyDocuments, wareIds, copyAllWares, copyGuarantees }) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${DTS}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&copyDocuments=${copyDocuments}` +
        `&userId=${uid}` +
        `&copyAllWares=${copyAllWares}`+
        `&copyGuarantees=${copyGuarantees}`
      ;
      return axios.post(url, wareIds);
    },
    deleteDocumentArr({ commit, state }, selected) {
      const declarationId = state.selected.id;
      return axios
        .post(`${DTS}presentedDocument/deleteByIds?declarationId=${declarationId}`,
          [...selected]
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    fetchNsiTransportMarks({ commit }) {
      return axios.get(`${CATALOGS}nsiTransportMarks`).then((res) => {
        const result =
          res.data?.list.map((i) => {
            return {
              ...i,
              text: [i.code, i.name].join("-"),
              search: [i.code, i.name].join("-").toLowerCase(),
            };
          }) || [];
        commit("SET_NSI_TRANSPORT_MARKS", result);
      });
    },
    // Заявка
    attachWorksAct(_, formData){
      return axios.post(`${DTS}order/attachWorksAct`, formData);
    },
    getDocumentArchive(_, id){
      return axios
        .get(`${DTS}order/downloadDocuments?declarationId=${id}`, {
          responseType: "blob",
        })
    },
    getPdf(_, id) {
      return axios.get(`${DTS}order/downloadPdf?declarationId=${id}`, {
        responseType: "blob",
      });
    },
    addDocuments(_, formData){
      return axios.post(`${DTS}order/attachDocuments`, formData);
    },
    sendToEclient(_, {xml, declarationId, userId, divisionId, ptoNumber}) {
      return axios.post(
        `${DTS}declaration/sendToEclient?userId=${userId}&divisionId=${divisionId}&declarationId=${declarationId}&ptoId=${ptoNumber}`,
        xml,
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
    },
    uploadExcel(
      { dispatch, state },
      { formData, id, ...params }
    ) {
      let url = `${DTS}declaration/uploadXlsx?declarationId=${id}`
      Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
      })
      return axios
        .post(url, formData)
        .then((res) => {
          dispatch('getDocumentById', state.selected.id)
          return res;
        });
    },
    // TODO: вынести в модуль ТНВЭД
    getAntiDumpingsByTnved(_, {tn_ved, date}){
      return axios.get(
        `${TNVED}api/read/getAntidumpingsByTnVd?tnVed=${tn_ved}&declarationDate=${date}`
      )
    },
    getAvailableExcise(_, payload) {
      if (!payload.tn_ved) Promise.reject(new Error("Отсутствует код ТНВЭД"));
      const promise = new Promise((resolve, reject) => {
        axios
          .get(
            `${TNVED}api/read/nsiTransitExcise/getByTnVed?tnVed=${payload.tn_ved}`
          )
          .then(({ data }) => {
            if (!data.length) {
              if (!payload.date) {
                let error = new Error(
                  "Отсутствует дата для загрузки акциза из таможни"
                );
                error.color = "orange";
                throw error;
              }
              axios
                .get(
                  `${TNVED}api/read/getExcisesByTnVed?tnVed=${payload.tn_ved}&declarationDate=${payload.date}`
                )
                .then(({ data }) => {
                  resolve({ data, type: 2 });
                })
                .catch((err) => reject(err));
            } else {
              resolve({ data, type: 1 });
            }
          })
          .catch((e) => reject(e));
      });
      return promise;
    },
    sortGoods({ state, commit }, { sortBy = "id" }) {
      const id = state.selected.id;
      if (!id) return;
      axios
        .post(`${DTS}ware/sortWares?declarationId=${id}&sortBy=${sortBy}`)
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
        })
    },
    deleteGoodsAmount({ commit, state }, { ids }) {
      return axios.post(`${DTS}ware/deleteByIds?declarationId=${state.selected.id}`, ids).then((res) => {
        commit("RESET_GOODS_AND_DOCS", res.data);
        return res;
      });
    },
    deleteAllGoods({ commit, state }) {
      return axios
        .post(`${DTS}ware/deleteByIds?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("RESET_GOODS_AND_DOCS", res.data);
          return res;
        });
    },
    addExtraTnvedRules({commit}, {declarationId, rules}){
      return axios.post(`${DTS}ware/applyAdDTSnVedRule?declarationId=${declarationId}`, rules)
        .then((res) => {
          commit('REPLACE_BLOCK_IN_DECLARATION', {
            wares:res.data
          })
        })
    },
    divideCost({state}){
      const id = state.selected.id;
      return axios.post(`${DTS}declaration/calculateIncludingCosts?declarationId=${id}`)
    },
    fillNetWeightNoPackFromNetWeight({ commit }, declarationId) {
      return axios.post(`${DTS}ware/fillNetWeightNoPackFromNetWeight?declarationId=${declarationId}`)
        .then((res) => commit("RESET_GOODS", res.data))
    },
    calcWeightWithParam({ commit }, {id, weight, field}) {
      return axios.post(`${DTS}ware/calculateWeight?declarationId=${id}&weight=${weight}&fromField=${field}`)
        .then((res) => commit("RESET_GOODS", res.data))
    },
    calcPackageWeight({ commit }, { id, weight}) {
      return axios
        .post(`${DTS}ware/recalculateGrossWeightByAddingPackage?declarationId=${id}&commonGrossWeight=${weight}`
        ).then((res) => commit("RESET_GOODS", res.data));
    },
    clearPayments({state, commit}){
      const id = state.selected.id;
      return axios.post(`${DTS}declaration/clearAllPayments?declarationId=${id}`)
        .then((res) => commit('SET_SELECTED', res.data))
    }
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getNsiAeos: (s) => s.aeos,
    getRequests: (s) => s.requests,
    getEditableWare: (s) => s.editableWare,
    getNsiTransportMarks: (s) => s.nsiTransportMarks,
    getControl: (s) => s.control,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getExtraTnvedOptions: (s) => s.extraTnvedOptions,
    getPaymentTypes: (s) => s.paymentTypes,
    isImport40: (s) => {
      const {customs_procedure, declaration_kind_code} = s.selected?.type_declaration ?? {}
      return declaration_kind_code === 'ИМ' && customs_procedure === "40"
    }
  },
};
