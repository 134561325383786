<template>
  <section id="login">
    <v-container>
      <v-row>
        <v-col
          lg="5"
          style="margin: 0 auto;"
        >
          <div class="grey-box text-center px-16">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Придумайте новый пароль
            </div>

            <v-form
              ref="passChange"
              @submit.prevent="changePass"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Пароль</label>
                  <v-text-field
                    id="field1"
                    v-model="pass"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :append-icon="hValue ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hValue ? 'password' : 'text'"
                    :rules="inputRules"
                    autocomplete="do-not-autofill"
                    @click:append="() => (hValue = !hValue)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Повторите пароль</label>
                  <v-text-field
                    v-model="pass2"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :append-icon="hValue2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hValue2 ? 'password' : 'text'"
                    :rules="passRules"
                    @click:append="() => (hValue2 = !hValue2)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="load"
                    style="width:100%;"
                  >
                    Изменить пароль
                  </v-btn>
                  <div
                    v-if="errTxt !== '' "
                    class="red--text mt-2"
                  >
                    {{ errTxt }}
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import {AUTH} from '@/http-routes'

export default {
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data(){
    return{
      inputRules: [
        v => !!v || 'Поле должно быть заполнено'
      ],
      passRules: [
        v => !!v || 'Поле должно быть заполнено',
        v => v === this.pass || 'Пароли не совпадают'
      ],
      pass: '',
      errTxt: '',
      load: false,
      hValue: String,
      hValue2: String
    }
  },
  methods: {
    changePass(){
      if(this.$refs.passChange.validate()){
        this.load = true
        axios
          .post(`${AUTH}user/setNewPassword`, {phone: this.id, password: this.pass})
          .then(() =>{
            this.load = false
            this.$router.replace(`/login`)
          })
          .catch(err =>{
            this.errTxt = err.response.data
            this.load = false
          })
      }
    },
  }
}
</script>
