import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {

  navigator.serviceWorker.addEventListener('message', (event) => {
    const {type, message} = event.data
    if(type === 'duplicated-id'){
      window.alert(message)
      window.close() 
    }
  })

  register(`${process.env.BASE_URL}service-worker-main.js`, {
    ready () {
      // console.log('Service worker is active.');
    },
    registered (registration) {
      setInterval(() => {
        console.warn('Проверка обновлений (60 мин)')
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    updatefound (e) {
      console.log('New content is downloading.', e);
    },
    updated () {
      console.log('New content is available; please refresh.');
      document.dispatchEvent(new CustomEvent('service-worker-updated'))
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    },
  });
}

