import NsiUpdate from "@/views/nsi/nsi-update.vue";
import NsiLine from "@/views/nsi/nsi-line.vue";
import NsiNew from "@/views/nsi/nsi-new.vue";
import NsiMain from "@/views/nsi/nsi-main.vue";

const routes = [
  {
    path: "/nsi",
    component: NsiMain,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nsinew",
    component: NsiNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nsiline",
    component: NsiLine,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nsi/:id",
    component: NsiUpdate,
    props: true,
    meta: {
      requiresAuth: true,
    },
  }
]

export default routes
