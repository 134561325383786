<template>
  <v-dialog
    v-model="historyDialog"
    width="1000"
    persistent
    @click:outside="closeHistory"
    @keydown.esc="closeHistory"
  >
    <v-card>
      <v-toolbar
        color="white"
        class="font-weight-medium"
        elevation="0"
      >
        История транзакций
      </v-toolbar>

      <div
        v-if="billingHistory.length"
        class="pa-4"
      >
        <div class="company-card-wrapper">
          <v-data-table
            :headers="headers"
            :items="billingHistory"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template #[`item.doc_date`]="{ item }">
              <div
                class="text-body-1"
                :class="item.trans_direction == 0 ? 'red--text' : 'black--text'"
              >
                {{ formatDate(item.doc_date) }}
              </div>
            </template>
            <template #[`item.transactionSum`]="{ item }">
              <div
                class="text-body-1"
                :class="item.trans_direction == 0 ? 'red--text' : 'black--text'"
              >
                <span v-if="item.trans_direction == 0">-</span>
                <span v-else>+</span>
                {{ item.transaction_sum }} BYN
              </div>
            </template>
            <template #[`item.service`]="{ item }">
              <span
                v-if="item.service_type_id && !item.declaration_type"
                class="text-body-1"
                :class="item.price < 0 ? 'red--text' : 'black--text'"
              >
                <strong>{{ getServName(item.service_type_id) }}</strong> <br>
                {{ getPackageName(item.service_type_id) }}
              <!-- {{item.serviceTypeId}} -->
              <!-- servPackages -->
              </span>
              <span v-else-if="item.declaration_type === 'SD_EX'">
                Экспортная статистка: {{ item.count }}шт.
              </span>
              <span v-else-if="item.declaration_type === 'SD_IM'">
                Импортная статистка: {{ item.count }}шт.
              </span>
              <span v-else>
                Пополнение баланса
              </span>
            </template>
          </v-data-table>
        </div>
      </div>

      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="closeHistory"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import formatDate from '@/mixins/format-date.js'

export default {
  mixins: [formatDate],
  props: {
    historyDialog: {
      type: Boolean,
      required: true
    }
  },
  data:() => ({
    headers: [{
      text: 'Дата',
      value: 'doc_date'
    },
    // {
    //     text: 'Услуга',
    //     value: 'service',
    //     sortable: false
    // },
    {
      text: 'Стоимость',
      value: 'transactionSum'
    },
    {
      text: 'Услуга',
      value: 'service',
      sortable: false
    }
      // {
      //     text: 'Акт',
      //     value: 'act',
      //     sortable: false
      // },
    ],

  }),
  computed: {
    ...mapGetters({
      billingHistory: "billing/getBillingHistory",
      services: 'billing/getServices',
      servPackages: 'billing/getServPackages'
    })
  },
  created(){
    this.$store.dispatch("billing/loadServices")
    this.$store.dispatch("billing/loadServPackages")
  },
  methods: {
    closeHistory() {
      this.$emit('closeHistory')
    },
    getPackageName(id) {
      let res = this.servPackages.find(item => {
        return item.id == id
      })
      return res.name
    },
    getServName(id) {
      let res = this.servPackages.find(item => {
        return item.id == id
      })
      let serv = this.services.find(item => {
        return item.id == res.parent_id
      })
      return serv.name
    }
  },

}
</script>


<style scoped>
  .company-card-wrapper {
    height: 500px !important;
  }
</style>
