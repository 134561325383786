import axios from "axios";
import {NETWORK_ERROR} from "@/constants/messages";

export function downloadXml(xmlString, filename) {
  const fileURL = window.URL.createObjectURL(new Blob([xmlString]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", filename);
  document.body.appendChild(fileLink);
  fileLink.click();
}

export function downloadExcel(blob, docName) {
  const fileURL = window.URL.createObjectURL(
    new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${docName}`);
  document.body.appendChild(fileLink);
  fileLink.click();
}

export function downloadFile({ data, type, name = "" }) {
  const blob = new Blob([data], { type });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  if (name) {
    link.download = name;
  }
  if (type === "application/pdf") link.target = "_blank";
  link.click();
  setTimeout(() => URL.revokeObjectURL(link.href), 10000);
}

export function getFilenameFromContentDisposition(res){
  const disposition = res.headers["content-disposition"] || "";
  if (disposition) {
    const filename = disposition.split(";")[1];
    const value = filename.includes("filename*=UTF-8''")
      ? filename.replace("filename*=UTF-8''", "").trim()
      : filename.replace("filename=", "").replaceAll("\"", "").trim();
    return decodeURI(value)
  }
  return "";
}


export function signDocument({ guid, password, xml }) {
  return axios.post(`http://localhost:5210/Sign`, { guid, password, xml });
}

export function signDocumentPTD({ guid, password, xml }) {
  return axios.post(`http://localhost:5210/Sign/PKCS`, { guid, password, xml });
}

export function getSignErrorMessage(code) {
  const errno = Number.isInteger(code) ? code : Number.parseInt(code);
  if (errno === -19) {
    return "Сертификат отозван";
  } else if (errno === -18) {
    return "Невозможно определить статус отзыва сертификата";
  } else if (errno === -17) {
    return "Недействительный сертификат ЦС";
  } else if (errno === -16) {
    return "Отсутствует сертификат ЦС";
  } else if (errno === -15) {
    return "Неверная подпись сертификата";
  } else if (errno === -14) {
    return "Срок действия сертификата истек";
  } else if (errno === -13) {
    return "Криптоалгоритм подписи не поддерживается";
  } else if (errno === -12) {
    return "Неверная подпись";
  } else if (errno === -11) {
    return "Подпись не соответствует данным";
  } else if (errno === -4) {
    return "Неверные параметры вызова функции";
  } else if (errno === -3) {
    return "Не найден действительный сертификат пользователя или отсутствует ключ";
  } else if (errno === -2) {
    return "Операция отменена пользователем";
  } else if (errno === -1) {
    return "Внутренняя ошибка";
  } else if (errno === 0) {
    return "Операция завершена успешно";
  } else {
    return "Неизвестная ошибка";
  }
}

export function dieIfAlreadySent(){
  return new Promise((resolve, reject) => {
    if(this.selected.eclient_request_id){
      this.$error('Документ не может быть отправлен повторно')
      return reject()
    }
    return resolve()
  })
}

export function performDocumentControl(){
  return this.$store.dispatch(`${this.namespace}/performControlNCES`, this.selected.id)
    .then((res) => {
      return res.data.length ? res.data.filter((i) => i.message.type !== 'info') : []
    })
    .catch((err) => {
      this.$error('Ошибка проведения контроля')
      throw err
    })
}

export function displayControlResponse(errorsAndWarnings) {
  if (errorsAndWarnings.length) {
    this.setControl(errorsAndWarnings)
    return this.showDocumentControlResponse()
  }
  return Promise.resolve()
}

export const NAMESPACE_REQUIRED = {
  namespace:"Укажите тип документа",
}

export const SELECTED_REQUIRED = {
  selected:"Отсутствует информация о текущем документе"
}

export const PTO_REQUIRED = {
  selectedPto: "Отсутствует код ПТО"
}

export const USER_REQUIRED = {
  user: "Отсутствует информация о пользователе"
}

export const COMMON_VALIDATIONS = {
  ...NAMESPACE_REQUIRED,
  ...SELECTED_REQUIRED,
  ...PTO_REQUIRED,
  ...USER_REQUIRED
}

export function validateRequiredParams(validations = COMMON_VALIDATIONS){
  return new Promise((resolve, reject) => {
    const error = Object.keys(validations).find(key => !this[key] && this[key] !== null)
    if(error) return reject(validations[error])
    return resolve()
  }).catch((err) => {
    this.$error(err)
    throw err
  })
}
export function getXmlString(){
  return new Promise((resolve, reject) => {
    this.$store.dispatch(`${this.namespace}/getXmlString`, this.selected.id)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if(err.response.status === 400){
          const message = err.response.data
          this.showXsdControlResponse({message})
        }
        this.$error('Ошибка загрузки документа')
        reject()
      })
  })
}

export function updateSelectedPto({code}){
  return new Promise((resolve, reject) => {
    if(!code) return reject()
    this.selectedPto = code
    return resolve()
  })
}
export function sendToNces(xml){
  const fileGuid = this.selected.unique_key;
  const remark = this.selected?.about_declaration?.out_number || ""; // ! ЗНАЧЕНИЕ ЭТО ИСХОДЯЩИЙ НОМЕР !
  const divisionId = this.user.user.activeDivisionId;
  const declarationId = this.selected.id;
  const dtGuid = this.selected?.dt_guid
  return this.$store.dispatch(`${this.namespace}/sendSignedXmlString`, {
    xml,
    ptoId: this.selectedPto,
    fileGuid,
    remark,
    divisionId,
    declarationId,
    dtGuid
  }).then(() => {
    this.$success("Документ успешно отправлен")
  }).catch(err => {
    this.$error(getMessageFromNcesResponse(err))
    throw err
  })
}
export function sendToEclient(xml){
  const declarationId = this.selected.id;
  const divisionId = this.user.user.activeDivisionId;
  const userId = this.user.user.id;
  return this.$store.dispatch(`${this.namespace}/sendToEclient`, {
    xml,
    declarationId,
    userId,
    divisionId,
    ptoNumber: this.selectedPto
  }).then(() => {
    this.$success("Документ успешно отправлен")
  }).catch(err => {
    this.$error(getMessageFromNcesResponse(err))
    throw err
  })
}

export function signOnElDocIndicatorCondition({xml, guid}){
  if(this.selected.type_declaration.el_doc_indicator === "ЭД"){
    return getSignedDocument.call(this, {xml, guid})
  }else if(this.selected.type_declaration.el_doc_indicator === "ОО"){
    return Promise.resolve(xml)
  }else {
    const message = 'Отсутствует признак ОО/ЭД'
    this.$error(message)
    return Promise.reject(message)
  }
}

export function getMessageFromNcesResponse(err){
  return typeof err.response?.data === "object" && "errDescr" in err.response.data
    ? err.response?.data?.errDescr
    : err.response?.data || err;
}

export function getSignedDocument({xml, guid}){
  return new Promise((resolve, reject) => {
    this.$info('Документ отправлен на подпись...')
    signDocument({xml, guid, password:""}).then((res) => {
      if (!res.data?.toString().startsWith("0")) {
        this.$error(getSignErrorMessage(res.data))
        return reject()
      }
      this.$success("Документ подписан ЭЦП")
      return resolve(res.data)
    }).catch(err => {
      if(err.message === NETWORK_ERROR){
        this.$error("SIGN SERVICE недоступен");
      }else {
        const message = getSignErrorMessage(err.response?.data);
        this.$error(message);
      }
      return reject()
    })
  })
}

export function getSignedDocumentPTD({xml, guid}){
  return new Promise((resolve, reject) => {
    this.$info('Документ отправлен на подпись...')
    signDocumentPTD({xml, guid, password:""}).then((res) => {
      if (!res.data?.toString().startsWith("0")) {
        this.$error(getSignErrorMessage(res.data))
        return reject()
      }
      this.$success("Документ подписан ЭЦП")
      return resolve(res.data)
    }).catch(err => {
      if(err.message === NETWORK_ERROR){
        this.$error("SIGN SERVICE недоступен");
      }else {
        const message = getSignErrorMessage(err.response?.data);
        this.$error(message);
      }
      return reject()
    })
  })
}
