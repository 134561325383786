<template>
  <v-sheet
    class="pa-8"
    color="#f4f4f4"
  >
    <v-row>
      <card-for-buy
        v-for="(serv, i) in servicesForBuy"
        :key="i"
        :serv="serv"
        @slideToAvailable="slideToAvailable"
      />
    </v-row>
  </v-sheet>
</template>

<script>
import CardForBuy from '../../components/billing/card-for-buy.vue'
import { mapGetters } from 'vuex'

export default {
  components: {CardForBuy},
  computed: {
    ...mapGetters({
      servicesForBuy: "billing/getServicesForBuy",
      user: 'auth/getUser',
    })
  },
  methods: {
    slideToAvailable(){
      this.$emit('slideToAvailable')
    }
  }
}
</script>
