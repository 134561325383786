<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-b-lg rounded-t-0 "
  >
    <v-card-title>Водитель</v-card-title>
    <v-col>
      <v-row>
        <v-col
          cols="2"
        >
          <label>
            Фамилия
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Имя
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Отчество
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Телефон
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>

export default {

}
</script>