<template>
  <div>
    <filler />
    <vehicle />
    <goods />
    <documents />
    <location />

    <div class="my-5 d-flex justify-end">
      <v-btn
        color="#5CB7B1"
        x-large
      >
        Отправить заявку
      </v-btn>
    </div>
  </div>
</template>
<script>
import Filler from './filler.vue'
import Vehicle from './vehicle.vue'
import Documents from './documents.vue'
import Goods from './goods.vue'
import Location from './location.vue'
export default {
  components:{
    Filler, Vehicle, Documents, Goods, Location
  }
}
</script>
