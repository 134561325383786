<template>
  <div>
    <!-- <Alertbox /> -->
    <links />
    <services />
    <!-- <News />
    <Usefull /> -->
  </div>
</template>

<script>
// import Alertbox from '../components/ui/Alertbox.vue'
import Links from '../components/ui/links.vue'
import Services from '../components/ui/services.vue'
// import News from '../components/ui/News.vue'
// import Usefull from '../components/ui/Usefull.vue'

export default{
  components: {Links, Services },
}
</script>
