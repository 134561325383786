<template>
  <v-card
    elevation="2"
    class="mb-2"
  >
    <v-toolbar
      color="grey lighten-3"
      flat
    >
      <v-card-title
        class="text-h5 font-weight-medium"
        color="black"
      >
        <v-icon
          class="mr-2"
          :color="getColor"
        >
          {{ getIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
    </v-toolbar>

    <div class="mini-table pa-4">
      <table>
        <tr :class="{ highlightedTr : itemBefore && cardItem.doc_on !== itemBefore.doc_on }">
          <td class="text-body-2">
            {{ field.doc_on }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.doc_on }}
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.d_on !== itemBefore.d_on }">
          <td class="text-body-2">
            {{ field.d_on }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ formateData(cardItem.d_on) }}
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.code !== itemBefore.code }">
          <td class="text-body-2">
            {{ field.code }}
          </td>
          <td class="text-body-2 font-weight-bold">
            <router-link
              v-if="cardItem.code.length > 2"
              tag="a"
              :to="`/tnved/${cardItem.code}`"
              class="clickable" 
              target="_blank"
            >
              {{ cardItem.code }}
            </router-link>
            <span v-else>{{ cardItem.code }}</span>
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.description !== itemBefore.description }">
          <td class="text-body-2">
            {{ field.description }}
          </td>
          <td class="text-body-2 font-weight-bold">
            <v-tooltip
              bottom
              max-width="300"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                {{ cardItem.description.slice(0,50) }}
                <v-btn
                  v-if="cardItem.description.length > 50"
                  v-bind="attrs"
                  x-small
                  class="px-0"
                  color="#333"
                  dark
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <span>{{ cardItem.description }}</span>
            </v-tooltip>
          </td>
        </tr>

        <tr :class="{ highlightedTr : itemBefore && cardItem.doc_off !== itemBefore.doc_off }">
          <td class="text-body-2">
            {{ field.doc_off }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.doc_off }}
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.d_off !== itemBefore.d_off }">
          <td class="text-body-2">
            {{ field.d_off }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ formateData(cardItem.d_off) }}
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.code_ed_izm !== itemBefore.code_ed_izm }">
          <td class="text-body-2">
            {{ cardItem.code_ed_izm }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.code_ed_izm }}
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.add_code_ed_izm !== itemBefore.add_code_ed_izm }">
          <td class="text-body-2">
            {{ field.add_code_ed_izm }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.add_code_ed_izm }}
          </td>
        </tr>
      </table>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    cardItem: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    title:{
      type: String,
      required: true
    },
    itemBefore: {
      type: Object,
      required: false,
      default:null
    }
  },
  computed: {
    getIcon(){
      if(this.title === 'Удаление'){
        return 'mdi-alert'
      }else if(this.title === 'Добавление'){
        return 'mdi-plus-circle'
      }else{
        return 'mdi-folder-swap'
      }
    },
    getColor(){
      if(this.title === 'Удаление'){
        return 'red'
      }else if(this.title === 'Добавление'){
        return '#5CB7B1'
      }else{
        return 'amber'
      }
    }
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>