Object.defineProperty(Object.prototype, 'pick', {
  value: function (keys) {
    if (Array.isArray(keys)) {
      const result = {}
      keys.forEach(key => {
        result[key] = key in this ? this[key] : null
      })
      return result
    }
    throw 'Argument must be Array'
  },
  enumerable: false
})