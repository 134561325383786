<template>
  <div>
    <v-btn
      class="elevation-0 px-0"
      min-width="32px"
      small
      plain
      @click="showDebitList"
    >
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
    <component
      :is="activeComponent"
      :show.sync="show"
      :params="activeComponentProps"
    />
  </div>
</template>
<script>
export default {
  props:{
    item:{
      type:Object,
      required:true
    }
  },
  data(){
    return{
      activeComponent:null,
      show:false,
      activeComponentProps:{
        selectedPaymentOrder: null
      }
    }
  },
  methods:{
    showDebitList(){
      this.activeComponentProps.selectedPaymentOrder = this.item
      this.activeComponent = () => import('@/components/payment-orders/payment-order-debit-table.vue')
      this.show = true
    }
  }
}
</script>
