import {CATALOGS, TNVED as HELPER} from "@/http-routes";
import axios from "axios";
import {capitalize} from "../helpers/text";

const contactTypes = [
  {
    code: 'TE',
    name: 'ТЕЛЕФОН',
    text: 'TE - ТЕЛЕФОН'
  },
  {
    code: 'EM',
    name: 'ЭЛЕКТРОННАЯ ПОЧТА',
    text: 'EM - ЭЛЕКТРОННАЯ ПОЧТА'
  },
  {
    code: 'FX',
    name: 'ТЕЛЕФАКС',
    text: 'FX - ТЕЛЕФАКС'
  },
]

// Не включены в основной перечень - запрос отдельно
// nsiRailwayStations - Ж/д станции
// nsiAeos - справочник УЭО

const CATALOGS_TYPES = [
  "nsiCountries", // страны
  "nsiDealCharactersSD", // характер сделки СД
  "nsiDealCharactersDT", // характер сделки ДТ
  "nsiDealFeaturesSD", // особенность сделки СД
  "nsiDealFeaturesDT", // особенность сделки СД
  "nsiTypesOfDelivery", // типы поставок
  "nsiCurrencies", // валюты
  "nsiCustoms", // таможни
  "nsiCustomsAgent", // таможенные представители
  "nsiMeasureUnits", // единица измерения
  "nsiTypesOfDocument", // коды документов
  "nsiCancellationReasons", // справочник основ для аннулирования
  "nsiCorrectionReasons", // справочник основ для корректировок
  "nsiRegions", // справочник административно территориальных делений
  "nsiDeliveryTerms", // условия поставки
  "nsiTransportTypes", // тип транспорта
  "nsiPackageTypes", // тип упаковки
  "nsiTarifs", // подакцизные товары
  "nsiCustomsPoints", // ПТО
  "nsiIdentityDocuments", // документы подтвержд. личность
  "nsiVehicleTypes", // типы ТС
  "nsiGuaranteeTypes", // справочник типов гарантий
  "nsiGuaranteeTypesSo", // типы гарантий СО
  "nsiGuaranteeReasons", // основания гарантий
  "nsiTransportCategories", // категории транспорта
  "nsiTransportOtherCategories", // категории ТС для РС
  "nsiPaymentModes", // код СП
  "nsiCustomsStorages", // СВХ
  "nsiCustomsStorageTypes", // Типы СВХ
  "nsiMovementFeatures", // Способы перемещения товаров
  "nsiCustomsProcedures", // Таможенные процедуры,
  "nsiCustomsCostMethods", // Метод определения таможенной стоимости
  "sanctionsProducts", // Санкционные товары
  "nsiPresentTypes", // Признак представления документа
  "nsiPurposePlacements", // Цель размещения (УВР)
  "nsiPresentAddTypes",
  "nsiDeclarationFeatures" // 7 Код особенности декл.
]
const getDefaultState = () => {
  let data = {};
  CATALOGS_TYPES.forEach(key => {
    data[key] = {
      list: [],
      updated: ""
    }
  })
  return data
}
const getActions = () => {
  const except = ["nsiTarifs", "sanctionsProducts"]
  let actions = {};
  CATALOGS_TYPES.filter(i => !except.includes(i)).forEach(type => {
    const action = `fetch${capitalize(type)}`
    actions[action] = function ({commit}) {
      axios.get(`${CATALOGS}${type}`).then((res) => {
        commit("SET_CATALOG", {
          type: type,
          items: res.data,
        });
      });
    }
  })
  return actions
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_CATALOG(state, {items: {list = [], updated = ""} = {}, type}) {
      if (type in state) {
        list.length ?
          state[type].list.splice(0, state[type].list.length, ...list)
          : state[type].list.splice(0, state[type].list.length)
        state[type].updated = updated
      } else {
        console.log(`Возникла ошибка при сохранении каталога: ${type}`)
      }
    },
  },
  actions: {
    checkUpdates() {
      return axios.get(`${CATALOGS}common/getLastUpdated`)
    },
    async fetchAll({state, dispatch}) {
      const storage = window.localStorage
      const store = JSON.parse(storage.getItem('vuex')) || {}
      const {catalogs = {}} = store
      const {data} = await dispatch('checkUpdates')
      const stateCatalogs = [...Object.keys(state)]
      if (Object.keys(catalogs).length) {
        Object.keys(catalogs).forEach((type) => {
          // Чтобы обновлялись только актуальные (перечислены в state)
          if (stateCatalogs.includes(type)) {
            const action = `fetch${capitalize(type)}`
            if (!catalogs[type].updated
              || catalogs[type].updated !== data[type]
              || !catalogs[type].list
              || !catalogs[type].list.length) {
              console.log('Вызвано обновление справочника:', type, 'action:', action)
              if (!this._actions[`catalogs/${action}`]) return console.log(type, ': устарел или действие не найдено');
              dispatch(action)
            }
          }
        })
      } else {
        CATALOGS_TYPES.forEach(type => {
          const action = `fetch${capitalize(type)}`
          dispatch(action)
        })
      }
    },
    ...getActions(),
    fetchSanctionsProducts({commit}){
      axios.get(`${CATALOGS}sanctionsProducts`).then((res) => {
        const list = res.data.list.map(product => {
          return {
            ...product,
            tnVedArray: product.tnVed.split(", ")
          }
        })
        commit("SET_CATALOG", {
          type: "sanctionsProducts",
          items:{
            list,
            updated:res.data.updated
          }
        });
      }).catch(err => console.log(err));
    },
    fetchNsiTarifs({commit}) {
      axios.get(`${CATALOGS}tnVedsWithExcise`).then((res) => {
        commit("SET_CATALOG", {
          type: "nsiTarifs",
          items: res.data,
        });
      });
    },

    // Ниже не обязательные каталоги
    fetchNsiTransportMarks() {
      return axios.get(`${CATALOGS}nsiTransportMarks`).then(res => {
        return res.data?.list.map(i => {
          return {
            ...i,
            text: [i.code, i.name].join('-'),
            search: [i.code, i.name, i.ownName].join('-').toLowerCase(),
          }
        }) || []
      })
    },
    getLocalStorageSize() {
      var _lsTotal = 0, _xLen, _x;
      for (_x in localStorage) {
        if (!Object.prototype.hasOwnProperty.call(localStorage, _x)) {
          continue;
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2);
        _lsTotal += _xLen;
        console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
      }
      console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
    },
    fetchNsiRailwayStations() {
      return axios.get(`${CATALOGS}nsiRailwayStations`)
    },
    fetchNsiAeos() {
      return axios.get(`${CATALOGS}nsiAeos`)
    },
    getExtraTnved(_, {code, actionDate}) {
      if (!code) return Promise.reject(new Error('Отсутствует код ТНВЭД'))
      return axios.get(`${CATALOGS}nsiAddTnVed/getByCode?code=${code}&actionDate=${actionDate}`)
    },
    getBrandCatalogByCode(_, {code}) {
      return axios.get(`${CATALOGS}nsiAddTnVedCategory/getByTnVed?code=${code}`)
    },
    getBrandCatalogTypes() {
      return axios.get(`${CATALOGS}nsiAddTnVedCategory/getAll`)
    },
    getTrademarksByType(_, {listId}) {
      return axios.get(`${CATALOGS}nsiTradeMarks/getByListIdAndName?listId=${listId}`)
    },
    fetchVehiclesAll(_, {number, divisionId}) { // По всей компании
      return axios.get(`${HELPER}divVehicle/getByNumber?divisionId=${divisionId}&number=${number ?? ''}`)
    },
    fetchVehiclesDivision(_, {divisionId}) { // По отделу
      return axios.get(`${HELPER}divVehicle/getByDivisionId?divisionId=${divisionId}`)
    },
    searchVehicles(_, {value, divisionId}) { // Поиск по номеру/вину/техпаспорту в рамках всей компании
      return axios.get(`${HELPER}divVehicle/getByAnyField?value=${value}&divisionId=${divisionId}`)
    },
    deleteVehicle(_, {id}) {
      return axios.post(`${HELPER}divVehicle/deleteById?id=${id}`)
    },
    saveVehicle(_, payload) {
      return axios.post(`${HELPER}divVehicle/save`, payload)
    },
    completeUpdateVehicle(_, payload){
      return axios.post(`${HELPER}divVehicle/completeUpdate`, payload)
    },
    saveVehicleDistinct(_, payload){
      return axios.post(`${HELPER}divVehicle/add`, payload)
    },
    fetchCarriersAll(_, {divisionId}) {
      return axios.get(`${HELPER}divCarrier/getByDivisionId?divisionId=${divisionId}`)
    },
    fetchCarriersDivision(_, {divisionId}) {
      return axios.get(`${HELPER}divCarrier/getByDivisionId?divisionId=${divisionId}`)
    },
    searchCarrier(_, {value, divisionId}) {
      return axios.get(`${HELPER}divCarrier/getByAnyField?value=${value}&divisionId=${divisionId}`)
    },
    deleteCarrier(_, {id}) {
      return axios.post(`${HELPER}divCarrier/deleteById?id=${id}`)
    },
    saveCarrier(_, payload) {
      return axios.post(`${HELPER}divCarrier/save`, payload)
    },
    fetchDriversDivision(_, {divisionId}) {
      return axios.get(`${HELPER}divDriver/getByDivisionId?divisionId=${divisionId}`)
    },
    searchDriver(_, {value, divisionId}) {
      return axios.get(`${HELPER}divDriver/getByAnyField?value=${value}&divisionId=${divisionId}`)
    },
    deleteDriver(_, {id}) {
      return axios.post(`${HELPER}divDriver/deleteById?id=${id}`)
    },
    saveDriver(_, payload) {
      return axios.post(`${HELPER}divDriver/save`, payload)
    },
    saveGuarantee(_, {value, id}) {
      return axios.post(`${HELPER}divGuarantee/save?userId=${id}`, value)
    },
    fetchGuaranteeByDivision(_, {divisionId}) {
      return axios.get(`${HELPER}divGuarantee/getByDivisionId?divisionId=${divisionId}`)
    },
    fetchGuaranteeRoot(_, {divisionId}) {
      return axios.get(`${HELPER}divGuarantee/getByRootParentDivisionId?divisionId=${divisionId}`)
    },
    fetchNsiPaymentTypes() {
      return axios.get(`${CATALOGS}nsiPaymentTypes`)
    },
    fetchNsiPaymentFeatures() {
      return axios.get(`${CATALOGS}nsiPaymentFeatures`)
    },
    fetchGovernmentRegistrations(_, payload){
      return axios.post(`${CATALOGS}sgr`, payload)
    },
    fetchNsiPreferences(){
      return axios.get(`${CATALOGS}nsiPreferences`)
    },
    fetchExtraTnvedOptions(){
      return axios.get(`${CATALOGS}nsiAddTnVed/getDistinctNames`)
    },
    fetchModelsByDivision(_, {divisionId}) {
      return axios.get(`${HELPER}divTransportModel/getByDivisionId?divisionId=${divisionId}`)
    },
    searchModels(_, {markName,divisionId }){
      return axios.get(`${HELPER}divTransportModel/getByMarkNameAndModelName?markName=${markName}&divisionId=${divisionId}`)
    },
    saveModel(_, {vehicle, userId}){
      return axios.post(`${HELPER}divTransportModel/save?userId=${userId}`, vehicle)
    },
    loadBankBIC() {
      const axiosInstance = axios.create();
      return axiosInstance
        .get("https://www.nbrb.by/api/bic")
        .then((res) => {
          return res.data.map((item) => {
            return {
              ...item,
              search: `${item.CDBank} - ${item.NmBankShort}`,
            };
          });
        });
    },
  },
  getters: {
    getNsiCountries: (s) =>
      s.nsiCountries.list.map((i) => {
        return {
          ...i,
          text: `${i.letterCode} - ${i.name}`,
          search: `${i.letterCode} - ${i.name}-${i.digitCode}-${i.ownName || ""}`.toLowerCase(),
        };
      }),
    getNsiPackageTypes: (s) =>
      s.nsiPackageTypes.list.map((i) => {
        return {
          ...i,
          text: `${i.code}-${i.name}`,
          search: `${i.code}-${i.name}`.toLowerCase(),
        };
      }),
    getNsiDeliveryTerms: (s) => s.nsiDeliveryTerms.list,
    getNsiTransportTypes: (s) =>
      s.nsiTransportTypes.list.map((i) => {
        return {
          ...i,
          search: `${i.code}-${i.name}`.toLowerCase(),
          text: `${i.code} - ${i.name}`,
        };
      }),
    getNsiDealCharactersSD: (s) => s.nsiDealCharactersSD.list,
    getNsiDealCharactersDT: (s) =>
      s.nsiDealCharactersDT.list.map((i) => {
        return {
          ...i,
          text: `${i.code}-${i.name}`,
          search: `${i.code}-${i.name}`.toLowerCase(),
        };
      }),
    getNsiDealFeaturesSD: (s) => s.nsiDealFeaturesSD.list,
    getNsiDealFeaturesDT: (s) => s.nsiDealFeaturesDT.list,
    getNsiTypesOfDelivery: (s) =>
      s.nsiTypesOfDelivery.list.map((i) => {
        return {
          ...i,
          text: `${i.code}-${i.name}`,
          search: `${i.code}-${i.name}`.toLowerCase(),
        };
      }),
    getNsiCurrencies: (s) => s.nsiCurrencies.list.map((i) => {
      return {
        ...i,
        text: `${i.letterCode} (${i.name})`,
        search: `${i.letterCode}-${i.name}-${i.digitCode}-${i.ownName || ""}`.toLowerCase(),
      }
    }),
    getNsiCustoms: (s) => s.nsiCustoms.list.map(i => {
      return {
        ...i,
        search: `${i.code} ${i.name}`.toLowerCase(),
        text: `${i.code}-${i.name}`
      }
    }),
    getNsiCustomsAgent: (s) => s.nsiCustomsAgent.list,
    getNsiMeasureUnits: (s) => s.nsiMeasureUnits.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.name}-${i.shortDescription}-${i.code}`.toLowerCase(),
        text: `${i.shortDescription} (${i.code})`
      }
    }),
    getNsiTypesOfDocument: (s) => s.nsiTypesOfDocument.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.ownName || ""}`.toLowerCase(),
      }
    }),
    getNsiCancellationReasons: (s) => s.nsiCancellationReasons.list.map(i => {
      return {
        ...i,
        search: `${i.code} ${i.name.substr(0, 100)}`
      }
    }),
    getNsiCorrectionReasons: (s) => s.nsiCorrectionReasons.list.map(i => {
      return {
        ...i,
        search: `${i.code} ${i.name.substr(0, 100)}`
      }
    }),
    getNsiRegions: (s) =>
      s.nsiRegions.list.map((i) => {
        return {
          ...i,
          text: `${i.code} - ${i.name}`,
          search: `${i.code}-${i.name}-${i.ownName || ""}`.toLowerCase(),
        };
      }),
    getNsiTarifs: s => s.nsiTarifs.list,
    // getNsiCustomsPoints: s => s.nsiCustomsPoints.list,
    getNsiCustomsPoints: (s) =>
      s.nsiCustomsPoints.list.map((i) => {
        return {
          ...i,
          text:`${i.code} - ${i.name}`,
          search: `${i.code} - ${i.name}-${i.address}`.toLowerCase(),
        }
      }),
    getNsiIdentityDocuments: (s) =>
      s.nsiIdentityDocuments.list.map((i) => {
        return {
          ...i,
          search: `${i.code} - ${i.name}`
        }
      }),
    getNsiVehicleTypes: s => s.nsiVehicleTypes.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.name}`.toLowerCase(),
        text: `${i.code}-${i.name}`
      }
    }),
    gethNsiGuaranteeTypes: s => s.nsiGuaranteeTypes.list.map((i) => {
      return {
        ...i,
        search: i.code,
        text: `${i.code}-${i.name}`
      }
    }),
    gethNsiGuaranteeTypesSo: s => s.nsiGuaranteeTypesSo.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.name}`.toLowerCase(),
        text: `${i.code}-${i.name}`
      }
    }),
    getNsiGuaranteeReasons: s => s.nsiGuaranteeReasons.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.name}`.toLowerCase(),
        text: `${i.code}-${i.name}`
      }
    }),
    getNsiTransportCategories: s => s.nsiTransportCategories.list.map((i) => {
      return {
        ...i,
        search: `${i.code}-${i.name}`.toLowerCase(),
        text: `${i.code}-${i.name}`
      }
    }),
    getNsiTransportOtherCategories: s =>
      s.nsiTransportOtherCategories.list.filter((i) => i.code.startsWith(3)).map((i) => {
        return {
          ...i,
          search: `${i.code}-${i.name}`.toLowerCase(),
          text: `${i.code}-${i.name}`
        }
      }),
    getContactTypes: () => contactTypes,
    getNsiPaymentModes: (s) =>
      s.nsiPaymentModes.list.map((i) => {
        return {
          ...i,
          search: `${i.code} - ${i.name}`
        };
      }),
    getNsiCustomsStorages: s => s.nsiCustomsStorages.list.map((i) => {
      return {
        ...i,
        search: `${i.licenseNumber} - ${i.name}-${i.unp}`.toLowerCase(),
        text: `${i.licenseNumber} - ${i.name}`
      }
    }),
    getNsiCustomsStorageTypes: s => s.nsiCustomsStorageTypes.list.map((i) => {
      return {
        ...i,
        search: `${i.code} - ${i.name}`.toLowerCase(),
        text: `${i.code} - ${i.name}`
      }
    }),
    getNsiMovementFeatures: s => s.nsiMovementFeatures.list.map((i) => {
      return {
        ...i,
        text: `${i.code}-${i.name}`.toLowerCase(),
        search: `${i.code}-${i.name}`.toLowerCase()
      }
    }),
    getNsiCustomsProcedures: s => s.nsiCustomsProcedures.list.map((i) => {
      return {
        ...i,
        text: `${i.code} - ${i.name}`.toLowerCase()
      }
    }),
    getNsiCustomsCostMethods: s => s.nsiCustomsCostMethods.list.map((i) => {
      return {
        ...i,
        text: `${i.code} - ${i.name}`.toLowerCase()
      }
    }),
    getSanctionedProducts: s => s.sanctionsProducts.list.map((product) => {
      const ownNameTags = product.ownName ? product.ownName?.toLowerCase().split(', ') : []
      const tradeMark = product.tradeMark?.toString().toLowerCase()
      return {
        ...product,
        tags: [tradeMark,...ownNameTags]
      }
    }),
    getUniqueSanctionedCodes: s => {
      const codes = s.sanctionsProducts.list.reduce((previous, current) => {
        return [...previous, ...current.tnVedArray]
      }, [])
      return [...new Set(codes)].sort()
    },
    getNsiPresentTypes: (s) => s.nsiPresentTypes.list.map(i => {
      return {
        ...i,
        text:`${i.code} - ${i.name}`
      }
    }),
    getNsiPurposePlacements : (s) => s.nsiPurposePlacements.list.map(i => {
      return {
        ...i,
        search: `${i.code}-${i.name}`
      }
    }),
    getNsiPresentAddTypes: (s) => s.nsiPresentAddTypes.list.map(i => {
      return {
        ...i,
        text: `${i.code} - ${i.name}`,
        search: `${i.code} - ${i.name}`
      }
    }),
    getNsiDeclarationFeatures: (s) => s.nsiDeclarationFeatures.list.map(i => {
      return {
        ...i,
        search: `${i.code} - ${i.name}`
      }
    }),
  },
};
