<template>
  <div
    class="d-flex"
  >
    <search-conditions
      :value="params.search.condition"
      :conditions="$options.CONDITIONS"
      @select="onConditionChange"
    />

    <v-text-field
      v-model="searchValue"
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-0"
      filled
      rounded
      placeholder="Поиск"
      clearable
      @click:clear="clear"
      @keyup.enter="search"
    />
    <v-btn
      color="#5CB7B1"
      height="auto"
      class="elevation-0 my-0 rounded-l-0 white--text"
      x-large
      min-width="50px"
      @click="search"
    >
      <v-icon>mdi-magnify</v-icon>
      <span class="d-none d-lg-block">Поиск</span>
    </v-btn>
  </div>
</template>
<script>
import SearchConditions from "@/components/payment-orders/search-conditions.vue";
import {CONDITIONS} from "@/components/payment-orders/search-conditions";

export default {
  CONDITIONS,
  components: {SearchConditions},
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchValue: ""
    }
  },
  created() {
    this.setDefaultSearchValue()
  },
  methods: {
    setDefaultSearchValue() {
      this.searchValue = this.params.search.value
    },
    onConditionChange(condition) {
      this.$emit("update", {
        ...this.params,
        search: {
          ...this.params.search,
          condition
        }
      })
    },
    clear() {
      this.updateParamsSearchValue()
    },
    updateParamsSearchValue(value = ""){
      this.$emit('update', {
        ...this.params,
        search: {
          ...this.params.search,
          value
        }
      })
    },
    search() {
      this.updateParamsSearchValue(this.searchValue)
    }
  }
}
</script>
