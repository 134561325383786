<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg mb-5"
  >
    <v-card-title>Юрид лицо / Физ лицо</v-card-title>

    <v-col>
      <v-row justify="space-between">
        <v-col cols="3">
          <label>
            Наименование
            <v-text-field
              outlined
              dense
              background-color="white"
              hide-details="auto"
            /></label>
        </v-col>
        <v-col
          cols="2"
          align-self="end"
        >
          <v-btn>В справочник</v-btn>
        </v-col>
      </v-row>
      <v-row><reciever-shipment /></v-row>
      <v-row>
        <v-col>
          <v-btn>
            <v-icon>mdi-plus</v-icon>
            Добавить партию
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col />
  </v-card>
</template>

<script>
import RecieverShipment from './reciever-shipment.vue'
export default {
  components:{
    RecieverShipment
  }
}
</script>
