<template>
  <div>
    <header>
      <v-container fluid>
        <v-btn
          v-if="mobile"
          plain
          dark
          @click.stop="drawer = !drawer"
        >
          <v-icon large>
            mdi-equal
          </v-icon>
        </v-btn>
        <div class="d-none d-lg-flex justify-space-between align-center">
          <router-link
            v-if="!user"
            tag="a"
            to="/"
          >
            <img
              src="../../assets/img/small-logowh.svg"
              alt=""
              class="logo"
            >
          </router-link>
          <div
            v-else
            class="d-flex justify-flex-start align-center"
          >
            <router-link
              tag="a"
              to="/"
            >
              <v-btn
                class="mr-4"
                fab
                dark
                small
                color="white"
              >
                <v-icon color="#5CB7B1">
                  mdi-home
                </v-icon>
              </v-btn>
            </router-link>
            <div
              v-if="visibleDvsns.length"
              class="company-select mr-5"
            >
              <v-select
                v-model="user.user.activeDivisionId"
                dark
                :items="visibleDvsns"
                text-color="white"
                color="#ffffff"
                solo
                hide-details="auto"
                item-color="#5CB7B1"
                append-icon="mdi-chevron-down"
                background-color="#5CB7B1"
                full-width
                placeholder="Выберите компанию"
                item-value="real_division_id"
                item-text="division_name"
                @change="setDivision"
              />
            </div>
          </div>

          <div class="cont-links hidden-xs">
            <a
              v-for="(item, idx) in activeTopMenu"
              :key="`top-menu-${idx}`"
              class="white-link"
              @click="callFunction(item.action)"
            >
              {{ item.name }}
            </a>
          </div>

          <div>
            <currency-rates />

            <user-notification v-if="user" />

            <v-btn
              class="mx-1"
              fab
              dark
              small
              color="white"
            >
              <v-icon color="#5CB7B1">
                mdi-help
              </v-icon>
            </v-btn>
            <router-link
              tag="a"
              to="/login"
            >
              <v-btn
                v-if="!user"
                class="mx-1"
                fab
                dark
                small
                color="white"
              >
                <v-icon color="#5CB7B1">
                  mdi-login
                </v-icon>
              </v-btn>
            </router-link>


            <company-menu />
            <!--  -->
            <user-menu />
          </div>
        </div>
      </v-container>
    </header>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="z-index:10"
    >
      <template
        v-if="user"
        #prepend
      >
        <drawer-avatar />
        <v-divider />
        <v-select
          id="company-select-mobile"
          dark
          :value="user.user.activeDivisionId"
          :items="visibleDvsns"
          solo
          flat
          class="rounded-0"
          text-color="white"
          color="#ffffff"
          hide-details="true"
          item-color="#5CB7B1"
          append-icon="mdi-chevron-down"
          full-width
          placeholder="Выберите компанию"
          item-value="real_division_id"
          item-text="division_name"
          @change="setDivision"
        />
        <v-divider />
      </template>
      <v-list
        nav
        dense
      >
        <user-menu drawer />

        <v-list-item
          v-for="(item,idx) in activeTopMenu"
          :key="`top-menu-mobile-${idx}`"
        >
          <v-list-item-title @click="callFunction(item.action)">
            <v-icon class="mr-1">
              {{ item.icon }}
            </v-icon>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserNotification from "./user-notification.vue";
import checkAccess from "../../mixins/check-access";
import CompanyMenu from './menu/company-menu.vue'
import UserMenu from './menu/user-menu.vue'
import DrawerAvatar from './menu/drawer-avatar.vue'
import CurrencyRates from "@/components/ui/currency-rates.vue";
import {callFunction} from '@/helpers/common'
import {DIVISION_CHANGE_EVENT} from "@/helpers/broadcast";
export default {
  components: {
    UserNotification,
    CompanyMenu,
    UserMenu,
    DrawerAvatar,
    CurrencyRates
  },
  mixins: [checkAccess],
  data:() => ({
    topMenu:[
      {
        icon:"mdi-login",
        name:"Войти",
        action:"toLogin",
      },
      {
        icon:"",
        name:"ТН ВЭД",
        action:"toTnved",
      },
      {
        icon:"",
        name:"НСИ",
        action:"toNsi",
      },
      {
        icon:"",
        name:"Журнал",
        action:"toJournal",
      },
    ],
    drawer:false,
    offset: true,
    langs: false,
    clientsValue: "Все клиенты",
  }),

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      divisions: "auth/getDivisions",
    }),
    activeTopMenu(){
      return this.topMenu.filter(i => {
        if(i?.permissions?.length){
          const allowed = i.permissions.map(permission => this.$can(permission))
          if(allowed.includes(false)) return false
        }
        if(i.action === 'toJournal'){
          return !!this.user
        }
        if(i.action === 'toLogin'){
          return !this.user
        }
        return true
      })
    },
    visibleDvsns() {
      let visibleDvsns = [];
      this.divisions.forEach((item) => {
        if (item.is_active_user_in_division) {
          visibleDvsns.push(item);
        }
      });

      return visibleDvsns;
    },

    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    let app = document.querySelector("#app");
    let userBox = document.querySelector(".change-lang-btn");
    let userBoxSpan = document.querySelector(".change-lang-btn span");
    let userBoxImg = document.querySelector(".change-lang-btn img");

    app.addEventListener("click", (e) => {
      if (
        e.target == userBox ||
        e.target == userBoxSpan ||
        e.target == userBoxImg
      ) {
        console.log("");
      } else {
        this.langs = false;
      }
    });
    return this.langs;
  },
  methods: {
    callFunction,

    toTnved(){
      const route = this.$router.resolve({
        path: `/tnved`
      });
      window.open(route.href ,'_blank')
    },
    toNsi(){
      this.$router.push({path:'/nsi'})
    },
    toJournal(){
      this.$router.push({path:'/journal'})
    },
    toLogin(){
      this.$router.push({path:'/login'})
    },
    notifyOtherTabs(){
      this.$broadcastChannel.postMessage({
        type:DIVISION_CHANGE_EVENT
      })
      return Promise.resolve()
    },
    loadDivisions(value){
      return this.$store.dispatch('auth/loadSingleDivisions', value)
    },
    redirect(){
      if(this.$route.name !== "Journal"){
        this.toJournal()
      }
      return Promise.resolve()
    },
    setDivision(value) {
      this.$store.dispatch("auth/setDivision",value)
        .then(() => this.loadDivisions(value))
        .then(this.redirect)
        .then(this.notifyOtherTabs)
        .catch(() => this.$error())
    },

  },
};
</script>
<style scoped>

</style>
