<template>
  <v-row>
    <!-- <pre>{{docs}}</pre> -->


    <!-- <v-col cols="12" lg="6" md="6" sm="6" v-for="(item, index) in docs.items" :key="index" :offset="getOffset(item.update_type)"
        :class="{offDelete : item.update_type === 'delete'}">

            <UpdateTariffs :cardItem="item" :field="docs.table_description.field_description"
            :title="getTitle(item.update_type)" :itemBefore="item.update_type === 'update_after' ? docs.items[index - 1] : null" />

        </v-col> -->

    <v-col
      v-for="(item, index) in docs.items"
      :key="index"
      cols="12"
      lg="12"
      md="12"
      sm="12"
      class="mb-0"
    >
      <div class="shadow-box px-4">
        <h2 class="mb-0">
          {{ item.description }}
          <v-btn
            x-small
            fab
            color="#4B4B4B"
            @click="openTable(index)"
          >
            <v-icon color="#fff">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </h2>

        <v-row v-if="openedTables.includes(index)">
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
          >
            <h4>Было:</h4>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
          >
            <h4>Стало:</h4>
          </v-col>
          <v-col
            v-for="(tarif, ind) in item.tarifs"
            :key="ind"
            cols="12"
            lg="6"
            md="6"
            sm="6"
            :offset="getOffset(tarif.update_type)"
            :class="{offDelete : tarif.update_type === 'delete'}"
          >
            <update-tariffs
              :card-item="tarif"
              :title="getTitle(tarif.update_type)"
              :item-before="tarif.update_type === 'update_after' ? item.tarifs[ind - 1] : null"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>


<script>
import UpdateTariffs from '../nsi/update-tariffs.vue'

export default {
  components: {
    UpdateTariffs
  },
  props: {
    docs: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openedTables: []
    }
  },
  computed: {
    updatedBefore() {
      let arr = []
      this.docs.items.forEach(item => {
        if (item.update_type === 'update_before') {
          arr.push(item)
        }
      })
      return arr
    },
    updatedAfter() {
      let arr = []
      this.docs.items.forEach(item => {
        if (item.update_type === 'update_after') {
          arr.push(item)
        }
      })
      return arr
    },
    insert() {
      let arr = []
      this.docs.items.forEach(item => {
        if (item.update_type === 'insert') {
          arr.push(item)
        }
      })
      return arr
    },
  },
  methods: {
    openTable(index) {
      if (this.openedTables.includes(index)) {
        this.openedTables.splice(this.openedTables.indexOf(index), 1)

      } else {
        this.openedTables.push(index)
      }

    },
    getOffset(type) {
      if (type === 'insert') {
        return 6
      } else {
        return 0
      }
    },
    getTitle(type) {
      if (type === 'update_before') {
        return 'Изменение (Было)'
      } else if (type === 'update_after') {
        return 'Изменение (Стало)'
      } else if (type === 'insert') {
        return 'Добавление'
      } else {
        return 'Удаление'
      }
    }
  }
}
</script>
