<template>
  <div>
    <section id="usual">
      <v-container>
        <!-- верхний бар с кнопками -->
        <!-- <pre>{{codePage}}</pre> -->
        <v-skeleton-loader
          v-if="codePage == null"
          type="article"
          dark
        />


        <div
          v-else-if="codePage === 'Notfound' "
          class="row mt-10"
        />


        <div v-else>
          <div class="d-sm-block d-md-flex d-lg-flex align-center mb-4">
            <div class="d-flex align-center">
              <v-btn
                class="mr-3 d-none d-md-flex d-lg-flex"
                small
                color="#e0e0e0"
                @click="visibleTree = !visibleTree"
              >
                <v-icon
                  v-if="visibleTree"
                  color="#676767"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-else
                  color="#676767"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>

              <h2 class="mr-3 mb-sm-1 mb-md-0 mb-lg-0">
                {{ customSpaces(id) }}
              </h2>
              <copy-chip :copied="copied" />
            </div>
            <v-spacer />
            <div class="d-flex align-center">
              <extra-tnved-button
                :date="date"
                :code="id"
              />
              <!-- datepicker -->
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    style="max-width: 180px;"
                    append-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    color="#5CB7B1"
                    background-color="#EBEBEB"
                    solo
                    hide-details="auto"
                    flat
                    class="mr-4"
                    v-on="on"
                    @click:append="menu = !menu"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  color="#5CB7B1"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="#5CB7B1"
                    @click="menu = false"
                  >
                    ОТМЕНА
                  </v-btn>
                  <v-btn
                    text
                    color="#5CB7B1"
                    @click="updatePage"
                  >
                    ОК
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- datepicker -->


              <v-select
                v-model="procedure"
                :items="procedures"
                item-value="value"
                item-text="txt"
                text-color="#333"
                color="#333"
                solo
                hide-details="auto"
                item-color="#EBEBEB"
                append-icon="mdi-chevron-down"
                background-color="#EBEBEB"
                flat
                style="max-width: 180px;"
                @change="updatePage"
              />
              <share-btn />
            </div>
          </div>


          <v-sheet
            v-if="tnved.length && visibleTree"
            class="px-4 py-2 mb-4"
            rounded
          >
            <v-treeview
              ref="tnved"
              v-model="copied"
              :items="tnved"
              item-key="id"
              open-on-click
              class="piz123"
              open-all
              selection-type="independent"
              selectable
              selected-color="black"
              return-object
            >
              <template
                #prepend="{ item }"
              >
                <div class="black-txt d-flex justify-start align-flex-start">
                  <div class="txt-box">
                    <div class="code-txt mr-5">
                      <div
                        v-if="item.code === ''"
                        class="green-minus"
                      />
                      <span v-else>{{ item.code }}</span>
                    </div>
                    <div class="light-txt mt-1">
                      {{ item.description }}
                    </div>
                  </div>
                </div>
              </template>
            </v-treeview>
          </v-sheet>
          <v-divider
            v-else
            class="my-3"
            dark
          />

          <v-chip-group
            v-model="activeIndex"
            active-class="active-tnved-tag"
            column
            mandatory
            @change="changeIndex"
          >
            <v-chip
              v-for="(tag, index) in tags"
              :key="index"
              v-scroll-to="'header'"
              class="mr-2"
              :value="index"
              style="overflow: visible;"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-container>
      <div
        v-if="codePage"
        v-scroll-to="'header'"
        class="bigPrev"
      >
        <v-icon x-large>
          mdi-chevron-left
        </v-icon>
      </div>
      <div
        v-if="codePage"
        v-scroll-to="'header'"
        class="bigNext"
      >
        <v-icon x-large>
          mdi-chevron-right
        </v-icon>
      </div>
    </section>

    <!-- нижний бар со слайдером -->

    <section
      id="tvedResult"
      class="pb-15"
    >
      <v-container>
        <div
          v-if="!codePage || loader"
          class="row"
        >
          <div class="col-lg-2 mx-auto text-center">
            <h4 class="mb-4">
              Загружаем код {{ id }}
            </h4>
            <v-progress-linear
              color="#32a1af"
              indeterminate
              rounded
              height="6"
            />
          </div>
        </div>

        <div
          v-else-if="codePage === 'Notfound' "
          class="row"
        >
          <div class="col-lg-4 mx-auto text-center">
            <v-icon
              class="mb-4"
              large
            >
              mdi-eye-off
            </v-icon>
            <h2 class="mb-4">
              Код {{ id }} не найден
            </h2>
          </div>
        </div>

        <div
          v-else
          class="row"
        >
          <swiper
            ref="mySwiper2"
            :options="swiperOptions"
            @ready="getSliderIndex"
            @slideChange="getSliderIndex"
          >
            <swiper-slide>
              <preferencies :preferencies="preferencies" />
            </swiper-slide>
            <swiper-slide>
              <code-page-tariffs :code-page="codePage" />
            </swiper-slide>
            <swiper-slide>
              <code-page-no-tariffs :no-tarifs="noTarifs" />
            </swiper-slide>
            <swiper-slide>
              <intellectual
                v-if="intellectual"
                :intellectual="intellectual"
              />
            </swiper-slide>
            <swiper-slide>
              <special
                v-if="special"
                :special="special"
              />
            </swiper-slide>
          </swiper>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CodePageTariffs from '../../components/tnved/code-pgae-tariffs.vue'
import CodePageNoTariffs from '../../components/tnved/code-page-no-tariffs.vue'
import Intellectual from '../../components/tnved/intellectual.vue'
import CopyChip from '../../components/tnved/copy-chip.vue'
import ShareBtn from '../../components/tnved/share-btn.vue'
import Special from '../../components/tnved/special.vue'
import Preferencies from '../../components/tnved/preferencies.vue'
import moment from "moment";
import ExtraTnvedButton from "@/components/catalogs/buttons/extra-tnved-button.vue";
export default {
  components: {
    CodePageTariffs,
    CodePageNoTariffs,
    CopyChip,
    ShareBtn,
    Intellectual,
    Preferencies,
    Special,ExtraTnvedButton
  },
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() => ({

    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    procedures: [{
      txt: 'Ввоз',
      value: 'IMPORT'
    }, {
      txt: 'Вывоз',
      value: 'EXPORT'
    }, {
      txt: 'Транзит',
      value: 'TRANSIT'
    }],
    procedure: 'IMPORT',
    copied: [],
    tags: [
      'Льготы и преференции',
      'Тарифные меры',
      'Нетарифные меры',
      'Интеллектуальная собственность',
      'Специальные требования'
    ],
    visibleTree: false,
    loader: false,
    swiperOptions: {
      slidesPerView: 1,
      createdSlides: true,
      touchRatio: false,
      spaceBetween: 30,
      draggable: true,
      loop: false,
      infinite: true,
      simulateTouch: false,
      speed: 600,
      navigation: {
        nextEl: '.bigNext',
        prevEl: '.bigPrev',
      },
    },
    tnved: [],
    activeIndex: 0,
  }),
  computed: {
    ...mapGetters({
      codePage: "tnved/getCodePage",
      noTarifs: "tnved/getNoTarifs",
      intellectual: "tnved/getIntellectual",
      special: "tnved/getSpecial",
      preferencies: "tnved/getPreferencies"
    }),
    computedDateFormatted() {
      return moment(this.date).format('DD.MM.YYYY')
    }
  },
  watch: {
    codePage() {
      this.tnved.push(this.codePage.tnVed)


      let ids = []

      function parseObjectKeys(obj) {
        for (var prop in obj) {
          var sub = obj[prop]
          if (prop == "id") {
            ids.push(obj)
          }
          if (typeof (sub) == "object") {
            parseObjectKeys(sub);
          }
        }
      }

      parseObjectKeys(this.tnved);

      let lastOpen = ids.find(item => {
        return item.code === this.id
      })
      this.copied.push(lastOpen)
      document.title = lastOpen.name
      this.loader = false
    }
  },
  created() {
    let codeParams = {
      code: this.id,
      procedure: this.procedure,
      date: this.date
    }
    this.$store.dispatch('tnved/loadPreferencies', codeParams)
    this.$store.dispatch('tnved/loadSingleCode', codeParams)
    this.$store.dispatch('tnved/loadNoTariffs', codeParams)
    this.$store.dispatch('tnved/loadIntellectual', codeParams)
    this.$store.dispatch('tnved/loadSpecial', codeParams)
  },
  methods: {
    customSpaces(code){
      // 1513 21 900 2
      let space1 = code.substr(0, 4)
      let space2 = code.substr(4, 2)
      let space3 = code.substr(6, 3)
      let space4 = code.substr(9, 1)

      return space1 + ' ' + space2 + ' ' + space3 + ' ' + space4

    },
    changeIndex() {
      this.$refs.mySwiper2.$swiper.activeIndex = this.activeIndex
    },
    getSliderIndex() {
      if (this.activeIndex == 0) {
        this.$refs.mySwiper2.$swiper.slideTo(1, 1)
      }

      this.activeIndex = this.$refs.mySwiper2.$swiper.activeIndex
    },
    updatePage() {
      //    this.loader = true
      this.$refs.menu.save(this.date)
      this.tnved = []
      this.copied = []
      //    console.log(this.procedure)
      //    console.log(this.date)
      let codeParams = {
        code: this.id,
        procedure: this.procedure,
        date: this.date
      }
      this.$store.dispatch('tnved/loadSingleCode', codeParams)
      this.$store.dispatch('tnved/loadNoTariffs', codeParams)
    }
  }
}
</script>

<style scoped>
@media (min-width: 1904px){
    #usual .container {
        max-width: 1300px;
    }
}
    #usual {
        padding: 15px 0;
        background: #333333;
        margin-bottom: 20px;
    }
    #usual h2{
        color: #fff;
    }
    .shadow-box {
        position: relative;
    }

    .code-txt:hover {
        text-decoration: none;
        color: #333;
    }

    .shadow-box {
        position: relative;
        z-index: 20;
    }

    .bigPrev,
    .bigNext {
        z-index: 5;
        position: fixed;
        top: 160px;
    }
</style>
