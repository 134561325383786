<template>
  <v-menu
    v-if="user && user.authorities.length"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="mx-1"
        fab
        dark
        small
        color="white"
        v-on="on"
      >
        <v-icon color="#5CB7B1">
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="item in activeCompanyMenu"
        :key="item.action"
        link
        exact
        @click="callFunction(item.action)"
      >
        <v-list-item-title>
          <v-icon class="mr-1">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {callFunction} from '@/helpers/common'
import {mapGetters} from 'vuex'
export default {
  data:() => ({
    companyMenu:[
      {
        icon:"mdi-file-cabinet",
        name:"Кабинет организации",
        action:"toCabinet",
        permissions:["organization.div.update"]
      },
      // { 
      //   icon:"mdi-format-list-bulleted",
      //   name:"Список компаний",
      //   action:"toCompanyList",
      //   permissions:["organization.div.update"]
      // },
      {
        icon:" mdi-star-check",
        name:"Зарегистрированные компании",
        action:"toRegisteredCompanyList",
        permissions:["shop.global.update"]
      },
      {
        icon:"mdi-toolbox",
        name:"Услуги",
        action:"toServices",
        permissions:["shop.global.update"]
      },
      {
        icon:"mdi-currency-usd",
        name:"Платежные поручения",
        action:"toPaymentOrders",
        permissions:[]
      }
   
    ],
  }),
  computed:{
    ...mapGetters({
      user: "auth/getUser",
    }),
    activeCompanyMenu(){
      return this.companyMenu.filter(i => {
        if(i?.permissions?.length){
          const allowed = i.permissions.map(permission => this.$can(permission))
          if(allowed.includes(false)) return false
        }
        return true
      })
    },
  },
  methods:{
    callFunction,
    toCabinet(){
      this.$router.push({path:`/new-division/${this.user.user.activeDivisionId}`})
    },
    toCompanyList(){
      this.$router.push({path:`/new-division`})
    },
    toRegisteredCompanyList(){
      this.$router.push({path:`/billing-companies`})
    },
    toServices(){
      this.$router.push({path:`/billing-services`})
    },
    toPaymentOrders(){
      this.$router.push({path:`/payment-orders`})
    }
  }
}
</script>