import PaymentOrders from "@/views/payment-orders/payment-orders.vue"

const routes = [
  {
    path: "/payment-orders",
    name: "PaymentOrders",
    component: PaymentOrders,
  },
];

export default routes;
