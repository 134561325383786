<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-b-lg rounded-t-0 "
  >
    <v-card-title>Общие характеристики</v-card-title>
    <v-col>
      <v-row>
        <v-col
          cols="2"
        >
          <label>
            Цель ?
            <v-select
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Брутто
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Нетто
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="1"
        >
          <label>
            Валюта
            <v-select
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Cтоимость
            <v-text-field
              outlined
              dense
              background-color="white"           
              hide-details="true"
            />
          </label>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>

export default {

}
</script>