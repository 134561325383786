import store from './store/index'
import axios from 'axios'
import router from './router'

axios.interceptors.request.use(
  (config) => {
    const token = store.getters["auth/getUser"];

    if (token && token.jwtToken) {
      config.headers.Authorization = `Bearer ${token.jwtToken}`;
    }
    return config;
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status == 401) {
      store.dispatch('auth/SIGN_OUT').then(() => {
        if(router.history.current.path !== "/login"){
          router.replace("/login");
        }
      });
    }
    return Promise.reject(error);
  }
);



