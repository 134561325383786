<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Специальные требования</h3>
        <div class="body-1 white--text font-weight-medium">
          Обновлено: {{ formateData(special.maxUpdated) }}
        </div>
      </div>


      <div
        v-if="special.specialRequirementGroups.length"
        class="tnved-slide-box-body"
      >
        <div
          v-for="(require, index) in special.specialRequirementGroups"
          :key="index"
          class="grey-box"
        >
          <h4 class=" mb-0">
            {{ require.specialRequirement }}
          </h4>
          <v-divider class="my-4" />
          <div class="white-box mb-5">
            <div
              v-for="(good, idx) in require.specialRequirements"
              :key="idx"
              class="d-sm-block d-md-flex d-lg-flex justify-start align-start mb-8"
            >
              <div class="code-txt mr-5">
                <span>{{ good.nomenclatureCode }}</span>
                <!-- <div class="red-date" v-if="item.offDate">до {{formateData(item.offDate)}}</div> -->
              </div>
              <div class="light-txt mt-1">
                {{ good.name }}
              </div>
              <v-tooltip
                v-if="require.documents.length"
                max-width="400"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <p
                  v-for="(doc, id) in require.documents"
                  :key="id"
                >
                  {{ doc }}
                </p>
              </v-tooltip>
            </div>
          </div>


          <div class="d-flex justify-space-between align-center">
            <v-tooltip
              v-if="require.procedures.length"
              max-width="600"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  class="body-1 px-5"
                  text
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                  <span class="text-decoration-underline">Процедуры</span>
                </div>
              </template>
              <span
                v-for="(proc, ind) in require.procedures"
                :key="ind"
              >{{ proc }}<span v-if="ind != require.procedures.length - 1">,</span> </span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <div
        v-else
        class="tnved-slide-box-body"
      >
        <p class="grey-txt">
          Специальные требования отсутствуют
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    special: {
      required: true,
      type: Object
    }
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
    .code-txt:hover {
        text-decoration: none;
        color: #333;
    }
@media (max-width: 512px) {
    .light-txt{
        width: 100%;
    }
}
</style>