<template>
  <div>
    <section id="usual">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="9"
          >
            <v-chip-group
              v-model="chanel"
              active-class="active-tnved-tag"
              column
              mandatory
            >
              <v-chip
                v-for="(tag, index) in chanels"
                :key="index"
                v-scroll-to="'header'"
                :to="tag.link"
                class="mr-5"
                :value="tag.value"
                style="overflow: visible;"
              >
                {{ tag.txt }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            class="text-right"
          >
            <v-btn
              class="mt-2"
              dark
              color="#5cb7b1"
              @click="updateData"
            >
              <v-icon>mdi-autorenew</v-icon> перезагрузить
            </v-btn>
          </v-col>




          <v-col
            v-if="nsiItems"
            cols="12"
            lg="12"
            class="text-center"
          >
            <div
              v-if="!Object.keys(nsiItems).length"
              class="shadow-box"
            >
              <h4 class="mb-0">
                Новых обновлений нет
              </h4>
            </div>
            <div
              v-else
              class="shadow-box"
            >
              <v-timeline
                v-for="(item, index) in Object.keys(nsiItems)"
                :key="index"
                dense
                clipped
                align-top
              >
                <v-timeline-item
                  fill-dot
                  color="#5cb7b1"
                  large
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-left"
                    >
                      <h2 class="mt-1 mb-0">
                        {{ formateData(item) }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-timeline-item>



                <v-timeline-item
                  v-for="(update, ind) in nsiItems[item]"
                  :key="ind"
                  color="grey"
                  small
                  class="align-center curs-p"
                >
                  <v-row>
                    <v-col cols="12">
                      <div @click="$router.push(`/nsi/${update.groupId}`)">
                        <v-hover v-slot="{ hover }">
                          <v-sheet
                            elevation="1"
                            :class="{'hovered-sheet' : hover}"
                            :color="update.status === 'ERROR' ? 'red lighten-3' : ''"
                            class="px-4 py-0 mb-3 rounded"
                          >
                            <v-row>
                              <v-col lg="3">
                                <v-card-title
                                  class="pa-0 text-left"
                                  style="word-break: inherit;"
                                >
                                  {{ update.groupName }}
                                </v-card-title>
                              </v-col>
                              <v-col
                                v-if="update.updatedRecordsCount"
                                lg="5"
                                class="text-left"
                              >
                                <div class="text-body-2 mt-2">
                                  {{ update.updatedRecordsCount[2] }} удаление
                                  {{ update.updatedRecordsCount[1] }} изменение,
                                  {{ update.updatedRecordsCount[0] }} добавление
                                </div>
                              </v-col>
                              <v-col
                                v-else
                                lg="5"
                                class="text-left"
                              />
                              <v-col
                                v-if="update.status === 'ERROR'"
                                lg="4"
                              >
                                <v-chip
                                  class="ma-2"
                                  color="#333"
                                  label
                                  text-color="white"
                                >
                                  <v-icon
                                    left
                                    color="red"
                                  >
                                    mdi-alert
                                  </v-icon>
                                  Обновление не прошло
                                </v-chip>
                              </v-col>
                              <v-col
                                v-if="update.status !== 'ERROR'"
                                lg="4"
                                @click.stop
                              >
                                <v-radio-group
                                  v-model="update.defaultUpdateType"
                                  row
                                  color="#5cb7b1"
                                  hide-details
                                  @click.stop
                                >
                                  <v-radio
                                    value="Плановое"
                                    class="mb-0"
                                    label="Плановое"
                                    color="#5cb7b1"
                                    @click.stop
                                  />
                                  <v-radio
                                    value="Срочное"
                                    class="mb-0"
                                    label="Срочное"
                                    color="#5cb7b1"
                                    @click.stop
                                  />
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-hover>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>


          <v-col
            v-else
            cols="12"
            lg="3"
            class="mx-auto text-center"
          >
            <h4 class="mb-4">
              Загружаем обновления
            </h4>
            <v-progress-linear
              color="#32a1af"
              indeterminate
              rounded
              height="6"
            />
          </v-col>




          <v-col
            v-if="nsiItems && Object.keys(nsiItems).length"
            cols="12"
            lg="12"
            class="text-center"
          >
            <v-btn
              color="#5cb7b1"
              dark
              x-large
              :loading="loading"
              @click="updateNsi"
            >
              Обновить
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>


<script>
import moment from "moment";
import {
  mapGetters
} from 'vuex'

export default {
  data() {
    return {
      loading: false,
      chanels: [{
        txt: 'Новые',
        value: 'PRESENTED',
        link: '/nsinew'
      },
      {
        txt: 'В очереди',
        value: 'CHECKED',
        link: '/nsiline'
      },
      {
        txt: 'Выпущенные',
        value: 'UPDATED',
        link: '/nsi'
      }
      ],
      chanel: 'PRESENTED',
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      nsiItems: "nsi/getNewNsiItems",
      user: "auth/getUser"
    })
  },
  created() {
    if(!this.user.authorities.some(e => e === 'nsi.global.read')){
      this.$router.replace("/nsi");
      return
    }

    if(this.nsiItems){
      return
    }else{
      this.$store.dispatch("nsi/loadNewNsi", this.chanel)
    }
  },
  methods: {
    updateData(){
      this.$store.dispatch("nsi/loadNewNsi", this.chanel)
    },
    updateNsi() {
      this.loading = true

      let plan = []
      let srochn = []

      Object.keys(this.nsiItems).forEach(item => {
        this.nsiItems[item].forEach(update => {
          if (update.defaultUpdateType === 'Плановое') {
            plan.push(update.groupId)
          } else {
            srochn.push(update.groupId)
          }
        })
      })

      let planStr = plan.join(',')
      let srochnStr = srochn.join(',')

      let url = ''
      if (!srochnStr) {
        url = `delayIds=${planStr}`
      } else if (!planStr) {
        url = `updateIds=${srochnStr}`
      } else {
        url = `delayIds=${planStr}&updateIds=${srochnStr}`
      }



      this.$store.dispatch('nsi/pushNewUpdate', url).then((res) => {
        if(res.status == 500){
          this.loading = false
          this.$store.dispatch("nsi/loadNewNsi", this.chanel)
        }else{
          this.loading = false
          this.$store.dispatch("nsi/loadNewNsi", this.chanel)
          this.$router.replace('/nsiline')
        }
                    
      })

    },
    changeChanel(link) {
      this.$router.go(link)
    },
    goToUpdate(id) {
      this.$router.replace(`/nsi/${id}`)
    },
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>

<style scoped>
    #usual {
        min-height: calc(100vh - 129px);
    }

    a {
        color: #000 !important;
    }
</style>