<template>
  <v-card
    id="editMode"
    elevation="2"
    class="mb-6"
  >
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Удалить пользователя
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Вы уверены, что хотите удалить пользователя <br>
          <strong>{{ item.email }}</strong>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="red lighten-2"
            dark
            :loading="load"
            @click="deleteUser"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-btn
      v-if="!editMode && item.is_active"
      color="grey"
      elevation="0"
      dark
      x-small
      fab
      class="my-6 mx-n2"
      absolute
      top
      right
      @click="editMode = !editMode"
    >
      <v-icon small>
        mdi-pencil-outline
      </v-icon>
    </v-btn>
    <div
      class="pa-7"
      style="min-height:326px;position:relative;"
    >
      <div
        v-if="!item.is_active"
        class="pause-box"
      >
        <div class="text-center">
          <v-btn
            v-if="item.is_active == null"
            color="#FFBC58"
            fab
            dark
            class="mb-2"
          >
            <v-icon>mdi-car-brake-alert</v-icon>
          </v-btn>
          <v-btn
            v-else-if="item.d_off && item.is_active === false"
            fab
            dark
            color="red"
            class="mb-2"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-else
            fab
            dark
            color="#32a1af"
            class="mb-2"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>

          <div
            v-if="item.is_active == null"
            class="text-body-1 font-weight-medium"
          >
            Не пройдена регистрация
          </div>
          <div
            v-else-if="item.d_off && item.is_active === false"
            class="text-body-1 font-weight-medium"
          >
            Удален
          </div>
          <div
            v-else
            class="text-body-1 font-weight-medium"
          >
            В паузе
          </div>
        </div>
      </div>
      <v-row>
        <v-col lg="3">
          <v-avatar
            size="70"
            color="#5cb7b1"
          >
            <div
              v-if="item.first_name && item.last_name"
              class="text-h5 white--text"
            >
              {{ item.first_name.substr(0, 1) }}{{ item.last_name.substr(0, 1) }}
            </div>
          </v-avatar>
        </v-col>
        <!-- <pre>{{item}}</pre> -->
        <v-col
          lg="8"
          class="mb-2"
        >
          <div class="text-body-1 font-weight-medium">
            {{ item.last_name }} {{ item.first_name }} {{ item.middle_name }}
          </div>
          <div class="text-body-2 grey--text">
            {{ item.email }}
          </div>
          <div
            v-if="item.phone"
            class="text-body-2 grey--black"
          >
            {{ item.phone }}
          </div>
        </v-col>
        <v-col lg="12">
          <div
            v-if="!editMode"
            class="text-body-2 font-weight-bold"
          >
            {{ item.division_name }}
          </div>
          <v-select
            v-else
            v-model="item.divid"
            :items="branchesAlowed"
            solo
            color="#32a1af"
            item-color="#32a1af"
            background-color="grey lighten-3"
            flat
            item-text="division_name"
            item-value="id"
            hide-details="auto"
            @change="getRolesForInvite"
          />
        </v-col>
        <v-col lg="12">
          <div
            v-if="!editMode"
            class="text-body-2"
          >
            {{ item.role_name }}
          </div>
          <v-select
            v-else
            v-model="item.role_id"
            :items="roles"
            solo
            color="#32a1af"
            item-color="#32a1af"
            background-color="grey lighten-3"
            flat
            item-text="role_name"
            item-value="id"
            hide-details="auto"
          />
        </v-col>
        <v-col
          v-if="item.other_div_names"
          lg="12"
        >
          <div
            class="text-body-2"
            style="color: #32a1af;"
          >
            <span v-if="item.other_div_names.length <= 50">{{ item.other_div_names }}</span>
            <v-tooltip
              v-else
              bottom
              max-width="300"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                <a
                  style="color: #32a1af;"
                  href="#"
                  v-bind="attrs"
                  v-on="on"
                >{{ item.other_div_names.substr(0, 50) }}...</a>
              </template>
              <span>{{ item.other_div_names }}</span>
            </v-tooltip>
          </div>
        </v-col>

        <v-col lg="12">
          <div
            v-if="item.d_off"
            class="text-body-2"
          >
            {{ formatDate(item.d_on) }} - {{ formatDate(item.d_off) }}
          </div>
          <div v-else>
            <div
              v-if="!item.nastd_d_off"
              class="text-body-2 red--text"
            >
              <v-icon
                class="pb-1"
                small
                color="red"
              >
                mdi-cancel
              </v-icon> Нет индификатора НАСТД
            </div>
            <div
              v-else
              class="text-body-2"
              :class="getDateColor()"
            >
              Cрок действия ключа НАСТД по: {{ formatDate(item.nastd_d_off) }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider />

    <v-card-actions style="min-height:44px;">
      <div v-if="editMode">
        <v-btn
          small
          text
          class="mr-1"
          @click="cancelUpdate"
        >
          Отмена
        </v-btn>
        <v-btn
          small
          dark
          color="#32a1af"
          @click="updateUser"
        >
          Сохранить
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          v-if="item.is_active"
          small
          text
          color="#333"
          @click="changeActivity"
        >
          <v-icon small>
            mdi-pause
          </v-icon> Приостановить
        </v-btn>
        <v-btn
          v-if="item.is_active === false && !item.d_off"
          small
          text
          color="#333"
          @click="changeActivity"
        >
          <v-icon small>
            mdi-play
          </v-icon> Активировать
        </v-btn>
        <v-btn
          v-if="!item.d_off"
          small
          text
          color="#333"
          @click="dialog = true"
        >
          <v-icon small>
            mdi-trash-can
          </v-icon> Удалить
        </v-btn>
        <v-btn
          v-if="item.is_active == null"
          small
          text
          color="#333"
          @click="refreshInvite"
        >
          <v-icon small>
            mdi-refresh
          </v-icon> Пригласить повторно
        </v-btn>
      </div>
    </v-card-actions>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
    >
      <span><v-icon
        class="mr-1"
        color="#32a1af"
        small
      >mdi-check</v-icon> Пользователь приглашен</span>
      <template #action="{ attrs }">
        <v-btn
          color="#32a1af"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ок
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import {AUTH} from '@/http-routes.js'
import formatDate from '@/mixins/format-date.js'
import moment from "moment";

export default {
  mixins: [formatDate],
  props: {
    item: {
      type: Object,
      required: true
    },
    branchesAlowed: {
      type: Array,
      required: true
    }
  },
  data:() => ({
    snackbar: false,
    roles: [],
    dialog: false,
    editMode: false,
    load: false
  }),
  computed: {
    ...mapGetters({
      dvsnUsers: 'auth/getDivisionUsers',
      user: "auth/getUser",
      dvsn: 'auth/getSingleDivision',
    })
  },
  created(){
    this.getRolesForInvite();
  },
  methods: {
    getDateColor(){
      const currentDate = moment(new Date, 'DD.MM.YYYY');
      const dateOff = moment(this.formatDate(this.item.nastd_d_off), 'DD.MM.YYYY');
      const diffDays = dateOff.diff(currentDate, 'days');
      if(diffDays > 30){
        return 'teal--text'
      }else if(diffDays <= 0){
        return 'red--text'
      }else{
        return 'orange--text'
      }
    },
    refreshInvite(){
      let invite = {
        email: this.item.email,
        branch: this.item.divid,
        role: this.item.role_id
      }
      this.$store.dispatch('auth/inviteUser', invite).then(()=>{
        this.snackbar = true
      })
    },
    deleteUser() {
      this.load = true
      axios
        .post(`${AUTH}user/deleteUserFromDivision?otherUserId=${this.item.user_id}&divisionId=${this.item.divid}`)
        .then(() => {
          this.dialog = false
          this.$store.dispatch('auth/loadUsers', this.user.user.id)
        }).catch((err) => this.$error(err.response.data || "Ошибка удаления пользователя"))
        .finally(() => {
          this.load = false
        })
    },
    changeActivity(){
      this.item.is_active = !this.item.is_active
      this.$store.dispatch('auth/updateDivisionUser', this.item)
    },
    cancelUpdate(){
      this.editMode = false
      this.$store.dispatch('auth/loadUsers', this.user.user.id)
    },
    updateUser(){
      this.$store.dispatch('auth/updateDivisionUser', this.item).then(()=>{
        this.editMode = false
      })
    },
    getRolesForInvite(){
      axios
        .get(`${AUTH}role/getRolesForInvite?userId=${this.user.user.id}&divisionId=${this.item.divid}`)
        .then(res =>{
          this.roles = res.data
        })
    }
  },

}
</script>
