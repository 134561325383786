import AutoMain from '@/views/auto/auto-main.vue'

const routes = [
  {
    path: "/auto",
    name: "AutoMain",
    component: AutoMain,
  },
];

export default routes;
