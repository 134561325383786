<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        class="elevation-0 settings__button"
        :class="{
          'rounded-l rounded-r-0': rounded === 'left',
          'rounded-r rounded-l-0': rounded === 'right',
          'rounded': rounded === 'all'
        }"
        height="56px"
        x-large
        v-bind="attrs"
        v-on="on"
      >
        <span>{{ value }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="size of [15,30,50]"
        :key="`perpage-${size}`"
        class="cursor-pointer"
        @click="$emit('select', size)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ size }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props:{
    value:{
      required:true,
      type:Number
    },
    rounded:{
      required:false,
      type:String,
      default:() => "none"
    }
  },

}
</script>