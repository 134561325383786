import BillingServices from "@/views/billing/billing-services.vue";
import BillingCompanies from "@/views/billing/billing-companies.vue";
import BillingCompaniesSingle from "@/views/billing/billing-companies-single.vue";
import BillingBuy from "@/views/billing/billing-buy.vue";
import BillingHistory from "@/views/billing/billing-history.vue";

const routes = [
  {
    path: "/billing-services",
    component: BillingServices,
    meta: {
      permissions: ["shop.global.update"],
    },
  },
  {
    path: "/billing-companies",
    component: BillingCompanies,
    meta: {
      keepAlive: true, // Need to be cached
      permissions: ["shop.global.update"],
    },
  },
  {
    path: "/billing-companies/:id",
    component: BillingCompaniesSingle,
    props: true,
    meta: {
      permissions: ["shop.global.update"],
    }
  },
  {
    path: "/new-division/:id/billing-buy",
    component: BillingBuy,
    name: "Услуги",
    props: true,
  },
  {
    path: "/new-division/:id/billing-history",
    component: BillingHistory,
    name: "Биллинг",
    props: true,
  },
]

export default routes
