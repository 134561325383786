import {SEZ} from "@/http-routes";

import documentMutations from "./utils/document-mutations";

import documentActionsFactory from "./utils/document-actions";
import axios from "axios";
const documentActions = documentActionsFactory(SEZ);


export default {
  namespaced: true,
  mutations:{
    ...documentMutations,
  },
  actions:{
    ...documentActions,
    fillGuid(_, {id, guid}){
      return axios.post(`${SEZ}declaration/fillDtGuid?id=${id}&dtGuid=${guid}`)
    }
  }
}
