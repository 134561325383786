import {debounce} from "@/helpers/inputs";

const TYPE_INPUT = "input"

function getChild(vnode){
  return vnode.componentInstance.$vnode.componentInstance
}

function getInputCallback(componentInstance){
  return componentInstance.$listeners.input
}

export default {
  install(Vue) {
    Vue.directive("debounced", {
      bind(el, binding, vnode) {
        if(vnode.componentOptions.tag === "v-autocomplete") throw new Error("Директива не поддерживает v-autocomplete")
        const timeout = binding.value
        const childInput = getChild(vnode)
        const callback = getInputCallback(childInput)
        const debounced = debounce(callback, timeout)
        childInput.$el.removeEventListener(TYPE_INPUT, callback)
        childInput.$el.addEventListener(TYPE_INPUT, (event) => {
          event.stopPropagation()
          debounced(event.target.value)
        }, true)
      },
      unbind(el, binding, vnode){
        const childInput = getChild(vnode)
        const callback = getInputCallback(childInput)
        childInput.$el.removeEventListener(TYPE_INPUT, callback)
      }
    })
  }
}




