import NotificationMain from "@/views/notification/notification-main.vue";

const routes = [
  {
    path: "/notification",
    name: "NotificationMain",
    component: NotificationMain,
  },
];

export default routes;
