import {base, multipleEventsOneCallback} from '@/events/common'


// Показ справочников
const showCatalog = {
  ...base,
  name:'sd-show-catalog',
}

// Выбор типа документа из справочника
const documentTypeSelected = {
  ...base,
  name:"sd-doctype-selected"
}

// Выбор кода ТНВЭД из справочника
const tnvedCodeSelected = {
  ...base,
  name:"sd-tnved-code-selected"
}

// Изменился код ТНВЭД в товаре
const tnvedCodeChanged = {
  ...base,
  name:'sd-tnved-code-changed'
}

// Требуется обновить вложенные товары (артикулы)
const forceUpdateWareDetail = {
  ...base,
  name:'sd-force-update-ware-detail'
}

// Вложенный товар был обновлен
const wareDatailForceUpdated = {
  ...base,
  name:'sd-ware-detail-force-updated'
} 

// Вложенный товар был изменен (добавлен/удален)
const wareDetailModified = {
  ...base,
  name:"sd-ware-detail-modified"
}
// Требуется обновить блок параметров
const updateGoodParameters = {
  ...base,
  name:'sd-update-good-parameters'
}

//Все документы были обновлены
const allDocumentsUpdated = {
  ...base,
  name:'sd-all-docs-updated'
}

// Был выбран импортер из справочника
const importerSelected = {
  ...base,
  name:'sd-importer-selected'
}

// была изменена дата отгрузки
const wareShipmentDateChanged = {
  ...base,
  name:'sd-ware-shipment-date-changed'
}
// Выбрано значение из справочника
const catalogValueSelected = {
  ...multipleEventsOneCallback
}

// Изменена доп единица в товаре
// const addMeasureChanged = {
//   ...base,
//   name:'sd-add-measure-changed'
// }
const latestWareUpdate = {
  ...base,
  name:'sd-latest-ware-update'
}

const addPromise = {
  ...base,
  name:'ware-add-promise'
}

const setContragent = {
  ...base,
  name:'sd-set-search-contragent'
}

const customsProcedureChanged = {
  ...base,
  name:'sd-customs-procedure-changed'
}

const exciseChanged = {
  ...base,
  name:'sd-excise-changed'
}

const paymentsChanged = {
  ...base,
  name:'sd-payments-changed'
}

const recalculateStatisticCost = {
  ...base,
  name:'sd-recalculate-statistic-cost'
}

const addExtraTnvedRules = {
  ...base,
  name:'sd-add-extra-tnved-rules'
}

export {
  showCatalog,
  documentTypeSelected,
  tnvedCodeSelected,
  tnvedCodeChanged,
  forceUpdateWareDetail,
  wareDatailForceUpdated,
  wareDetailModified,
  updateGoodParameters,
  allDocumentsUpdated,
  importerSelected,
  wareShipmentDateChanged,
  catalogValueSelected,
  // addMeasureChanged,
  latestWareUpdate,
  addPromise,
  setContragent,
  customsProcedureChanged,
  exciseChanged,
  recalculateStatisticCost,
  addExtraTnvedRules,
  paymentsChanged
}