const routes = [
  {
    path: "/reports",
    name: "ReportsHomepage",
    component: () => import('@/views/reports/homepage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes
