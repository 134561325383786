import moment from "moment";

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export function capitalizeString(str) {
  return str && typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export function getInitialsString({first_name = "", middle_name = "", last_name = ""}) {
  const name_first_letter = first_name ? first_name.slice(0, 1) : ""
  const middle_first_letter = middle_name ? middle_name.slice(0, 1) : ""
  const name = name_first_letter && name_first_letter.length ? `${name_first_letter}.` : ""
  const middle = middle_first_letter && middle_first_letter.length ? `${middle_first_letter}.` : ""
  return [last_name, name, middle].join(" ").trim()
}

export function googleImage(search) {
  if (!search) return;
  const url = `https://www.google.com/search?q=${search}&tbm=isch&source=hp&oq=${search}&sclient=img`
  return window.open(url, '_blank')
}

export function copy(value) {
  return navigator.permissions.query({name: 'clipboard-write'}).then((res) => {
    if (res.state === "granted" || res.state === "prompt") {
      navigator.clipboard.writeText(value)
      return true
    }
    return false
  })
}

export function paste() {
  return navigator.permissions.query({name: 'clipboard-read'}).then((res) => {
    if (res.state === "granted" || res.state === "prompt") {
      return navigator.clipboard.readText()
    }
    return false
  })
}

export function setDeclarationTitle({prefix,outNumber, remark}){
  document.title = outNumber
    ? `${prefix} [${outNumber}] ${remark || ""}`
    : (prefix || "Веб-Декларант") ;
}

export function getCostString(invoiceCost, currency){
  const costValue = invoiceCost ? parseFloat(invoiceCost).toLocaleString().replace(',', '.') : null
  return costValue && currency ?
    `${costValue} ${currency}` :
    costValue
}
export function getGrossWeightString(weight){
  return weight ? parseFloat(weight).toLocaleString().replace(',', '.'): ""
}

export function getJournalDeclarationTitle(declaration){
  const {out_number, remark} = declaration?.about_declaration ?? {}
  const {document_type, customs_procedure} = declaration?.type_declaration ?? {}
  const number = out_number ? `[${out_number}]` : ''
  return `${declaration?.id}:  ${document_type || ""} `+
    `${customs_procedure || ""} `+
    `${number} `+
    `${remark || ""} `.trim()
}

export function getDocumentString(docDate, docNumber){
  const date = docDate ? moment(docDate, 'YYYY-MM-DD').format('DD.MM.YYYY') :null
  return docNumber ? `№ ${ docNumber } от ${ date || '(неизвестно)' }` : 'отсутствует'
}


export function getFirstSeparator(text, separators = [',', ';', '\n']) {
  if (!text) return;
  const [separator] = separators.map(separator => ({
    value: separator,
    index: text.indexOf(separator)
  })).filter(separator => separator.index !== -1)
    .sort((a, b) => a.index - b.index)
  return separator?.value
}


