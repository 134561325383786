<template>
  <div>
    <h2 class="mb-8">
      Платежные поручения
    </h2>
    <v-row class="mb-4">
      <v-col cols="8">
        <payment-orders-search
          :params="params"
          @update="updateParams"
        />
      </v-col>
      <v-col>
        <payment-orders-settings
          :params="params"
          @update="updateParams"
        />
      </v-col>
    </v-row>
    <payment-orders-toolbox
      :params="params"
      @update="updateParams"
    />
    <payment-orders-table
      :banks="banks"
      :items="paymentOrders"
      :total="total"
      :params="params"
      @update="updateParams"
    />
  </div>
</template>
<script>
import PaymentOrdersToolbox from "@/components/payment-orders/payment-orders-toolbox.vue";
import PaymentOrdersTable from "@/components/payment-orders/payment-orders-table.vue";
import PaymentOrdersSettings from "@/components/payment-orders/payment-orders-settings.vue";
import PaymentOrdersSearch from "@/components/payment-orders/payment-orders-search.vue"
import moment from "moment";
import {prettifyNumber} from "@/helpers/math";
import {mapGetters} from "vuex";
import {fetchPaymentOrdersInjector} from "@/helpers/injectors";
import {OWNER_NAME} from "@/components/payment-orders/search-conditions";

function defaultParams() {
  return {
    page: 1,
    size: 15,
    selectType: "user",
    search: {
      condition: OWNER_NAME,
      value: ""
    },
    withRemains: true,
    code: "",
    trash:false,
  }
}

export default {
  components: {
    PaymentOrdersSettings,
    PaymentOrdersSearch,
    PaymentOrdersToolbox, PaymentOrdersTable
  },
  provide() {
    return {
      [fetchPaymentOrdersInjector]: this.fetchPaymentOrders
    }
  },
  data() {
    return {
      params: defaultParams(),
      paymentOrders: [],
      total: 0,
      banks:[]
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      divisions:"auth/getDivisions"
    }),
    divisionId() {
      return this.user.user.activeDivisionId
    }
  },
  created() {
    this.setDefaultSearchValue()
    this.loadBanks().then(() => this.fetchPaymentOrders())
  },
  methods: {
    loadBanks() {
      return this.$store.dispatch('catalogs/loadBankBIC')
        .then((banks) => {
          this.banks = banks
          return banks;
        }).catch(() => [])
    },
    setDefaultSearchValue(){
      const division = this.divisions.find(division => division.real_division_id === this.divisionId)
      this.params.search.value = division.tax_payer_num
    },
    updateParams(updatedParams) {
      this.params = updatedParams
      this.fetchPaymentOrders()
    },
    preparePaymentOrders(orders) {
      return orders.map((payment) => {
        return {
          ...payment,
          payment_date_pretty: moment(payment.payment_date).isValid()
            ? moment(payment.payment_date).format("DD.MM.YYYY")
            : null,
          owner: payment.unp || payment.personal_number,
          remainsText: `${prettifyNumber(payment.remains)} ${payment.currency_letter}`,
          sumText: `${prettifyNumber(payment.sum)} ${payment.currency_letter}`
        }
      })
    },
    fetchPaymentOrders() {
      const params = {
        ...this.params,
        divisionId: this.divisionId
      }
      this.$store.dispatch("dt/getJournal", params).then((res) => {
        this.paymentOrders = this.preparePaymentOrders(res.data)
        this.total = this.paymentOrders.length ? this.paymentOrders[0].total_count : 0
      }).catch(() => {
        this.$error()
        this.paymentOrders = []
        this.total = 0
      })
    }
  }
}
</script>
