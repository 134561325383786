<template>
  <v-card
    elevation="2"
    class="mb-6"
  >
    <v-toolbar
      color="grey lighten-3"
      flat
    >
      <v-card-title
        class="text-h5 font-weight-medium"
        color="black"
      >
        <v-icon
          class="mr-2"
          :color="getColor"
        >
          {{ getIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
    </v-toolbar>

    <div class="mini-table pa-4">
      <table>
        <tr :class="{ highlightedTr : itemBefore && cardItem.name !== itemBefore.name }">
          <td class="text-body-2">
            {{ field.name }}
          </td>
          <td class="text-body-2 font-weight-bold">
            <!-- {{cardItem.name.slice(0,23)}}
                        <v-btn v-if="cardItem.name.length > 23" x-small class="px-0" color="#333" dark>
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn> -->


            <v-tooltip
              bottom
              max-width="300"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                {{ cardItem.name.slice(0,50) }}
                <v-btn
                  v-if="cardItem.name.length > 50"
                  v-bind="attrs"
                  x-small
                  class="px-0"
                  color="#333"
                  dark
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <span>{{ cardItem.name }}</span>
            </v-tooltip>
          </td>
        </tr>
        <tr :class="{ highlightedTr : itemBefore && cardItem.number !== itemBefore.number }">
          <td class="text-body-2">
            {{ field.number }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.number }}
          </td>
        </tr>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    cardItem: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    title:{
      type: String,
      required: true
    },
    itemBefore: {
      type: Object,
      required: false,
      default:null
    }
  },
  computed: {
    getIcon(){
      if(this.title === 'Удаление'){
        return 'mdi-alert'
      }else if(this.title === 'Добавление'){
        return 'mdi-plus-circle'
      }else{
        return 'mdi-folder-swap'
      }
    },
    getColor(){
      if(this.title === 'Удаление'){
        return 'red'
      }else if(this.title === 'Добавление'){
        return '#5CB7B1'
      }else{
        return 'amber'
      }
    }
  }
}
</script>