

const routes = [
  {
    path: "/ensuring/:id",
    name: "ensuringMain",
    component: () => import('@/views/ensuring/ensuring-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  }
];

export default routes;
