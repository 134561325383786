<template>
  <v-card
    id="editMode"
    elevation="2"
  >
    <v-btn
      v-if="!editMode && item.active"
      color="grey"
      elevation="0"
      dark
      x-small
      fab
      class="my-6 mx-n2"
      absolute
      top
      right
      @click="editMode = !editMode"
    >
      <v-icon small>
        mdi-pencil-outline
      </v-icon>
    </v-btn>
    <div
      v-if="!editMode"
      class="pa-7"
      style="min-height:296px;position:relative;"
    >
      <div
        v-if="!item.active"
        class="pause-box"
      >
        <div class="text-center">
          <!-- <span class="mdi mdi-pause"></span> -->
          <v-btn
            fab
            dark
            color="#32a1af"
            class="mb-2"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <div class="text-body-1 font-weight-medium">
            В паузе
          </div>
        </div>
      </div>
      <v-row>
        <!-- <pre>{{item}}</pre> -->
        <v-col
          lg="12"
          class="mb-2"
        >
          <div class="text-body-1 font-weight-medium">
            {{ item.name }}
          </div>
          <div class="text-body-2 grey--text">
            {{ item.fullAddress }}
          </div>
        </v-col>
        <v-col lg="4">
          <div class="fz-12 grey--text">
            Просмотр для:
          </div>
        </v-col>
        <v-col lg="8">
          <div class="text-body-2">
            {{ getSelected.join(', ') }}
          </div>
        </v-col>
        <v-col lg="4">
          <div class="fz-12 grey--text">
            Примечание:
          </div>
        </v-col>
        <v-col lg="8">
          <div class="text-body-2">
            {{ item.description }}
          </div>
        </v-col>
        <v-col lg="5">
          <div class="fz-12 grey--text">
            Администраторы:
          </div>
        </v-col>
        <v-col lg="7">
          <div class="text-body-2">
            {{ item.admins.join(', ') }}
          </div>
        </v-col>
      </v-row>
    </div>
    <!--  -->
    <div
      v-else
      class="pa-7"
      style="min-height:296px;"
    >
      <v-row>
        <v-col lg="12">
          <v-text-field
            v-model="item.name"
            color="#32a1af"
            background-color="grey lighten-3"
            x-small
            solo
            hide-details="auto"
            flat
            class="mb-2"
          />
          <v-text-field
            v-model="item.fullAddress"
            color="#32a1af"
            background-color="grey lighten-3"
            x-small
            solo
            hide-details="auto"
            flat
          />
        </v-col>
        <v-col lg="5">
          <div class="fz-12 grey--text">
            Просмотр для:
          </div>
        </v-col>
        <v-col lg="7">
          <div class="text-body-2">
            <v-menu
              bottom
              :offset-y="true"
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-text-field
                    color="#32a1af"
                    background-color="grey lighten-3"
                    x-small
                    solo
                    hide-details="auto"
                    disabled
                    append-icon="mdi-menu-down"
                    flat
                    :value="`${getSelected.join(', ').substr(0,13)}...`"
                  />
                </div>
              </template>

              <v-list>
                <v-list-item
                  v-for="(rel, index) in item.relations"
                  :key="index"
                >
                  <v-checkbox
                    v-model="rel.active"
                    hide-details
                    color="#32a1af"
                    :label="rel.name"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col lg="5">
          <div class="fz-12 grey--text">
            Примечание:
          </div>
        </v-col>
        <v-col lg="7">
          <div class="text-body-2">
            <v-text-field
              v-model="item.description"
              color="#32a1af"
              background-color="grey lighten-3"
              x-small
              solo
              hide-details="auto"
              flat
            />
          </div>
        </v-col>
        <v-col lg="5">
          <div class="fz-12 grey--text">
            Администраторы:
          </div>
        </v-col>
        <v-col lg="7">
          <div class="text-body-2">
            <!-- <v-select flat background-color="grey lighten-3" v-model="item.relations" :items="admins" 
                        item-color="#32a1af" multiple solo hide-details>
                           <template v-slot:selection="{ item, index }">
                               <span v-if="index === 0">{{item.substr(0,10)}}...</span>
                            </template>
                       </v-select> -->
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider />

    <v-card-actions>
      <div v-if="editMode">
        <v-btn
          small
          text
          class="mr-1"
          @click="cancelUpdate"
        >
          Отмена
        </v-btn>
        <v-btn
          small
          dark
          color="#32a1af"
          @click="updateBranch"
        >
          Сохранить
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          v-if="item.active"
          small
          text
          color="#333"
          @click="changeActivity"
        >
          <v-icon small>
            mdi-pause
          </v-icon> Приостановить
        </v-btn>
        <v-btn
          v-else
          small
          text
          color="#333"
          @click="changeActivity"
        >
          <v-icon small>
            mdi-play
          </v-icon> Активировать
        </v-btn>
        <v-btn
          small
          text
          color="red"
        >
          <v-icon small>
            mdi-trash-can
          </v-icon> Удалить
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data:()=> ({
    editMode: false,
    admins: ['hyhdasdh@ya.ru', 'kt@webink.site'],
    allowedAdmins: ['hyhdasdh@ya.ru']   
  }),
  computed: {
    ...mapGetters({
      branches: "auth/getBranches"
    }),
    getSelected(){
      // console.log(obj.relations)
      var newArray = this.item.relations.filter(function (el) {
        return el.active == true
      });
      let names = []
      newArray.forEach(item =>{
        names.push(item.name)
      })

      return names
    },
  },
  methods: {
    cancelUpdate(){
      this.editMode = false
      this.$store.dispatch('auth/loadBranches', this.item.parentId)
    },
    changeActivity(){
      this.item.active = !this.item.active
      this.$store.dispatch('auth/updateBranch', this.item)
    },
    updateBranch(){
      this.$store.dispatch('auth/updateBranch', this.item).then(()=>{
        this.editMode = false
      })
    }
  },
}
</script>
