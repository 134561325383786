

const routes = [

  {
    path: "/statistics/:id",
    name: "StatisticsMain",
    component: () => import('@/views/statistics/statistics-main.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
