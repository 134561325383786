<template>
  <section class="payment-orders pb-16 mx-auto my-5">
    <v-container fluid>
      <payment-orders-page />
    </v-container>
  </section>
</template>
<script>
import PaymentOrdersPage from "@/components/payment-orders/payment-orders-page.vue";
export default {
  components:{
    PaymentOrdersPage,
  }
}
</script>
<style scoped>
.orders{
  min-height: calc(100vh - 129px)
}
</style>
<style scoped>
.payment-orders{
  width:100%;
  max-width: 1440px;
  min-height: calc(100vh - 129px)
}
</style>
