<template>
  <section>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <!-- <div class="text-h4 font-weight-bold mb-6">
            Популярные сервисы
          </div> -->
        </v-col>
        <v-col
          v-for="(serv, i) in services"
          :key="i"
          lg="3"
          cols="12"
        >
          <v-card
            elevation="2"
            class="serv-card mb-6 pa-6"
            color="#F5F5F7"
          >
            <h4 class="mb-2">
              {{ serv.name }}
            </h4>
            <div
              class="text-body-2 text-left mb-4"
              v-html="serv.descr"
            />
            <div class="text-center">
              <img
                :src="serv.img"
                alt=""
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          name: 'API ТН ВЭД',
          descr: 'Если Вам необходима актуальная информация о товаре по коду ТН ВЭД Вы можете интегрировать наш API в свою ERP (например, 1С). Для получения более подробной информации пройдите по <a href="http://web.declarant.by:7020/helper/swagger-ui/index.html" target="_blank" class="text-decoration-underline">ссылке</a> .',
          img: require('@/assets/img/api_tnved.svg')
        },
        // {
        //   name: 'Статистическое декларирование',
        //   img: require('@/assets/img/serv1.svg'),
        //   descr: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, quas incidunt.'
        // },
        // {
        //   name: 'Электронное предварительное декларирование',
        //   img: require('@/assets/img/serv2.svg'),
        //   descr: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, quas incidunt.'
        // },
        // {
        //   name: 'Утилизационный сбор',
        //   img: require('@/assets/img/serv3.svg'),
        //   descr: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, quas incidunt.'
        // }
      ],
    }
  }
}
</script>