<template>
  <v-card
    elevation="2"
    class="mb-6"
  >
    <v-toolbar
      color="grey lighten-3"
      flat
    >
      <v-card-title
        class="text-h5 font-weight-medium"
        color="black"
      >
        <v-icon
          class="mr-2"
          :color="getColor"
        >
          {{ getIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
    </v-toolbar>


    <div class="mini-table pa-4">
      <table>
        <!-- :class="{ highlightedTr : itemBefore && cardItem.name !== itemBefore.name }" -->
        <tr>
          <td class="text-body-2">
            {{ field.paymentCode }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.code }}
          </td>
        </tr>
        <tr>
          <td class="text-body-2">
            {{ field.onDate }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.ondate }}
          </td>
        </tr>

        <tr>
          <td class="text-body-2">
            {{ field.offDate }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.offdate }}
          </td>
        </tr>
        <tr>
          <td class="text-body-2">
            {{ field.excCountry }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.exccountrieslist }}
          </td>
        </tr>
        <tr>
          <td class="text-body-2">
            {{ field.longDesc }}
          </td>
          <td class="text-body-2 font-weight-bold">
            {{ cardItem.longdesc }}
          </td>
        </tr>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    cardItem: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    title:{
      type: String,
      required: true
    },
    itemBefore: {
      type: Object,
      required: false,
      default:null
    }
  },
  computed: {
    getIcon(){
      if(this.title === 'Удаление'){
        return 'mdi-alert'
      }else if(this.title === 'Добавление'){
        return 'mdi-plus-circle'
      }else{
        return 'mdi-folder-swap'
      }
    },
    getColor(){
      if(this.title === 'Удаление'){
        return 'red'
      }else if(this.title === 'Добавление'){
        return '#5CB7B1'
      }else{
        return 'amber'
      }
    }
  }
}
</script>