<template>
  <v-card
    v-if="services.length"
    elevation="2"
    class="mb-2"
  >
    <v-toolbar
      color="#5cb7b1"
      flat
      dark
    >
      <v-card-title
        class="text-body-1 font-weight-medium"
        color="black"
      >
        Новый пакет услуг
      </v-card-title>
    </v-toolbar>

    <div class="pa-7">
      <v-form
        ref="newPackage"
        @submit.prevent="addPackage"
      >
        <v-row>
          <v-col
            cols="12"
            lg="12"
            class="py-0"
          >
            <label for="">Выберите услугу</label>
            <v-select
              v-model="service"
              :items="services"
              solo
              color="#32a1af"
              item-color="#32a1af"
              background-color="white"
              item-text="name"
              return-object
              flat
              class="mb-2"
              hide-details="auto"
              outlined
              dense
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="12"
            class="py-0"
          >
            <label for="">Название пакета</label>
            <v-text-field
              v-model="name"
              color="#32a1af"
              background-color="white"
              solo
              hide-details="auto"
              flat
              class="mb-2"
              outlined
              dense
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="py-0"
          >
            <label for="">Количество</label>
            <v-text-field
              v-model="amount"
              color="#32a1af"
              background-color="white"
              solo
              hide-details="auto"
              flat
              class="mb-2"
              outlined
              dense
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="py-0"
          >
            <label for="">Тип периода</label>
            <v-select
              v-model="period"
              :items="periodItems"
              solo
              color="#32a1af"
              item-color="#32a1af"
              item-text="txt"
              item-value="val"
              background-color="white"
              flat
              class="mb-2"
              hide-details="auto"
              outlined
              dense
              :rules="inputRules"
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="py-0"
          >
            <label for="">Период</label>
            <v-text-field
              v-model="amountTime"
              color="#32a1af"
              background-color="white"
              solo
              hide-details="auto"
              flat
              class="mb-2"
              outlined
              dense
              :rules="inputRules"
            />
          </v-col>

          <v-col
            cols="12"
            lg="6"
            class="py-0"
          >
            <label for="">Стоимость</label>
            <v-text-field
              v-model="price"
              color="#32a1af"
              background-color="white"
              solo
              hide-details="auto"
              flat
              class="mb-2"
              outlined
              dense
              suffix="BYN"
              :rules="inputRules"
              @input="(v) => (price = v.replace(',', '.'))"
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="py-0"
          >
            <label for="">Дата активации</label>
            <!-- <v-text-field color="#32a1af" background-color="white" solo hide-details="auto" flat class="mb-1" v-model="dateActive" outlined dense /> -->
            <v-text-field
              v-model="dateActive"
              color="#32a1af"
              background-color="#fff"
              solo
              hide-details="auto"
              flat
              class="mb-5"
              append-icon="mdi-calendar-blank-outline"
              type="date"
              outlined
              dense
              :rules="inputRules"
              @change="deleteNulls($event, 'dateActive')"
            />
          </v-col>

          <v-col
            cols="12"
            lg="12"
            class="text-center"
          >
            <v-btn
              dark
              block
              x-large
              color="#333"
              type="submit"
              :loading="load"
            >
              + Добавить
            </v-btn>
            <!-- <div class="text-body-2 red--text mt-2" v-if="errTxt !== '' ">{{errTxt}}</div> -->
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data:() => ({
    load: false,
    dateActive: '',
    price: '',
    period: '',
    periodItems: [
      {
        txt: 'День',
        val: 'D'
      },
      {
        txt: 'Месяц',
        val: 'M'
      },
      {
        txt: 'Год',
        val: 'Y'
      }
    ],
    amount: '',
    amountTime: '',
    name: '',
    service: null,
    inputRules: [
      v => !!v || 'Обязательное поле'
    ],
  }),
  computed: {
    ...mapGetters({
      services: 'billing/getServices'
    })
  },
  methods: {
    addPackage(){
      if(this.$refs.newPackage.validate()){
        this.load = true

        let servPackage = {
          id: null,
          parentId: this.service.id,
          name: this.name,
          description: this.service.description,
          priorityOrder: null,
          cost: this.price,
          forTimeType: this.period,
          forTime: this.amountTime,
          forNum: this.amount,
          moduleId: null,
          dOn: this.dateActive,
          dOff: null,
          updated: null
        }

        this.$store.dispatch("billing/addNewPackage", servPackage).then(()=>{
          this.load = false
          this.$refs.newPackage.reset() 
        })
      }
            
    },
    deleteNulls(e, param){
      if(e[0] === '0' && e[1] === '0'){
        this[param] = '20' + this[param].substr(2)
      }else{
        return e
      }
    }
  },
}
</script>