<template>
  <v-card
    elevation="2"
    class="mb-2"
  >
    <v-toolbar
      color="grey lighten-3"
      flat
    >
      <v-card-title
        class="text-h5 font-weight-medium"
        color="black"
      >
        <v-icon
          class="mr-2"
          :color="getColor"
        >
          {{ getIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
    </v-toolbar>

    <div class="mini-table pa-4 text-body-2">
      <!-- <pre>{{cardItem}}</pre>
            <pre>{{itemBefore}}</pre> -->
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="py-1"
          :class="{ highlightedTr : itemBefore && cardItem.reg_number !== itemBefore.reg_number }"
        >
          {{ cardItem.reg_number }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="py-1" 
          :class="{ highlightedTr : itemBefore && cardItem.d_on !== itemBefore.d_on }"
        >
          Дата включения в реестр: {{ formateData(cardItem.d_on) }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="py-1"
          :class="{ highlightedTr : itemBefore && cardItem.certificate_info !== itemBefore.certificate_info }"
        >
          {{ cardItem.certificate_info }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="py-1"
          :class="{ highlightedTr : itemBefore && cardItem.d_off !== itemBefore.d_off }"
        >
          Дата окончания срока: {{ formateData(cardItem.d_off) }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="py-1"
          :class="{ highlightedTr : itemBefore && cardItem.copyright_holder_info !== itemBefore.copyright_holder_info }"
        >
          <div class="font-weight-bold">
            Сведения о правообладателе 
            <v-tooltip
              bottom
              max-width="400"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ cardItem.copyright_holder_info }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="py-1"
          :class="{ highlightedTr : itemBefore && cardItem.represent_copyright_info !== itemBefore.represent_copyright_info }"
        >
          <div class="font-weight-bold">
            Сведения о представителях правообладателя
            <v-tooltip
              bottom
              max-width="400"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ cardItem.represent_copyright_info }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <v-sheet
        color="grey lighten-3"
        elevation="0"
        width="100%"
        rounded="lg"
        class="pa-4 mt-4"
      >
        <v-row>
          <v-col
            cols="12"
            lg="3"
          >
            <div
              v-if="!cardItem.image"
              class="text-body-1 font-weight-bold"
            >
              {{ cardItem.short_description }}
            </div>
            <img
              v-else
              :src="`data:image/png;base64, ${cardItem.image}`"
              width="80"
              class="mr-5"
            >
          </v-col>
          <v-col
            cols="12"
            lg="9"
          >
            <div class="text-body-1">
              <div
                :class="{ highlightedTr : itemBefore && cardItem.goods_list !== itemBefore.goods_list }"
                class="mb-2"
              >
                <span v-if="cardItem.goods_list.length > 75">
                  {{ cardItem.goods_list.slice(0,75) }}...</span>
                <span v-else>{{ cardItem.goods_list }}</span>
              </div>
                            
              <div :class="{ highlightedTr : itemBefore && cardItem.tn_ved_codes_list !== itemBefore.tn_ved_codes_list }">
                <span v-if="cardItem.tn_ved_codes_list.length > 120">
                  {{ cardItem.tn_ved_codes_list.slice(0,120) }}...</span>
                <span v-else>{{ cardItem.tn_ved_codes_list }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    cardItem: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    title:{
      type: String,
      required: true
    },
    itemBefore: {
      type: Object,
      required: false,
      default:null
    }
  },
  computed: {
    getIcon(){
      if(this.title === 'Удаление'){
        return 'mdi-alert'
      }else if(this.title === 'Добавление'){
        return 'mdi-plus-circle'
      }else{
        return 'mdi-folder-swap'
      }
    },
    getColor(){
      if(this.title === 'Удаление'){
        return 'red'
      }else if(this.title === 'Добавление'){
        return '#5CB7B1'
      }else{
        return 'amber'
      }
    }
  },
  methods: {
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>