<template>
  <div>
    <v-btn
      style="font-size: 14px"
      height="48"
      class="mr-4"
      small
      color="#e0e0e0"
      @click="show"
    >
      <span>Дополнительный код</span>
    </v-btn>
    <catalogs />
  </div>
</template>
<script>
import {showCatalog} from "@/events/statistics";
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import moment from "moment";
export default {
  components: {Catalogs},
  props:{
    date:{
      required:true,
      type:String
    },
    code:{
      required:true,
      type:String
    }
  },
  methods:{
    show(){showCatalog.trigger({
      type: "tnved_extra",
      id: 0,
      search: this.code,
      field:"",
      actionDate:moment(this.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    });
    }
  }
}

</script>
