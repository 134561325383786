const routes = [
  {
    path: "/catalogs",
    name: "CatalogsHomepage",
    component: () => import('@/views/catalogs/homepage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes
