export default {
  ADD_SHIPMENT(state, payload) {
    state.selected.ware_shipments.push(payload);
  },
  SET_SHIPMENT_INDEX(state, index) {
    state.shipmentIndex = index;
  },
  DELETE_SHIPMENT(state, index) {
    state.selected.ware_shipments.splice(index, 1);
  },
  UPDATE_SELECTED_SHIPMENT_FIELDS(state, { name, value }) {
    const index = state.shipmentIndex;
    state.selected.ware_shipments[index][name] = value;
  },
  ADD_SHIPMENT_DOCUMENT(state, { item, nextItemPosition }) {
    const index = state.shipmentIndex;
    state.selected.ware_shipments[index].presented_documents.splice(nextItemPosition, 0, item);
  },
  ADD_SHIPMENT_GOODS_ITEM(state, { item, nextItemPosition }) {
    const index = state.shipmentIndex;
    state.selected.ware_shipments
      .at(index)
      ?.wares.splice(nextItemPosition, 0, item);
  },
  REPLACE_SHIPMENT_BLOCK(state, blocks) {
    const index = state.shipmentIndex;
    Object.keys(blocks).forEach((block) => {
      if (block in state.selected.ware_shipments[index]) {
        state.selected.ware_shipments[index][block] = blocks[block];
      }
    });
  },
  UPDATE_DOCUMENT_IN_SHIPMENT(state, items) {
    const shipmentIndex = state.shipmentIndex;
    const presented_documents = state.selected.ware_shipments[shipmentIndex].presented_documents ?? []
    if (items.length === presented_documents.length) {
      presented_documents.splice(
        0,
        presented_documents.length,
        ...items
      );
    } else {
      items.forEach((item) => {
        const { id } = item;
        const current = presented_documents.find(
          (i) => i.id === id
        );
        const index = presented_documents.indexOf(current);
        console.log("STORE UPDATE DOC idx >>", index);
        presented_documents.splice(index, 1, item);
      });
    }
  },
  UPDATE_SHIPMENT_GOODS(state, items) {
    const shipment = state.selected.ware_shipments[state.shipmentIndex];
    items.forEach((item) => {
      const { id } = item;
      const index = shipment.wares.findIndex((i) => i.id === id);
      shipment.wares.splice(index, 1, item);
    });
  },
}