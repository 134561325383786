import axios from "axios";
import {TNVED,SO} from "@/http-routes";
import documentMutations from './utils/document-mutations'
import documentActionsFactory from "./utils/document-actions";

const documentActions = documentActionsFactory(SO)

export default {
  namespaced: true,
  state: {
    selected: {},
    control: [],
    selectedWares:[],
    editableWare:{id:null, index:null},
    requests:[]
  },
  mutations: {
    ...documentMutations
  },
  actions: {
    ...documentActions,
   
    getAvailableExcise(_, payload){
      if(!payload.tn_ved) Promise.reject(new Error('Отсутствует код ТНВЭД'))
      const promise = new Promise((resolve, reject) => {
        axios.get(`${TNVED}api/read/nsiTransitExcise/getByTnVed?tnVed=${payload.tn_ved}`)
          .then(({data}) => {     
            if(!data.length){
              if(!payload.date){
                let error = new Error('Отсутствует дата для загрузки акциза из таможни');
                error.color = 'orange'
                throw error
              }
              axios.get(`${TNVED}api/read/getExcisesByTnVed?tnVed=${payload.tn_ved}&declarationDate=${payload.date}`)
                .then(({data}) =>{
                  resolve({data, type:2})
                }).catch(err => reject(err))
            }else{
              resolve({data, type:1})
            }
          }).catch((e) => reject(e))
      })
      return promise
    },
    refreshPayments({commit}, {id, type}){
      return axios.post(`${SO}declaration/updateCommonPaymentSumFromTd?id=${id}&declarationType=${type}`)
        .then(res => {
          commit("SET_SELECTED", res.data)
          return res
        })  
    },
    sendToEclient(_, {xml, declarationId, userId, divisionId}){
      return axios.post(`${SO}declaration/sendToEclient?userId=${userId}&divisionId=${divisionId}&declarationId=${declarationId}`, xml, {
        headers:{
          'Content-Type': 'application/xml',
        }
      })
    },
    fillDocGuidByRegNum(_, id) {
      return axios.post(`${SO}declaration/fillDocGuidByRegNum?declarationId=${id}`)
    },
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares:(s) => s.selectedWares,
    getRequests: (s) => s.requests,
    getControl: (s) => s.control,
  },
};
