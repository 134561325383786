<template>
  <div>
    <div>
      <reciever
        v-for="(i) in 5"
        :key="i"
      />
    </div>
    <div>
      <v-btn
        color="teal"
        class="white--text"
      >
        <v-icon>mdi-plus</v-icon>Добавить
      </v-btn>
    </div>
  </div>
</template>
<script>
import Reciever from './reciever.vue'
export default {
  components:{
    Reciever
  }
}
</script>
