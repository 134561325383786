<template>
  <section id="usual">
    <v-container>
      <v-row>
        <!-- <pre>{{dataBase}}</pre> -->
        <v-col
          v-for="(tab, index) in dataBase.tables"
          :key="index"
          cols="12"
          lg="12"
        >
          <h2>{{ tab.name.toUpperCase() }}</h2>
          <!-- <pre>{{tab.name}}</pre> -->
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <h4 class="mb-4 grey--text">
                TEST
              </h4>
              <v-data-table
                :headers="headers"
                :items="tab.fieldsFromTest"
                class="elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template #item.comparedType="{ item }">
                  <v-chip
                    small
                    :color="getColor(item.comparedType)"
                    dark
                    class="font-weight-medium"
                  >
                    {{ item.comparedType }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <h4 class="mb-4 grey--text">
                PROD
              </h4>
              <v-data-table
                v-if="tab.fieldsFromProd"
                :headers="headers"
                :items="tab.fieldsFromProd"
                class="elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template #item.comparedType="{ item }">
                  <v-chip
                    small
                    :color="getColor(item.comparedType)"
                    dark
                    class="font-weight-medium"
                  >
                    {{ item.comparedType }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-divider class="my-5" />
          </v-row>
        </v-col>


                

        <v-col
          cols="12"
          lg="6"
        >
          <v-card elevation="2">
            <h4 class="mb-4 grey--text pl-4 pt-4">
              TEST SEQUENCES
            </h4>
            <v-divider />
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in dataBase.testSequences"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <v-card elevation="2">
            <h4 class="mb-4 grey--text pl-4 pt-4">
              PROD SEQUENCES
            </h4>
            <v-divider />
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in dataBase.prodSequences"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

import axios from 'axios'
export default {
  data() {
    return {
      dataBase: [],
      prodSeqHead: [{
        name: 'prod sequences'
      }],
      headers: [{
        text: 'Название поля в БД',
        value: 'fieldName',
        sortable: false
      },
      {
        text: 'Тип поля',
        value: 'fieldType',
        sortable: false
      },
      {
        text: 'По отношению к тесту',
        value: 'comparedType',
        sortable: false
      },
      ],
    }
  },
  created() {
    axios.get('http://10.128.8.142:7020/updater/api/compare')
      .then(res => {
        this.dataBase = res.data

      })
  },
  methods: {
    getTables(tab) {
      let tableKeys = Object.keys(tab)
      var newArray = tableKeys.filter(function (f) {
        return f !== 'name'
      })
      return newArray
    },
    getColor(type){
      if(type === 'SAME'){
        return 'grey'
      }else if(type === 'TO_INSERT'){
        return 'green'
      }else if(type === 'TO_DELETE'){
        return 'red'
      }else{
        return 'transparent'
      }
    }
  }
}
</script>

<style scoped>
    #usual {
        min-height: calc(100vh - 134px);
    }
</style>