<template>
  <v-col
    v-if="dvsnUsers.length && dvsn"
    cols="12"
  >
    <v-row class="mb-4">
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filtersActive"
          flat
          background-color="white"
          :items="filters"
          item-color="#32a1af"
          color="#32a1af"
          solo
          hide-details
          outlined
          dense
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filtersBranch"
          flat
          background-color="white"
          :items="branchesAlowed"
          item-color="#32a1af"
          color="#32a1af"
          solo
          hide-details
          placeholder="Филиал/отдел"
          item-text="division_name"
          item-value="id"
          clearable
          outlined
          dense
          @change="getRolesForFilter"
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filterRolesVal"
          flat
          background-color="white"
          :items="filterRoles"
          item-color="#32a1af"
          color="#32a1af"
          solo
          hide-details
          placeholder="Роль"
          item-text="role_name"
          item-value="id"
          clearable
          outlined
          dense
          :disabled="!filtersBranch"
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model="search"
          color="#32a1af"
          background-color="white"
          solo
          hide-details="auto"
          flat
          placeholder="Иван Иванов"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
        class="text-right"
      >
        <!-- смена вида -->
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              elevation="0"
              max-width="40px"
              min-width="40px"
              height="40px"
              color="#≈"
              v-bind="attrs"
              @click="listView = !listView"
              v-on="on"
            >
              <v-icon v-if="!listView">
                mdi-format-list-bulleted
              </v-icon>
              <v-icon v-else>
                mdi-view-grid-outline
              </v-icon>
            </v-btn>
          </template>
          <span v-if="!listView">Список</span>
          <span v-else>Плитка</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="listView">
      <v-col lg="12">
        <v-form
          ref="inviteUser"
          @submit.prevent="inviteUser"
        >
          <v-sheet
            elevation="1"
            class="px-4 py-0 mb-3 rounded"
          >
            <v-row>
              <v-col lg="2">
                <div class="mt-3 text-body-1 font-weight-medium">
                  Новый пользователь
                </div>
              </v-col>
              <v-col lg="3">
                <v-text-field
                  v-model="email"
                  color="#32a1af"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  flat
                  placeholder="E-mail"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                v-if="dvsn.divisionType !== 'IMP'"
                lg="2"
              >
                <v-select
                  v-model="selectedBranch"
                  :items="branchesAlowed"
                  solo
                  color="#32a1af"
                  item-color="#32a1af"
                  background-color="#fff"
                  flat
                  item-text="division_name"
                  item-value="id"
                  hide-details="auto"
                  :rules="inputRules"
                  placeholder="Подразделение"
                  outlined
                  dense
                  @change="getRolesForInvite"
                />
              </v-col>
              <v-col lg="2">
                <v-select
                  v-if="dvsn.divisionType !== 'IMP'"
                  v-model="selectedRole"
                  :items="roles"
                  solo
                  color="#32a1af"
                  item-color="#32a1af"
                  background-color="#fff"
                  flat
                  item-text="role_name"
                  item-value="id"
                  hide-details="auto"
                  :rules="inputRules"
                  placeholder="Роль"
                  outlined
                  dense
                />

                <v-select
                  v-else
                  v-model="selectedRole"
                  :items="roles2"
                  solo
                  color="#32a1af"
                  item-color="#32a1af"
                  background-color="#fff"
                  flat
                  item-text="role_name"
                  item-value="id"
                  hide-details="auto"
                  :rules="inputRules"
                  placeholder="Роль"
                  outlined
                  dense
                />
              </v-col>
              <v-col lg="3">
                <v-btn
                  dark
                  large
                  color="#333"
                  type="submit"
                  block
                >
                  + Добавить пользователя
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-form>
      </v-col>

      <v-col
        v-for="(user, ind) in dvsnUsers"
        :key="ind"
        lg="12"
        cols="12"
        :class="[(!filteredUsers(user) ? 'hidden' : '' ), (!filterByBranch(user) ? 'hidden' : '' ), (!filterByRole(user) ? 'hidden' : '' ), (!filterBySearch(user) ? 'hidden' : '' )]"
      >
        <division-user-card-list
          :branches-alowed="branchesAlowed"
          :item="user"
        />
      </v-col>
    </v-row>


    <v-row v-else>
      <v-col
        cols="12"
        lg="4"
      >
        <v-card
          elevation="1"
          class="mb-6"
        >
          <v-toolbar flat>
            <v-card-title
              class="text-body-1 font-weight-medium "
              color="black"
            >
              Новый пользователь
            </v-card-title>
          </v-toolbar>

          <div
            class="px-7 py-2"
            style="min-height:307px;"
          >
            <v-form
              ref="inviteUser"
              @submit.prevent="inviteUser"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                  class="py-0"
                >
                  <label for="">E-mail</label>
                  <v-text-field
                    v-model.trim="email"
                    color="#32a1af"
                    background-color="grey lighten-3"
                    solo
                    hide-details="auto"
                    flat
                    placeholder="E-mail"
                    class="mb-4"
                    :rules="emailRules"
                    dense
                  />
                </v-col>
                <v-col
                  v-if="dvsn.divisionType !== 'IMP'"
                  cols="12"
                  lg="12"
                  class="py-0"
                >
                  <label for="">Подразделение</label>
                  <v-select
                    v-model="selectedBranch"
                    :items="branchesAlowed"
                    solo
                    color="#32a1af"
                    item-color="#32a1af"
                    background-color="grey lighten-3"
                    flat
                    item-text="division_name"
                    item-value="id"
                    class="mb-4"
                    hide-details="auto"
                    :rules="inputRules"
                    dense
                    @change="getRolesForInvite"
                  />
                </v-col>
                <v-col
                  cols="12"
                  :lg="dvsn.divisionType === 'TA' || dvsn.divisionType === 'BTS' ? '12' : '12' "
                  class="py-0"
                >
                  <label for="">Роль</label>
                  <v-select
                    v-if="dvsn.divisionType !== 'IMP'"
                    v-model="selectedRole"
                    :items="roles"
                    solo
                    color="#32a1af"
                    item-color="#32a1af"
                    background-color="grey lighten-3"
                    flat
                    item-text="role_name"
                    item-value="id"
                    class="mb-4"
                    hide-details="auto"
                    :rules="inputRules"
                    dense
                  />

                  <v-select
                    v-else
                    v-model="selectedRole"
                    :items="roles2"
                    solo
                    color="#32a1af"
                    item-color="#32a1af"
                    background-color="grey lighten-3"
                    flat
                    item-text="role_name"
                    item-value="id"
                    class="mb-4"
                    hide-details="auto"
                    :rules="inputRules"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <v-btn
                    dark
                    large
                    color="#333"
                    type="submit"
                  >
                    + Добавить пользователя
                  </v-btn>
                  <div
                    v-if="errTxt !== '' "
                    class="text-body-2 red--text mt-2"
                  >
                    {{ errTxt }}
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <!--  -->
      <v-col
        v-for="(item, i) in dvsnUsers"
        :key="i"
        cols="12"
        lg="4"
        :class="[(!filteredUsers(item) ? 'hidden' : '' ), (!filterByBranch(item) ? 'hidden' : '' ), (!filterByRole(item) ? 'hidden' : '' ), (!filterBySearch(item) ? 'hidden' : '' )]"
      >
        <division-user-card
          :branches-alowed="branchesAlowed"
          :item="item"
        />
      </v-col>
    </v-row>


    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
    >
      <span><v-icon
        class="mr-1"
        color="#32a1af"
        small
      >mdi-check</v-icon> Пользователь приглашен</span>
      <template #action="{ attrs }">
        <v-btn
          color="#32a1af"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ок
        </v-btn>
      </template>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from 'axios'
import {AUTH} from '@/http-routes'
import {mapGetters} from 'vuex'
import DivisionUserCard from '../components/profile/division-user-card.vue'
import DivisionUserCardList from '../components/profile/division-user-card-list.vue'

export default {
  components: {DivisionUserCard, DivisionUserCardList},
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  },
  data:() => ({
    snackbar: false,
    search: null,
    filtersBranch: null,
    filterRoles: [],
    filterRolesVal: null,
    filtersActive: 'Активные',
    filters: ['Все', 'Активные', 'В паузе', 'Неподтвержденные', 'Удаленные'],
    listView: true,
    email: '',
    errTxt: '',
    branchesAlowed: [],
    selectedBranch: null,
    roles: [],
    roles2: [],
    selectedRole: null,
    emailRules: [
      v => !!v || 'Поле должно быть заполнено',
      v => /.+@.+\..+/.test(v) || 'Некорректный e-mail',
    ],
    inputRules: [
      v => !!v || 'Обязательное поле'
    ],

  }),
  computed: {
    ...mapGetters({
      dvsnUsers: 'auth/getDivisionUsers',
      user: "auth/getUser",
      dvsn: 'auth/getSingleDivision',
    })
  },
  async mounted(){
    await this.$store.dispatch('auth/loadSingleDivisions', this.id)
    this.getRolesForIMP();
  },
  created(){
    this.$store.dispatch('auth/loadUsers', this.user.user.id)
    axios
      .get(`${AUTH}division/getDivisionsForInvite?userId=${this.user.user.id}`)
      .then(res =>{
        this.branchesAlowed = res.data
      })
  },
  methods: {
    filterBySearch(item){
      if(!item.last_name && !item.first_name && !this.search){
        return true
      }
      // поиск только при наличии email
      if(!item.last_name && !item.first_name && this.search){
        let keySearch = `${item.email.toLowerCase()}`
        if(this.search && this.search !== ''){
          if(keySearch.search(this.search.toLowerCase()) == -1){
            return false
          }else{
            return true
          }
        }else{
          return true
        }
      }
      // поиск под заполненные карточки
      let keySearch = `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()} ${item.email.toLowerCase()}`
      if(this.search && this.search !== ''){
        if(keySearch.search(this.search.toLowerCase()) == -1){
          return false
        }else{
          return true
        }
      }else{
        return true
      }
    },
    inviteUser(){
      if(this.$refs.inviteUser.validate()){
        let invite = {
          email: this.email,
          branch: this.dvsn.divisionType === 'IMP' ? this.id : this.selectedBranch,
          role: this.selectedRole
        }

        this.$store.dispatch('auth/inviteUser', invite)
          .then(() => {
            this.selectedBranch = null
            this.selectedRole = null
            this.email = ''
            this.$refs.inviteUser.reset()
          }).catch(err => {
            this.$snackbar({text:err.response.data || "Ошибка", color:'red', top:false, timeout:3000})
          })
      }
    },
    getRolesForFilter(){
      this.filterRolesVal = null
      axios
        .get(`${AUTH}role/getRolesForInvite?userId=${this.user.user.id}&divisionId=${this.filtersBranch}`)
        .then(res =>{
          this.filterRoles = res.data
        })
    },
    getRolesForInvite(){
      axios
        .get(`${AUTH}role/getRolesForInvite?userId=${this.user.user.id}&divisionId=${this.selectedBranch}`)
        .then(res =>{
          this.roles = res.data
        })
    },
    getRolesForIMP(){
      axios
        .get(`${AUTH}role/getRolesForInvite?userId=${this.user.user.id}&divisionId=${this.id}`)
        .then(res =>{
          this.roles2 = res.data
        })
    },
    filteredUsers(item){
      if(this.filtersActive === 'Все'){
        return true
      }else if(this.filtersActive === 'Активные' && item.is_active){
        return true
      }else if(this.filtersActive === 'В паузе' && item.is_active === false && item.d_off === null){
        return true
      }else if(this.filtersActive === 'Удаленные' && item.is_active === false && item.d_off !== null){
        return true
      }else if(this.filtersActive === 'Неподтвержденные' && item.is_active == null){
        return true
      }else{
        return false
      }
    },
    filterByBranch(item){

      if(this.filtersBranch === null){
        return true
      }

      if(item.divid == this.filtersBranch){
        return true
      }else{
        return false
      }

    },
    filterByRole(item){
      if(this.filterRolesVal === null){
        return true
      }

      if(item.role_id == this.filterRolesVal){
        return true
      }else{
        return false
      }
    }
  },
}
</script>
