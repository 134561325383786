import axios from "axios";
import { TNVED } from "@/http-routes";
import {getDivisionResidentStructure , getDivisionNoResidentStructure } from '@/helpers/catalogs'
import {convertEmptyStringsToNull} from '@/helpers/objects'

export default {
  namespaced:true,
  state:{
    residents:[],
    noResidents:[]
  },
  mutations:{
    SET_RESIDENTS(state, items){
      state.residents = items
    },
    SET_NO_RESIDENTS(state, items){
      state.noResidents = items
    }
  },
  actions:{
    loadResidents({rootState}){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      if(!divisionId) return;

      return axios.get(`${TNVED}divResident/getByDivisionId?divisionId=${divisionId}`)
    },
    loadNoResidents({rootState}){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      if(!divisionId) return;

      return axios.get(`${TNVED}divNoResident/getByDivisionId?divisionId=${divisionId}`)
    },
    fetchResidents({commit, dispatch}){
      return dispatch('loadResidents').then(res => {
        commit('SET_RESIDENTS', res.data)
        return res
      })
    },
    fetchNoResidents({commit, dispatch}){
      return dispatch('loadNoResidents').then(res => {
        commit('SET_NO_RESIDENTS', res.data)
        return res
      })
    },
    saveResidentRaw(_, payload){
      return axios.post(`${TNVED}divResident/save`, convertEmptyStringsToNull(payload))
    },
    saveResident({rootState}, {alias, docNum, docDate}){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      const resident = rootState?.statistics?.selected?.subject;
      if(!divisionId || !resident) return;
      const payload = getDivisionResidentStructure({resident, divisionId, alias, docNum, docDate})
      return axios.post(`${TNVED}divResident/save`, convertEmptyStringsToNull(payload))
    },
    saveNoResidentRaw(_, payload){
      return axios.post(`${TNVED}divNoResident/save`, convertEmptyStringsToNull(payload))
    },
    saveNoResident({rootState}, {alias}){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      const noResident = rootState?.statistics?.selected?.counter_agent
      if(!divisionId || !noResident) return;
      const payload = getDivisionNoResidentStructure({noResident, divisionId, alias})
      return axios.post(`${TNVED}divNoResident/save`, convertEmptyStringsToNull(payload))
    },
    searchResident({rootState}, payload){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      return axios.get(`${TNVED}divResident/getByAnyField?divisionId=${divisionId}&value=${payload}`)
    },
    searchNoResident({rootState}, payload){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      return axios.get(`${TNVED}divNoResident/getByAnyField?divisionId=${divisionId}&value=${payload}`)
    },
    deleteResident(_, id){
      return axios.post(`${TNVED}divResident/deleteById?id=${id}`)
    },
    deleteNoResident(_, id){
      return axios.post(`${TNVED}divNoResident/deleteById?id=${id}`)
    },
    saveSoClient(_,payload){
      return axios.post(`${TNVED}divSoClient/save`, payload)
    },
    getSoClients({rootState}){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      return axios.get(`${TNVED}divSoClient/getByRootParentDivisionId?divisionId=${divisionId}`)
    },
    searchSoClients({rootState}, payload){
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      return axios.get(`${TNVED}divSoClient/getByAnyField?divisionId=${divisionId}&value=${payload}`)
    },
  },
  getters:{
    getResidents: s => s.residents,
    getNoResidents: s => s.noResidents
  }

}
