<template>
  <v-dialog
    v-model="show"
    width="500"
    persistent
  >
    <v-card>
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Регистрация
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        <div class="mb-4">
          Введите SMS-код, который пришел на <strong style="color:#5CB7B1;">{{ phone }}</strong> номер телефона.
          <v-btn
            text
            x-small
            @click="changeNumber"
          >
            Изменить номер
          </v-btn>
        </div>

        <div class="text-h6 font-weight-regular black--text mb-1">
          SMS-код
        </div>
        <code-input
          type="number"
          :loading="false"
          class="mb-2"
          @change="onChange"
        />

        <div class="text-body-2 mb-6">
          <a
            v-if="countdown === 0"
            class="blue-link"
            @click.prevent="resendCode"
          >Получить код повторно</a>
          <span v-else>Получить код повторно через <strong>{{ timer }}</strong> </span>
        </div>
        <div class="text-center">
          <v-btn
            color="#333"
            dark
            x-large
            @click="confirm"
          >
            Далее
          </v-btn>
          <div
            v-if="errorText.length"
            class="mt-2 red--text"
          >
            {{ errorText }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CodeInput from "vue-verification-code-input";
import moment from "moment/moment";
export default {
  components:{
    CodeInput
  },
  props:{
    errorText:{
      type:String,
      required:true
    },
    show:{
      type:Boolean,
      required:true
    },
    phone:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      countdown: 120,
      countdownInterval: null
    }
  },
  computed: {
    timer() {
      return moment.utc(this.countdown*1000).format('mm:ss');
    },
  },
  watch:{
    show(value){
      if(value === true){
        this.startCountDown()
      }
    }
  },
  methods:{
    startCountDown(){
      this.countdown = 120
      this.countdownInterval = setInterval(() => {
        this.countdown += -1;
        if(this.countdown === 0){
          clearInterval(this.countdownInterval);
        }
      }, 1000)
    },
    resendCode(){
      this.$emit('retry')
      this.startCountDown()
    },
    changeNumber(){
      this.$emit('changeNumber')
    },
    onChange(code){
      this.$emit('input', code)
    },
    confirm(){
      this.$emit('confirm')
    }
  }
}
</script>