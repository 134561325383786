
const routes = [
  {
    path: "/journal",
    name: "Journal",
    component: () => import('@/views/journal/journal-main.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/journal/request",
    name: "JournalRequest",
    component: () => import('@/views/journal/journal-request.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
