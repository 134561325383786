export default {
  namespaced:true,
  state:{
    wareDetailsFields:{
      woods:[],
      ware:[],
      transport:[]
    }
  },
  mutations:{
    SET_WARE_DETAILS_FIELDS(state, {type, value}){
      state.wareDetailsFields[type] = value
    },
  },
  getters:{
    getWareDetailsFields: (s) => s.wareDetailsFields
  }
}