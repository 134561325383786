<template>
  <v-card
    elevation="2"
    class="mb-6"
  >
    <v-toolbar
      color="grey lighten-3"
      flat
    >
      <v-card-title
        class="text-body-1 font-weight-medium"
        color="black"
      >
        <v-icon
          class="mr-2"
          :color="getColor"
        >
          {{ getIcon }}
        </v-icon>
        {{ title }}
      </v-card-title>
      <v-spacer />
            
      <v-tooltip
        bottom
        max-width="400"
        z-index="150"
      >
        <template #activator="{ on, attrs }">            
          <v-chip
            color="#000"
            v-bind="attrs"
            label
            outlined
            class="font-weight-bold"
            v-on="on"
          >
            {{ customSpaces(cardItem.nomenclaturecode) }}
          </v-chip>
        </template>
        <span>{{ cardItem.tnVedDescription }}</span>
      </v-tooltip>
    </v-toolbar>


        
        
    <div class="mini-table pa-4 text-body-2">
      <v-row>
        <v-col
          cols="12"
          lg="12"
          :class="{ highlightedTr : itemBefore && cardItem.longname !== itemBefore.longname }"
        >
          <div class="text-body-1">
            {{ cardItem.longname }}
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          :class="{ highlightedTr : itemBefore && cardItem.ondate !== itemBefore.ondate }"
        >
          <div class="text-body-1">
            С {{ formateData(cardItem.ondate) }}
            <v-tooltip
              v-if="cardItem.basisOnName"
              bottom
              max-width="400"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ cardItem.basisOnName }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          :class="{ highlightedTr : itemBefore && cardItem.offdate !== itemBefore.offdate }"
        >
          <div
            v-if="cardItem.basisOffName"
            class="text-body-1"
          >
            По {{ formateData(cardItem.offdate) }}
            <v-tooltip
              bottom
              max-width="400"
              z-index="150"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ cardItem.basisOffName }}</span>
            </v-tooltip>
          </div>
          <!-- <div class="text-body-1" v-if="cardItem.offdate">
                        По {{formateData(cardItem.offdate)}}
                    </div> -->
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    cardItem: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: false,
      default:null
    },
    title:{
      type: String,
      required: true
    },
    itemBefore: {
      type: Object,
      required: false,
      default:null
    }
  },
  computed: {
    getIcon(){
      if(this.title === 'Удаление'){
        return 'mdi-alert'
      }else if(this.title === 'Добавление'){
        return 'mdi-plus-circle'
      }else{
        return 'mdi-folder-swap'
      }
    },
    getColor(){
      if(this.title === 'Удаление'){
        return 'red'
      }else if(this.title === 'Добавление'){
        return '#5CB7B1'
      }else{
        return 'amber'
      }
    }
  },
  methods: {
    customSpaces(code){
      let space1 = code.substr(0, 4)
      let space2 = code.substr(4, 2)
      let space3 = code.substr(6, 3)
      let space4 = code.substr(9, 1)
      return space1 + ' ' + space2 + ' ' + space3 + ' ' + space4
    },
    formateData(d) {
      return moment(d).format('DD.MM.YYYY')
    },
  }
}
</script>