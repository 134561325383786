import {eventBus} from '@/bus'

const base = {
  callback:null,
  subscribe:function(callback){
    this.callback = callback
    eventBus.$on(this.name, this.callback)
  },
  unsubscribe:function(){
    eventBus.$off(this.name, this.callback)
  },
  trigger(payload){
    eventBus.$emit(this.name, payload)
  }
}


const multipleEventsOneCallback = {
  callback:null,
  events:[],
  name:null,
  subscribe:function(callback, events = []){
    this.callback = callback
    this.events = events
    if(!this.name && this.events.length){
      this.events.forEach(event => {
        eventBus.$on(event, this.callback)
      })
    }else{
      eventBus.$on(this.name, this.callback)
    }
  
  },
  unsubscribe:function(){
    if(this.events.length){
      this.events.forEach(event => {
        eventBus.$off(event, this.callback)
      })
    }else{
      eventBus.$off(this.name, this.callback)
    }
   
  },
  trigger(payload, name){
    const event = name ?? this.name
    eventBus.$emit(event, payload)
  }
}

const notification = {
  ...base,
  name:"app-notification"
}

const recalculateBodyOffset = { // Используется для пересчета отступа при закрепленном блоке контроля
  ...base,
  name:"document-recalculate-body-offset"
}

const swipeToPinnedComponent = {
  ...base,
  name:"document-swipe-to-pinned-component"
}

const openGoodsModal = {
  ...base,
  name:"document-open-goods-modal"
}
const submitCopyToAll = {
  ...base,
  name:"document-submit-copy-to-all"
}

const copyToAllSucceed = {
  ...base,
  name:"document-copy-to-all-succeed"
}

const toggleControlsPanel = {
  ...base,
  name:"document-toggle-controls-panel"
}

const toggleControlsNotification = {
  ...base,
  name:"document-toggle-controls-notification"
}

const applyToWares = {
  ...base,
  name:"document-apply-to-wares"
}

const dateSelection = {
  ...base,
  name:"document-add-tnved-date-selection"
}

const sanctionedProductNotification = {
  ...base,
  name:"document-sanctioned-product-notification"
}

const wareDetailsModal = {
  ...base,
  name:"document-ware-details-modal"
}

const disablePageScroll = {
  ...base,
  name:"document-disable-page-scroll"
}

const forbiddenCarrierNotification = {
  ...base,
  name:"document-forbidden-carrier-notification"
}


export {
  base,
  multipleEventsOneCallback,
  notification,
  recalculateBodyOffset,
  swipeToPinnedComponent,
  openGoodsModal,
  toggleControlsPanel,
  toggleControlsNotification,
  applyToWares,
  submitCopyToAll,
  dateSelection,
  sanctionedProductNotification,
  copyToAllSucceed,
  wareDetailsModal,
  disablePageScroll,
  forbiddenCarrierNotification
}