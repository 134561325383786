import axios from "axios";
// TODO: сделать описание для методов

export default function (module) {
  const MODULE = module;
  return {
    getDocumentById({ commit }, id) {
      axios
        .get(`${MODULE}declaration/getById?id=${id}`)
        .then((res) => {
          commit("SET_SELECTED", res.data);
          return res;
        })
        .catch((err) => {
          this._vm.$snackbar({
            text: "Не удалось загрузить документ.",
            timeout: 5000,
            top: false,
            right: false,
            color: "red",
          });
          if (err?.response?.status !== 401) return Promise.reject(err);
        })
    },
    getDeclarationById(_, id ){
      return axios.get(`${MODULE}declaration/getById?id=${id}`)
    },
    createNewDocument({ rootState }) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${MODULE}declaration/create?userId=${userId}`);
    },
    // ОБНОВИТЬ БЛОК
    uploadBlockData({ commit }, payload) {
      return axios
        .post(`${MODULE}declaration/update`, payload.value)
        .then((res) => {
          commit("UPDATE_SELECTED_FIELDS", payload);
          return res;
        })
    },

    // ОБНОВИТЬ ДОКУМЕНТ
    updateDocument({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${MODULE}presentedDocument/updateAll`, data)
        .then((res) => {
          commit("UPDATE_DOCUMENT_IN_DECLARATION", data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // СОЗДАТЬ ДОКУМЕНТ
    addDocument({ commit, state }) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selected.presented_documents.length !== 0) {
        lastElement =
          state.selected.presented_documents[
            state.selected.presented_documents.length - 1
          ];
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selected.presented_documents.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      return axios
        .post(
          `${MODULE}presentedDocument/create?declarationId=${state.selected.id}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_DOCUMENT", { item: res.data, nextItemPosition });
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ДОКУМЕНТ
    deleteDocument({ commit, state }, { id }) {
      const declarationId = state.selected.id;
      return axios
        .post(
          `${MODULE}presentedDocument/deleteByIds?declarationId=${declarationId}`,
          [id]
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    deleteDocumentArr({ commit, state }, selected) {
      const declarationId = state.selected.id;
      return axios
        .post(
          `${MODULE}presentedDocument/deleteByIds?declarationId=${declarationId}`,
          [...selected]
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    // ОБНОВЛЕНИЕ ТОВАРА
    updateGoodsItem({ commit }, data) {
      const payload = Array.isArray(data) ? data : [data];
      return axios
        .post(`${MODULE}ware/updateAll`, payload)
        .then((res) => {
          commit("UPDATE_GOODS_ITEM_IN_DECLARATION", res.data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    // ОБНОВЛЕНИЕ БЛОКА ТОВАРА
    uploadWareBlockData({ commit }, payload) {
      return axios
        .post(`${MODULE}ware/update`, payload.value)
        .then((res) => {
          commit("UPDATE_SELECTED_FIELDS", payload);
          return res;
        })
    },

    // ДОБАВИТЬ НОВЫЙ ТОВАР
    addNewGoodsItem({ commit, state }) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selectedWares.length !== 0) {
        lastElement = state.selectedWares[state.selectedWares.length - 1];
        if (!lastElement.sort_index) {
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selectedWares.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      return axios
        .post(
          `${MODULE}ware/create?declarationId=${state.selectedId}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_GOODS_ITEM", { item: res.data, nextItemPosition });
          return res;
        });
    },
    deleteGoodsItem({ commit, state }, { id }) {
      return axios
        .post(`${MODULE}ware/deleteByIds?declarationId=${state.selectedId}`, [
          id,
        ])
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
          return res;
        });
    },

    //ПОЛУЧИТЬ АРТИКУЛЫ У ТОВАРА
    fetchWareDetails(_, { wareId }) {
      return axios.get(`${MODULE}wareDetails/getByWareId?wareId=${wareId}`);
    },
    createWareDetail(_, { wareId, index }) {
      return axios.post(
        `${MODULE}wareDetails/create?wareId=${wareId}&sortIndex=${index}`
      );
    },
    deleteWareDetail(_, { wareId }) {
      return axios.post(`${MODULE}wareDetails/deleteById?id=${wareId}`);
    },
    updateWareDetail(_, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios.post(`${MODULE}wareDetails/updateAll`, data);
    },
    copyWareDetail(_, payload) {
      return axios.post(
        `${MODULE}wareDetails/copyWareDetails?id=${payload.id}&sortIndex=${payload.index}`
      );
    },
    getDocumentInfo(_, id) {
      return axios.get(
        `${MODULE}declaration/getDeclarationDetailsInJournal?id=${id}`
      );
    },
    uploadXml({ rootState }, payload) {
      const uid = rootState?.auth?.user?.user?.id;
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!divisionId)
        return Promise.reject(new Error("Отсутствует ID подразделения"));
      if (uid) {
        return axios.post(
          `${MODULE}declaration/import?userId=${uid}&divisionId=${divisionId}`,
          payload
        );
      }
      return Promise.reject(new Error("Ошибка"));
    },
    getXml(_, id) {
      return axios.get(`${MODULE}declaration/export?declarationId=${id}`);
    },
    getXmlString(_, declarationId) {
      return axios.get(`${MODULE}declaration/getXml?declarationId=${declarationId}`);
    },
    sendSignedXmlString(
      _,
      { xml, ptoId, fileGuid, remark, divisionId, declarationId, dtGuid }
    ) {
      let url = `${MODULE}declaration/sendToNces?divisionId=${divisionId}`
      if(dtGuid){
        url += `&dtGuid=${dtGuid}`
      }
      return axios.post(url,
        { xml, ptoId, fileGuid, remark, declarationId }
      );
    },
    performControlNCES(_, id) {
      if (!id) return Promise.reject(new Error("Отсутствует номер документа"));
      return axios.get(`${MODULE}declaration/flk?declarationId=${id}`);
    },
    calculatePayments({ state }) {
      return axios.post(
        `${MODULE}declaration/calculatePayments?id=${state.selected.id}`
      );
    },
    validatePayments({ state, commit }) {
      return axios
        .get(`${MODULE}declaration/validatePayments?id=${state.selected.id}`)
        .then((res) => {
          if (res.data) {
            commit("documents/SET_PAYMENT_ERRORS", res.data, { root: true });
          }
          return res;
        });
    },
    recalculateCustomsCost({ commit }, id) {
      if (!id) return Promise.reject(new Error("Отсутствует номер документа"));
      return axios
        .post(`${MODULE}declaration/recalculateCustomsCost?declarationId=${id}`)
        .then((res) => {
          commit("SET_SELECTED", res.data);
          return res;
        });
    },
    performControl({ commit }, id) {
      if (!id) return Promise.reject(new Error("Отсутствует номер документа"));
      return axios
        .get(`${MODULE}declaration/flk?declarationId=${id}`)
        .then((res) => {
          commit("SET_CONTROL", res.data);
          return res;
        });
    },
    toTrash(_, { id, value }) {
      return axios.post(`${MODULE}declaration/setTrash?id=${id}&trash=${value}`);
    },
    changePerformer(_, { documentId, personId }) {
      return axios.post(
        `${MODULE}declaration/setUserId?declarationId=${documentId}&otherUserId=${personId}`
      );
    },
    createCancellation(_, { id, type }) {
      return axios.post(
        `${MODULE}declaration/createCancellation?declarationToCopyId=${id}&type=${type}`
      );
    },
    createCorrection(_, { id }){
      return axios.post(
        `${MODULE}declaration/createCorrection?declarationToCopyId=${id}`
      );
    },
    clearWareRange({commit}, items){
      return axios.post(`${MODULE}presentedDocument/clearWareRange`, items).then((res) => {
        commit('UPDATE_DOCUMENT_IN_DECLARATION', res.data)
        return res
      })
    },
    changeSortIndexes(_, {id, items}){
      return axios.post(`${MODULE}ware/changeSortIndexes?declarationId=${id}`, items)
    },
    getXmlPackage(_, payload){
      return axios.post(`${MODULE}declaration/packageXmlList`, payload, {
        responseType: "blob",
      })
    },
    addFromBuffer(_, {id, payload, clearOptions}){
      let url = `${MODULE}ware/addFromBuffer?declarationId=${id}`
      if(clearOptions && Object.keys(clearOptions).length){
        Object.keys(clearOptions).forEach(option => {
          url += `&${option}=${clearOptions[option]}`
        })
      }
      return axios.post(url, payload)
    },
    createWarranty({ commit }, id) {
      axios
        .post(`${MODULE}guarantee/create?declarationId=${id}`)
        .then((res) => {
          commit("SET_NEW_WARRANTY", res.data);
        })
    },
    updateWarranty({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${MODULE}guarantee/updateAll`, data)
        .then(() => {
          commit("UPDATE_WARRANTY_IN_DECLARATION", data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ГАРАНТИЮ
    deleteWarranty({ commit, state }, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${MODULE}guarantee/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then(() => {
          commit("DELETE_WARRANTY", payload);
        })
    },
    downloadPdf(_, id) {
      return axios.get(`${MODULE}declaration/pdf?id=${id}`, {
        responseType: "blob",
      });
    },
    downloadExcel(_, id) {
      return axios.get(`${MODULE}declaration/downloadXlsx?declarationId=${id}`, {
        responseType: "blob",
      });
    },
    mergedDocumentsPdf(_, ids){
      return axios.get(`${MODULE}declaration/manyToOnePdf?ids=${ids.join()}`, {
        responseType: "blob",
      });
    },
    getBD44Xml(_, payload){
      return axios.post(`${MODULE}declaration/getBD44Xml`, payload);
    },
    sendBD44ToNces(_, {
      userId, payload
    }){
      return axios.post(`${MODULE}presentedDocument/sendBD44ToNces?userId=${userId}`, payload);
    },
    loadTemplate() {
      return axios.get(`${MODULE}declaration/downloadXlsxTemplate`, {
        responseType: "blob",
      });
    },
    createCertificate(_, id) {
      return axios.post(`${MODULE}declaration/createSo?declarationId=${id}`);
    },
    applyToAll({ commit }, { id, values }) {
      if (!id) return Promise.reject("Не выбран товар");
      if (!values || !Object.keys(values).length)
        return Promise.reject("Не выбраны параметры для копирования");
      return axios
        .post(`${MODULE}ware/copyInOtherWares?declarationId=${id}`, {
          ids: [],
          values,
        })
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
          return res;
        });
    },
    applyToSeveral({ commit }, { ids, values }) {
      if (!ids) return Promise.reject("Не выбраны товары");
      if (!values || !Object.keys(values).length)
        return Promise.reject("Не выбраны параметры для копирования");
      return axios
        .post(`${MODULE}ware/copyInOtherWares`, { ids, values })
        .then((res) => {
          const wares = res.data?.wares ?? []
          commit("UPDATE_GOODS_ITEM_IN_DECLARATION", wares)
          return res;
        });
    },
    deleteGoodsAmount({ commit }, { ids }) {
      return axios.post(`${MODULE}ware/deleteByIds`, ids).then((res) => {
        commit("RESET_GOODS_AND_DOCS", res.data);
        return res;
      });
    },
    searchInWares({ commit }, { id, value }) {
      return axios
        .get(
          `${MODULE}ware/getByDeclarationIdAndTitleOrTnVed?declarationId=${id}&value=${value}`
        )
        .then((res) => {
          commit("REPLACE_GOODS_ITEM_IN_DECLARATION", res.data);
          return res;
        });
    },
    fetchGoods(_, id) {
      return axios.get(`${MODULE}ware/getByDeclarationId?declarationId=${id}`);
    },
    xsdErrorOnEmail(_, id) {
      return axios.post(`${MODULE}declaration/xsdErrorOnEmail?declarationId=${id}`);
    },
    copyWare({ commit, state }, { id }) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selectedWares.length !== 0) {
        lastElement = state.selectedWares[state.selectedWares.length - 1];
        if (!lastElement.sort_index) {
          console.log("SORT INDEX NOT FOUND");
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selectedWares.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      if (!id || !index)
        return Promise.reject(new Error("Возникла ошибка при копировании"));
      return axios
        .post(`${MODULE}ware/copyWare?id=${id}&sortIndex=${index}`)
        .then((res) => {
          const item = res.data;
          commit("ADD_GOODS_ITEM", { item, nextItemPosition });
          return res;
        });
    },
  };
}
