<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="sd-doctypes-viewer">
        <document-types
          :search="search"
          @select="closeOnSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import DocumentTypes from "@/components/catalogs/document-types.vue";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { documentTypeSelected } from "@/events/statistics";
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
import {eventBus} from "@/bus";

export default {
  components: {
    DocumentTypes,
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  methods: {
    closeOnSelect(code) {
      const payload = { code, id: this.id }
      if(this.customEventName){
        eventBus.$emit(this.customEventName, payload)
      }else {
        documentTypeSelected.trigger(payload);
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.sd-doctypes-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
