<template>
  <v-menu
    bottom
    left
    :offset-y="true"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="ml-4"
        height="48"
        dark
        small
        color="#5CB7B1"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="#fff">
          mdi-share-variant
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-clipboard:copy="pageUrl"
        link
      >
        <v-list-item-title>
          <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          Копировать ссылку
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="(item, index) in socials"
        :key="index"
        link
      >
        <v-list-item-title>
          <ShareNetwork
            :network="item.title"
            :url="pageUrl" 
            :title=" 'Веб-декларант' "
          >
            <i
              class="mr-2"
              :style="{backgroundColor: item.color}"
              :class="item.icon"
            />
            <span style="color:#333;font-size:18px;">{{ item.title }}</span>
          </ShareNetwork>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data(){
    return{
      pageUrl: '',
      socials: [
        { title: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        { title: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        { title: 'Facebook', icon: 'fab fah fa-lg fa-facebook', color: '#1877f2' },
        { title: 'Telegram', icon: 'fab fah fa-lg fa-telegram', color: '#0088cc' },
      ],
    }
  },
  created(){
    this.pageUrl = window.location.origin + this.$route.fullPath
  }
}
</script>

<style scoped>
a[class^="share-network-"] {
    flex: none;
    color: #fff;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
    /* margin: 0 10px 10px 0; */
  }
  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px 7px;
    flex: 0 1 auto;
    border-radius: 30px;
  }
</style>