<template>
  <v-list-item two-line>
    <v-avatar
      class="ml-1"
      color="#F44336"
      size="40"
    >
      <strong class="white--text">
        {{ initials }}
      </strong>
    </v-avatar>

    <v-list-item-content class="px-4">
      <v-list-item-title>{{ userFirstName }} {{ userLastName }}</v-list-item-title>
      <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { mapGetters } from 'vuex';
export default {

  computed:{
    ...mapGetters({
      user: "auth/getUser",
      divisions: "auth/getDivisions",
    }),
    initials(){
      return [this.userFirstName.substr(0, 1), this.userLastName.substr(0, 1)].join('')
    },
    userEmail() {
      return this.user?.user?.email || "";
    },
    userFirstName() {
      return this.user?.user?.firstName || ""
    },
    userLastName() {
      return this.user?.user?.lastName || ""
    },
  }
}
</script>