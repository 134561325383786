<template>
  <div
    class="grey-box"
  >
    <div class="row">
      <v-col
        cols="12"
      >
        <div class="text-h5 font-weight-regular mb-4">
          Персональный сертификат
        </div>
        <v-row>
          <v-col cols="8">
            <div class="d-flex">
              <v-file-input
                v-model="file"
                dense
                outlined
                color="#32a1af"
                solo
                flat
                clearable
                accept=".p7b"
                placeholder="Выберите файл"
              />
              <v-btn
                class="mx-2 elevation-0"
                @click="upload"
              >
                <v-icon>mdi-upload</v-icon>
                Загрузить
              </v-btn>
              <v-btn
                v-if="exist"
                color="teal lighten-2"
                dark
                class="mx-2 elevation-0"
                @click="download"
              >
                <v-icon>mdi-download</v-icon>
                Скачать
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  data:() => ({
    file:null,
    exist:false
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    }),
    userId(){
      return this.user?.user?.id
    }
  },
  mounted() {
    this.checkCertificate()
  },
  methods:{
    checkCertificate(){
      this.$store.dispatch('auth/downloadPersonalCertificate', {
        userId:this.userId,
      }).then(res => {
        this.exist = !!res.data?.size
      })
    },
    upload(){
      const payload = new FormData()
      payload.append('file', this.file)
      this.$store.dispatch('auth/attachPersonalCertificate', {
        userId:this.userId,
        payload
      }).then(() => {
        this.exist = true
        this.$snackbar({
          text:"Готово",
          color:"green",
          top:false
        })
      }).catch(() => {
        this.$snackbar({
          text:'Ошибка',
          color:'red',
          top:false
        })
      })
    },
    download(){
      this.$store.dispatch('auth/downloadPersonalCertificate', {
        userId:this.userId,
      }).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({data:res.data, type:"application/x-pkcs7-certificates", name})
      }).catch(() => {
        this.$snackbar({
          text:'Ошибка',
          color:'red',
          top:false
        })
      })

    }


  }
}
</script>