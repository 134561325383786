<template>
  <v-sheet
    elevation="2"
    class="small-listing px-4 py-0 mb-3 rounded"
    style="position:relative;"
    color="#5cb7b1"
  >
    <v-form
      ref="newPackage"
      @submit.prevent="addPackage"
    >
      <v-row>
        <v-col
          cols="12"
          lg="2"
        >
          <v-select
            v-model="service"
            :items="services"
            solo
            color="#32a1af"
            item-color="#32a1af"
            background-color="white"
            item-text="name"
            return-object
            flat
            class="mb-2"
            hide-details="auto"
            outlined
            dense
            :rules="inputRules"
            placeholder="Услуга"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-text-field
            v-model="name"
            color="#32a1af"
            background-color="white"
            solo
            hide-details="auto"
            flat
            class="mb-2"
            outlined
            dense
            :rules="inputRules"
            placeholder="Назв. пакета"
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <v-text-field
            v-model="amount"
            color="#32a1af"
            background-color="white"
            solo
            hide-details="auto"
            flat
            class="mb-2"
            outlined
            dense
            :rules="inputRules"
            placeholder="Кол-во"
          />
        </v-col>

        <v-col
          cols="12"
          lg="1"
        >
          <v-select
            v-model="period"
            :items="periodItems"
            solo
            color="#32a1af"
            item-color="#32a1af"
            item-text="txt"
            item-value="val"
            background-color="white"
            flat
            class="mb-2"
            hide-details="auto"
            outlined
            dense
            :rules="inputRules"
            placeholder="Тип пер."
          />
        </v-col>
        <v-col
          cols="12"
          lg="1"
        >
          <v-text-field
            v-model="amountTime"
            color="#32a1af"
            background-color="white"
            solo
            hide-details="auto"
            flat
            class="mb-2"
            outlined
            dense
            :rules="inputRules"
            placeholder="Период"
          />
        </v-col>

        <v-col
          cols="12"
          lg="2"
        >
          <v-text-field
            v-model="price"
            color="#32a1af"
            background-color="white"
            solo
            hide-details="auto"
            flat
            class="mb-2"
            outlined
            dense
            suffix="BYN"
            :rules="inputRules"
            @input="(v) => (price = v.replace(',', '.'))"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-text-field
            v-model="dateActive"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            append-icon="mdi-calendar-blank-outline"
            type="date"
            outlined
            dense
            :rules="inputRules"
            @change="deleteNulls($event, 'dateActive')"
          />
        </v-col>

        <v-col
          lg="1"
          class="text-right"
        >
          <v-btn
            dark
            block
            large
            color="#333"
            type="submit"
            :loading="load"
            style="height:40px;"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data:() => ({
    load: false,
    dateActive: '',
    price: '',
    period: '',
    periodItems: [
      {
        txt: 'День',
        val: 'D'
      },
      {
        txt: 'Месяц',
        val: 'M'
      },
      {
        txt: 'Год',
        val: 'Y'
      }
    ],
    amount: '',
    amountTime: '',
    name: '',
    service: null,
    inputRules: [
      v => !!v || 'Обяз. поле'
    ],
  }),
  computed: {
    ...mapGetters({
      services: 'billing/getServices'
    })
  },
  methods: {
    addPackage(){
      if(this.$refs.newPackage.validate()){
        this.load = true
        let servPackage = {
          id: null,
          parentId: this.service.id,
          name: this.name,
          description: this.service.description,
          priorityOrder: null,
          cost: this.price,
          forTimeType: this.period,
          forTime: this.amountTime,
          forNum: this.amount,
          moduleId: null,
          dOn: this.dateActive,
          dOff: null,
          updated: null
        }

        this.$store.dispatch("billing/addNewPackage", servPackage).then(()=>{
          this.load = false
          this.$refs.newPackage.reset() 
        })
      }
            
    },
    deleteNulls(e, param){
      if(e[0] === '0' && e[1] === '0'){
        this[param] = '20' + this[param].substr(2)
      }else{
        return e
      }
    }
  },
}
</script>
