<template>
  <div class="d-flex">
    <pagination-size-button
      rounded="all"
      :value="params.size"
      @select="onSizeSelect"
    />
  </div>
</template>
<script>
import PaginationSizeButton from "@/components/shared/pagination-size-button.vue";
export default {
  components:{
    PaginationSizeButton
  },
  props:{
    params:{
      required:true,
      type:Object
    }
  },
  methods:{
    onSizeSelect(size){
      this.$emit("update", {...this.params, size})
    }
  }
}
</script>
