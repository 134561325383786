<template>
  <v-sheet
    elevation="2"
    class="small-listing px-4 py-0 mb-3 rounded"
    style="position:relative;"
  >
    <v-row>
      <v-col
        cols="12"
        lg="3"
      >
        <div
          class="text-body-2 font-weight-medium"
          color="black"
        >
          {{ getParent.name }} ({{ card.name }})
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="3"
      >
        <p style="font-size:10px;">
          {{ getParent.description }}
        </p>
      </v-col>
      <v-col
        cols="12"
        lg="1"
      >
        <div class="text-body-2 font-weight-bold">
          {{ card.for_num }} шт.
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="1"
      >
        <div class="text-body-2 font-weight-bold">
          {{ card.for_time }} {{ getTimeType }}
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="1"
      >
        <div
          class="text-body-2 font-weight-bold"
          :class="getDateColor()"
        >
          {{ formatDate(card.d_on) }}
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="1"
      >
        <div
          class="text-body-2 font-weight-bold"
          style="color:#5cb7b1;"
        >
          {{ card.cost.toLocaleString() }} BYN
        </div>
        <p style="font-size:10px;">
          {{ card.cost_for_one }}
        </p>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        class="text-right"
      >
        <v-btn
          v-if="card.d_off"
          dark
          block
          color="#CE1B0E"
          type="submit"
        >
          До {{ formatDate(card.d_off) }}
        </v-btn>
        <v-btn
          v-else
          dark
          block
          color="#333333"
          type="submit"
          outlined
          @click="dialog = true"
        >
          Завершить
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="360"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Завершение пакета
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          <label for="">Выберите дату окончания пакета</label>
          <v-text-field
            v-model="dateStop"
            color="#32a1af"
            background-color="#fff"
            solo
            hide-details="auto"
            flat
            class="mb-5"
            append-icon="mdi-calendar-blank-outline"
            type="date"
            outlined
            dense
            @change="deleteNulls($event, 'dateStop')"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false, dateStop = '' "
          >
            Отмена
          </v-btn>
          <v-btn
            color="#333"
            dark
            @click="updateDateOff"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>


<script>
import formatDate from '@/mixins/format-date.js'
import moment from 'moment'

export default {
  mixins: [formatDate],
  props: {
    services: {
      required: true,
      type: Array
    },
    card: {
      required: true,
      type: Object
    }
  },
  data(){
    return{
      dialog: false,
      dateStop: ''
    }
  },
  computed: {
    getParent() {
      let parent = this.services.find(item => {
        return item.id == this.card.parent_id
      })
      return parent
    },
    getTimeType() {
      if (this.card.for_time_type === 'D') {
        return 'дн.'
      } else if (this.card.for_time_type === 'M') {
        return 'мес.'
      } else {
        return 'г.'
      }
    }
  },
  methods: {
    getDateColor(){
      var a = moment(new Date, 'DD.MM.YYYY');
      var b = moment(this.formatDate(this.card.d_on), 'DD.MM.YYYY');
      var diffDays = b.diff(a, 'days');

      if(diffDays > 0){
        return 'red--text'
      }else{
        return 'black--text'
      }
    },
    updateDateOff(){
      console.log(this.dateStop)
      this.card.d_off = this.dateStop
      this.$store.dispatch("billing/addNewPackage", this.card).then(() =>{
        this.dialog = false
      })
    },
    deleteNulls(e, param){
      if(e[0] === '0' && e[1] === '0'){
        this[param] = '20' + this[param].substr(2)
      }else{
        return e
      }
    }
  }
}
</script>
