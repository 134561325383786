<template>
  <v-col
    cols="12"
    lg="4"
  >
    <v-card
      elevation="2"
      class="mb-6"
    >
      <v-toolbar
        color="#fff"
        flat
      >
        <v-card-title
          class="text-body-1 font-weight-medium"
          color="black"
        >
          {{ serv.name }}
        </v-card-title>
      </v-toolbar>
      <v-divider />
      <div class="pa-7">
        <p style="font-size:10px;min-height:50px;">
          {{ serv.description }}
        </p>
        <v-form
          ref="buyForm"
          @submit.prevent="openDialog"
        >
          <v-row>
            <v-col
              cols="12"
              lg="12"
              class="py-0 mt-2 mb-2"
            >
              <label for="">Пакет</label>
              <v-select
                v-model="selectedPackage"
                :items="serv.packages"
                solo
                color="#32a1af"
                item-color="#32a1af"
                background-color="white"
                flat
                class="mb-2"
                hide-details="auto"
                outlined
                dense
                :rules="inputRules"
              >
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  {{ data.item.name }}
                </template>
                <template
                  slot="item"
                  slot-scope="data"
                >
                  {{ data.item.name }} ({{ data.item.period }})
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              lg="5"
            >
              <div class="text-body-2">
                Период:
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="7"
            >
              <div class="text-body-2 font-weight-bold">
                {{ selectedPackage ? selectedPackage.period : '' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="5"
            >
              <div class="text-body-2">
                Цена:
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="7"
            >
              <div
                v-if="selectedPackage"
                style="color:#5cb7b1;"
                class="text-h5 font-weight-bold"
              >
                {{ selectedPackage.cost.toLocaleString() }} BYN
              </div> 
              <p
                style="font-size:10px;"
                class="mb-0"
              >
                {{ selectedPackage ? selectedPackage.cost_for_one : '' }}
              </p>
            </v-col> 
            <v-col
              cols="12"
              lg="12"
            >
              <v-btn
                dark
                block
                x-large
                color="#333333"
                type="submit"
              >
                + Купить
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
            
    <!-- dialogAccept -->
    <v-dialog
      v-model="dialogAccept"
      width="550"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Подтвердить действие
          </div>
        </v-toolbar>
        <div class="pa-6">
          <div class="text-body-1 black--text">
            Подтвердить покупку услуги?
          </div>
        </div>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialogAccept = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#5cb7b1"
            dark
            @click="buyPackage"
          >
            Подтвердить 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbarErr"
      color="red accent-2"
    >
      {{ errTxt }}
    </v-snackbar>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    serv: {
      required: true,
      type: Object
    }
  },
  data:() => ({
    dialogAccept: false,
    snackbarErr: false,
    errTxt: null,
    selectedPackage: null,
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
  methods:{
    openDialog(){
      if(this.$refs.buyForm.validate()){
        this.dialogAccept = true
      }
    },
    async buyPackage(){
      this.dialogAccept = false
      await this.$store.dispatch("billing/buyPackage", 
        {pack: this.selectedPackage.package_id, 
          division: this.user.user.activeDivisionId})
        .then((err)=>{
          this.$refs.buyForm.reset()
          if(!err){
            this.$emit('slideToAvailable')   
          }else{
                        
            this.errTxt = err.response.data
            this.snackbarErr = true
            setTimeout(()=>{
              this.snackbarErr = false
            }, 5000) 
          }
                    
                     
        })
    }
  }
}
</script>