<template>
  <v-col
    cols="12"
    class="rounded-b-0 border-b-0 mb-0 px-0"
  >
    <v-row>
      <v-col
        align-self="center"
        cols="2"
      >
        <payment-order-create-button />
      </v-col>


      <v-col cols="2">
        <v-select
          color="#5CB7B1"
          item-color="teal"
          :value="params.withRemains"
          :items="remains"
          hide-details="auto"
          background-color="white"
          class="ba-0 rounded"
          filled
          rounded
          label="Остаток"
          auto-select-first
          @change="onRemainsChange"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          clearable
          color="#5CB7B1"
          :value="params.code"
          :items="paymentCodes"
          background-color="white"
          class="ba-0 rounded"
          filled
          rounded
          item-text="code"
          item-value="code"
          hide-details="auto"
          label="Вид платежа"
          auto-select-first
          @change="onCodeChange"
        />
      </v-col>
      <v-col
        cols="1"
        class="d-flex justify-end align-center"
      >
        <v-checkbox
          color="#5CB7B1"
          hide-details
          label="Удаленные"
          :input-value="params.trash"
          @change="onTrashChange"
        />
      </v-col>
      <v-col
        cols="2"
        align-self="center"
        class="d-flex justify-end"
      >
        <v-switch
          color="#5CB7B1"
          prepend-icon="mdi-account-multiple"
          append-icon="mdi-account"
          hide-details="true"
          :input-value="params.selectType"
          true-value="user"
          false-value="division"
          @change="onSelectTypeChange"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import PaymentOrderCreateButton from "@/components/payment-orders/payment-order-create-button.vue";

export default {
  components: {PaymentOrderCreateButton},
  props:{
    params:{
      type:Object,
      required:true
    }
  },
  data(){
    return{
      remains:[
        {
          text:"С остатком",
          value:true
        },
        {
          text:"Без остатка",
          value:false
        }
      ],
      paymentCodes:[]
    }
  },
  created() {
    this.fetchNsiPaymentCodes()
  },
  methods:{
    fetchNsiPaymentCodes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.paymentCodes = res.data.list.map((i) => ({...i, search: `${i.code}-${i.name}`}))
      })
    },
    onTrashChange(trash){
      this.$emit('update', {...this.params, trash})
    },
    onRemainsChange(withRemains){
      this.$emit('update', {...this.params, withRemains})
    },
    onCodeChange(code){
      this.$emit('update', {...this.params, code})
    },
    onSelectTypeChange(selectType){
      this.$emit('update', {...this.params, selectType})
    }
  }
}
</script>
