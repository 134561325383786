import axios from "axios";
import {getShipment, getShipmentId} from "@/store/utils/shipment-helper";

export default function (module) {
  const MODULE = module;
  return {
    addShipment({ commit, state }) {
      const id = state.selected.id;
      const index = state.selected.ware_shipments.length + 1;
      return axios
        .post(
          `${MODULE}wareShipment/create?declarationId=${id}&sortIndex=${index}`
        )
        .then((res) => {
          commit("SET_SHIPMENT_INDEX", index - 1);
          commit("ADD_SHIPMENT", res.data);
          return res;
        });
    },
    deleteShipment({ commit, state }) {
      const index = state.shipmentIndex;
      const shipment = state.selected.ware_shipments[index];
      const declarationId = state.selected.id
      return axios
        .post(`${MODULE}wareShipment/deleteById?id=${shipment.id}&declarationId=${declarationId}`)
        .then((res) => {
          let nextIndex;
          if (index === 0) {
            nextIndex =
              state.selected.ware_shipments.length - 1 === 0 ? null : 0;
          } else {
            nextIndex =
              state.selected.ware_shipments.length - 1 === 0 ? null : index - 1;
          }
          commit("SET_SHIPMENT_INDEX", nextIndex);
          commit("DELETE_SHIPMENT", index);
          return res;
        });
    },
    uploadShipmentBlockData({ commit }, payload) {
      return axios
        .post(`${MODULE}wareShipment/update`, payload.value)
        .then((res) => {
          commit("UPDATE_SELECTED_SHIPMENT_FIELDS", payload);
          return res;
        });
    },
    addShipmentDocument({ state, commit }) {
      const shipment = getShipment(state)

      let index;
      let nextItemPosition;
      let lastElement;

      if (shipment.presented_documents.length !== 0) {
        lastElement =
          shipment.presented_documents[shipment.presented_documents.length - 1];
        index = lastElement.sort_index + 1;
        nextItemPosition = shipment.presented_documents.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }

      return axios
        .post(
          `${MODULE}presentedDocument/create?wareShipmentId=${shipment.id}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_SHIPMENT_DOCUMENT", { item: res.data, nextItemPosition });
          return res
        });
    },
    deleteShipmentDocument({ commit, state }, { id }) {
      const shipment = getShipmentId(state)
      return axios
        .post(
          `${MODULE}presentedDocument/deleteByIds?wareShipmentId=${shipment}`,
          [id]
        )
        .then((res) => {
          commit("REPLACE_SHIPMENT_BLOCK", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    updateShipmentDocument({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${MODULE}presentedDocument/updateAll`, data)
        .then((res) => {
          commit("UPDATE_DOCUMENT_IN_SHIPMENT", data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    addShipmentPrecedingDocument(_, payload) {
      return axios.post(
        `${MODULE}precedingDocument/create?wareId=${payload.ware_id}&sortIndex=${payload.sort_index}`
      );
    },
    deleteShipmentPrecedingDocument(_, payload) {
      const ids = Array.isArray(payload.id) ? payload.id : [payload.id];
      return axios.post(
        `${MODULE}precedingDocument/deleteByIds?wareId=${payload.ware_id}`,
        ids
      );
    },
    addShipmentNewGoodsItem({ commit, state }) {
      const shipment = getShipment(state)

      let index;
      let nextItemPosition;
      let lastElement;

      if (shipment.wares.length !== 0) {
        lastElement = shipment.wares[shipment.wares.length - 1];
        if (!lastElement.sort_index) {
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = shipment.wares.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      return axios
        .post(
          `${MODULE}ware/create?wareShipmentId=${shipment.id}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_SHIPMENT_GOODS_ITEM", {
            item: res.data,
            nextItemPosition,
          });
          return res;
        });
    },
    deleteShipmentGoodsItem({ state, commit }, { id }) {
      const shipmentId = getShipmentId(state)
      return axios
        .post(`${MODULE}ware/deleteByIds?wareShipmentId=${shipmentId}`, [id])
        .then((res) => {
          commit("REPLACE_SHIPMENT_BLOCK", res.data);
          return res;
        });
    },
    updateShipmentGoodsItem({ commit }, data) {
      const payload = Array.isArray(data) ? data : [data];
      return axios.post(`${MODULE}ware/updateAll`, payload).then((res) => {
        commit("UPDATE_SHIPMENT_GOODS", res.data);
        return res;
      });
    },
    deleteDocumentArr({ state, commit }, selected) {
      const id = getShipmentId(state)
      return axios
        .post(
          `${MODULE}presentedDocument/deleteByIds?wareShipmentId=${id}`,
          [...selected]
        )
        .then((res) => {
          commit("REPLACE_SHIPMENT_BLOCK", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    applyToSeveralInShipment({ commit }, { ids, values }) {
      if (!ids) return Promise.reject("Не выбраны товары");
      if (!values || !Object.keys(values).length)
        return Promise.reject("Не выбраны параметры для копирования");
      return axios
        .post(`${MODULE}ware/copyInOtherWares`, { ids, values })
        .then((res) => {
          const wares = res.data?.wares ?? []
          commit("UPDATE_SHIPMENT_GOODS", wares)
          return res;
        });
    },
    applyToAllInShipment({state, commit}, { values }) {
      const id = getShipmentId(state)
      if (!id) return Promise.reject("Не выбран товар");
      if (!values || !Object.keys(values).length)
        return Promise.reject("Не выбраны параметры для копирования");
      return axios
        .post(`${MODULE}ware/copyInOtherWares?wareShipmentId=${id}`, {
          ids: [],
          values,
        })
        .then((res) => {
          const wares = res.data?.wares ?? []
          commit("UPDATE_SHIPMENT_GOODS", wares)
          return res;
        });
    },
    addFromBuffer({state}, {payload}){
      const shipmentId = getShipmentId(state)
      return axios.post(`${MODULE}ware/addFromBuffer?wareShipmentId=${shipmentId}`, payload)
    },
    deleteGoodsAmountInShipment({dispatch, state}, { ids }) {
      const shipmentId = getShipmentId(state)
      return axios.post(`${MODULE}ware/deleteByIds?wareShipmentId=${shipmentId}`, ids).then((res) => {
        dispatch("getDocumentById", state.selected.id)
        return res;
      });
    },
    deleteAllGoods({ state, dispatch }) {
      const id = getShipmentId(state)
      return axios
        .post(`${MODULE}ware/deleteByIds?wareShipmentId=${id}`)
        .then((res) => {
          dispatch("getDocumentById", state.selected.id)
          return res;
        });
    },
    changeSortIndexes({state}, {items}){
      const id = getShipmentId(state)
      return axios.post(`${MODULE}ware/changeSortIndexes?wareShipmentId=${id}`, items)
    },
    sortGoods({ state, commit }, { sortBy = "id" }) {
      const id = getShipmentId(state)
      axios
        .post(`${MODULE}ware/sortWares?wareShipmentId=${id}&sortBy=${sortBy}`)
        .then((res) => {
          commit("REPLACE_SHIPMENT_BLOCK", res.data)
          return res
        })
    },
    copyPrecedingDocumentsToAll({state, commit}, ids) {
      const shipmentId = getShipmentId(state)
      axios.post(`${MODULE}precedingDocument/copyInAllWares?wareShipmentId=${shipmentId}`, ids)
        .then((res) => {
          commit("UPDATE_SHIPMENT_GOODS", res.data)
        })
    },
    // ДОБАВИТЬ ПРЕДШЕВСТВУЮЩИЙ ДОКУМЕНТ
    addPrecedingDocument(_, payload) {
      return axios.post(
        `${MODULE}precedingDocument/create?wareId=${payload.ware_id}&sortIndex=${payload.sort_index}`
      );
    },
    deletePrecedingDocument({state}, payload) {
      const declarationId = state.selected.id;
      return axios.post(
        `${MODULE}precedingDocument/deleteByIds?wareId=${declarationId}`,
        payload
      );
    },
    copyShipmentWare({ commit, state }, { id }) {
      const shipment = getShipment(state)
      const wares = shipment.wares
      let index;
      let nextItemPosition;
      let lastElement;

      if (wares.length !== 0) {
        lastElement = wares[wares.length - 1];
        if (!lastElement.sort_index) {
          console.log("SORT INDEX NOT FOUND");
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = wares.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      if (!id || !index)
        return Promise.reject(new Error("Возникла ошибка при копировании"));
      return axios
        .post(`${MODULE}ware/copyWare?id=${id}&sortIndex=${index}`)
        .then((res) => {
          const item = res.data;
          commit("ADD_SHIPMENT_GOODS_ITEM", { item, nextItemPosition });
          return res;
        });
    },
    downloadExcel({state}, id) {
      const shipmentId = getShipmentId(state)
      return axios.get(`${MODULE}declaration/downloadXlsx?declarationId=${id}&wareShipmentId=${shipmentId}`, {
        responseType: "blob",
      });
    },
  }
}
