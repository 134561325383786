<template>
  <div
    class="pb-16 mx-auto mt-10 px-5"
    style="max-width: 1440px"
  >
    <h2>Заявка на оформление</h2>
    <calculator />
    <application />
  </div>
</template>
<script>
import Calculator from '@/components/auto/calculator/calculator.vue'
import Application from '@/components/auto/application/application.vue'
export default {
  components:{Calculator, Application}
}
</script>
