<template>
  <v-sheet
    elevation="1"
    class="px-4 py-0 mb-3 rounded"
    style="position:relative;"
  >
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="#333"
          dark
        >
          <div class="text-h5">
            Удалить пользователя
          </div>
        </v-toolbar>
        <v-card-text class="pa-6">
          Вы уверены, что хотите удалить пользователя <br>
          <strong>{{ item.email }}</strong>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#333"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="red lighten-2"
            dark
            :loading="load"
            @click="deleteUser"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col lg="3">
        <div class="d-flex align-center">
          <v-avatar
            size="35"
            color="#5cb7b1"
            class="mr-2"
          >
            <div
              v-if="item.first_name && item.last_name"
              class="text-body-1 white--text"
            >
              {{ item.first_name.substr(0, 1) }}{{ item.last_name.substr(0, 1) }}
            </div>
          </v-avatar>
          <div class="text-body-1 font-weight-medium">
            {{ item.last_name }} {{ item.first_name }} <br>
            <div class="text-body-2 grey--text">
              {{ item.email }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="2">
        <div
          v-if="!editMode"
          class="text-body-2 font-weight-bold"
        >
          {{ item.division_name }}
        </div>
        <v-select
          v-else
          v-model="item.divid"
          :items="branchesAlowed"
          solo
          color="#32a1af"
          item-color="#32a1af"
          background-color="grey lighten-3"
          flat
          item-text="division_name"
          item-value="id"
          hide-details="auto"
          @change="getRolesForInvite"
        />
        <div
          v-if="item.phone"
          class="text-body-2 grey--black"
        >
          {{ item.phone }}
        </div>
      </v-col>
      <v-col cols="2">
        <div
          v-if="!editMode"
          class="text-body-2 font-weight-bold"
        >
          {{ item.role_name }}
        </div>
        <v-select
          v-else
          v-model="item.role_id"
          :items="roles"
          solo
          color="#32a1af"
          item-color="#32a1af"
          background-color="grey lighten-3"
          flat
          item-text="role_name"
          item-value="id"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="2">
        <div
          v-if="!item.is_active"
          class="text-center d-flex align-center"
        >
          <v-chip
            v-if="item.is_active == null"
            label
            color="#FFBC58"
          >
            Не пройдена рег-ия
          </v-chip>
          <v-chip
            v-else-if="item.d_off && item.is_active === false"
            label
            color="red"
          >
            Удален
          </v-chip>
          <v-chip
            v-else
            label
            color="#32a1af"
          >
            В паузе
          </v-chip>
        </div>
      </v-col>


      <v-col cols="1">
        <div
          v-if="item.d_off"
          class="text-body-2"
        >
          {{ item.d_off }}
        </div>
      </v-col>


      <v-col
        v-if="!editMode"
        lg="2"
        class="text-right"
      >
        <v-btn
          v-if="!item.d_off"
          color="#eee"
          elevation="0"
          class="mr-2"
          x-small
          fab
          @click="dialog = true"
        >
          <v-icon small>
            mdi-trash-can
          </v-icon>
        </v-btn>
        <v-btn
          v-if="item.is_active"
          color="#eee"
          elevation="0"
          class="mr-2"
          x-small
          fab
          @click="changeActivity"
        >
          <v-icon small>
            mdi-pause
          </v-icon>
        </v-btn>
        <v-btn
          v-if="item.is_active === false && !item.d_off"
          color="#eee"
          elevation="0"
          class="mr-2"
          x-small
          fab
          @click="changeActivity"
        >
          <v-icon small>
            mdi-play
          </v-icon>
        </v-btn>
        <v-btn
          v-if="item.is_active"
          color="#eee"
          elevation="0"
          x-small
          fab
          @click="editMode = !editMode"
        >
          <v-icon small>
            mdi-pencil-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="item.is_active == null"
          color="#eee"
          elevation="0"
          x-small
          fab
          @click="refreshInvite"
        >
          <v-icon small>
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="editMode && !item.d_off"
        lg="3"
        class="pt-5"
      >
        <v-btn
          small
          text
          class="mr-2"
          @click="cancelUpdate"
        >
          Отмена
        </v-btn>
        <v-btn
          class="elevation-0"
          small
          dark
          color="#32a1af"
          @click="updateUser"
        >
          Сохранить
        </v-btn>
      </v-col>

      <!-- snackbar -->
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
      >
        <span><v-icon
          class="mr-1"
          color="#32a1af"
          small
        >mdi-check</v-icon> Пользователь приглашен</span>
        <template #action="{ attrs }">
          <v-btn
            color="#32a1af"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Ок
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import {AUTH} from '@/http-routes.js'
import formatDate from '@/mixins/format-date.js'
export default {
  mixins: [formatDate],
  props: {
    item: {
      type: Object,
      required: true
    },
    branchesAlowed: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    snackbar: false,
    roles: [],
    dialog: false,
    editMode: false,
    load: false
  }),
  computed: {
    ...mapGetters({
      dvsnUsers: 'auth/getDivisionUsers',
      user: "auth/getUser",
      dvsn: 'auth/getSingleDivision',
    })
  },
  created() {
    this.getRolesForInvite();
  },
  methods: {
    refreshInvite() {
      const invite = {
        email: this.item.email,
        branch: this.item.divid,
        role: this.item.role_id
      }
      this.$store.dispatch('auth/inviteUser', invite).then(() => {
        this.snackbar = true
      })
    },
    deleteUser() {
      this.load = true
      axios
        .post(`${AUTH}user/deleteUserFromDivision?otherUserId=${this.item.user_id}&divisionId=${this.item.divid}`)
        .then(() => {
          this.dialog = false
          this.$store.dispatch('auth/loadUsers', this.user.user.id)
        }).catch((err) => this.$error(err.response.data || "Ошибка удаления пользователя"))
        .finally(() => {
          this.load = false
        })
    },
    changeActivity() {
      this.item.is_active = !this.item.is_active
      this.$store.dispatch('auth/updateDivisionUser', this.item)
    },
    cancelUpdate() {
      this.editMode = false
      this.$store.dispatch('auth/loadUsers', this.user.user.id)
    },
    updateUser() {
      this.$store.dispatch('auth/updateDivisionUser', this.item).then(() => {
        this.editMode = false
      })
    },
    getRolesForInvite() {
      axios
        .get(`${AUTH}role/getRolesForInvite?userId=${this.user.user.id}&divisionId=${this.item.divid}`)
        .then(res => {
          this.roles = res.data
        })
    }
  },

}
</script>
