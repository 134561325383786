import moment from "moment";

export default {
  methods:{
    formatDate(d) {
      if(!d){
        return ''
      }
      return moment(d).format('DD.MM.YYYY')
    },
    formatUpdatedDate(d){
      if(!d){
        return ''
      }
      return moment(d).format('DD.MM.YYYY HH:mm:ss')
    },
    formatReleaseYear(d){
      if(!d){
        return ''
      }
      return moment(d).format('YYYY')
    }
  }
}

