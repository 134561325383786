<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg my-5"
  >
    <v-card-title>Предшествующие документы</v-card-title>
    <v-col>
      <previous-document />
    </v-col>
  </v-card>
</template>
<script>
import PreviousDocument from './previous-document.vue'
export default {
  components:{
    PreviousDocument
  }
}
</script>
