<template>
  <section id="p404">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-center mx-auto">
          <img
            src="@/assets/img/icons/error.svg"
            height="40px"
          >
          <h4
            style="font-size:24px;"
            class="mb-1"
          >
            Ошибка при загрузке кода {{ id }}
          </h4>
          <p class="black-txt">
            Данный код устарел!
          </p>
          <router-link
            tag="a"
            :to="`/tnved/${id.substr(0, 4)}`"
          >
            <v-btn
              color="#5cb7b1"
              dark
            >
              Перейти к коду {{ id.substr(0, 4) }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id:{
      validator: (prop) => typeof prop === "string" || typeof prop === "number" || prop === null,
      default: null,
    }
  }
}
</script>