<template>
  <section id="login">
    <phone-verification
      v-model="enteredCode"
      :show="showVerificationModal"
      :error-text="verificationError"
      :phone="form.phone"
      @confirm="onCodeConfirm"
      @changeNumber="onChangeNumber"
      @retry="sendSmsCode"
    />

    <v-container>
      <v-row>
        <v-col
          lg="6"
          style="margin: 0 auto;"
        >
          <div class="grey-box text-center px-16">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Регистрация
            </div>

            <v-form
              ref="form"
              @submit.prevent="sendSmsCode"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">E-mail</label>
                  <v-text-field
                    v-model="form.email"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :rules="emailRules"
                    placeholder="example@mail.by"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Фамилия</label>
                  <v-text-field
                    v-model="form.lastName"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Имя</label>
                  <v-text-field
                    v-model="form.firstName"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Отчество</label>
                  <v-text-field
                    v-model="form.middleName"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Номер телефона</label>
                  <v-text-field
                    v-model="form.phone"
                    v-mask="'+375#########'"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="loading"
                    block
                  >
                    Подтвердить номер телефона
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <router-link
                    tag="a"
                    to="/login"
                    class="blue-link"
                  >
                    Войти
                  </router-link>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
import PhoneVerification from "@/components/user/phone-verification.vue";
export default {
  components: {PhoneVerification},
  data: () => ({
    showVerificationModal:false,
    verificationError:"",
    loading:false,
    form: {
      email: '',
      lastName: '',
      middleName: '',
      firstName: '',
      phone: '+375',
    },
    enteredCode:null,
    encodedCode:null,
    emailRules: [
      v => !!v || 'Поле должно быть заполнено',
    ]
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  methods: {
    onCodeConfirm(){
      this.register()
    },
    onChangeNumber(){
      this.showVerificationModal = false
      this.enteredCode = null
      this.encodedCode = null
    },
    reset(){
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
      this.form.phone = '+375'
      this.enteredCode = null
      this.encodedCode = null
    },
    register(){
      this.$store.dispatch('auth/registration', {
        ...this.form,
        encodedCode: this.encodedCode,
        enteredCode: this.enteredCode
      }).then(() => {
        this.$snackbar({
          text:'На вашу почту была выслана ссылка для активации аккаунта',
          color:'blue',
          top:false,
          timeout: 10000
        })
        this.reset()
        this.showVerificationModal = false
      }).catch((err) => {
        this.verificationError = this.getErrorMessage(err)
      })
    },
    getErrorMessage(err){
      if(err.response.status === 400){
        return err.response.data.message
      }
      return "Ошибка"
    },
    sendSmsCode(){
      if(this.$refs.form.validate()){
        this.$store.dispatch('auth/sendSmsCode', this.form).then((res) => {
          this.encodedCode = res.data
          this.showVerificationModal = true
        }).catch((err) => this.$error(this.getErrorMessage(err)))
      }
    }
  }
}
</script>
