<template>
  <div>
    <v-menu
      offset-y
      :disabled="item.trash"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          small
          dense
          dark
          class="elevation-0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="openManualDebitModal"
        >
          <v-list-item-title>
            <v-icon>mdi-currency-usd</v-icon> Ручное списание
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="hasDebit"
          @click="openEditModal"
        >
          <v-list-item-title>
            <v-icon>mdi-file-edit-outline</v-icon> Корректировка
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="hasDebit"
          @click="remove(item.id)"
        >
          <v-list-item-title>
            <v-icon>mdi-delete</v-icon> Удалить
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <component
      :is="activeComponent"
      :show.sync="show"
      :params="activeComponentProps"
    />
  </div>
</template>
<script>
import {fetchPaymentOrdersInjector} from "@/helpers/injectors";

export default {
  inject:{
    fetchPaymentOrders:{
      from:fetchPaymentOrdersInjector
    }
  },
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  data(){
    return{
      activeComponent:null,
      activeComponentProps:{
        selectedPaymentOrder:null,
      },
      show:false
    }
  },
  computed:{
    hasDebit(){
      return this.item.remains !== this.item.sum
    }
  },
  methods:{
    mergeActiveComponentProps(props) {
      this.activeComponentProps = {
        ...this.activeComponentProps,
        ...props
      }
    },
    openManualDebitModal(){
      this.mergeActiveComponentProps({selectedPaymentOrder: this.item})
      this.activeComponent = () => import('@/components/payment-orders/manual-debit-modal.vue')
      this.show = true
    },
    openEditModal(){
      this.mergeActiveComponentProps({selectedPaymentOrder: this.item})
      this.activeComponent = () => import('@/components/payment-orders/payment-order-edit.vue')
      this.show = true
    },
    remove(id) {
      this.$store.dispatch('dt/deletePaymentOrder', id)
        .then(() => this.$info("Запись удалена"))
        .then(() => this.fetchPaymentOrders())
        .catch((err) => {
          const msg = err.response.status === 400 ? err.response.data || "Ошибка" : "Ошибка"
          this.$error(msg)
        })
    },
  }
}
</script>
