<template>
  <div class="d-inline">
    <template v-if="drawer">
      <v-list-item
        v-for="(item,idx) in activeUserMenu"
        :key="`user-menu-mobile-${idx}`"
        class="cursor-pointer"
      >
        <v-list-item-title

          @click="callFunction(item.action)"
        >
          <v-icon class="mr-1">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
      <v-divider />
    </template>
    <template v-else>
      <v-menu
        v-if="user"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            class="ml-1"
            color="#F44336"
            size="40"
            v-on="on"
          >
            <v-tooltip
              bottom
              color="white"
            >
              <template #activator="{ on:onTooltip, attrs:attrsTooltip }">
                <strong
                  style="color: #fff"
                  v-bind="attrsTooltip"
                  v-on="onTooltip"
                >
                  {{ initials }}
                </strong>
              </template>

              <span class="black--text">{{ userEmail }}</span>
            </v-tooltip>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item
            v-for="(item,idx) in activeUserMenu"
            :key="`user-menu-${idx}`"
            class="cursor-pointer"
          >
            <v-list-item-title @click="callFunction(item.action)">
              <v-icon class="mr-1">
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script>
import {callFunction} from '@/helpers/common'
import {mapGetters} from 'vuex'
import {SIGN_OUT_EVENT} from "@/helpers/broadcast";
export default {
  props:{
    drawer:{
      type:Boolean,
      required:false,
      default:false
    }
  },
  data:() => ({
    userMenu:[
      {
        icon:"mdi-account",
        name:"Персональные данные",
        action:"toUser",
      },
      {
        icon:"mdi-lock-reset",
        name:"Изменить пароль",
        action:"changePassword",
      },
      {
        icon:"mdi-logout",
        name:"Выйти",
        action:"logout",
      },
    ],
  }),
  computed:{
    ...mapGetters({
      user: "auth/getUser",
      divisions: "auth/getDivisions",
    }),
    activeUserMenu(){
      return this.userMenu.filter(i => {
        if(i?.permissions?.length){
          const allowed = i.permissions.map(permission => this.$can(permission))
          if(allowed.includes(false)) return false
        }
        if(i.action === 'goToUser'){
          return !!(this.user && this.divisions.length)
        }
        return true
      })
    },
    initials(){
      return [this.userFirstName.substr(0, 1), this.userLastName.substr(0, 1)].join('')
    },
    userEmail() {
      return this.user?.user?.email || "";
    },
    userFirstName() {
      return this.user?.user?.firstName || ""
    },
    userLastName() {
      return this.user?.user?.lastName || ""
    },
  },
  methods:{
    callFunction,
    toUser(){
      this.$router.push({path:'/user'})
    },
    changePassword(){
      this.$router.push({name:"UserChangePassword"})
    },
    notifyOtherTabs() {
      this.$broadcastChannel.postMessage({
        type:SIGN_OUT_EVENT
      })
    },
    logout() {
      this.$store.dispatch("auth/SIGN_OUT").then(() => {
        this.$store.commit('experiment/CLEAR_STATE')
        this.$router.replace("/login");
        this.notifyOtherTabs()
      });
    },
  },

}
</script>
