<template>
  <v-col cols="12">
    <v-text-field
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="() => (searchValue = '')"
    />
    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th>Код ISO</th>
          <th>Страна (код)</th>
          <th>Название</th>
          <th>Теги</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in filtered"
          :key="'type-item-' + idx"
        >
          <td
            width="10%"
            @click="select(item.code)"
          >
            <text-highlight
              :queries="searchValue || ''"
              class="code-txt"
            >
              {{ item.code }}
            </text-highlight>
          </td>
          <td width="10%">
            <div class="mt-1">
              <text-highlight :queries="searchValue || ''">
                {{ item.countryDigit }}
              </text-highlight>
            </div>
          </td>
          <td width="50%">
            <div class="light-txt mt-1">
              <text-highlight :queries="searchValue || ''">
                {{ item.name }}
              </text-highlight>
            </div>
          </td>
          <td width="30%">
            <div class="light-txt mt-1">
              <text-highlight :queries="searchValue || ''">
                {{ item.ownName }}
              </text-highlight>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
    filter:{
      type:Object,
      default:null
    }
  },
  data: () => ({
    searchValue: "",
  }),
  computed: {
    ...mapGetters({
      regions: "catalogs/getNsiRegions",
    }),
    filtered() {
      let filtered = this.regions;
      if(this.filter && Object.keys(this.filter).length){
        Object.keys(this.filter).forEach(key => {
          if(!([null, ''].includes(this.filter[key]))){ // Если не пустое значение/null
            filtered = filtered.filter(i => i[key] === this.filter[key])
          }
        })
      }
      if (this.searchValue !== null && this.searchValue.length) {
        filtered = filtered.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }

      return filtered;
    },
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true,
    },
  },
  created() {
    if (!this.regions.length) {
      this.$store.dispatch("catalogs/fetchNsiRegions");
    }
  },
  methods: {
    select(code) {
      this.$emit("select", code);
    },
  },
};
</script>