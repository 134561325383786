<template>
  <div>
    <v-btn
      class="elevation-0 white--text"
      color="#5CB7B1"
      @click="openCreateModal"
    >
      <v-icon>mdi-plus</v-icon> Создать
    </v-btn>
    <component
      :is="activeComponent"
      :show.sync="show"
    />
  </div>
</template>
<script>
export default {
  data(){
    return {
      activeComponent:null,
      show:false,
    }
  },
  methods:{
    openCreateModal(){
      this.activeComponent = () => import('@/components/payment-orders/payment-order-create.vue')
      this.show = true
    }
  }
}
</script>
