<template>
  <div class="tnved-slide">
    <div class="tnved-slide-box">
      <div class="tnved-slide-box-top d-flex justify-space-between align-center">
        <h3>Пояснения</h3>
        <div class="body-1 white--text font-weight-medium">
          Найдено:
        </div>
      </div>

      <div
        v-if="explanations && explanations.tnVedNotes.length"
        class="tnved-slide-box-body dark-h3"
      >
        <div
          v-for="(item, index) in explanations.tnVedNotes"
          :key="index"
          class="mb-16"
        >
          <div
            class="mb-3 font-weight-bold"
            color="#000"
            v-html="item.title"
          />
          <div
            class="code-txt mb-4"
            v-html="item.shortDescription"
          />
          <!-- <div class="light-txt mb-4 font-weight-medium" v-html="item.codeNote"></div> -->
          <div
            v-if="item.unwrap"
            class="light-txt mb-4"
            v-html="item.description"
          />

          <v-btn
            v-if="item.description !== ''"
            x-small
            color="grey lighten-3 pa-4"
            @click="item.unwrap = !item.unwrap"
          >
            <span v-if="!item.unwrap">Развернуть описание <v-icon color="#000">mdi-chevron-down</v-icon></span>
            <span v-else>Скрыть описание <v-icon color="#000">mdi-chevron-up</v-icon></span>
          </v-btn>
        </div>
      </div>

      <div
        v-else
        class="tnved-slide-box-body"
      >
        <p class="grey-txt   ">
          Пояснения отсутствуют
        </p>
      </div>

      <!-- <pre>{{explanations}}</pre> -->
    </div>
  </div>
</template>

<script>

export default {
  props: {
    explanations: {
      required: false,
      type: Object,
      default:null
    }
  },
}
</script>

<style scoped>
.code-txt:hover {
    text-decoration: none;
    color: #333;
}
.light-txt{
    width: 100% !important;
}
</style>

