import axios from "axios";

export default function (module) {
  const MODULE = module;
  return {
    createWareDetails(_, {wareId, sortIndex}){
      return axios.post(`${MODULE}wareDetails/create?wareId=${wareId}&sortIndex=${sortIndex}`)
    },
    updateWareDetails(_, wareDetails){
      const payload = Array.isArray(wareDetails) ? wareDetails : [wareDetails]
      return axios.post(`${MODULE}wareDetails/updateAll`, payload)
    },
    deleteWareDetails(_, { ids, wareId }) {
      return axios.post(`${MODULE}wareDetails/deleteByIds?wareId=${wareId}`, ids)
    },
    getWareDetails(_, {wareId}){
      return axios.get(`${MODULE}wareDetails/getByWareId?wareId=${wareId}`)
    },
    moveWareDetails(_, {wareId, wareDetails}){
      const payload = Array.isArray(wareDetails) ? wareDetails : [wareDetails]
      return axios.post(`${MODULE}wareDetails/moveToOtherWare?wareId=${wareId}`, payload)
    },
    sortWareDetails(_, {wareId, sortBy}){
      return axios.post(`${MODULE}wareDetails/sortWareDetails?sortBy=${sortBy}&wareId=${wareId}`)
    },
    copyInOtherWareDetails(_, payload){
      return axios.post(`${MODULE}wareDetails/copyInOtherWareDetails`,payload)
    },
    createWoodDetails(_, {wareId, sortIndex}){
      return axios.post(`${MODULE}woodDetails/create?wareId=${wareId}&sortIndex=${sortIndex}`)
    },
    updateWoodDetails(_, woodDetails){
      const payload = Array.isArray(woodDetails) ? woodDetails : [woodDetails]
      return axios.post(`${MODULE}woodDetails/updateAll`, payload)
    },
    deleteWoodDetails(_, {id}){
      return axios.post(`${MODULE}woodDetails/deleteById?id=${id}`)
    },
    getWoodDetails(_, {wareId}){
      return axios.get(`${MODULE}woodDetails/getByWareId?wareId=${wareId}`)
    },

    createTransportDetails(_, {wareId, sortIndex}){
      return axios.post(`${MODULE}wareTransport/create?wareId=${wareId}&sortIndex=${sortIndex}`)
    },
    updateTransportDetails(_, woodDetails){
      const payload = Array.isArray(woodDetails) ? woodDetails : [woodDetails]
      return axios.post(`${MODULE}wareTransport/updateAll`, payload)
    },
    deleteTransportDetails(_, {id}){
      return axios.post(`${MODULE}wareTransport/deleteById?id=${id}`)
    },
    getTransportDetails(_, {wareId}){
      return axios.get(`${MODULE}wareTransport/getByWareId?wareId=${wareId}`)
    },

  }
}