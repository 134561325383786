
export const KG_DIGIT = '166'
function getCountryCodeByName(catalog, name) {
  return getFieldFromCatalogElement(catalog, name, "name", "letterCode")
}

function getVehicleTypeNameByCode(catalog, code) {
  return getFieldFromCatalogElement(catalog, code , "code", "name")
}

function getCustomsNameByCode(catalog, code) {
  return getFieldFromCatalogElement(catalog, code, "code", "name")
}

function getCountryNameByCode(catalog, code) {
  return getFieldFromCatalogElement(catalog, code, "letterCode", "name")
}

function getIdentityCodeByName(catalog, name){
  return getFieldFromCatalogElement(catalog, name, "name", "code")
}

function getIdentityNameByCode(catalog, code){
  return getFieldFromCatalogElement(catalog, code, "code", "name")
}

function getCountryDigitCodeByLetterCode(catalog, code) {
  return getFieldFromCatalogElement(catalog, code, "letterCode", "digitCode")
}

function getMeasureUnitShortName(catalog, code) {
  return getFieldFromCatalogElement(catalog, code, "code", "shortDescription")
}

function getTransportCodeByName(catalog, name){
  return getFieldFromCatalogElement(catalog, name, "name", "code")
}

function getTransportMarkNameByCode(catalog, code) {
  return getFieldFromCatalogElement(catalog, code, "code", "name")
}


function getFieldFromCatalogElement(catalog = [], searchValue, searchKey, returnKey ){
  if (Array.isArray(catalog) && catalog.length && searchValue) {
    const searchResult = catalog.find(catalogElement => {
      return searchKey in catalogElement ? catalogElement[searchKey] === searchValue : false
    })
    return searchResult && returnKey in searchResult ? searchResult[returnKey]: ""
  }
  return ""
}


function isSanctionedProductCode(code, sanctionedCodes = []){
  if(!code) return false;
  return sanctionedCodes.find(sanctionedCode => code.startsWith(sanctionedCode))
}

function findSanctionedProductByTag(searchIn, sanctionedProducts = []){
  if(!searchIn) return;
  const searchInLower = searchIn.toString().toLowerCase()
  return sanctionedProducts.find(sanctionedProduct => {
    return sanctionedProduct.tags.some(tag => searchInLower.includes(tag))
  })
}

function getSanctionedProductsByCode(code, sanctionedProducts = []){
  if(!code) return [];
  return sanctionedProducts.filter(sanctionedProduct => sanctionedProduct.tnVedArray.includes(code))
}

function getDivisionResidentStructure({resident, divisionId, alias = "", docNum ="", docDate = ""}){
  const {
    subject_name,
    subject_person_name,
    subject_unp,
    subject_branch_unp,
    subject_branch_name,
    subject_json:{
      country_letter,
      country_name,
      region,
      city ,
      street_house,
      postal_index,
      phone,
      branch_country_name,
      branch_country_letter,
      branch_postal_index,
      branch_region,
      branch_city,
      branch_street_house,
    } = {}
  } = resident
  return {
    id:null,
    own_name: alias,
    division_id:divisionId,
    name:subject_name,
    person_name: subject_person_name,
    unp: subject_unp,
    country_letter:country_letter,
    country_name: country_name,
    region: region,
    city: city,
    street: street_house,
    house: "",
    room: "",
    postal_index: postal_index,
    contacts: [
      {
        code: "TE",
        name: "ТЕЛЕФОН",
        contact:phone
      }
    ],
    branch_name:  subject_branch_name,
    branch_unp: subject_branch_unp,
    branch_country_letter: branch_country_letter,
    branch_country_name:  branch_country_name,
    branch_region:  branch_region,
    branch_city:  branch_city,
    branch_street:  branch_street_house,
    branch_house: "",
    branch_room: "",
    branch_postal_index:  branch_postal_index,
    branch_contacts: [],
    subject_doc_num:docNum,
    subject_doc_date:docDate,

  }
}

function getSubjectFromResident({item, declaration_id}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_unp
  } = item;
  const [contactData] = contacts;
  const { contact } = contactData ?? {};
  const subject = {
    declaration_id: declaration_id,
    subject_unp: unp,
    name: name || person_name,
    subject_branch_unp: branch_unp,
    subject_branch_name: branch_name,
  };
  const subject_json = {
    country_letter: country_letter,
    postal_index: postal_index,
    region: region,
    city: city,
    street_house: [street, house, room].join(" ").trim(),
    phone: contact,
    customs_code: "",
    branch_country_letter: branch_country_letter,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_city: branch_city,
    branch_street_house: branch_street,
  };
  return {subject, subject_json}
}

function getDeclarantFromResident({item, declaration_id}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const declarant = {
    declaration_id: declaration_id,
    declarant_last_name: person_name,
    declarant_middle_name: '',
    declarant_name: name,
    declarant_branch_inn: branch_unp,
    declarant_branch_name: branch_name,
    declarant_first_name: '',
    declarant_identity_doc_number: subject_doc_num,
    declarant_inn: unp
  }
  const declarant_json = {
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { declarant, declarant_json }
}


function getDtsDeclarantFromResident({item}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const declarant_json = {
    last_name: person_name,
    middle_name: '',
    name: name,
    branch_inn: branch_unp,
    branch_name: branch_name,
    first_name: '',
    identity_doc_number: subject_doc_num,
    inn: unp,
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { declarant_json }
}


function getSenderFromResident({item, declaration_id}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const sender = {
    declaration_id: declaration_id,
    sender_last_name: person_name,
    sender_middle_name: '',
    sender_name: name,
    sender_branch_inn: branch_unp,
    sender_branch_name: branch_name,
    sender_first_name: '',
    sender_identity_doc_number: subject_doc_num,
    sender_inn: unp
  }
  const sender_json = {
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { sender, sender_json }
}

function getSellerFromResident({item}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const seller_json = {
    last_name: person_name,
    middle_name: '',
    name: name,
    branch_inn: branch_unp,
    branch_name: branch_name,
    first_name: '',
    identity_doc_number: subject_doc_num,
    inn: unp,
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { seller_json }
}


function getRecipientFromResident({item, declaration_id}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const recipient = {
    declaration_id: declaration_id,
    recipient_last_name: person_name,
    recipient_middle_name: '',
    recipient_name: name,
    recipient_branch_inn: branch_unp,
    recipient_branch_name: branch_name,
    recipient_first_name: '',
    recipient_identity_doc_number: subject_doc_num,
    recipient_inn: unp
  }
  const recipient_json = {
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { recipient, recipient_json }
}


function getBuyerFromResident({item}){
  const {
    city,
    country_letter,
    house,
    name,
    person_name,
    postal_index,
    region,
    room,
    street,
    unp,
    contacts,
    branch_city,
    branch_country_letter,
    branch_name,
    branch_postal_index,
    branch_region,
    branch_street,
    branch_room,
    branch_unp,
    branch_house,
    subject_doc_num
  } = item;

  const buyer_json = {
    last_name: person_name,
    middle_name: '',
    name: name,
    branch_inn: branch_unp,
    branch_name: branch_name,
    first_name: '',
    recipient_identity_doc_number: subject_doc_num,
    recipient_inn: unp,
    aeo_country_letter: "",
    aeo_reg_number: "",
    branch_city: branch_city,
    branch_country_letter: branch_country_letter,
    branch_house: branch_house,
    branch_postal_index: branch_postal_index,
    branch_region: branch_region,
    branch_room: branch_room,
    branch_street_house: branch_street,
    city: city,
    contacts: contacts,
    country_letter: country_letter,
    house: house,
    identity_doc_date: "",
    personal_number: "",
    postal_index: postal_index,
    region: region,
    room: room,
    street_house: street
  }
  return { buyer_json }
}


function getDivisionNoResidentStructure({noResident, divisionId, alias = ""}){
  const {counter_agent_person_name, counter_agent_name,
    counter_agent_inn, 
    counter_agent_json:{
      country_letter, country_name, region, city, street_house, postal_index, phone
    } = {}
  } = noResident
  return {
    id: null,
    own_name:alias,
    division_id: divisionId,
    name: counter_agent_name,
    person_name: counter_agent_person_name,
    inn: counter_agent_inn,
    country_letter: country_letter,
    country_name: country_name,
    region: region,
    city: city,
    street: street_house,
    house: null,
    room: null,
    postal_index: postal_index,   
    contacts: [
      {
        code: "TE",
        name: "ТЕЛЕФОН",
        contact:phone
      }
    ],
    branch_name:null,
    branch_inn: null,
    branch_country_letter: null,
    branch_country_name:  null,
    branch_region:  null,
    branch_city:  null,
    branch_street:  null,
    branch_house: null,
    branch_room: null,
    branch_postal_index:  null,
    branch_contacts: []
  }
}

function getContragentFromNoResident({item, declaration_id}){

  const {inn, name, person_name, contacts, street, country_letter, postal_index, region, city} = item
  const [contactData] = contacts;
  const { contact } = contactData ?? {};
  const counter_agent = { 
    declaration_id:declaration_id, 
    counter_agent_inn: inn,
    name: name || person_name,
  }
  const counter_agent_json = {
    country_letter:country_letter,
    postal_index: postal_index,
    region:region,
    city:city,
    street_house: street,
    phone:contact
  }
  return {counter_agent, counter_agent_json}

}

// При смене страны изменяет регион по условию
// countries - справочник стран
// regions -  справочник регионов

function unsetRegion(type, rootObject = 'item'){
  const digit = getCountryDigitCodeByLetterCode(this.countries, this[rootObject][`${type}_country_letter`])

  const {countryDigit = null} = this.regions.find(
    (i) => i.code === this[rootObject][`${type}_country_region`]
  ) || {}

  if(digit !== countryDigit){
    ['KZ', 'UA', 'RU', '', null].includes(this[rootObject][`${type}_country_letter`])?
      this[rootObject][`${type}_country_region`] = null :
      this[rootObject][`${type}_country_region`] = "000"
  }
}
function capitalize(str){
  return str && typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1): str
}
// Рекурсивно форматирует справочник доп кодов ТНВЭД
// data - источник данных
// fullCode - склееный код
// fullName - склееное имя
// depth - итерация
// withIncluding - параметр которые позволяет убирать оставлять иконки "включения" на всей вложенности дерева
function normalize({data, fullCode, fullName, depth = 0, withIncluding = false}){ 
  if(Array.isArray(data)){
    const deep = depth + 1
    return data.map(item => normalize({data:item, fullCode, fullName, depth:deep , withIncluding}))
  }else if(typeof data === 'object' && data !== null && !Array.isArray(data)){
    const obj = {}
    if(data.children.length === 1 && data.children[0].code === '0'){ // Если дальше нули то убираем children   
      Object.keys(data).forEach(key => {
        key !== 'children' ? obj[key] = data[key]: false
      })
      obj.fullCode = fullCode ? [fullCode,data.code].join('').padEnd(4, '0') : data.code.padEnd(4, '0')
    }else{ // Потомки рекурсивно перебираем, остальные свойства копируем
      Object.keys(data).forEach(key => {
        obj[key] = key === 'children' ?
          normalize({
            data:data[key],
            depth,
            withIncluding,
            fullName: fullName ? [fullName, capitalize(data.name)].join('. ') : capitalize(data.name), 
            fullCode: fullCode ? [fullCode, data.code].join('') : data.code
          }) :
          data[key]         
      })
      obj.fullCode = fullCode ? [fullCode, data.code].join('') : data.code // Объединяем значения для получения кода
    }
    if(obj.name){ // Значением может быть пустым, поэтому проверяем его наличие
      obj.name = capitalize(obj.name)
    }
    obj.fullName = fullName ? [fullName, capitalize(data.name)].join('. ') : capitalize(data.name)// Объединяем значения для получения кода
    obj.depth = depth // Устанавливаем глубину вложенности
    obj.including = obj.depth > 1 && !withIncluding ? null : obj.including // Показываем 'включение' в списки только на верхнем уровне
    obj.key = [obj.fullCode,obj.depth, obj.name].join('-') // Делаем ключ с использванием наименования тк коды дублируются
    return obj
  }  
}

function openCatalogInNewTab(name, type){
  if(!name) return
  let path = `/catalogs?list=${name}`
  if(type) path += `&type=${type}`
  const route = this.$router.resolve({ path });
  window.open(route.href, "_blank");
}

export { 
  getCountryCodeByName,
  getCountryNameByCode,
  getIdentityCodeByName,
  getMeasureUnitShortName,
  getCountryDigitCodeByLetterCode,
  getDivisionResidentStructure,
  getSubjectFromResident,
  getDeclarantFromResident,
  getSenderFromResident,
  getRecipientFromResident,
  getDivisionNoResidentStructure,
  getContragentFromNoResident,
  unsetRegion,
  normalize,
  getTransportCodeByName,
  getIdentityNameByCode,
  getCustomsNameByCode,
  getTransportMarkNameByCode,
  isSanctionedProductCode,
  findSanctionedProductByTag,
  getSanctionedProductsByCode,
  getVehicleTypeNameByCode,
  getBuyerFromResident,
  getSellerFromResident,
  getDtsDeclarantFromResident,
  openCatalogInNewTab
};
