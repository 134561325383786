<template>
  <section id="login">
    <v-container>
      <v-row>
        <v-col
          lg="5"
          style="margin: 0 auto;"
        >
          <div class="grey-box text-center px-16">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Восстановить пароль
            </div>

            <v-form
              ref="recovery"
              @submit.prevent="submit"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label>Email</label>
                  <v-text-field
                    v-model="email"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="loading"
                    block
                  >
                    Получить ссылку
                  </v-btn>
                  <div
                    v-if="errorMessage.length"
                    class="red--text mt-2"
                  >
                    {{ errorMessage }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <div class="d-flex justify-space-between">
                    <router-link
                      tag="a"
                      to="/login"
                      class="blue-link"
                    >
                      Вход
                    </router-link>
                    <router-link
                      tag="a"
                      to="/registration"
                      class="blue-link"
                    >
                      Регистрация
                    </router-link>
                  </div>
                </v-col>
              </v-row>	
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>


export default{
  data:() => ({
    email:"",
    errorMessage:"",
    loading:false
  }),
  methods: {
    getErrorMessage(err){
      const defaultMessage = "Ошибка"
      if(err.response.status === 400){
        return err?.response?.data?.message ?? defaultMessage
      }
      return defaultMessage
    },
    submit(){
      this.errorMessage = ''
      this.loading = true
      this.$store.dispatch('auth/sendResetPasswordLink', this.email)
        .then(() => {
          this.email = ""
          this.$info("Ссылка для восстановления пароля выслана на почту")
        }).catch((err) => {
          this.errorMessage = this.getErrorMessage(err)
        }).finally(() => this.loading = false)
    }
  },
}
</script>