<template>
  <section id="login">
    <div class="bg-big" />
    <!-- <div class="container-fluid">
			<div class="col-lg-12">
				<div class="log-box text-center">
					<img src="../assets/img/small-logo.svg" alt="">
					<div class="text-h4 font-weight-bold">Войти</div>


					<form @submit.prevent="logIn" class="login-form text-left">
						<label for="logform">Логин</label>
						<input type="text" name="logform" placeholder="Введите логин" v-model="login">
						<label for="passform">Пароль</label>
						<input type="password" name="passform" placeholder="Введите пароль" v-model="password">
						<v-progress-circular indeterminate color="#5CB7B1" v-if="load" class="w-100 d-block mx-auto my-2"></v-progress-circular>
						<button class="subm-btn" v-else>Войти</button>
						<div class="d-flex justify-space-between">
							<router-link tag="a" to="/registration" class="blue-link">Регистрация</router-link>
							<router-link tag="a" to="/recovery" class="blue-link">Забыли пароль?</router-link>
						</div>
					</form>
				</div>
			</div>
		</div> -->
    <v-container>
      <v-row>
        <v-col
          lg="4"
          offset="8"
        >
          <div class="text-center">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Войти
            </div>

            <v-form
              ref="form"
              @submit.prevent="logIn"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Логин</label>
                  <v-text-field
                    v-model="login"
                    color="#32a1af"
                    background-color="#EDEDED"
                    solo
                    hide-details="auto"
                    flat
                    placeholder="Введите логин"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Пароль</label>
                  <v-text-field
                    v-model="password"
                    color="#32a1af"
                    background-color="#EDEDED"
                    solo
                    hide-details="auto"
                    flat
                    placeholder="Введите пароль"
                    :append-icon="hValue ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hValue ? 'password' : 'text'"
                    @click:append="() => (hValue = !hValue)"
                  />
                </v-col>
                <v-col
                  v-if="capsAlert"
                  cols="12"
                  lg="12"
                >
                  <v-alert
                    type="error"
                    class="mb-0"
                  >
                    Внимание, включен Caps-Lock!
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center mt-2"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="load"
                    style="width:100%;"
                  >
                    Войти
                  </v-btn>

                  <div
                    v-if="errTxt !== '' "
                    class="red--text mt-2"
                  >
                    {{ errTxt }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <div class="d-flex justify-space-between">
                    <router-link
                      tag="a"
                      to="/registration"
                      class="blue-link"
                    >
                      Регистрация
                    </router-link>
                    <router-link
                      tag="a"
                      to="/recovery"
                      class="blue-link"
                    >
                      Забыли пароль?
                    </router-link>
                  </div>
                </v-col>
              </v-row>	
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// import servBox1 from '../components/ui/servBox1.vue'
// import infoBox from '../components/ui/infoBox.vue'
import {mapGetters, mapActions} from 'vuex'

export default{
  data:() => ({
    errTxt: '',
    capsAlert: false,
    hValue: String,
    load: false,
    login: '',
    password: '',
    ecp: false,
    
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      divisions: 'auth/getDivisions',
    })
  },
  mounted(){
    document.addEventListener('keyup', (e) => {
      if (e.getModifierState('CapsLock')) {
        this.capsAlert = true
      } else {
        this.capsAlert = false
      }
    });
  },
  methods: {
    changeEcp(param){
      this.ecp = param
    },
    ...mapActions({
      AUTH_REQUEST: "auth/AUTH_REQUEST",
    }),
    logIn(){
      this.errTxt = ''
      this.load = true
      let form = {
        email: this.login,
        password: this.password,
      }

      this.AUTH_REQUEST(form).then((res) => {
        this.errTxt = res
        this.load = false
        if(!this.user){
          return
        }else{
          if(!this.divisions.length){
            this.$router.replace("/");
          }else{
            this.$router.replace("/new-division");
          }
        }
      });
    }
  },
}
</script>

<style scoped>
#login{
    position: relative;
}
.bg-big{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-image: url('https://images.unsplash.com/photo-1580213845113-a44868f9d8d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>