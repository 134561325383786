<template>
  <v-overlay value="true">
    <v-card
      height="250"
      width="500"
      class="reload"
    >
      <div class="reload__content">
        <p class="reload__title">
          Обновление ПО
        </p>
   
        <div class="reload__info">
          <p>Закройте все вкладки с приложением для завершения обновления.</p>
        </div>
        <div class="reload__detail">
          <img
            src="@/assets/img/warning.svg"
            height="100px"
            alt=""
          >
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
export default {

}
</script>
<style scoped>
.reload__content{
  padding: 20px;
}
.reload__detail{
  display: flex;
  justify-content: center;
  align-items: center;
}
.reload__title{
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.reload__info{
  padding: 0 10px;
  text-align: center;
}
</style>