import Personal from "@/views/user/personal.vue";
import Registration from "@/views/user/registration.vue";
import Login from "@/views/user/login.vue";
import Recovery from "@/views/user/recovery.vue";
import ResetPass from "@/views/user/reset-pass.vue";
import Login2 from "@/views/user/login2.vue";
import ActivateAccount from "@/views/user/activate-account.vue";
import ResetPassword from "@/views/user/reset-password.vue";


const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/login2",
    component: Login2,
  },
  {
    path: "/recovery",
    component: Recovery,
  },
  {
    path: "/reset-password=:id",
    component: ResetPass,
    props: true,
  },
  {
    path: "/registration",
    component: Registration,
  },
  {
    path: "/user/activation",
    component: ActivateAccount,
    props: true,
  },
  {
    path: "/user/resetPassword",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/user",
    component: Personal,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/user/change-password",
    name: "UserChangePassword",
    component: () => import('@/views/user/change-password.vue'),
    meta:{
      requiresAuth:true
    }
  },
]

export default routes
