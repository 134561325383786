<template>
  <section
    id="rega"
    class="py-15"
  >
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="grey-box text-center">
            <img
              src="../assets/img/small-logo.svg"
              alt=""
            >
            <div class="text-h4 font-weight-bold">
              Регистрация
            </div>
            <div class="text-body-1 grey--text mb-8">
              Для регистрации заполните следующие поля
            </div>

            <v-sheet class="d-flex align-center justify-space-around rounded pa-2">
              <div
                v-for="(item, index) in steps"
                :key="index"
                class="text-center"
              >
                <v-btn
                  class="white--text mr-3 mb-3"
                  fab
                  dark
                  x-small
                  :color="actColor(index)"
                >
                  {{ index+1 }}
                </v-btn>
                <div class="text-body-2">
                  {{ item }}
                </div>
              </div>
            </v-sheet>

            <keep-alive>
              <v-sheet
                v-if="activeStep == 0"
                class="py-10 text-left"
                color="transparent"
              >
                <v-form
                  ref="step1"
                  @submit.prevent="goStep2"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <label for="">УНП</label>
                      <v-text-field
                        v-model="taxPayerNum"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        :rules="unpRules"
                        class="mb-5"
                        :error-messages="unpErr ? [`${unpErr}`] : []"
                        @input="unpErr = ''"
                      />

                      <v-sheet
                        color="#fff"
                        rounded
                        class="pa-3 mb-5"
                      >
                        <v-radio-group
                          v-model="license"
                          row
                          label="Являетесь ли Вы таможенный представителем?"
                          @change="changeLicense"
                        >
                          <v-radio
                            label="Да"
                            color="#32a1af"
                            :value="true"
                          />
                          <v-radio
                            label="Нет"
                            color="#32a1af"
                            :value="false"
                          />
                        </v-radio-group>
                      </v-sheet>

                      <v-checkbox
                        v-model="individualPerson"
                        label="Индивидуальный предприниматель"
                        color="#333"
                        hide-details
                      />

                      <!-- <v-checkbox v-model="foreignDivivsion" label="Иностранная организация"
                                                color="#333" hide-details></v-checkbox> -->
                    </v-col>
                    <v-col
                      cols="12"
                      lg="12"
                      class="text-center mt-8"
                    >
                      <v-btn
                        type="submit"
                        color="#333"
                        dark
                        x-large
                        :loading="load"
                      >
                        Далее
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>

              <!-- Step 2 -->



              <v-sheet
                v-if="activeStep == 1"
                class="py-10 text-left"
                color="transparent"
              >
                <v-form
                  ref="step2"
                  @submit.prevent="goStep3"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      lg="8"
                    >
                      <label
                        v-if="individualPerson"
                        for=""
                      >Индивидуальный предприниматель</label>
                      <label
                        v-else
                        for=""
                      >Наименование организации</label>

                      <v-text-field
                        v-model="divisionName"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        :rules="inputRules"
                      />
                    </v-col>

                    <v-col
                      v-if="license"
                      cols="12"
                      lg="4"
                    >
                      <label for="">Номер лицензии таможенного агентства</label>
                      <v-text-field
                        v-model="custAgentDocNum"
                        v-mask="'ТА-####/#######'"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        :rules="licenseRules"
                        placeholder="ТА-XXXX/XXXXXXX"
                      />
                    </v-col>
                    <v-col
                      v-else
                      cols="12"
                      lg="4"
                    />

                    <v-col
                      cols="12"
                      lg="3"
                    >
                      <label for="">Страна регистрации</label>
                      <v-text-field
                        v-model="countryName"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        :rules="inputRules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="3"
                    >
                      <label for="">Почтовый индекс</label>
                      <v-text-field
                        v-model="postalIndex"
                        color="#32a1af"
                        background-color="#fff"
                        solo
                        hide-details="auto"
                        flat
                        :rules="inputRules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="12"
                    >
                      <label for="">Адрес (регион, город, номер дома, номер помещения)</label>
                      <v-textarea
                        v-model="fullAddress"
                        solo
                        flat
                        color="#32a1af"
                        background-color="#fff"
                        hide-details="auto"
                        no-resize
                        :rules="inputRules"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      lg="12"
                      class="text-center mt-8"
                    >
                      <v-btn
                        color="#333"
                        dark
                        x-large
                        class="mr-15"
                        @click="activeStep-=1"
                      >
                        Назад
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="#333"
                        dark
                        x-large
                        :loading="load"
                      >
                        Далее
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>






              <v-sheet
                v-if="activeStep == 2"
                class="py-10 text-left"
                color="transparent"
              >
                <v-form ref="step3">
                  <v-row>
                    <v-col
                      v-if="!individualPerson"
                      cols="12"
                      lg="6"
                    >
                      <label for="">Доверенность или Приказ</label>
                      <v-file-input
                        v-model="attorney"
                        color="#32a1af"
                        solo
                        flat
                        multiple
                        :rules="fileRules"
                        clearable
                        placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <label for="">Свидетельство о государственной регистрации</label>
                      <v-file-input
                        v-model="registrationCertificate"
                        color="#32a1af"
                        solo
                        flat
                        multiple
                        :rules="fileRules"
                        placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                      />
                    </v-col>

                    <v-col
                      v-if="!individualPerson"
                      cols="12"
                      lg="6"
                    >
                      <label for="">Устав (4 стр.)</label>
                      <v-file-input
                        v-model="charter"
                        color="#32a1af"
                        solo
                        flat
                        multiple
                        :rules="fileRules"
                        placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                      />
                    </v-col>

                    <v-col
                      v-if="license"
                      cols="12"
                      lg="6"
                    >
                      <label for="">Лицензия таможенного агентства</label>
                      <v-file-input
                        v-model="customsAgentCertificate"
                        color="#32a1af"
                        solo
                        flat
                        multiple
                        :rules="fileRules"
                        placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                      />
                    </v-col>





                    <v-col
                      cols="12"
                      lg="12"
                      class="text-center mt-8"
                    >
                      <v-btn
                        color="#333"
                        dark
                        x-large
                        class="mr-15"
                        @click="activeStep-=1"
                      >
                        Назад
                      </v-btn>
                      <v-btn
                        color="#333"
                        dark
                        x-large
                        :loading="load"
                        @click="submit"
                      >
                        Зарегистрироваться
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>
            </keep-alive>


            <v-dialog
              v-model="dialog"
              width="500"
            >
              <v-card>
                <v-toolbar
                  color="#333"
                  dark
                >
                  <div class="text-h5">
                    Публичный договор
                  </div>
                </v-toolbar>
                <v-card-text class="pa-6">
                  Менеджер отдела продаж проверит Ваши данные. В случае недостоверности данных Ваша организация будет заблокирована.
                  <br><br>
                  <a
                    :href="`${baseUrl}division/getOfferAgreement`"
                    target="_blank"
                  >
                    Ознакомиться с Публичным договором
                  </a>
                  <br><br>
                  <v-form ref="step4">
                    <v-checkbox
                      v-model="agreeWith"
                      :rules="checkRules"
                      label="Согласен с условиями договора, подтверждаю, что имею полномочия "
                      color="#333"
                      hide-details
                    />
                  </v-form>
                </v-card-text>


                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="#333"
                    dark
                    @click="dialog = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                    color="#5CB7B1"
                    dark
                    :loading="load"
                    @click="agreeWithConditions"
                  >
                    Ок
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="red"
    >
      Ошибка, данная организация уже зарегистрирована!
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>


<script>
import axios from 'axios'
import {AUTH} from '@/http-routes';
import {mapGetters} from 'vuex'

export default {
  data:()=> ({
    baseUrl: '',
    userDivisionId: null,
    steps: ['УНП', 'Наименование и Юр. адрес', 'Подтверждение'],
    activeStep: 0,
    taxPayerNum: '',
    divisionName: '',
    countryName: '',
    postalIndex: '',
    fullAddress: '',
    custAgentDocNum: '',
    license: false,
    foreignDivivsion: false,
    individualPerson: false,
    attorney: [],
    registrationCertificate: [],
    charter: [],
    customsAgentCertificate: [],
    savedInfo:  {},
    // rules
    unpRules: [
      v => v.length == 9 || 'Некорректный номер УНП'
    ],
    inputRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
    checkRules: [
      v => !!v || 'Необходимо дать согласие'
    ],
    fileRules: [
      v => !!v.length || 'Поле должно быть заполнено',
      files => !files || !files.some(file => file.size > 5e6) ||
                    'Файл не может превышать размер 5 MB!'
    ],
    licenseRules: [
      v => v.length >= 15 || 'Некорректный номер лицензии'
    ],
    load: false,
    snackbar: false,
    dialog: false,
    agreeWith: false,
    unpErr: ''
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },
  created(){
    this.baseUrl = AUTH
  },

  methods: {
    changeUserPermissions(division_id){
      this.$store.dispatch("auth/setDivision", division_id).then(() => {
        this.$router.replace(`/new-division/${division_id}`)
      })
    },
    agreeWithConditions(){
      if (this.$refs.step4.validate()) {
        this.load = true
        this.dialog = true

        let config = {
          headers: {
            'Authorization': `Bearer ${this.user.jwtToken}`,
          }
        }

        axios.post(`${AUTH}division/offerAgreement?userId=${this.user.user.id}`, this.savedInfo, config)
          .then((res) =>{
            this.load = false
            this.dialog = false
            this.changeUserPermissions(res.data);
          })
          .catch((error) => {
            if(error.response.status == 400){
              this.snackbar = true
            }
            this.load = false
            this.dialog = false
          })

      }
    },
    submit() {
      if (this.$refs.step3.validate()) {
        this.load = true

        var formData = new FormData();

        if(!this.individualPerson){
          for (let index = 0; index < this.attorney.length; index++) {
            formData.append("attorney", this.attorney[index]);
          }
        }
        for (let index = 0; index < this.registrationCertificate.length; index++) {
          formData.append("registrationCertificate", this.registrationCertificate[index]);
        }
        if(!this.individualPerson){
          for (let index = 0; index < this.charter.length; index++) {
            formData.append("charter", this.charter[index]);
          }
        }
        if(this.license){
          for (let index = 0; index < this.customsAgentCertificate.length; index++) {
            formData.append("customsAgentCertificate", this.customsAgentCertificate[index]);
          }
        }

        axios.post(`${AUTH}division/uploadDocuments/${this.taxPayerNum}`, formData, {
          headers: {
            'Authorization': `Bearer ${this.user.jwtToken}`,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res =>{
            console.log(res)
            this.load = false
            this.dialog = true

          })
          .catch(() => {
            this.load = false
          })

      }
    },
    goStep2() {
      if (this.$refs.step1.validate()) {
        this.load = true
        axios.get(`${AUTH}division/fromEgr?taxPayerNum=${this.taxPayerNum}`)
          .then(res => {
            this.load = false
            if (res.status == 204) {
              this.unpErr = 'Некорректный номер УНП'
              return
            }
            this.countryName = res.data.countryName
            this.fullAddress = res.data.fullAddress
            this.divisionName = res.data.divisionName
            this.postalIndex = res.data.postalIndex
            this.activeStep = 1
          })
          .catch(error => {
            this.load = false
            if(error.response.status == 400){
              this.unpErr = error.response.data
            }else{
              this.activeStep = 1
            }
            // error.response.status == 503
          })


      }
    },
    goStep3() {
      if (this.$refs.step2.validate()) {
        this.load = true
        this.savedInfo = {
          custAgentDocNum: this.license ? this.custAgentDocNum : null,
          divisionName: this.divisionName,
          taxPayerNum: this.taxPayerNum,
          individualPerson: this.individualPerson,
          countryName: this.countryName,
          postalIndex: this.postalIndex,
          fullAddress: this.fullAddress,
          divisionType: this.license ? 'TA' : 'IMP',
          id: null,
          accountNumber: null,
          active: null,
          bankName: null,
          bicBank: null,
          dOff: null,
          dOn: null,
          description: null,
          docDate: null,
          docNumber: null,
          parentId: null,
          updated: null
        }

        this.activeStep = 2
        this.load = false
      }
    },
    changeLicense(){
      if(!this.license){
        this.custAgentDocNum = ''
      }
    },
    actColor(index) {
      if (index == this.activeStep) {
        return '#5CB7B1'
      } else {
        return '#9E9E9E'
      }
    }
  }
}
</script>

<style scoped>
    .v-radio:last-child {
        margin-bottom: 0 !important;
    }
</style>
