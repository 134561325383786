<template>
  <v-sheet
    class="pa-8"
    color="#f4f4f4"
  >
    <v-row v-if="availablePackages.length && checkArchived">
      <v-col
        v-for="(pack, j) in availablePackages"
        :key="j"
        cols="12"
        lg="4"
        :class="{hidden : pack.is_active !== false}"
      >
        <v-card
          elevation="2"
          class="mb-6"
        >
          <v-toolbar
            flat
            :color="pack.remaining_count < 10 || getDateColor(pack.d_off) ? 'red' : 'white'"
            :dark="pack.remaining_count < 10 || getDateColor(pack.d_off) ? true : false"
          >
            <v-card-title
              class="text-body-1 font-weight-medium"
              color="black"
            >
              {{ pack.service_name }} ({{ pack.name }})
            </v-card-title>
          </v-toolbar>
          <v-divider />
          <div class="pa-7">
            <div
              v-if="!pack.is_active"
              class="pause-box"
            />
            <v-row>
              <v-col
                cols="12"
                lg="5"
                class="py-2"
              >
                <div class="text-body-2">
                  Объем пакета
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="7"
                class="py-2"
              >
                <div class="text-body-2 font-weight-bold">
                  {{ pack.period }}
                </div>
              </v-col>

              <v-col
                cols="12"
                lg="5"
                class="py-0 mt-2"
              >
                <div class="text-body-2">
                  Остаток
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="7"
                class="py-0 mt-2"
              >
                <div class="text-body-2 font-weight-bold">
                  {{ pack.remaining_count }}
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="5"
                class="py-0 mt-2"
              >
                <div class="text-body-2">
                  Окончание пакета
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="7"
                class="py-0 mt-2"
              >
                <div class="text-body-2 font-weight-bold">
                  {{ pack.d_off ? formatDate(pack.d_off) : 'Не активирован' }}
                </div>
              </v-col>


              <v-col
                cols="12"
                lg="5"
              >
                <div class="text-body-2">
                  Цена
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="7"
              >
                <div
                  style="color:#5cb7b1;"
                  class="text-h5 font-weight-bold"
                >
                  {{ pack.cost.toLocaleString() }}
                  BYN
                </div>
                <p style="font-size:10px;">
                  {{ pack.cost_for_one }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        lg="12"
        class="text-center"
      >
        <div class="text-body-2 mb-1 grey--text px-10">
          <v-icon color="grey">
            mdi-archive-cancel-outline
          </v-icon><br>
          У вас еще нет архивных услуг или пакетов
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDate from '@/mixins/format-date.js'
import moment from 'moment'

export default {
  mixins: [formatDate],
  computed: {
    ...mapGetters({
      availablePackages: "billing/getAvailablePackages"
      // user: 'auth/getUser',
    }),
    checkArchived(){
      if(!this.availablePackages.length){
        return false
      }else{
        let archived = this.availablePackages.filter(pack =>{
          return pack.is_active === false
        })
        if(archived.length){
          return true
        }else{
          return false
        }
      }
    },
  },
  methods: {
    getDateColor(param){
      var a = moment(new Date, 'DD.MM.YYYY');
      var b = moment(this.formatDate(param), 'DD.MM.YYYY');
      var diffDays = b.diff(a, 'days');

      if(diffDays <= 30){
        return true
      }else {
        return false
      }
    },
  }
}
</script>

<style scoped>
.pause-box{
    background-color: rgba(255, 255, 255, .6);
}
</style>
