const routes = [
  {
    path: "/settings",
    name: "SettingsHomepage",
    component: () => import('@/views/settings/homepage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes
