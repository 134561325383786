<template>
  <v-row>
    <v-col cols="6">
      <main-info />
    </v-col>
    <v-col cols="6">
      <customs-info />
    </v-col>
    <v-col cols="12">
      <vehicles-block />
    </v-col>
    <v-col cols="12">
      <documents-block />
    </v-col>
    <v-col cols="12">
      <transporter />
      <driver />
    </v-col>
    <v-col cols="12">
      <previous-documents-block />
    </v-col>
    <v-col cols="12">
      <common-params />
    </v-col>
    <v-col cols="12">
      <recievers-block />
    </v-col>
  </v-row>
</template>
<script>
import MainInfo from './main-info.vue'
import CustomsInfo from './customs-info.vue'
import VehiclesBlock from './vehicles/vehicles-block.vue'
import DocumentsBlock from './documents/documents-block.vue'
import Transporter from './transporter.vue'
import Driver from './driver.vue'
import PreviousDocumentsBlock from './documents/previous-documents-block.vue'
import CommonParams from './common-params.vue'
import RecieversBlock from './recievers/recievers-block.vue'

export default {
  components:{
    MainInfo,
    CustomsInfo,
    VehiclesBlock,
    DocumentsBlock,
    Transporter,
    Driver,
    PreviousDocumentsBlock,
    RecieversBlock,
    CommonParams

  }
}
</script>
