<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
      md="6"
      sm="6"
    >
      <h2>До обновления</h2>
      <!-- {{docs}} -->
    </v-col>
    <v-col
      cols="12"
      lg="6"
      md="6"
      sm="6"
    >
      <h2>После обновления</h2>
      <!-- {{docs.items}} -->
    </v-col>

    <v-col
      v-for="(item, index) in docs.items"
      :key="index"
      cols="12"
      lg="6"
      md="6"
      sm="6"
      :offset="getOffset(item.update_type)"
      :class="{offDelete : item.update_type === 'delete'}"
    >
      <update-int
        :card-item="item"
        :field="docs.table_description.field_description"
        :title="getTitle(item.update_type)"
        :item-before="item.update_type === 'update_after' ? docs.items[index - 1] : null"
      />
    </v-col>
  </v-row>
</template>


<script>
import UpdateInt from '../nsi/update-int.vue'

export default {
  components: {UpdateInt},
  props: {
    docs: {
      type: Object,
      required: true
    }
  },
  computed: {
    updatedBefore(){
      let arr = []
      this.docs.items.forEach(item =>{
        if(item.update_type === 'update_before'){
          arr.push(item)
        }
      })
      return arr
    },
    updatedAfter(){
      let arr = []
      this.docs.items.forEach(item =>{
        if(item.update_type === 'update_after'){
          arr.push(item)
        }
      })
      return arr
    },
    insert(){
      let arr = []
      this.docs.items.forEach(item =>{
        if(item.update_type === 'insert'){
          arr.push(item)
        }
      })
      return arr
    },
  },
  methods: {
    getOffset(type){
      if(type === 'insert'){
        return 6
      }else{
        return 0
      }
    },
    getTitle(type){
      if(type === 'update_before'){
        return 'Изменение (Было)'
      }else if(type === 'update_after'){
        return 'Изменение (Стало)'
      }else if(type === 'insert'){
        return 'Добавление'
      }else{
        return 'Удаление'
      }
    }
  }
}
</script>
