<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg my-5"
  >
    <v-card-title>Прочие документы</v-card-title>
    <v-col>
      <documents-item />
    </v-col>
  </v-card>
</template>
<script>
import DocumentsItem from './documents-item.vue'
export default {
  components:{
    DocumentsItem
  }
}
</script>
